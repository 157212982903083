import { defineStore } from 'pinia';
import { ref } from 'vue';

interface ConfirmationModalData {
  title: string;
  description: string;
  validBtnText?: string;
  cancelBtnText?: string;
  validAction: () => void;
  cancelAction?: () => void;
  level?: 'warning' | 'confirm' | 'danger';
  hideCancel?: boolean;
}

export const useConfirmModal = defineStore('confirmModal', function () {
  const isConfirmModalVisible = ref(false);
  const confirmModalData = ref({});

  const prompt = (data: ConfirmationModalData) => {
    confirmModalData.value = data;
    isConfirmModalVisible.value = true;
  };

  const close = () => {
    confirmModalData.value = {};
    isConfirmModalVisible.value = false;
  };

  return {
    confirmModalData,
    isConfirmModalVisible,
    prompt,
    close
  };
});
