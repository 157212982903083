import { RetrieveInstitutionOutput } from '@/api/schemas/connector';
import {
  RetrieveAccountOutput,
  RetrieveBalanceOutput
} from '@/api/schemas/fawkes';
import { PaymentAccountOutput } from '@/api/schemas/payments';

export interface BankOrdersCreateAccountSelectionFieldItem {
  isPaymentAccount: boolean;
  providerAccount: PaymentAccountOutput;
  account: RetrieveAccountOutput;
  institution: RetrieveInstitutionOutput;
  balance: RetrieveBalanceOutput;
}

export interface BankOrdersCreateAccountSelectionFieldList {
  paymentAccounts: BankOrdersCreateAccountSelectionFieldItem[];
  connectorAccounts: BankOrdersCreateAccountSelectionFieldItem[];
  ebicsAccounts: BankOrdersCreateAccountSelectionFieldItem[];
  sftpAccounts?: BankOrdersCreateAccountSelectionFieldItem[];
}

export const joinProviderAccountsWithFawkesAccountAndBalanceAndInstitution = (
  providerAccounts: PaymentAccountOutput[],
  accounts: RetrieveAccountOutput[],
  availableBalances: RetrieveBalanceOutput[],
  institutions: RetrieveInstitutionOutput[]
): BankOrdersCreateAccountSelectionFieldList => {
  // According to Laurent, an account is a payment account if the provider account has a provider_id that is 1 or 2

  const paymentAccounts = providerAccounts
    .filter((providerAccount) => providerAccount.connection_method === 'SWAN')
    .map((providerAccount) => {
      const account = accounts.find(
        (account) => account.id === providerAccount.account_id
      );
      const balance = availableBalances.find(
        (availableBalance) =>
          availableBalance.account_id === providerAccount.account_id
      );
      const institution = institutions.find(
        (institution) => institution.id === account?.institution_id
      );
      return {
        isPaymentAccount: true,
        providerAccount,
        account,
        institution,
        balance
      };
    });

  const ebicsAccounts = providerAccounts
    .filter((providerAccount) => providerAccount.connection_method === 'EBICS')
    .map((providerAccount) => {
      const account = accounts.find(
        (account) => account.id === providerAccount.account_id
      );
      const balance = availableBalances.find(
        (availableBalance) =>
          availableBalance.account_id === providerAccount.account_id
      );
      const institution = institutions.find(
        (institution) => institution.id === account?.institution_id
      );
      return {
        isPaymentAccount: false,
        providerAccount,
        account,
        institution,
        balance
      };
    });

  const sftpAccounts = providerAccounts
    .filter((providerAccount) => providerAccount.connection_method === 'SFTP')
    .map((providerAccount) => {
      const account = accounts.find(
        (account) => account.id === providerAccount.account_id
      );
      const balance = availableBalances.find(
        (availableBalance) =>
          availableBalance.account_id === providerAccount.account_id
      );
      const institution = institutions.find(
        (institution) => institution.id === account?.institution_id
      );
      return {
        isPaymentAccount: false,
        providerAccount,
        account,
        institution,
        balance
      };
    });

  const connectorAccounts = accounts
    .filter((acc) => {
      return !providerAccounts.map((pa) => pa.account_id).includes(acc.id);
    })
    .filter((co) => co.is_active)
    .map((providerAccount) => {
      const account = accounts.find(
        (account) => account.id === providerAccount.id
      );
      const balance = availableBalances.find(
        (availableBalance) => availableBalance.account_id === providerAccount.id
      );
      const institution = institutions.find(
        (institution) => institution.id === account?.institution_id
      );
      return {
        isPaymentAccount: false,
        providerAccount,
        account,
        institution,
        balance
      };
    });

  return {
    sftpAccounts,
    paymentAccounts,
    connectorAccounts,
    ebicsAccounts
  };
};
