<template>
  <div class="ds-accounts-count">
    <p class="count">
      {{ count }}
    </p>
    <p class="label">
      {{
        count >= 2
          ? $t('components.DsAccountsCount.accounts')
          : $t('components.DsAccountsCount.account')
      }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'DsAccountsCount',
  props: {
    count: {
      type: Number,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.ds-accounts-count {
  display: inline-flex;
  align-items: baseline;
}

.count {
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  color: $blue500;
}

.label {
  margin-left: 8px;

  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: $dark700;
  letter-spacing: -0.12px;
}
</style>
