<template>
  <div
    class="ds-toast"
    :class="`toast--${type}`"
  >
    <DsIcon
      :name="iconName"
      color="white"
    />
    <div class="message">
      <p>
        {{ computedMessage }}

        <slot />
      </p>
      <p :class="`subtext subtext--${type}`">
        {{ subtext }}
      </p>
    </div>

    <DsIconButton
      v-if="!hidesCloseButton"
      class="hide-button"
      name="cross"
      size="big"
      :color="iconColor"
      @click="$emit('hide')"
    />
  </div>
</template>

<script>
import DsIcon from './DsIcon.vue';
import DsIconButton from './DsIconButton.vue';

export default {
  name: 'DsToast',
  components: {
    DsIcon,
    DsIconButton
  },
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['info', 'warning', 'error', 'success'].includes(value);
      }
    },
    message: {
      type: String,
      default: ''
    },
    subtext: {
      type: String,
      default: ''
    },
    hidesCloseButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ['hide'],
  computed: {
    iconName() {
      switch (this.type) {
        case 'info':
          return 'info';
        case 'warning':
          return 'warning';
        case 'error':
          return 'failure';
        case 'success':
          return 'success';
        default:
          return undefined;
      }
    },
    iconColor() {
      switch (this.type) {
        case 'info':
          return 'blue300';
        case 'warning':
          return 'orange300';
        case 'error':
          return 'red300';
        case 'success':
          return 'green300';
        default:
          return undefined;
      }
    },
    computedMessage() {
      if (this.message === ' ') {
        return '';
      }
      if (this.message?.trim?.()) {
        return this.message;
      }

      switch (this.type) {
        case 'info':
          return 'Info';
        case 'warning':
          return 'Warning';
        case 'error':
          return 'An unknown error has occurred.';
        case 'success':
          return 'Success';
        default:
          return undefined;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ds-toast {
  display: inline-flex;
  align-items: center;

  width: 370px;
  min-height: 44px;
  padding: 12px 16px;

  border-radius: 8px;
  box-shadow: 0 14px 24px rgba(0, 0, 0, 0.15);

  &.toast--info {
    background-color: $blue500;
  }

  &.toast--warning {
    background-color: $orange500;
  }

  &.toast--error {
    background-color: $red500;
  }

  &.toast--success {
    background-color: $green500;
  }
}

.message {
  margin-right: 12px;
  margin-left: 16px;

  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: white;
  letter-spacing: -0.16px;
  white-space: pre-wrap;

  .subtext {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.12px;

    &.subtext--info {
      color: $blue150;
    }

    &.subtext--warning {
      color: $orange150;
    }

    &.subtext--error {
      color: $red150;
    }

    &.subtext--success {
      color: $green150;
    }
  }
}

.hide-button {
  margin-left: auto;

  .body-ds-full-modal-is-opened & {
    display: none;
  }
}
</style>
