<template>
  <div>
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
div {
  display: flex;
  gap: 16px;
  justify-content: space-between;

  padding: 12px 24px;
  padding-bottom: 24px;
}
</style>
