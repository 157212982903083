<template>
  <div
    class="client-tag"
    :class="{
      'client-tag--admin': settingsRole === 'ADMIN',
      'client-tag--owner': settingsRole === 'OWNER',
      'client-tag--default': settingsRole === null,
      'client-tag--clickable':
        clickingOpensInUsersManagement && clientStore.isAdminOrOwner
    }"
    @click="handleClick"
  >
    <DsLoader
      v-if="loading"
      size="small"
      class="loader"
    />
    <template v-else>
      <span class="client-tag__avatar">
        {{ avatarInitials }}
      </span>
      <span class="client-tag__name">
        {{ name }}
      </span>
      <DsIconButton
        v-if="hasRemoveButton"
        name="cross"
        size="12"
        :color="removeButtonColor"
        @click.stop="$emit('onRemove', client)"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import DsIconButton from '@/components/DesignSystem/DsIconButton.vue';
import { ClientModel, SettingsRole } from '@/custom-types/client';
import { RouteNames } from '@/router/routes';
import { useClientStore } from '@/stores/data-models/client';

const props = defineProps<{
  client?: ClientModel;
  settingsRole?: SettingsRole;
  hasRemoveButton?: boolean;
  loading?: boolean;
  nameFormat?: 'FIRST_AND_LAST_INITIAL' | 'FIRST_AND_LAST_NAME';
  clickingOpensInUsersManagement?: boolean;
}>();

defineEmits<{
  (event: 'onRemove', value: ClientModel): void;
}>();

const removeButtonColor = computed(() => {
  if (props.settingsRole === 'ADMIN') {
    return 'blue400';
  }

  if (props.settingsRole === 'OWNER') {
    return 'red400';
  }

  return 'gray400';
});

const avatarInitials = computed(() => {
  if (props.client?.status === 'INVITED') {
    return props.client.email[0]?.toUpperCase() || '';
  }

  return (
    (props.client?.first_name?.toUpperCase() || '')[0] +
    (props.client?.last_name?.toUpperCase() || '')[0]
  );
});

const name = computed(() => {
  if (props.client?.status === 'INVITED') {
    return props.client.email;
  }

  if (props.nameFormat === 'FIRST_AND_LAST_INITIAL') {
    return props.client?.first_name + ' ' + props.client?.last_name?.[0] + '.';
  }

  return props.client?.first_name + ' ' + props.client?.last_name;
});

const router = useRouter();

const clientStore = useClientStore();

const handleClick = function (event: MouseEvent): void {
  if (props.clickingOpensInUsersManagement && clientStore.isAdminOrOwner) {
    event.preventDefault();
    event.stopPropagation();
    router.push({
      name: RouteNames.SETTINGS_USERS_MANAGEMENT,
      query: {
        userId: props.client?.id
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.client-tag {
  display: inline-flex;
  align-items: center;

  min-height: 24px;
  padding-right: 8px;

  background-color: $gray100;
  border-radius: 12px;

  .loader {
    margin-right: 32px;
    margin-left: calc(6px + 32px);
  }

  &__name {
    padding-left: 2px;
    padding-block: 4px;

    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: $gray1000;
    letter-spacing: -0.12px;
    white-space: nowrap;
  }

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    margin: 2px;

    font-size: 8px;
    font-weight: 800;
    line-height: initial;
    color: $gray700;
    letter-spacing: 0.16px;

    background-color: $gray300;
    border-radius: 20px;
  }

  &--admin {
    background-color: $blue100;

    .client-tag__avatar {
      min-width: 20px;
      color: $blue100;
      background-color: $blue600;
    }

    .client-tag__name {
      color: $blue600;
      white-space: nowrap;
    }
  }

  &--owner {
    background-color: $red100;

    .client-tag__avatar {
      color: $red100;
      background-color: $red600;
    }

    .client-tag__name {
      color: $red600;
    }
  }

  &--default {
    background-color: $gray150;

    .client-tag__avatar {
      color: $gray1000;
      background-color: $gray300;
    }

    .client-tag__name {
      color: $gray1000;
    }
  }
}

.client-tag--clickable {
  cursor: pointer;
}
</style>
