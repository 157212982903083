import { Amplify, Auth } from 'aws-amplify';

Amplify.configure({
  // aws_project_region: import.meta.env.VITE_REGION_AWS,
  // aws_cognito_region: import.meta.env.VITE_REGION_AWS,
  // aws_user_pools_id: import.meta.env.VITE_USER_POOL_ID,
  // aws_user_pools_web_client_id: import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID,
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['NAME', 'FAMILY_NAME', 'EMAIL'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS'
    ]
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],

  oauth: {
    domain: import.meta.env.VITE_OAUTH_DOMAIN,
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: import.meta.env.VITE_OAUTH_REDIRECT,
    redirectSignOut: import.meta.env.VITE_OAUTH_REDIRECT,
    clientId: import.meta.env.VITE_OAUTH_CLIENT_ID,
    responseType: 'code' // or 'code', note that REFRESH token will only be generated when the responseType is code
  },

  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID,

    // REQUIRED - Amazon Cognito Region
    region: import.meta.env.VITE_REGION_AWS,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: import.meta.env.VITE_REGION_AWS,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: import.meta.env.VITE_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: true,

    // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
    // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
    // signUpVerificationMethod: 'code', // 'code' | 'link'

    // cognito_username_attributes: ['EMAIL'],

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' }
  },
  API: {
    endpoints: [
      {
        name: 'connector',
        endpoint: import.meta.env.VITE_CONNECTOR_API_BASE_URL,
        region: import.meta.env.VITE_REGION_AWS
      },
      {
        name: 'mystix',
        endpoint: import.meta.env.VITE_MYSTIX_API_BASE_URL,
        region: import.meta.env.VITE_REGION_AWS
      },
      {
        name: 'fawkes',
        endpoint: import.meta.env.VITE_FAWKES_API_BASE_URL,
        region: import.meta.env.VITE_REGION_AWS
      },
      {
        name: 'workflow',
        endpoint: import.meta.env.VITE_WORKFLOW_API_BASE_URL,
        region: import.meta.env.VITE_REGION_AWS
      },
      {
        name: 'bouncer',
        endpoint: import.meta.env.VITE_BOUNCER_API_BASE_URL,
        region: import.meta.env.VITE_REGION_AWS
      },
      {
        name: 'intake',
        endpoint: import.meta.env.VITE_INTAKE_API_BASE_URL,
        region: import.meta.env.VITE_REGION_AWS
      },
      {
        name: 'analytical_axes',
        endpoint: import.meta.env.VITE_FAWKES_API_BASE_URL,
        region: import.meta.env.VITE_REGION_AWS
      },
      {
        name: 'audit_trail',
        endpoint: import.meta.env.VITE_AUDIT_TRAIL_API_BASE_URL,
        region: import.meta.env.VITE_REGION_AWS
      },
      {
        name: 'payments',
        endpoint: import.meta.env.VITE_PAYMENTS_API_BASE_URL,
        region: import.meta.env.VITE_REGION_AWS
      },
      {
        name: 'notification',
        endpoint: import.meta.env.VITE_NOTIFICATION_API_BASE_URL,
        region: import.meta.env.VITE_REGION_AWS
      },
      {
        name: 'counterparty',
        endpoint: import.meta.env.VITE_COUNTERPARTY_API_BASE_URL,
        region: import.meta.env.VITE_REGION_AWS
      },
      {
        name: 'erp',
        endpoint: import.meta.env.VITE_ERP_API_BASE_URL,
        region: import.meta.env.VITE_REGION_AWS
      },
      {
        name: 'approval',
        endpoint: import.meta.env.VITE_APPROVAL_API_BASE_URL,
        region: import.meta.env.VITE_REGION_AWS
      },
      {
        name: 'luigi',
        endpoint: import.meta.env.VITE_LUIGI_API_BASE_URL,
        region: import.meta.env.VITE_REGION_AWS
      }
    ]
  },

  Storage: {
    AWSS3: {
      region: import.meta.env.VITE_REGION_AWS
    },
    customPrefix: {
      public: '',
      protected: '',
      private: ''
    }
  }
});

export default {
  install(app) {
    app.config.globalProperties.$getUserInfo = async function () {
      try {
        const { attributes } = await Auth.currentUserInfo();
        return {
          email: attributes.email,
          firstname: attributes.name,
          lastname: attributes.family_name
        };
      } catch (error) {
        console.error(error);
      }
    };
    app.provide('$getUserInfo', app.config.globalProperties.$getUserInfo);
  }
};
