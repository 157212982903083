<template>
  <Teleport to="body">
    <div
      v-if="!isOutside"
      class="tip"
      :style="{
        left: left + width / 2 + 'px',
        top: bottom - height / 2 + 'px'
      }"
    >
      <div style="position: relative">
        <div
          style="
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
          "
        >
          <slot name="tip" />
        </div>
      </div>
    </div>
  </Teleport>
  <div
    ref="target"
    class="target"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { useElementBounding, useMouseInElement } from '@vueuse/core';
import { ref } from 'vue';

const target = ref(null);

const { x, y, isOutside } = useMouseInElement(target);
// get center bottom of target
const { bottom, left, width, height } = useElementBounding(target);
</script>

<style lang="scss" scoped>
.tip {
  position: fixed;
  z-index: 1000;
  top: 200px;
  left: 200px;
}
</style>
