<template>
  <div
    :class="{
      'ds-tag': true,
      'ds-tag--white': color === DsTagColor.WHITE,
      'ds-tag--disabled': disabled,
      'ds-tag--transparent': color === DsTagColor.TRANSPARENT
    }"
  >
    <DsIcon
      v-if="leftIcon"
      :name="leftIcon"
      :color="iconColor"
      size="small"
    />
    <p class="slot-text">
      <slot />
    </p>
    <DsIconButton
      v-if="actionIcon && !disabled"
      :name="actionIcon"
      :color="iconColor"
      size="small"
      @click.stop.prevent="$emit('action')"
    />
  </div>
</template>

<script lang="ts">
import { COLORS_PALETTE } from '../../helpers/design-system';
import DsIcon from './DsIcon.vue';
import DsIconButton from './DsIconButton.vue';

enum DsTagColor {
  BLUE = 'blue',
  PINK = 'pink',
  GREEN = 'green',
  RED = 'red',
  ORANGE = 'orange',
  CYAN = 'cyan',
  PURPLE = 'purple',
  YELLOW = 'yellow',
  GRAY = 'gray',
  WHITE = 'white',
  BLACK = 'black',
  TRANSPARENT = 'transparent'
}

enum DsTagColorShade {
  DEFAULT = 'DEFAULT',
  PRIMARY = 'PRIMARY',
  DARK = 'DARK'
}

const colorsMap = {
  [DsTagColor.BLUE]: {
    [DsTagColorShade.DEFAULT]: {
      backgroundColor: COLORS_PALETTE.blue100,
      textColor: COLORS_PALETTE.blue600,
      iconColor: COLORS_PALETTE.blue400
    },
    [DsTagColorShade.PRIMARY]: {
      backgroundColor: COLORS_PALETTE.blue500,
      textColor: '#ffffff',
      iconColor: COLORS_PALETTE.blue200
    },
    [DsTagColorShade.DARK]: {
      backgroundColor: COLORS_PALETTE.blue700,
      textColor: COLORS_PALETTE.blue100,
      iconColor: COLORS_PALETTE.blue300
    }
  },
  [DsTagColor.PINK]: {
    [DsTagColorShade.DEFAULT]: {
      backgroundColor: COLORS_PALETTE.pink100,
      textColor: COLORS_PALETTE.pink600,
      iconColor: COLORS_PALETTE.pink400
    },
    [DsTagColorShade.PRIMARY]: {
      backgroundColor: COLORS_PALETTE.pink500,
      textColor: '#ffffff',
      iconColor: COLORS_PALETTE.pink200
    },
    [DsTagColorShade.DARK]: {
      backgroundColor: COLORS_PALETTE.pink700,
      textColor: COLORS_PALETTE.pink100,
      iconColor: COLORS_PALETTE.pink300
    }
  },
  [DsTagColor.GREEN]: {
    [DsTagColorShade.DEFAULT]: {
      backgroundColor: COLORS_PALETTE.green100,
      textColor: COLORS_PALETTE.green600,
      iconColor: COLORS_PALETTE.green400
    },
    [DsTagColorShade.PRIMARY]: {
      backgroundColor: COLORS_PALETTE.green500,
      textColor: '#ffffff',
      iconColor: COLORS_PALETTE.green200
    },
    [DsTagColorShade.DARK]: {
      backgroundColor: COLORS_PALETTE.green700,
      textColor: COLORS_PALETTE.green100,
      iconColor: COLORS_PALETTE.green300
    }
  },
  [DsTagColor.RED]: {
    [DsTagColorShade.DEFAULT]: {
      backgroundColor: COLORS_PALETTE.red100,
      textColor: COLORS_PALETTE.red600,
      iconColor: COLORS_PALETTE.red400
    },
    [DsTagColorShade.PRIMARY]: {
      backgroundColor: COLORS_PALETTE.red500,
      textColor: '#ffffff',
      iconColor: COLORS_PALETTE.red200
    },
    [DsTagColorShade.DARK]: {
      backgroundColor: COLORS_PALETTE.red700,
      textColor: COLORS_PALETTE.red100,
      iconColor: COLORS_PALETTE.red300
    }
  },
  [DsTagColor.ORANGE]: {
    [DsTagColorShade.DEFAULT]: {
      backgroundColor: COLORS_PALETTE.orange150,
      textColor: COLORS_PALETTE.orange600,
      iconColor: COLORS_PALETTE.orange400
    },
    [DsTagColorShade.PRIMARY]: {
      backgroundColor: COLORS_PALETTE.orange500,
      textColor: '#ffffff',
      iconColor: COLORS_PALETTE.orange200
    },
    [DsTagColorShade.DARK]: {
      backgroundColor: COLORS_PALETTE.orange700,
      textColor: COLORS_PALETTE.orange100,
      iconColor: COLORS_PALETTE.orange300
    }
  },
  [DsTagColor.CYAN]: {
    [DsTagColorShade.DEFAULT]: {
      backgroundColor: COLORS_PALETTE.cyan150,
      textColor: COLORS_PALETTE.cyan700,
      iconColor: COLORS_PALETTE.cyan500
    },
    [DsTagColorShade.PRIMARY]: {
      backgroundColor: COLORS_PALETTE.cyan500,
      textColor: '#ffffff',
      iconColor: COLORS_PALETTE.cyan200
    },
    [DsTagColorShade.DARK]: {
      backgroundColor: COLORS_PALETTE.cyan700,
      textColor: COLORS_PALETTE.cyan100,
      iconColor: COLORS_PALETTE.cyan400
    }
  },
  [DsTagColor.PURPLE]: {
    [DsTagColorShade.DEFAULT]: {
      backgroundColor: COLORS_PALETTE.purple100,
      textColor: COLORS_PALETTE.purple600,
      iconColor: COLORS_PALETTE.purple400
    },
    [DsTagColorShade.PRIMARY]: {
      backgroundColor: COLORS_PALETTE.purple500,
      textColor: '#ffffff',
      iconColor: COLORS_PALETTE.purple200
    },
    [DsTagColorShade.DARK]: {
      backgroundColor: COLORS_PALETTE.purple700,
      textColor: COLORS_PALETTE.purple100,
      iconColor: COLORS_PALETTE.purple300
    }
  },
  [DsTagColor.YELLOW]: {
    [DsTagColorShade.DEFAULT]: {
      backgroundColor: COLORS_PALETTE.yellow150,
      textColor: COLORS_PALETTE.yellow800,
      iconColor: COLORS_PALETTE.yellow600
    },
    [DsTagColorShade.PRIMARY]: {
      backgroundColor: COLORS_PALETTE.yellow500,
      textColor: COLORS_PALETTE.yellow900,
      iconColor: COLORS_PALETTE.yellow700
    },
    [DsTagColorShade.DARK]: {
      backgroundColor: COLORS_PALETTE.yellow700,
      textColor: COLORS_PALETTE.yellow100,
      iconColor: COLORS_PALETTE.yellow500
    }
  },
  [DsTagColor.GRAY]: {
    [DsTagColorShade.DEFAULT]: {
      backgroundColor: COLORS_PALETTE.gray100,
      textColor: COLORS_PALETTE.gray1000,
      iconColor: COLORS_PALETTE.gray700
    },
    [DsTagColorShade.PRIMARY]: {
      backgroundColor: COLORS_PALETTE.gray400,
      textColor: '#ffffff',
      iconColor: COLORS_PALETTE.gray200
    },
    [DsTagColorShade.DARK]: {
      backgroundColor: COLORS_PALETTE.gray700,
      textColor: COLORS_PALETTE.gray100,
      iconColor: COLORS_PALETTE.gray400
    }
  },
  [DsTagColor.TRANSPARENT]: {
    [DsTagColorShade.DEFAULT]: {
      backgroundColor: COLORS_PALETTE.gray150,
      textColor: COLORS_PALETTE.gray1000,
      iconColor: COLORS_PALETTE.gray700
    },
    [DsTagColorShade.PRIMARY]: {
      backgroundColor: COLORS_PALETTE.gray400,
      textColor: COLORS_PALETTE.gray1000,
      iconColor: COLORS_PALETTE.gray200
    },
    [DsTagColorShade.DARK]: {
      backgroundColor: COLORS_PALETTE.gray1000,
      textColor: COLORS_PALETTE.gray1000,
      iconColor: COLORS_PALETTE.gray700
    }
  }
};

export default {
  name: 'DsTag',
  components: {
    DsIcon,
    DsIconButton
  },
  props: {
    color: {
      type: String,
      required: true,
      validator(value: DsTagColor) {
        return Object.values(DsTagColor).includes(value);
      }
    },
    leftIcon: {
      type: String,
      default: null
    },
    actionIcon: {
      type: String,
      default: null
    },
    primary: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['action'],
  data() {
    return {
      DsTagColor
    };
  },
  computed: {
    colorShade() {
      if (this.primary) {
        return DsTagColorShade.PRIMARY;
      }
      if (this.dark) {
        return DsTagColorShade.DARK;
      }
      return DsTagColorShade.DEFAULT;
    },
    backgroundColor() {
      if (this.disabled) {
        return COLORS_PALETTE.gray100;
      }
      if (this.color === DsTagColor.WHITE) {
        return '#ffffff';
      }
      if (this.color === DsTagColor.BLACK) {
        return COLORS_PALETTE.gray1000;
      }
      return colorsMap[this.color][this.colorShade].backgroundColor;
    },
    textColor() {
      if (this.disabled) {
        return COLORS_PALETTE.gray500;
      }
      if (this.color === DsTagColor.WHITE) {
        return COLORS_PALETTE.gray1000;
      }
      if (this.color === DsTagColor.BLACK) {
        return '#ffffff';
      }
      return colorsMap[this.color][this.colorShade].textColor;
    },
    iconColor() {
      if (this.disabled) {
        return COLORS_PALETTE.gray400;
      }
      if (this.color === DsTagColor.WHITE) {
        return COLORS_PALETTE.gray700;
      }
      if (this.color === DsTagColor.BLACK) {
        return COLORS_PALETTE.gray400;
      }
      return colorsMap[this.color][this.colorShade].iconColor;
    }
  }
};
</script>

<style lang="scss" scoped>
.ds-tag {
  overflow: hidden;
  display: inline-flex;
  gap: 4px;
  align-items: center;

  width: 100%;
  padding: 4px 8px;

  text-overflow: ellipsis;
  white-space: nowrap;

  background-color: v-bind(backgroundColor);
  border-radius: 12px;

  &.ds-tag--white {
    box-sizing: border-box;
    outline: 1px solid $gray150;
  }

  &.ds-tag--disabled {
    cursor: not-allowed;
    border: none;
  }
  &.ds-tag--transparent {
    background-color: transparent;
    outline: 1px solid v-bind(backgroundColor);
  }
}

.slot-text {
  @include typo-small-body-bold;

  overflow-x: hidden;

  color: v-bind(textColor);
  text-align: center;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
</style>
