export default {
  install(app) {
    const componentFiles: Record<string, any> = import.meta.glob(
      '@/components/DesignSystem/*.vue',
      {
        eager: true
      }
    );

    Object.entries(componentFiles).forEach(([path, module]) => {
      const componentName = path
        .split('/')
        .pop()
        .replace(/\.vue$/, '');

      app.component(componentName, module.default);
    });
  }
};
