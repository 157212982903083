<template>
  <div class="analytical-axis-child-title">
    <div class="analytical-axis-child-title__item">
      <div
        v-if="cannotBeSelected"
        class="analytical-axis-child-title__item__stub"
      ></div>
      <template v-else>
        <DsCheckbox
          v-if="!singleSelect"
          v-model="isTitleChecked"
          style="padding: 4px 8px"
        />
        <DsRadio
          v-if="singleSelect"
          v-model="isSelectedBoxChecked"
          :name="'radio' + node.id.toString()"
          :value="node.id.toString()"
        />
      </template>
      <DsBreadcrumb
        :items="[props.node.name]"
        :color="analyticalAxesStore.getColor(props.node.id)"
        no-pop
      />
    </div>
    <PfIcon
      v-if="!!props.node.nodes.length"
      class="analytical-axis-child-title__chevron"
      :svg="IconChevronUp"
      :size="12"
      :rotate="props.isDeployed ? 0 : 180"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue';

import DsBreadcrumb from '@/components/DesignSystem/DsBreadcrumb.vue';
import DsCheckbox from '@/components/DesignSystem/DsCheckbox.vue';
import DsRadio from '@/components/DesignSystem/DsRadio.vue';
import { IconChevronUp } from '@/components/icons';
import PfIcon from '@/components/NewDesignSystem/PfIcon/PfIcon.vue';
import { AnalyticalAxesChildModel } from '@/custom-types/analytical-axes';
import { useAnalyticalAxesStore } from '@/stores/data-models/analytical-axes';

const props = defineProps<{
  node: AnalyticalAxesChildModel;
  isDeployed?: boolean;
  isChecked?: boolean;
  singleSelect?: boolean;
  filterByErp?: boolean;
}>();
const radioChecked = ref(inject<number>('radioChecked'));

const emit = defineEmits(['update:checkAction']);

const analyticalAxesStore = useAnalyticalAxesStore();

const isTitleChecked = computed({
  get: () => {
    return props.isChecked;
  },
  set: (value) => {
    emit('update:checkAction', value);
  }
});

const isSelectedBoxChecked = computed({
  get: () => {
    if (radioChecked.value !== null) {
      return radioChecked.value.toString();
    }
    return null;
  },
  set: (value) => {
    radioChecked.value = Number(value);
    emit('update:checkAction', !!value);
  }
});

const cannotBeSelected = computed(
  () =>
    props.filterByErp &&
    analyticalAxesStore.disabledAnalyticalAxesArray.includes(props.node.id)
);
</script>

<style lang="scss" scoped>
.analytical-axis-child-title {
  cursor: pointer;

  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;

  margin-top: 4px;
  padding: 8px 8px 8px 4px;

  &:hover {
    background-color: $gray50;
    border-radius: 8px;
  }

  .analytical-axis-child-title__item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    .analytical-axis-child-title__item__stub {
      width: 28px;
      min-width: 28px;
    }

    label {
      cursor: pointer;
      padding: 4px 8px;
      border-radius: 12px;
    }
  }
}
</style>
