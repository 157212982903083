<template>
  <span>
    <slot></slot>
  </span>
</template>

<style lang="scss" scoped>
span {
  @include typo-small-title;

  color: $gray1000;
}
</style>
