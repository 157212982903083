import { useStorage } from '@vueuse/core';
import { Auth } from 'aws-amplify';
import { createRouter, createWebHistory } from 'vue-router';

import routes, {
  notAuthenticatedRouteNames,
  RouteNames
} from '@/router/routes';
import { useRouterStore } from '@/stores/routerStore';
import { useSidebarStore } from '@/stores/sidebar';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

const isDebug = false; // import.meta.env.VITE_IS_MAIN !== 'true'

router.beforeEach(async (to, from) => {
  const routerStore = useRouterStore();
  routerStore.previousVisitedRouteName = from.name;

  const redirectedFromLocalStorage = useStorage(
    'unauthenticated_user_redirected_from',
    null
  );

  const sidebarStore = useSidebarStore();
  sidebarStore.currentRoutePath = to.path;
  if (to.path === from.path) {
    // DO NOT REMOVE, it prevents changing sidebar when query params change
  } else if (to.path.includes('/settings/')) {
    sidebarStore.full = false;
  } else {
    sidebarStore.full = true;
  }

  // Check if the authentication status is stored in localStorage
  const storedAuthenticationStatus = localStorage.getItem('isAuthenticated');
  let isAuthenticated;

  if (isDebug) {
    console.log('storedAuthenticationStatus', storedAuthenticationStatus);
  }

  if (storedAuthenticationStatus !== null) {
    isAuthenticated = JSON.parse(storedAuthenticationStatus);

    if (isDebug) {
      console.log('isAuthenticated', isAuthenticated);
    }
  } else if (!notAuthenticatedRouteNames.includes(to.name?.toString())) {
    // If not found in localStorage, fetch the authentication status
    try {
      const user = await Auth.currentAuthenticatedUser();

      if (isDebug) {
        console.log('user', user);
      }

      isAuthenticated = Boolean(user);
      // Store the authentication status in localStorage
      localStorage.setItem('isAuthenticated', JSON.stringify(isAuthenticated));
    } catch (error) {
      console.error(error);
    }
  }

  if (!isAuthenticated) {
    if (!notAuthenticatedRouteNames.includes(to.name?.toString())) {
      redirectedFromLocalStorage.value = to.fullPath;
      return { name: RouteNames.LOGIN };
    }
  }
});

export default router;
