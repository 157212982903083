import { difference, keyBy } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';

import { getUserRoles } from '@/api/bouncer';
import { getClients } from '@/api/mystix';
import {
  ClientId,
  ClientModel,
  clientRoleDataToUserPermissionsRows,
  ClientWithRoleModel,
  RequestRole
} from '@/custom-types/client';
import { getSessionPayload } from '@/helpers/amplify';
import { parallelize } from '@/helpers/async';

import { useToastStore } from '../toast';

export const useClientsStore = defineStore('clients', () => {
  const toast = useToastStore();

  const clientsArray = ref<ClientModel[]>([]);
  const clientsObjectAxes = reactive({});
  const clientsWithRolesArray = ref<ClientWithRoleModel[]>([]);

  const currentClientId = ref<number>();

  const hasGetClientsBeenCalled = ref(false);

  const isPending = ref(false);

  const callGetClients = async () => {
    try {
      isPending.value = true;

      hasGetClientsBeenCalled.value = true;
      const [getUserRolesRet, getUserRet]: any[] = await parallelize([
        getUserRoles(),
        getClients()
      ]);

      clientsArray.value = getUserRet.clients;
      clientsWithRolesArray.value = clientRoleDataToUserPermissionsRows(
        getUserRolesRet.user_roles,
        clientsArray.value
      );

      const session = await getSessionPayload();

      currentClientId.value = Number.parseInt(session.client_id);
    } catch (error) {
      toast.showError(error);
    } finally {
      isPending.value = false;
    }
  };

  const warnAboutGetClients = () => {
    if (!hasGetClientsBeenCalled.value) {
      let f = console.trace;
      if (import.meta.env.VITE_IS_MAIN === 'true') {
        f = console.warn;
      }
      f(
        "Clients store methods are being used but callGetClients API hasn't been called yet"
      );
    }
  };

  const isFetchMissingClientsPending = ref(false);

  async function fetchMissingClients(clientIds: number[]) {
    try {
      isFetchMissingClientsPending.value = true;

      const existingIds = Object.keys(clientByIdDictionnary.value).map(Number);
      const missingIds = difference(clientIds, existingIds);

      if (missingIds.length > 0) {
        const missingClientsData = await getClients({ client_ids: missingIds });
        clientsArray.value = [
          ...clientsArray.value,
          ...missingClientsData.clients
        ];
      }
    } catch (error) {
      toast.showError(error);
    } finally {
      isFetchMissingClientsPending.value = false;
    }
  }

  const clientByIdDictionnary = computed((): { [id: number]: ClientModel } =>
    keyBy(clientsArray.value, 'id')
  );

  const findClientById = (clientId: number): ClientModel | undefined => {
    warnAboutGetClients();
    return clientByIdDictionnary.value[clientId];
  };

  const updateUserRoleRequests = async (
    userId: ClientId,
    roles: RequestRole[]
  ) => {
    const user = clientsWithRolesArray.value.find((u) => u.user.id === userId);

    if (user) {
      user.workspaceScopes.REQUESTS = roles;
    }
  };

  const currentClient = computed(() => findClientById(currentClientId.value));

  const getClientFullName = (clientId: number): string => {
    const client = findClientById(clientId);
    return client ? `${client.first_name} ${client.last_name}` : undefined;
  };

  return {
    clientsArray,
    clientsObjectAxes,
    currentClientId,
    currentClient,
    clientByIdDictionnary,
    isPending,
    callGetClients,
    findClientById,
    hasGetClientsBeenCalled,
    clientsWithRolesArray,
    fetchMissingClients,
    isFetchMissingClientsPending,
    updateUserRoleRequests,
    getClientFullName
  };
});
