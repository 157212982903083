export const codesList = [
  'PMNT-RCDT-ESCT',
  'PMNT-ICHQ-CCHQ',
  'PMNT-ICCN-ICCT',
  'PMNT-RCCN-ICCT',
  'PMNT-ICDT-ESCT',
  'PMNT-RRCT-ESCT',
  'ACMT-NTAV-NTAV',
  'PMNT-IDDT-ESDD',
  'PMNT-RCDT-DMCT',
  'PMNT-IDDT-UPDD',
  'PMNT-RCHQ-CCHQ',
  'PMNT-RDDT-ESDD',
  'ACMT-MDOP-COMM',
  'PMNT-ICDT-OTHR',
  'ACMT-MCOP-ADJT',
  'ACMT-MDOP-ADJT',
  'ACMT-MDOP-OTHR',
  'PMNT-RCDT-XBCT',
  'PMNT-RCHQ-UPCQ',
  'PMNT-RDDT-BBDD',
  'PMNT-ICDT-DMCT',
  'ACMT-MCOP-INTR',
  'PMNT-RDDT-UPDD',
  'ACMT-MCOP-OTHR',
  'ACMT-MDOP-COMI',
  'PMNT-ICDT-XBCT',
  'PMNT-ICDT-RRTN',
  'LDAS-FTLN-PPAY',
  'PMNT-IRCT-ESCT',
  'LDAS-FTLN-RIMB',
  'LDAS-CSLN-PPAY',
  'ACMT-MDOP-INTR',
  'PMNT-MCOP-OTHR',
  'ACMT-MDOP-COMT'
];
