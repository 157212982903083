<template>
  <div
    class="input-amount"
    :class="{
      'input-amount--disabled': disabled,
      'input-amount--has-error': hasError,
      'input-amount--has-warning': hasWarning
    }"
  >
    <label
      v-if="label"
      class="DS_field-label"
    >
      {{ label }}
      <span
        v-if="required"
        class="required"
      >
        *
      </span>
    </label>
    <input
      v-bind="$attrs"
      ref="inputRef"
      class="input-amount__input"
      type="text"
      :disabled="disabled || readonly"
      :data-test="dataTest"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @keyup="$emit('keyup', $event)"
    />
  </div>
</template>

<script lang="ts" setup>
import { watchDebounced } from '@vueuse/core';
import { watch } from 'vue';
import { CurrencyDisplay, useCurrencyInput } from 'vue-currency-input';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  modelValue: number | null;
  label?: string;
  required?: boolean;
  currency?: string;
  currencyDisplay?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
  dataTest?: string;
}>();

const emit = defineEmits([
  'update:modelValue',
  'change',
  'blur',
  'focus',
  'keyup'
]);

const { locale } = useI18n();

const { inputRef, numberValue, setValue } = useCurrencyInput(
  {
    locale: locale.value,
    currency: props.currency || 'EUR',
    currencyDisplay: props.currencyDisplay
      ? CurrencyDisplay.symbol
      : CurrencyDisplay.hidden,
    precision: 2,
    hideCurrencySymbolOnFocus: true,
    hideGroupingSeparatorOnFocus: false,
    hideNegligibleDecimalDigitsOnFocus: false,
    autoDecimalDigits: false,
    useGrouping: true,
    accountingSign: true
  },
  false
);

watchDebounced(numberValue, (value) => emit('update:modelValue', value), {
  debounce: 350
});

watch(
  () => props.modelValue,
  (value) => {
    setValue(value);
  }
);

const focus = () => {
  inputRef.value?.focus();
};

const blur = () => {
  inputRef.value?.blur();
};

defineExpose({
  focus,
  blur
});
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/field-label.scss';

.input-amount {
  &__input {
    cursor: text;

    display: block;

    width: 100%;
    height: 36px;
    padding: 0 11px;

    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: $gray1000;
    text-align: left;
    letter-spacing: -0.14px;

    background-color: white;
    border: 1px solid $gray100;
    border-radius: 12px;

    &:hover {
      border-color: $gray200;
    }

    &:focus {
      border-color: $blue500;
    }
  }

  &--disabled {
    & .input-amount__input {
      cursor: not-allowed;
      background-color: $gray50;
    }
  }

  &--has-error {
    & .input-amount__input {
      border-color: $red500;
    }
  }

  &--has-warning {
    & .input-amount__input {
      border-color: $orange500;
    }
  }
}

.required {
  color: $red500;
}
</style>
