import { ClientId } from './client';

export type AnalyticalAxesId = number;
export type NodeId = number;

export enum AnalyticalAxisType {
  TEAM = 'TEAM',
  BUSINESS_UNIT = 'BUSINESS_UNIT',
  GEOGRAPHY = 'GEOGRAPHY',
  CATEGORY = 'CATEGORY',
  PROJECT = 'PROJECT',
  PRODUCT_AND_SERVICE = 'PRODUCT_AND_SERVICE',
  COST_CENTER = 'COST_CENTER',
  OTHER = 'OTHER',
  PURCHASE_CATEGORY = 'PURCHASE_CATEGORY'
}

export enum AnalyticalAxisCategory {
  TREASURY = 'TREASURY',
  PURCHASE = 'PURCHASE',
  SALES = 'SALES'
}

export interface AnalyticalAxesChildModel {
  id: NodeId;
  color: string;
  created_at?: string;
  nodes: AnalyticalAxesChildModel[];
  created_by?: number;
  last_updated_by?: number;
  name: string;
  parent_node_id?: number;
  updated_at?: string;
  updated_by?: number;
  owner?: number;
  disabled?: boolean;
  erp_analytical_axis_node_id?: number;
  entity_ids?: number[];

  // FIELDS FOR SEARCH
  expanded?: boolean;
  matched?: boolean;
}
export interface AnalyticalAxesModel {
  analytical_axis_id?: number;
  can_have_members: boolean;
  color: string;
  created_at?: string;
  created_by?: number;
  id: AnalyticalAxesId;
  name: string;
  owner?: number;
  updated_at?: string;
  updated_by?: number;
  nodes: AnalyticalAxesChildModel[];
  last_updated_by?: number;
  axis_type: AnalyticalAxisType;
  max_depth?: number;
  erp_analytical_axis_node_id?: number;
  erp_analytical_axis_type?: string;
  entity_ids?: number[];
  categories: AnalyticalAxisCategory[];
}

export interface AnalyticalAxesFlatNodeModel {
  analytical_axis_id: AnalyticalAxesId;
  color: string;
  created_at?: string;
  created_by?: ClientId;
  id: NodeId;
  name: string;
  updated_at?: string;
  updated_by?: ClientId;
  erp_analytical_axis_node_id?: number;
}

export interface SelectedChildNode {
  id: NodeId;
  name: string;
}
export interface AnalyticalAxisAssocOutput {
  analytical_axis_id: number;
  analytical_axis_node_ids: number[];
}

export interface AnalyticalAxisAssocInput {
  analytical_axis_id: number;
  analytical_axis_node_id: number;
}
