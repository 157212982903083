import { keyBy } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { getAllAnalyticalAxes, getNodesFlat } from '@/api/analyticalAxes';
import { RetrieveNodeOutput } from '@/api/schemas/analyticalAxes';
import { useCallApi } from '@/composables/useCallApi';
import { codesList } from '@/constants/camtTransactionCodesList';
import {
  AnalyticalAxesChildModel,
  AnalyticalAxesId,
  AnalyticalAxesModel,
  AnalyticalAxisCategory,
  AnalyticalAxisType
} from '@/custom-types/analytical-axes';

import { useClientStore } from './client';

export const useAnalyticalAxesStore = defineStore('analyticalAxes', () => {
  const analyticalAxesArray = ref<AnalyticalAxesModel[]>([]);
  const nodesFlatArray = ref<RetrieveNodeOutput[]>([]);
  const refDropDownOpen = ref(null);
  const refDropDownOpenSideBar = ref(null);
  const enumAAWording = ref({});

  const hasGetAnalyticalAxesBeenCalled = ref(false);
  const hasGetFlatNodeBeenCalled = ref(false);
  const hasGetAnalyticalAxesRunning = ref(false);

  const isGetFlatNodesPending = ref(false);

  const clientStore = useClientStore();

  const { isPending, hasError, errorMessage, callApi } = useCallApi(
    async () => {
      hasGetAnalyticalAxesRunning.value = true;
      hasGetAnalyticalAxesBeenCalled.value = true;
      await clientStore.ready();

      const response = await getAllAnalyticalAxes();
      analyticalAxesArray.value = response.analytical_axes_trees;
    }
  );

  const disabledAnalyticalAxesArray = computed<number[]>(() => {
    if (
      nodesFlatArray.value.every((node) => !node.erp_analytical_axis_node_id)
    ) {
      return [];
    }

    const erpMappedAxes = new Set(
      nodesFlatArray.value
        .filter((node) => !!node.erp_analytical_axis_node_id)
        .map((node) => node.analytical_axis_id)
    );

    return nodesFlatArray.value
      .filter(
        (node) =>
          erpMappedAxes.has(node.analytical_axis_id) &&
          !node.erp_analytical_axis_node_id
      )
      .map((node) => node.id);
  });

  const deleteDisabledDescendants = (
    node: AnalyticalAxesModel | AnalyticalAxesChildModel
  ) => {
    if (!node.nodes) {
      return;
    }
    for (let i = 0; i < node.nodes.length; i++) {
      const childNode = node.nodes[i];
      if (!childNode.nodes.length) {
        // delete if no descendants
        if (disabledAnalyticalAxesArray.value.includes(childNode.id)) {
          node.nodes.splice(i, 1);
          i--;
        }
      } else {
        deleteDisabledDescendants(childNode);
        // delete if no descendants after the recursion
        if (
          !childNode.nodes.length &&
          disabledAnalyticalAxesArray.value.includes(childNode.id)
        ) {
          node.nodes.splice(i, 1);
          i--;
        }
      }
    }
  };

  const callGetFlatNodes = async () => {
    try {
      hasGetFlatNodeBeenCalled.value = true;
      isGetFlatNodesPending.value = true;

      await clientStore.ready();
      const data = await getNodesFlat();
      if (!!data && !!data.analytical_axis_nodes) {
        nodesFlatArray.value = data.analytical_axis_nodes;
      }
    } catch (error) {
      console.error(error);
    } finally {
      isGetFlatNodesPending.value = false;
    }
  };

  const getColor = (id) => {
    let color = '#ffffff';
    const nodeInfo = nodesFlatArray.value?.find((elt) => elt.id === id);
    if (nodeInfo) {
      color = analyticalAxesArray.value?.find(
        (elt) => elt.id === nodeInfo.analytical_axis_id
      )?.color;
    }

    return color;
  };

  const getNameAxisNode = (nodeId) => {
    let res = '';
    const node = nodesFlatArray.value?.find((elt) => elt.id === nodeId);

    if (node) {
      res = node.name;
    }

    return res;
  };

  const callGetAnalyticalAxis = callApi;

  const camtTransactionCodes = ref(codesList);

  const analyticalAxesByIdDictionnary = computed(
    (): { [id: number]: AnalyticalAxesModel } =>
      keyBy(analyticalAxesArray.value, 'id')
  );

  const findAnalyticalAxisById = (
    analyticalAxisId: AnalyticalAxesId
  ): AnalyticalAxesModel | undefined => {
    return analyticalAxesByIdDictionnary.value[analyticalAxisId];
  };

  const getNodeName = (nodeId: number) =>
    nodesFlatArray.value.find((elt) => elt.id === nodeId)?.name ?? '';

  const getAxisName = (axisId: number) =>
    analyticalAxesArray.value.find((elt) => elt.id === axisId)?.name;

  const getIconByAxisType = (type: AnalyticalAxisType) => {
    switch (type) {
      case AnalyticalAxisType.TEAM:
        return 'team';
      case AnalyticalAxisType.GEOGRAPHY:
        return 'earth';
      case AnalyticalAxisType.COST_CENTER:
        return 'pin';
      case AnalyticalAxisType.CATEGORY:
        return 'category';
      default:
        return 'axis';
    }
  };

  const filterNodesByEntity = (nodes, allowedEntities) => {
    if (!nodes) return [];
    if (!allowedEntities) return nodes;
    const allowedEntitiesSet = new Set(allowedEntities);

    return nodes
      .filter(
        (n) =>
          !n.entity_ids?.length ||
          n.entity_ids?.some((element) => allowedEntitiesSet.has(element))
      )
      .map((n) => ({
        ...n,
        nodes: filterNodesByEntity(n.nodes, allowedEntities)
      }));
  };

  const analyticalAxesArrayOnlyProc = computed(() =>
    analyticalAxesArray.value.filter(
      (i) =>
        i.categories.includes(AnalyticalAxisCategory.PURCHASE) &&
        i.categories.length > 0
    )
  );

  const analyticalAxesArrayOnlyTreasury = computed(() =>
    analyticalAxesArray.value.filter(
      (i) =>
        i.categories.includes(AnalyticalAxisCategory.TREASURY) &&
        i.categories.length > 0
    )
  );

  // use the result as PfTreeViewSelectV2's expand-only-ids prop
  const getIdsToAllowLeafsOnly = (
    node: AnalyticalAxesModel | AnalyticalAxesChildModel
  ) => {
    const expandOnlyIds = [];

    if (node.nodes?.length > 0) {
      expandOnlyIds.push(node.id);

      node.nodes.forEach((n) =>
        expandOnlyIds.push(...getIdsToAllowLeafsOnly(n))
      );
    }

    return expandOnlyIds;
  };

  return {
    hasGetAnalyticalAxesBeenCalled,
    analyticalAxesArray,
    analyticalAxesArrayOnlyProc,
    analyticalAxesArrayOnlyTreasury,
    isPending,
    hasError,
    errorMessage,
    callApi,
    callGetAnalyticalAxis,
    callGetFlatNodes,
    isGetFlatNodesPending,
    nodesFlatArray,
    refDropDownOpen,
    refDropDownOpenSideBar,
    getColor,
    getIconByAxisType,
    enumAAWording,
    camtTransactionCodes,
    getNameAxisNode,
    hasGetAnalyticalAxesRunning,
    analyticalAxesByIdDictionnary,
    findAnalyticalAxisById,
    hasGetFlatNodeBeenCalled,
    getNodeName,
    getAxisName,
    disabledAnalyticalAxesArray,
    deleteDisabledDescendants,
    filterNodesByEntity,
    getIdsToAllowLeafsOnly
  };
});
