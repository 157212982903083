import { Auth, Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import { AWSDocumentWithFile } from '@/custom-types/storage';
import { getSessionPayload, getTenantId } from '@/helpers/amplify';

import { AWSDocument } from './schemas/common';

export const uploadFiles = async (
  selectedFiles: FileList | File[],
  bucket: string,
  onProgress: (progress: number, file?: File) => void,
  parentPath?: string,
  retry: boolean = true
): Promise<AWSDocumentWithFile[]> => {
  try {
    // this is what you need to do to get the progress in the component
    // async uploadWithProgress() {
    //   const files = // ...
    //   const results = await uploadFiles(files, (progress) => {
    //     this.progress = progress;
    //   });
    //   // ...
    // },

    const mode = import.meta.env.VITE_STORAGE_MODE || 'development';

    await Auth.currentAuthenticatedUser();
    const tenantId = await getTenantId();
    const session = await getSessionPayload();
    const randomHash = uuidv4();
    const results = [];
    // Upload each file to S3
    for (let i = 0; i < selectedFiles.length; i++) {
      const selectedFile = selectedFiles[i];
      const parent = parentPath || session.client_id;
      const result = await Storage.put(
        `${mode}/${tenantId}/${parent}/${randomHash}/${selectedFile.name}`,
        selectedFile,
        {
          bucket,
          contentType: selectedFile.type,
          progressCallback(progress) {
            onProgress((progress.loaded / progress.total) * 100, selectedFile);
          }
        }
      );
      results.push({
        key: result.key,
        bucket,
        file: selectedFile
      });
    }

    return results;
  } catch (error) {
    if (error.message === 'ExpiredToken' && retry) {
      console.log('Token expired, refreshing...');
      await Auth.currentAuthenticatedUser();
      // refresh token
      await Auth.currentSession();
      // Retry the upload
      return uploadFiles(selectedFiles, bucket, onProgress, parentPath, false);
    } else {
      console.error('Upload failed', error);
      throw error;
    }
  }
};

export const uploadFilesNonAuth = async (
  selectedFiles: FileList | File[],
  forcedTenantId: number,
  onProgress: (progress: number, file?: File) => void = (progress) => {
    console.log(progress + '%');
  }
): Promise<AWSDocumentWithFile[]> => {
  try {
    const mode = import.meta.env.VITE_STORAGE_MODE || 'development';

    const bucket = 'payflows-unauthenticated';

    const randomHash = uuidv4();
    const results = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const selectedFile = selectedFiles[i];
      const result = await Storage.put(
        `${mode}/${forcedTenantId}/${randomHash}/${selectedFile.name}`,
        selectedFile,
        {
          bucket,
          contentType: selectedFile.type,
          progressCallback(progress) {
            onProgress((progress.loaded / progress.total) * 100, selectedFile);
          }
        }
      );
      results.push({
        key: result.key,
        bucket,
        file: selectedFile
      });
    }

    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteFile = async (storageObject: AWSDocument) => {
  return Storage.remove(storageObject.key, {
    bucket: storageObject.bucket
  });
};

export const getFile = async (storageObject: AWSDocument, download = true) => {
  return Storage.get(storageObject.key, {
    bucket: storageObject.bucket,
    download
  });
};

export const mimeIcon = (file: AWSDocumentWithFile | string) => {
  if (!file) {
    return 'document';
  }
  const mimeType =
    typeof file === 'string'
      ? file
      : file.file?.type || file.key?.split('.').pop();
  const spl = (mimeType || '').split('/') || [];
  switch (spl[0] || mimeType) {
    case 'image':
      return 'image';
    case 'video':
      return 'video';
  }
  return 'document';
};

export const getFileName = (file: AWSDocument) => file?.key?.split('/').pop();
