<template>
  <span
    class="ds-neo-tag"
    :class="{ dark, 'raw-color': !!rawColor }"
    :style="{ backgroundColor: color, color: textColor }"
  >
    <DsIcon
      v-if="icon"
      :name="icon"
      size="small"
      :color="iconColor"
    />
    <slot />
    <DsIcon
      v-if="actionIcon"
      :name="actionIcon"
      size="small"
      :color="iconColor"
      @click.stop.prevent="$emit('action')"
    />
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { COLORS_PALETTE } from '@/helpers/design-system';

const props = defineProps<{
  color?: string;
  rawColor?: string;
  secondary?: boolean;
  dark?: boolean;
  icon?: string;
  actionIcon?: string;
}>();

const emit = defineEmits<{
  (e: 'action'): void;
}>();

const color = computed(() => {
  if (props.color) {
    return COLORS_PALETTE[
      props.color + (props.dark ? '700' : props.secondary ? '100' : '500')
    ];
  }
  return props.rawColor;
});

const iconColor = computed(() => {
  if (props.secondary && props.color === 'gray') {
    return 'gray700';
  }
  if (props.color) {
    return COLORS_PALETTE[props.color + '400'];
  }
  return textColor.value;
});

const textColor = computed(() => {
  if (props.dark) {
    return 'rgba(255, 255, 255, 0.9)';
  }
  if (props.secondary && props.color === 'gray') {
    return 'black';
  }
  if (props.secondary) {
    return COLORS_PALETTE[props.color + '600'];
  }
  const r = parseInt(color.value.substring(1, 3), 16);
  const g = parseInt(color.value.substring(3, 5), 16);
  const b = parseInt(color.value.substring(5, 7), 16);
  if (r * 0.299 + g * 0.587 + b * 0.114 > 186) {
    return 'black';
  }
  return 'white';
});
</script>

<style scoped lang="scss">
.ds-neo-tag {
  display: inline-flex;
  gap: 4px;
  align-items: center;
  justify-content: center;

  padding: 4px 8px;

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.12px;

  border-radius: 12px;
  &.raw-color.dark {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background: rgba(0, 0, 0, 0.2);
    }
    & > * {
      z-index: 0;
    }
  }
}
</style>
