<template>
  <div
    :class="{
      'ds-autocomplete': true,
      'ds-autocomplete--open': isDropdownShown
    }"
  >
    <label
      v-if="label"
      class="DS_field-label"
      @click="focusAutocompleteInput"
    >
      {{ label }}
      <span
        v-if="required"
        class="label-asterisk"
        >{{ '*' }}</span
      >
    </label>
    <div
      class="autocomplete-container"
      :class="{
        focus: isDropdownShown,
        disabled: disabled
      }"
      @click="focusAutocompleteInput"
    >
      <input
        ref="autocompleteInput"
        v-model.trim="inputValue"
        type="text"
        class="autocomplete-input"
        :style="{
          'padding-left': hasIcon || selectedOptionImgSrc ? '40px' : '12px'
        }"
        :placeholder="placeholder"
        :disabled="isDisabled"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keydown.stop="onKeydown"
      />
      <img
        v-if="selectedOptionImgSrc"
        :src="selectedOptionImgSrc"
        class="autocomplete-selected-option-img"
        width="20"
        height="20"
      />
      <DsAmount
        v-if="
          selectedOption &&
          selectedOption.balance &&
          selectedOption.wording === inputValue
        "
        style="margin-right: 60px"
        colored
        :amount="selectedOption.balance.amount"
        :currency="selectedOption.balance.currency"
      />
      <div
        v-else-if="hasIcon"
        class="autocomplete-slot-container"
        @click="focusAutocompleteInput"
      >
        <slot />
      </div>

      <button
        v-if="modelValue !== null && !noCross"
        class="autocomplete-cross-button"
        type="button"
        :disabled="isDisabled"
        @click.stop="onClickOnCrossButton"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.52876 3.52851C3.78911 3.26816 4.21122 3.26816 4.47157 3.52851L8.00016 7.05711L11.5288 3.52851C11.7891 3.26816 12.2112 3.26816 12.4716 3.52851C12.7319 3.78886 12.7319 4.21097 12.4716 4.47132L8.94297 7.99992L12.4716 11.5285C12.7319 11.7889 12.7319 12.211 12.4716 12.4713C12.2112 12.7317 11.7891 12.7317 11.5288 12.4713L8.00016 8.94273L4.47157 12.4713C4.21122 12.7317 3.78911 12.7317 3.52876 12.4713C3.26841 12.211 3.26841 11.7889 3.52876 11.5285L7.05735 7.99992L3.52876 4.47132C3.26841 4.21097 3.26841 3.78886 3.52876 3.52851Z"
            fill="#55596d"
          />
        </svg>
      </button>

      <DsIcon
        class="autocomplete-chevron-icon"
        :name="isDropdownShown ? 'chevron-up' : 'chevron-down'"
        color="gray500"
        size="small"
        @click="focusAutocompleteInput"
      />
      <div
        v-show="isDropdownShown"
        ref="autocompleteDropdown"
        :class="{
          'autocomplete-dropdown': true,
          'autocomplete-dropdown--upward': upward
        }"
      >
        <div
          v-for="(option, index) in filteredOptions"
          :key="`option.value-${index}`"
          :class="{
            'autocomplete-option': true,
            'autocomplete-option--is-selected': option.value === modelValue,
            'autocomplete-option--is-focused': index === focusedOptionIndex,
            'autocomplete-option--is-disabled': option.disabled,
            'autocomplete-option--is-indented': option.indent
          }"
          @mousedown.prevent.stop="
            option.disabled ? null : onMousedownOnOption(option.value, option)
          "
        >
          <img
            v-if="option.imgSrc"
            :src="option.imgSrc"
            width="20"
            height="20"
          />
          <DsIcon
            v-else-if="option.icon"
            :name="option.icon"
            :color="option.color || 'gray500'"
            style="margin-right: 8px"
          />
          <span>
            {{ option.wording }}
          </span>
          <DsAmount
            v-if="option.balance"
            style="margin-left: auto"
            :amount="option.balance.amount"
            :currency="option.balance.currency"
            colored
          />
        </div>
        <p v-show="filteredOptions.length === 0">
          <span
            v-if="hasCustomNoResult"
            @mousedown.prevent.stop="$emit('onCustomNoResultClicked')"
          >
            <slot name="no-result" />
          </span>

          <span
            v-else
            class="autocomplete-no-results"
          >
            {{ $t('common.no-results') }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { matchSorter } from 'match-sorter';
import { computed, nextTick, PropType, ref, watch } from 'vue';

import { IDropdownOption } from '@/custom-types/dropdown';

import DsAmount from './DsAmount.vue';
import DsIcon from './DsIcon.vue';

const props = defineProps({
  modelValue: { type: [String, Number], default: null },
  options: { type: Array as PropType<IDropdownOption[]>, required: true },
  required: { type: Boolean, default: false },
  label: { type: String, default: undefined },
  placeholder: { type: String, default: undefined },
  noCross: { type: Boolean, default: false },
  order: { type: String, default: 'ASC' },
  hasIcon: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  hasCustomNoResult: { type: Boolean, default: false },
  upward: { type: Boolean, default: false }
});

const emit = defineEmits([
  'update:modelValue',
  'update:search-query',
  'pickedOption',
  'onCustomNoResultClicked',
  'dropdownShown',
  'pressedEnter'
]);

const inputValue = ref('');
const isDropdownShown = ref(false);
const focusedOptionIndex = ref(null);
const autocompleteInput = ref<HTMLInputElement>();
const autocompleteDropdown = ref<HTMLDivElement>();

const isDisabled = computed(() => props.options.length === 0 || props.disabled);

const firstNonDisabledOptionIndex = computed(() =>
  filteredOptions.value.findIndex((option) => !option.disabled)
);

const lastNonDisabledOptionIndex = computed(() => {
  return (
    filteredOptions.value.length -
    1 -
    filteredOptions.value
      .slice()
      .reverse()
      .findIndex((option) => !option.disabled)
  );
});

const selectedOption = computed(() => {
  if (props.modelValue === null) {
    return null;
  }
  return props.options.find((option) => option.value === props.modelValue);
});

const selectedOptionImgSrc = computed(() => selectedOption.value?.imgSrc);

const filteredOptions = computed(() => {
  const term = inputValue.value.replaceAll(' ', '').replaceAll("'", '');

  const sort = (a, b) => {
    const v1 = a.item ?? a;
    const v2 = b.item ?? b;
    if (v1.value === 'ADD_AND_MANAGE_ACCOUNT_MANUALLY') {
      return 1;
    }
    if (v2.value === 'ADD_AND_MANAGE_ACCOUNT_MANUALLY') {
      return -1;
    }
    return v1.wording < v2.wording ? -1 : 1;
  };

  if (term === '') {
    return props.options.slice().sort(sort);
  }
  if (props.order === 'keepsHierarchy') {
    return props.options
      .filter(
        (o) =>
          o.value === 'ADD_AND_MANAGE_ACCOUNT_MANUALLY' ||
          o.wording.toLowerCase().includes(term.toLowerCase())
      )
      .sort(sort);
  }
  if (props.order === 'ASC') {
    return matchSorter(props.options, term, {
      keys: ['wording'],
      baseSort: sort
    });
  } else {
    return matchSorter(props.options, term, {
      keys: ['wording'],
      baseSort: sort
    });
  }
});

watch(
  () => props.modelValue,
  (value) => {
    if (value === null) {
      inputValue.value = '';
    } else if (selectedOption.value === undefined) {
      console.error(
        Error(
          `Design System: "${value}" is not an existing option value for DsAutocomplete component.`
        ),
        props.options
      );
      emit('update:modelValue', null);
    } else {
      inputValue.value = selectedOption.value.wording;
    }
  },
  { immediate: true }
);

watch(isDropdownShown, () => {
  nextTick(() => {
    emit('dropdownShown');
  });
});

const onInput = (ev) => {
  focusedOptionIndex.value = null;
  isDropdownShown.value = true;
  emit('update:search-query', ev.target.value);
};

const onFocus = () => {
  focusedOptionIndex.value = null;
  isDropdownShown.value = true;
};

const onBlur = () => {
  nextTick(() => {
    isDropdownShown.value = false;

    if (props.modelValue === null) {
      inputValue.value = '';
    } else {
      inputValue.value = selectedOption.value.wording;
    }
  });
};

const onKeydown = (event) => {
  switch (event.code) {
    case 'ArrowDown':
      event.preventDefault();
      handleKeyArrows(false);
      break;
    case 'ArrowUp':
      event.preventDefault();
      handleKeyArrows(true);
      break;
    case 'Enter':
      event.preventDefault();
      handleKeyEnter();
      break;
    case 'Escape':
      event.preventDefault();
      event.stopPropagation();
      handleKeyEscape();
      break;
  }
};

const handleKeyArrows = (isUp = true) => {
  if (!isDropdownShown.value) return;
  if (filteredOptions.value.length === 0) return;
  if (focusedOptionIndex.value === null) {
    focusedOptionIndex.value = firstNonDisabledOptionIndex.value;
    updateDropdownScroll();
    return;
  }
  if (focusedOptionIndex.value === lastNonDisabledOptionIndex.value) {
    focusedOptionIndex.value = firstNonDisabledOptionIndex.value;
    updateDropdownScroll();
    return;
  }

  focusedOptionIndex.value += isUp ? -1 : 1;

  while (filteredOptions.value[focusedOptionIndex.value].disabled) {
    if (focusedOptionIndex.value === lastNonDisabledOptionIndex.value) {
      focusedOptionIndex.value = firstNonDisabledOptionIndex.value;
      updateDropdownScroll();
      return;
    }
    focusedOptionIndex.value += isUp ? -1 : 1;
  }
  updateDropdownScroll();
};

const handleKeyEnter = () => {
  if (isDropdownShown.value && focusedOptionIndex.value !== null) {
    const focusedOption = filteredOptions.value[focusedOptionIndex.value];
    const focusedOptionValue = focusedOption?.value;
    isDropdownShown.value = false;
    if (focusedOptionValue === props.modelValue) {
      inputValue.value = selectedOption.value.wording;
    } else {
      emit('update:modelValue', focusedOptionValue);
      emit('pickedOption', focusedOption);
    }
  }
  emit('pressedEnter');
};

const handleKeyEscape = () => {
  inputValue.value = '';
  emit('update:modelValue', null);
  emit('pickedOption', { value: null });
  focusedOptionIndex.value = null;
  isDropdownShown.value = true;
  updateDropdownScroll();
};

const updateDropdownScroll = () => {
  nextTick(() => {
    if (focusedOptionIndex.value > 2) {
      const optionHeight = (
        autocompleteDropdown.value.firstElementChild as HTMLElement
      ).offsetHeight;
      autocompleteDropdown.value.scrollTop =
        (focusedOptionIndex.value - 2) * optionHeight;
    } else {
      autocompleteDropdown.value.scrollTop = 0;
    }
  });
};

const onMousedownOnOption = (optionValue, option) => {
  isDropdownShown.value = false;
  if (optionValue === props.modelValue) {
    inputValue.value = selectedOption.value.wording;
  } else {
    emit('update:modelValue', optionValue);
    emit('pickedOption', option);
  }
};

const onClickOnCrossButton = () => {
  emit('update:modelValue', null);
  emit('pickedOption', { value: null });
};

const focusAutocompleteInput = () => {
  autocompleteInput.value.focus();
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/field-label.scss';

.ds-autocomplete {
  flex-grow: 1;
}

.autocomplete-container {
  position: relative;

  display: flex;
  align-items: center;

  background-color: white;
  border-radius: 12px;

  &:hover {
    box-shadow: inset 0 0 0 1px $gray200;
  }

  &:focus-within,
  &:focus,
  &:active,
  &:active:focus,
  &.focus {
    outline: 2px solid $blue150;
    box-shadow: inset 0 0 0 1px $blue500;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: $gray50;
  }
}

.autocomplete-input {
  cursor: text;

  display: block;

  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-available;
  width: -ms-fill-available;
  height: 36px;
  padding-top: 8px;
  padding-bottom: 8px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $gray1000;
  text-align: left;
  letter-spacing: -0.14px;

  border-radius: 12px;
  box-shadow: inset 0 0 0 1px #eeeff1;

  &:focus {
    border: 1px solid $blue500;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    border-color: $gray100;
  }

  &:hover:not(:focus) {
    border: 1px solid $gray200;
  }
}

.autocomplete-selected-option-img {
  position: absolute;
  top: 8px;
  left: 12px;
  object-fit: cover;
}

.autocomplete-slot-container {
  cursor: pointer;

  position: absolute;
  bottom: 0;
  left: 0;

  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 36px;
  padding: 8px 10px;

  line-height: initial;

  .autocomplete-input:disabled ~ & {
    cursor: not-allowed;
  }
}

.autocomplete-cross-button {
  cursor: pointer;

  position: absolute;
  top: 50%;
  right: 32px;
  transform: translate(0%, -50%);

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  border-radius: 50%;

  &:disabled {
    cursor: not-allowed;
  }
}

.autocomplete-chevron-icon {
  cursor: pointer;

  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(0%, -50%);

  .autocomplete-input:disabled ~ & {
    cursor: not-allowed;
  }
}

.autocomplete-dropdown {
  position: absolute;
  z-index: 11;
  top: 100%;
  right: 0;
  left: 0;

  overflow-y: auto;

  max-height: 180px;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.2);

  &.autocomplete-dropdown--upward {
    top: unset;
    bottom: calc(100% + 4px);
  }
}

.autocomplete-option {
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;

  padding: 11px 12px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $dark700;
  text-align: left;
  letter-spacing: -0.14px;

  > img {
    margin-right: 8px;
    object-fit: cover;
  }

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.autocomplete-option--is-selected {
    color: $blue500;
  }

  &:hover,
  &.autocomplete-option--is-focused {
    background-color: $blue50;
  }

  &.autocomplete-option--is-disabled {
    cursor: not-allowed;
    color: $gray300;
    opacity: 1;
    background-color: $gray50;
  }

  &.autocomplete-option--is-indented {
    padding-left: 21px;
  }
}

.autocomplete-no-results {
  user-select: none;

  overflow: hidden;

  padding: 11px 12px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $dark700;
  text-align: left;
  text-overflow: ellipsis;
  letter-spacing: -0.14px;
  white-space: nowrap;
}

.label-asterisk {
  color: $red500;
}
</style>
