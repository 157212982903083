import { API } from 'aws-amplify';
import { Ref } from 'vue';

import { getTenantId, getUserJwtToken } from '@/helpers/amplify';
import { InvoicesHeaderTab } from '@/helpers/invoices';

import { AWSDocument, Pagination } from './schemas/common';

export interface GetCounterpartiesItemsInput {
  counterparty_ids: number[];
}

export const getCounterpartiesItems = async function (
  input: GetCounterpartiesItemsInput
) {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.get('intake', `/rest/v2/${tenantId}/counterparties/items`, {
    headers: header,
    queryStringParameters: input
  });
};

export const getItemById = async function (id: number) {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.get('intake', `/rest/v2/${tenantId}/items/${id}`, {
    headers: header
  });
};

export interface GetItemsInput {
  page: number;
  page_size: number;
  name_contains?: string;
  counterparty_ids?: number[];
}

export interface Item {
  erp_item_id: number;
  id: number;
  name: string;
  purchase_analytical_axis_node_id?: number;
  amortization_available: boolean;
  default_auto_receive: boolean;
  active: boolean;
}

export interface GetItemsOutput {
  items: Item[];
  pagination: {
    has_more: boolean;
    next_page: number;
    previous_page: number;
  };
}

export const getItems = async function (
  input: GetItemsInput
): Promise<GetItemsOutput> {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.get('intake', `/rest/v2/${tenantId}/items`, {
    headers: header,
    queryStringParameters: input
  });
};

export interface CreateCounterpartyItemsAssocInput {
  id: number;
  item_ids: number[];
}

export interface CreateCounterpartiesItemsAssocInput {
  counterparties: CreateCounterpartyItemsAssocInput[];
}

export async function addItemsToCounterparty(
  body: CreateCounterpartiesItemsAssocInput
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.put('intake', `/rest/v2/${tenantId}/counterparties/items`, {
    headers,
    body
  });
}

export enum BudgetTemplateFormat {
  CSV = 'CSV',
  XLSX = 'XLSX'
}
export interface PostBudgetCreateTemplateBody {
  max_depth_cost_center: number;
  max_depth_category: number;
  max_depth_team: number;
  format: BudgetTemplateFormat;
}

export interface PostBudgetCreateTemplateOutput {
  bucket: string;
  key: string;
}

export const postBudgetCreateTemplate = async (
  body: PostBudgetCreateTemplateBody
): Promise<PostBudgetCreateTemplateOutput> => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.post('intake', `/rest/v2/${tenantId}/budgets/create-template`, {
    headers: header,
    body
  });
};

export interface PostBudget {
  aws_doc: {
    bucket: string;
    key: string;
  };
  year: number;
  name: string;
  default: boolean;
}

export const postBudget = async (body: PostBudget): Promise<any> => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.post('intake', `/rest/v2/${tenantId}/budgets`, {
    headers: header,
    body
  });
};

export interface GetBudgetsOutputBudget {
  id: number;
  name: string;
  year: number;
  default: boolean;
  aws_doc: {
    bucket: string;
    key: string;
  };
}

export interface GetBudgetsOutput {
  budgets: GetBudgetsOutputBudget[];
}

export const getBudgets = async (): Promise<GetBudgetsOutput> => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };
  const queryStringParameters = JSON.parse(
    JSON.stringify({
      page: 1,
      page_size: 40
    })
  );

  return API.get('intake', `/rest/v2/${tenantId}/budgets`, {
    headers: header,
    queryStringParameters
  });
};

export const deleteBudget = async (budgetId: number) => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.del('intake', `/rest/v2/${tenantId}/budgets/${budgetId}`, {
    headers: header
  });
};

export interface GetBudgetOutputLine {
  cost_center_id: number;
  team_id: number;
  category_id: number;
  amount: number;
}

export interface GetBudgetOutput {
  id: number;
  name: string;
  year: number;
  default: boolean;
  aws_doc: {
    bucket: string;
    key: string;
  };
  lines: GetBudgetOutputLine[];
}

export const getBudget = async (budgetId: number): Promise<GetBudgetOutput> => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.get('intake', `/rest/v2/${tenantId}/budgets/${budgetId}`, {
    headers: header
  });
};

export interface PutBudgetBody {
  name?: string;
  year?: number;
  is_default?: boolean;
}

export const putBudget = async (
  budgetId: number,
  body: PutBudgetBody
): Promise<any> => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.put('intake', `/rest/v2/${tenantId}/budgets/${budgetId}`, {
    headers: header,
    body
  });
};

export enum GetCommitmentTreeQueryPeriod {
  MONTH = 'MONTH',
  QUARTER = 'QUARTER'
}

export interface GetCommitmentTreeQuery {
  budget_id?: number;
  period: GetCommitmentTreeQueryPeriod;
  year?: number;
  currency: string;
}

export interface BudgetCommitmentTreeAmountOutput {
  date: string;
  billed_amount: number;
  committed_amount: number;
  cumulated_committed_amount: number;
  cumulated_submitted_amount: number;
  budget_amount: number;
  percentage: number;
  cumulated_percentage: number;
  cumulated_submitted_percentage: number;
  pending_amount: number;
  cumulated_pending_amount: number;
  credit_amount: number;
  cumulated_credit_amount: number;
  form_instance_ids: number[];
  cumulated_billed_amount: number;
  cumulated_billed_percentage: number;
  cumulated_pending_percentage: number;
  cumulated_credit_percentage: number;
  cumulated_committed_percentage: number;
  submitted_amount: number;
}

export interface BudgetCommitmentTreeNodeOutput {
  analytical_axis_node_id: number;
  items: BudgetCommitmentTreeAmountOutput[];
  year_total: BudgetCommitmentTreeAmountOutput;
  children: BudgetCommitmentTreeNodeOutput[];
}

export interface GetCommitmentTreeOutput {
  currency: string;
  nodes: BudgetCommitmentTreeNodeOutput[];
  total: BudgetCommitmentTreeNodeOutput;
}

export const getCommitmentTree = async (
  query: GetCommitmentTreeQuery
): Promise<GetCommitmentTreeOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('intake', `/rest/v2/${tenantId}/commitment-tree`, {
    headers,
    queryStringParameters
  });
};

enum BudgetPeriod {
  MONTH = 'MONTH',
  QUARTER = 'QUARTER'
}

export enum ExportFormat {
  CSV = 'CSV',
  XLSX = 'XLSX'
}
export interface ExportBudgetCommitmentTreeQuery {
  budget_id?: number;
  year?: number;
  period: GetCommitmentTreeQueryPeriod;
  currency: string;
  format: ExportFormat;
}

export const exportBudgetCommitmentTree = async (
  query: ExportBudgetCommitmentTreeQuery
): Promise<AWSDocument> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('intake', `/rest/v2/${tenantId}/commitment-tree/export`, {
    headers,
    queryStringParameters
  });
};

export enum ExpectedInvoiceSortParam {
  AMOUNT = 'AMOUNT',
  EXPECTED_DATE = 'EXPECTED_DATE',
  PO_ID = 'PO_ID'
}

export enum OrderingMethod {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface GetExpectedInvoicesQuery {
  id_in?: number[] | null;
  form_instance_id_in?: number[] | null;
  ignore_fully_billed: boolean;
  filter_tab?: InvoicesHeaderTab | null;
  sort_param?: ExpectedInvoiceSortParam | null;
  sort_direction?: OrderingMethod | null;
  page: number;
  page_size: number;
}

interface Amount {
  amount: number;
  currency: string;
}

interface ExpectedInvoiceFormOutput {
  form_version_id: number;
  name: string;
}

export interface PurchaseOrderOutput {
  id: number;
  created_by: number;
  form: ExpectedInvoiceFormOutput;
}

export interface ExpectedInvoiceCounterpartyOutput {
  id: number;
  name: string;
  counterparty_payment_id: number;
  iban: string;
}

export interface ExpectedInvoice {
  due_date: string | number | Date;
  id: number;
  expected_date: Date;
  amount: Amount;
  purchase_order: PurchaseOrderOutput;
  counterparty?: ExpectedInvoiceCounterpartyOutput | null;
}

export interface GetExpectedInvoicesOutput {
  expected_invoices: ExpectedInvoice[];
  pagination: Pagination;
}

export const getExpectedInvoices = async (
  query: GetExpectedInvoicesQuery
): Promise<GetExpectedInvoicesOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('intake', `/rest/v2/${tenantId}/expected-invoices`, {
    headers,
    queryStringParameters
  });
};

export const getExpectedInvoice = async (
  id: number
): Promise<ExpectedInvoice> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('intake', `/rest/v2/${tenantId}/expected-invoices/${id}`, {
    headers
  });
};

export interface GetExportErpParamOutput {
  auto_export: boolean;
}

export const getExportErpParam = async (): Promise<GetExportErpParamOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('intake', `/rest/v2/${tenantId}/export-erp-param`, {
    headers
  });
};

export const putExportErpParam = async (
  body: GetExportErpParamOutput
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.put('intake', `/rest/v2/${tenantId}/export-erp-param`, {
    headers,
    body
  });
};

export interface PostInvoicesFileAlreadyExistsBody {
  bucket: string;
  key: string;
}

export interface PostInvoicesFileAlreadyExistsOutput {
  does_already_exist: boolean;
  duplicate_form_instance_ids?: number[];
}

export const postInvoicesFileAlreadyExists = async (
  body: PostInvoicesFileAlreadyExistsBody
): Promise<PostInvoicesFileAlreadyExistsOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'intake',
    `/rest/v2/${tenantId}/invoices/file-already-exists`,
    { headers, body }
  );
};

export interface PostInvoicesNumberAlreadyExistsBody {
  invoice_number: string;
  counterparty_id: number;
}

export interface PostInvoicesNumberAlreadyExistsOutput {
  does_already_exist: boolean;
  duplicate_form_instance_ids?: number[];
}

export const postInvoicesNumberAlreadyExists = async (
  body: PostInvoicesNumberAlreadyExistsBody,
  promiseToCancelRef?: Ref<Promise<any>>
): Promise<PostInvoicesNumberAlreadyExistsOutput> => {
  if (promiseToCancelRef?.value) {
    API.cancel(promiseToCancelRef.value);
  }

  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const promise = API.post(
    'intake',
    `/rest/v2/${tenantId}/invoices/number-already-exists`,
    { headers, body }
  );

  if (promiseToCancelRef) {
    promiseToCancelRef.value = promise;
  }

  return promise;
};

export interface PostVendorCreditNumberAlreadyExistsBody {
  vendor_credit_number: string;
  counterparty_id: number;
}

export const postVendorCreditNumberAlreadyExists = async (
  body: PostVendorCreditNumberAlreadyExistsBody,
  promiseToCancelRef?: Ref<Promise<any>>
): Promise<PostInvoicesNumberAlreadyExistsOutput> => {
  if (promiseToCancelRef?.value) {
    API.cancel(promiseToCancelRef.value);
  }

  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const promise = API.post(
    'intake',
    `/rest/v2/${tenantId}/vendor-credits/number-already-exists`,
    { headers, body }
  );

  if (promiseToCancelRef) {
    promiseToCancelRef.value = promise;
  }

  return promise;
};

export enum AutomaticReceptionPeriod {
  FIRST = 'FIRST',
  LAST = 'LAST'
}

export interface GetIntakeTenantParamOutput {
  auto_export: boolean;
  auto_reject_invoices: boolean;
  hide_po_pdf_amounts: boolean;
  auto_receive_invoice: boolean;
  send_po_pdf_to_vendor: boolean;
  has_erp_connected: boolean;
  allow_invoice_qty_gt_ordered_qty: boolean;
  allow_invoice_qty_gt_received_qty: boolean;
  allow_received_qty_gt_ordered_qty: boolean;
  auto_increase_po_qty_to_match_received_qty: boolean;
  automatic_reception_period: AutomaticReceptionPeriod;
}

export const getIntakeTenantParam =
  async (): Promise<GetIntakeTenantParamOutput> => {
    const tenantId = await getTenantId();
    const headers = { token: await getUserJwtToken() };

    return API.get('intake', `/rest/v2/${tenantId}/tenant-param`, { headers });
  };

export interface PutIntakeTenantParamBody {
  auto_export: boolean;
  auto_reject_invoices: boolean;
}

export const putIntakeTenantParam = async (
  body: PutIntakeTenantParamBody
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.put('intake', `/rest/v2/${tenantId}/tenant-param`, {
    headers,
    body
  });
};

export async function mapItemsWithAnalyticalNodes(mappings) {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.put(
    'intake',
    `/rest/v2/${tenantId}/items/purchase-analytical-axis-nodes`,
    {
      headers: header,
      body: {
        mappings
      }
    }
  );
}

export interface GetDefaultErpTaxItemOutput {
  erp_tax_item: {
    description: string; // "Standard - taux normal" for example
    id: number;
    name: string; // "S-FR" for example
    rate: number; // 20.0 for example
  };
}

export interface GetDefaultErpTaxItemQuery {
  counterparty_id: number;
  entity_id: number;
}

export const getDefaultErpTaxItem = async (
  queryStringParameters: GetDefaultErpTaxItemQuery
): Promise<GetDefaultErpTaxItemOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('intake', `/rest/v2/${tenantId}/default-erp-tax-item`, {
    headers,
    queryStringParameters
  });
};

export const getIntakeDashboard = async () => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('intake', `/rest/v2/${tenantId}/dashboard`, { headers });
};
