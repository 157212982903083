import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import 'resetr.css';

import mitt from 'mitt';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import VueVirtualScroller from 'vue-virtual-scroller';

import App from '@/App.vue';
import storeReset from '@/helpers/resetStore';
import amplify from '@/plugins/amplify';
import designSystem from '@/plugins/design-system';
import eventBus from '@/plugins/event-bus';
import sentry from '@/plugins/sentry';
import toast from '@/plugins/toast';
import router from '@/router';

import { i18n, initI18n } from './i18n';

const app = createApp(App);
const emitter = mitt();

await initI18n();

const store = createPinia();
store.use(storeReset);
app.use(store);
app.use(i18n);
app.use(router);
app.use(amplify);
app.use(sentry);
app.use(designSystem);
app.use(toast);
app.use(eventBus);
app.use(VueVirtualScroller);
app.use(
  VueGtag,
  {
    config: {
      id:
        import.meta.env.VITE_IS_MAIN === 'true'
          ? 'G-0ZRTSG0JPL'
          : 'G-TMLX21ZRK8'
    }
  },
  router
);
app.provide('emitter', emitter);
app.mount('#app');
