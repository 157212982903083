import { AWSDocument, Pagination } from '@/api/schemas/common';
import { ISODateString } from '@/custom-types/common';
import { LanguageCode } from '@/custom-types/languages';

export enum TenantModule {
  TREASURY = 'TREASURY',
  PROCUREMENT = 'PROCUREMENT'
}

export interface RetrieveClientMinimalOutput {
  id: number;
  first_name: string;
  last_name: string;
  entity_id: number;
  entity_ids: number;
  erp_subsidiary_ids: number;
  erp_employee_id: number;
}

export interface RetrieveClientsMinimalOutput {
  clients: RetrieveClientMinimalOutput[];
  pagination: Pagination;
}

export interface AddressInput {
  street: string;
  city: string;
  state: string;
  zip_code: string;
  country_code: string;
}

export interface EntityLegalInformation {
  address?: AddressInput;
  address_merged?: string;
  email?: string;
  phone?: string;
  document_purchase_terms?: AWSDocument;
  document_logo?: AWSDocument;
}

export interface EntityModel {
  id: number;
  name: string;
  registration_number?: string;
  country_code?: string;
  country_name?: string;
  legal_information: EntityLegalInformation;
  erp_subsidiary_id?: number;
  bacs_id?: string;
  created_at: ISODateString;
  updated_at?: ISODateString;
}

export interface GetTenantOutput {
  id: number;
  name: string;
  registration_number?: string;
  commercial_name?: string;
  email_domain: string;
  can_auto_signup: boolean;
  legal_information: EntityLegalInformation;
  logo?: AWSDocument;
  created_at: ISODateString;
  updated_at?: ISODateString;
  okta_enabled?: boolean;
  modules: TenantModule[];
  preferred_language?: LanguageCode;
}

export interface GetGroupOutput {
  id: number;
  details?: string | null;
  name: string;
}

export interface GetGroupListOutput {
  groups: GetGroupOutput[];
}
