<template>
  <DsNeoDropdown
    :label="label"
    :required="required"
    :options="options"
    :loading="counterpartiesStore.isLoading"
    search-box
    :disabled="disabled"
    fixed-width
    :z-index="zIndex"
    :model-value="modelValue"
    @update:search="onSearch"
    @update:model-value="emit('update:modelValue', $event)"
    @more="loadMore"
  >
    <template #item="{ value, wording, icon }">
      <DsIcon
        :name="icon"
        :color="value === -1 ? 'blue400' : 'black'"
      />
      <span :class="{ plus: value === -1 }">
        {{ wording }}
      </span>
    </template>
  </DsNeoDropdown>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import DsNeoDropdown, {
  NeoDropDownOption
} from '@/components/DesignSystem/DsNeoDropdown.vue';
import { useCounterpartiesStore } from '@/stores/counterparties';

const counterpartiesStore = useCounterpartiesStore();
const { t } = useI18n();

const props = defineProps<{
  modelValue?: number;
  disabled?: boolean;
  label?: string;
  required?: boolean;
  allowNew?: boolean;
  zIndex?: number;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', v: number): void;
}>();

onBeforeMount(() => {
  if (!counterpartiesStore.hasAlreadyCalledGetCounterparties) {
    counterpartiesStore.callGetCounterparties();
  }
});

const options = computed(() => {
  const ret: NeoDropDownOption[] =
    counterpartiesStore.arrayOfCounterpartiesActive.map((ctp) => ({
      value: ctp.counterparty.id,
      wording: ctp.counterparty.display_name,
      icon: counterpartiesStore.getCounterpartyIcon(
        ctp?.counterparty?.relationship
      )
    }));
  if (props.allowNew) {
    ret.unshift({
      value: -1,
      wording: t('selectCounterparty.createNewCounterparty'),
      alwaysVisible: true,
      icon: 'plus'
    });
  }
  return ret;
});

const debouncer = ref(null);
const onSearch = (search: string) => {
  clearTimeout(debouncer.value);
  debouncer.value = setTimeout(() => {
    delete counterpartiesStore.counterpartiesActiveInput.counterparty_ids;
    delete counterpartiesStore.counterpartiesActiveInput
      .counterparty_payment_ids;
    if (search) {
      counterpartiesStore.counterpartiesActiveInput.name = search;
    } else {
      delete counterpartiesStore.counterpartiesActiveInput.name;
    }
    counterpartiesStore.counterpartiesActiveInput.page_nb = 1;
    counterpartiesStore.arrayOfCounterpartiesActive = [];
    counterpartiesStore.callGetCounterparties();
  }, 250);
};

const loadMore = () => {
  if (!counterpartiesStore.isLoading && counterpartiesStore.hasMore) {
    counterpartiesStore.counterpartiesActiveInput.page_nb++;
    counterpartiesStore.callGetCounterparties();
  }
};
</script>

<style scoped lang="scss">
.plus {
  color: $blue500;
}
</style>
