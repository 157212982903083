// eslint-disable-next-line
const parallelize = (funcs: (Function | Promise<any>)[]) => {
  return Promise.all(
    funcs.map((f) => {
      if (f instanceof Function) {
        return f();
      }
      return Promise.resolve(f);
    })
  );
};

export { parallelize };
