import { API } from '@aws-amplify/api';

import {
  AnalyticalAxesModel,
  AnalyticalAxisType
} from '@/custom-types/analytical-axes';
import { getTenantId, getUserJwtToken } from '@/helpers/amplify';

import {
  MappingAnalyticalAxisNodeInputs,
  RetrieveNodesOutput
} from './schemas/analyticalAxes';

export async function getAnalyticalAxes() {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.get('analytical_axes', `/rest/v1/${tenantId}/analytical-axes`, {
    headers: header
  });
}

export async function getNodesFlat(): Promise<RetrieveNodesOutput> {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.get(
    'analytical_axes',
    `/rest/v1/${tenantId}/analytical-axes/nodes`,
    {
      headers: header
    }
  );
}

// Tree

interface Node {
  color: string;
  created_at: string;
  created_by: number;
  id: number;
  name: string;
  nodes: Node[];
  owner: any | null; // You can replace 'any' with the actual type if known
  updated_at: string;
  updated_by: number;
}

interface Axis {
  axis_type: string;
  color: string;
  created_at: string;
  created_by: number;
  id: number;
  max_depth: number;
  name: string;
  nodes: Node[];
}
export interface GetAllAnalyticalAxesOutput {
  analytical_axes_trees: AnalyticalAxesModel[];
}

export interface GetAllAnalyticalChildNodesInput {
  owner_id_in: number[];
}

export interface GetAllAnalyticalChildNodesOutput {
  analytical_axis_nodes: Node[];
}

export async function getAllAnalyticalAxes(): Promise<GetAllAnalyticalAxesOutput> {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.get(
    'analytical_axes',
    `/rest/v1/${tenantId}/analytical-axes/trees`,
    {
      headers: header
    }
  );
}

export async function createTree(axeId, body) {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.post(
    'analytical_axes',
    `/rest/v1/${tenantId}/analytical-axes/${axeId}/tree`,
    {
      body,
      headers: header
    }
  );
}

export async function getAnalyticalTreeById(
  axeId
): Promise<AnalyticalAxesModel> {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.get(
    'analytical_axes',
    `/rest/v1/${tenantId}/analytical-axes/${axeId}/tree`,
    {
      headers: header
    }
  );
}

/**
 * Axes
 */

export async function updateAxe(axeId: number, body: any) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.put(
    'analytical_axes',
    `/rest/v1/${tenantId}/analytical-axes/${axeId}`,
    { headers, body }
  );
}

export interface PostAnalyticalAxisBody {
  name: string;
  color?: string;
  axis_type?: AnalyticalAxisType;
  can_have_members?: boolean;
}

export async function createAxe(body: PostAnalyticalAxisBody) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('analytical_axes', `/rest/v1/${tenantId}/analytical-axes`, {
    headers,
    body
  });
}

export async function deleteAxe(axeId: number) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.del(
    'analytical_axes',
    `/rest/v1/${tenantId}/analytical-axes/${axeId}`,
    { headers }
  );
}

/**
 * Nodes
 */

export async function updateChildNode(nodeId: number, body) {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.put(
    'analytical_axes',
    `/rest/v1/${tenantId}/analytical-axes/nodes/${nodeId}`,
    {
      body,
      headers: header
    }
  );
}

export async function createChildNode(body) {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.post(
    'analytical_axes',
    `/rest/v1/${tenantId}/analytical-axes/nodes`,
    {
      body,
      headers: header
    }
  );
}

export async function deleteChildNode(nodeId) {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.del(
    'analytical_axes',
    `/rest/v1/${tenantId}/analytical-axes/nodes/${nodeId}`,
    {
      headers: header
    }
  );
}

export async function getChildNodes(
  queryStringParameters: GetAllAnalyticalChildNodesInput
): Promise<GetAllAnalyticalChildNodesOutput> {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.get(
    'analytical_axes',
    `/rest/v1/${tenantId}/analytical-axes/nodes`,
    {
      headers: header,
      queryStringParameters
    }
  );
}

export async function mapERPWithAnalyticalNodes(
  mappings: MappingAnalyticalAxisNodeInputs
) {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.post(
    'analytical_axes',
    `/rest/v1/${tenantId}/analytical-axis-nodes/erp-mappings`,
    {
      headers: header,
      body: {
        mappings
      }
    }
  );
}

export async function mapERPWithAnalyticalAxis(
  axisId: number,
  type: AnalyticalAxisType
) {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.put(
    'analytical_axes',
    `/rest/v1/${tenantId}/analytical-axes/${axisId}/erp-mapping`,
    {
      headers: header,
      body: {
        erp_analytical_axis_type: type
      }
    }
  );
}
