import { mapActions } from 'pinia';

import { useToastStore } from '@/stores/toast';

const toast = mapActions(useToastStore, ['show']);

export default {
  install(app) {
    app.config.globalProperties.$toast = function (type, message) {
      return toast.show(type, message);
    };
    app.provide('$toast', app.config.globalProperties.$toast);
  }
};
