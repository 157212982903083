<template>
  <DsTextField
    v-model="inputValue"
    :label="label"
    :type="type"
    :placeholder="placeholder"
    :disabled="disabled"
    :required="required"
    :autofocus="autofocus"
    :clearable="clearable"
    :has-error="hasError"
    :error-message="errorMessage"
    :message="message"
    :unit="unit"
    has-icon
    :data-test="dataTest"
    prevent-trim
    @keyup="$emit('keyup', $event)"
    @keydown="$emit('keydown')"
    @cleared="$emit('cleared')"
  >
    <DsIcon
      name="search"
      :color="disabled ? 'gray400' : 'gray400'"
    />
  </DsTextField>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import DsIcon from './DsIcon.vue';
import DsTextField from './DsTextField.vue';

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    label?: string;
    type?: string;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    autofocus?: boolean;
    clearable?: boolean;
    hasError?: boolean;
    errorMessage?: string;
    message?: string;
    unit?: string;
    dataTest?: string;
  }>(),
  {
    modelValue: '',
    label: '',
    type: 'text',
    placeholder: '',
    disabled: false,
    required: false,
    autofocus: false,
    clearable: false,
    hasError: false,
    errorMessage: '',
    message: '',
    unit: '',
    dataTest: undefined
  }
);

const emit = defineEmits(['update:modelValue', 'keyup', 'keydown', 'cleared']);

const inputValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});
</script>
