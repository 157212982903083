<template>
  <div
    v-if="modelValue"
    ref="wrapperRef"
    class="pf-fixed-tooltip"
  >
    {{ modelValue }}

    <svg
      :width="25"
      :height="8"
      :viewBox="'0 0 25 8'"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5 0H0.5C5.51936 0 9.81894 3.0817 11.6099 7.45627C11.7758 7.77912 12.1121 8 12.5 8C12.8879 8 13.2242 7.77912 13.3901 7.45627C15.1811 3.0817 19.4806 0 24.5 0Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

const props = defineProps({
  modelValue: { type: String, default: undefined },
  left: { type: Number, default: 0 },
  top: { type: Number, default: 0 }
});

const wrapperRef = ref<HTMLElement>();

const left = computed(
  () => `${props.left - wrapperRef.value?.getBoundingClientRect().width / 2}px`
);

const top = computed(
  () => `${props.top - wrapperRef.value?.getBoundingClientRect().height - 8}px`
);
</script>

<style lang="scss" scoped>
.pf-fixed-tooltip {
  @include typo-body;

  position: fixed;
  z-index: 101;
  top: v-bind('top');
  left: v-bind('left');

  max-width: 300px;
  padding: 8px 12px;

  color: $gray1000;
  text-align: center;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 6px 12px 0px rgba(20, 23, 37, 0.12);

  > svg {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
