<template>
  <Teleport to="body">
    <div
      v-if="modelValue"
      class="ds-full-modal"
    >
      <div class="modal-container">
        <div
          ref="modalBox"
          class="modal-box"
        >
          <slot />
          <button
            type="button"
            class="close-button"
            @click="closeModal"
          >
            <DsIcon
              name="cross"
              color="dark700"
            />
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import DsIcon from './DsIcon.vue';

export default {
  name: 'DsFullModal',
  components: {
    DsIcon
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue'],
  watch: {
    modelValue(value) {
      if (value === true) {
        window.document.addEventListener('mousedown', this.handleMousedown);
        window.document.body.classList.add('body-ds-full-modal-is-opened');
      } else {
        window.document.removeEventListener('mousedown', this.handleMousedown);
        window.document.body.classList.remove('body-ds-full-modal-is-opened');
      }
    }
  },
  mounted() {
    if (this.modelValue === true) {
      window.document.addEventListener('mousedown', this.handleMousedown);
      window.document.body.classList.add('body-ds-full-modal-is-opened');
    }
  },
  unmounted() {
    window.document.removeEventListener('mousedown', this.handleMousedown);
    window.document.body.classList.remove('body-ds-full-modal-is-opened');
  },
  methods: {
    handleMousedown(event) {
      if (this.$refs.modalBox.contains(event.target) === false) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    }
  }
};
</script>

<style lang="scss" scoped>
.body-ds-full-modal-is-opened {
  overflow: hidden;
}

.ds-full-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow-y: auto;

  background-color: rgba(0, 0, 0, 0.7);
}

.modal-container {
  position: absolute;
  top: 30px;
  right: 30px;
  left: 30px;

  padding-bottom: 30px;
}

.modal-box {
  position: relative;
  min-height: calc(100vh - 2 * 30px);
  background-color: $blue50;
  border-radius: 12px;
}

.close-button {
  position: absolute;
  top: 30px;
  right: 30px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 44px;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
</style>
