<template>
  <div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
div {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $gray600;
  letter-spacing: -0.14px;
  white-space: pre-line;
}
</style>
