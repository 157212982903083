<template>
  <div
    ref="phoneFieldContainerWrapper"
    :class="{
      'phone-field-container-wrapper--disabled': disabled
    }"
  >
    <PfLabel
      :model-value="label"
      :required="required"
      @click="focusPhoneInput"
    />
    <div
      class="phone-field-container"
      :class="{
        'phone-field-container--disabled': disabled
      }"
    >
      <div class="phone-field-box">
        <button
          class="select-country-button"
          type="button"
          @mousedown.prevent="toggleCountriesDropdown"
          @keydown.space="toggleCountriesDropdown"
          @keydown.enter="toggleCountriesDropdown"
        >
          <template v-if="selectedCountryCode">
            <DsFlagIcon
              :country-code="selectedCountryCode"
              size="18"
            />
            <span class="select-country-button-calling-code">
              {{ getCallingCodeFromCountryCode(selectedCountryCode) }}
            </span>
          </template>
          <span
            v-else
            class="select-country-button-empty-flag"
          ></span>
        </button>
        <input
          ref="phoneInput"
          class="phone-field-input"
          :style="`cursor: ${selectedCountryCode ? 'text' : 'pointer'};`"
          type="tel"
          :value="modelValue.inputValue"
          :placeholder="phoneInputPlaceholder"
          :required="required"
          @focus="onFocusOnPhoneInput"
          @input="updateModelValue(($event.target as HTMLInputElement).value)"
          @keydown.exact="onKeydownExact"
          @blur="$emit('blur')"
        />
      </div>
      <div
        v-show="isCountriesDropdownShown"
        class="countries-dropdown"
      >
        <div class="countries-search-container">
          <DsIcon
            class="countries-search-icon"
            name="search"
            color="gray700"
          />
          <input
            ref="searchCountryInput"
            v-model.trim="searchCountryInputValue"
            class="countries-search-input"
            :placeholder="$t('designSystem.phoneField.searchCountry')"
            @input="onInputOnSearchCountryInput"
            @blur="onBlurOnSearchCountryInput"
            @keydown="onKeydownOnSearchCountryInput"
          />
        </div>
        <div
          ref="countriesOptionsContainer"
          class="countries-options-container"
        >
          <div
            v-for="(country, index) in filteredCountries"
            :key="country.code"
            :class="{
              'countries-option': true,
              'countries-option--is-selected':
                country.code === selectedCountryCode,
              'countries-option--is-focused': index === focusedCountryIndex
            }"
            @mousedown.prevent="onMousedownOnCountry(country.code)"
          >
            <DsFlagIcon
              v-if="!isE2E"
              :country-code="country.code"
              size="18"
            />
            <p>({{ country.callingCode }}) {{ country.name }}</p>
          </div>
          <p
            v-show="filteredCountries.length === 0"
            class="countries-no-results"
          >
            {{ $t('designSystem.phoneField.noResults') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import parsePhoneNumber, {
  AsYouType,
  CountryCode,
  getCountries as getSupportedCountryCodes
} from 'libphonenumber-js';
import { matchSorter } from 'match-sorter';
import { computed, nextTick, onMounted, ref } from 'vue';

import { DsPhoneFieldModelValue } from '@/custom-types/phone';

import countries from '../../constants/countries';
import PfLabel from '../NewDesignSystem/PfLabel/PfLabel.vue';
import DsFlagIcon from './DsFlagIcon.vue';
import DsIcon from './DsIcon.vue';

const modelValue = defineModel<DsPhoneFieldModelValue>();

const props = defineProps({
  initialCountryCode: { type: String, default: undefined },
  label: { type: String, default: undefined },
  required: { type: Boolean, default: false },
  autofocus: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false }
});

const emit = defineEmits(['blur']);

const phoneFieldContainerWrapper = ref<HTMLDivElement>();
const phoneInput = ref<HTMLInputElement>();
const searchCountryInput = ref<HTMLInputElement>();
const countriesOptionsContainer = ref<HTMLDivElement>();

const supportedCountryCodes = getSupportedCountryCodes();
const supportedCountries = countries.filter((country) =>
  supportedCountryCodes.includes(country.code as CountryCode)
);

const countriesList = ref(supportedCountries);
const selectedCountryCode = ref(props.initialCountryCode as CountryCode);
const isCountriesDropdownShown = ref(false);
const searchCountryInputValue = ref('');
const focusedCountryIndex = ref(null);

const isE2E = computed(() => !window || !!window?.Cypress);

const phoneInputPlaceholder = computed(() => {
  if (selectedCountryCode.value) {
    return '06 23 45 67 89';
  }
  return 'Select Country';
});

const filteredCountries = computed(() => {
  const term = searchCountryInputValue.value
    .replaceAll(' ', '')
    .replaceAll("'", '');
  if (term === '') {
    return countriesList.value;
  }
  return matchSorter(countriesList.value, term, {
    keys: ['name', 'callingCode']
  });
});

const onKeydownExact = (event) => {
  if (
    event.code === 'Backspace' &&
    !event.target.value &&
    !!selectedCountryCode.value
  ) {
    selectedCountryCode.value = undefined;
    toggleCountriesDropdown();
    event.preventDefault();
  }
};

const updateModelValue = (inputValue: string) => {
  const toEmit: DsPhoneFieldModelValue = {
    inputValue: new AsYouType(selectedCountryCode.value).input(
      inputValue || ''
    ),
    isValid: false
  };

  const phoneNumber = parsePhoneNumber(
    inputValue || '',
    selectedCountryCode.value
  );

  if (phoneNumber) {
    toEmit.isValid = phoneNumber.isValid();
    if (toEmit.isValid === true) {
      toEmit.inputValue = phoneNumber.format('NATIONAL');
      toEmit.national = phoneNumber.format('NATIONAL');
      toEmit.international = phoneNumber.format('INTERNATIONAL');
      toEmit.e164 = phoneNumber.format('E.164');
    }
  }

  modelValue.value = toEmit;
};

const showCountriesDropdown = () => {
  focusedCountryIndex.value = null;
  isCountriesDropdownShown.value = true;
  updateDropdownScroll();
  nextTick(() => {
    searchCountryInput.value.focus();
    phoneFieldContainerWrapper.value.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  });
};

const toggleCountriesDropdown = () => {
  if (isCountriesDropdownShown.value === false) {
    showCountriesDropdown();
  } else {
    isCountriesDropdownShown.value = false;
  }
};

const getCallingCodeFromCountryCode = (countryCode) =>
  countriesList.value.find((country) => country.code === countryCode)
    ?.callingCode;

const getCountryCodeFromCallingCode = (callingCode) =>
  countriesList.value.find((country) => country.callingCode === callingCode)
    ?.code as CountryCode;

const onFocusOnPhoneInput = () => {
  if (!selectedCountryCode.value) {
    showCountriesDropdown();
  }
};

const onInputOnSearchCountryInput = () => {
  focusedCountryIndex.value = null;
};

const onBlurOnSearchCountryInput = () => {
  isCountriesDropdownShown.value = false;
  searchCountryInputValue.value = '';
  if (selectedCountryCode.value) {
    focusPhoneInput();
  }
};

const onKeydownOnSearchCountryInput = (event) => {
  switch (event.code) {
    case 'ArrowDown':
      event.preventDefault();
      handleKeyArrowDown();
      break;
    case 'ArrowUp':
      event.preventDefault();
      handleKeyArrowUp();
      break;
    case 'Enter':
      event.preventDefault();
      handleKeyEnter();
      break;
    case 'Escape':
      event.preventDefault();
      handleKeyEscape();
      break;
  }
};

const handleKeyArrowDown = () => {
  if (filteredCountries.value.length > 0) {
    if (focusedCountryIndex.value === null) {
      focusedCountryIndex.value = 0;
    } else if (focusedCountryIndex.value < filteredCountries.value.length - 1) {
      focusedCountryIndex.value += 1;
    }
    updateDropdownScroll();
  }
};

const handleKeyArrowUp = () => {
  if (filteredCountries.value.length > 0) {
    if (focusedCountryIndex.value === null) {
      focusedCountryIndex.value = 0;
    } else if (focusedCountryIndex.value > 0) {
      focusedCountryIndex.value -= 1;
    }
    updateDropdownScroll();
  }
};

const handleKeyEnter = () => {
  if (focusedCountryIndex.value !== null) {
    isCountriesDropdownShown.value = false;
    selectedCountryCode.value = filteredCountries.value[
      focusedCountryIndex.value
    ].code as CountryCode;
    focusPhoneInput();
  }
};

const handleKeyEscape = () => {
  searchCountryInputValue.value = '';
  focusedCountryIndex.value = null;
  updateDropdownScroll();
};

const updateDropdownScroll = () => {
  nextTick(() => {
    const containerElement = countriesOptionsContainer.value;
    if (focusedCountryIndex.value > 2) {
      const optionHeight = (containerElement.firstElementChild as HTMLElement)
        .offsetHeight;
      containerElement.scrollTop =
        (focusedCountryIndex.value - 2) * optionHeight;
    } else {
      containerElement.scrollTop = 0;
    }
  });
};

const onMousedownOnCountry = (countryCode) => {
  isCountriesDropdownShown.value = false;
  searchCountryInputValue.value = '';
  selectedCountryCode.value = countryCode;
  updateModelValue(modelValue.value.inputValue);
  focusPhoneInput();
};

const focusPhoneInput = () => {
  phoneInput.value.focus();
};

onMounted(() => {
  if (props.autofocus === true) {
    nextTick(focusPhoneInput);
  }

  if (modelValue.value.inputValue) {
    const asYouType = new AsYouType();
    asYouType.input(modelValue.value.inputValue);
    selectedCountryCode.value = asYouType.getCountry();
  }

  updateModelValue(modelValue.value.inputValue);

  nextTick(() => {
    if (modelValue.value.international) {
      const callingCode = modelValue.value.international.split(' ')[0];
      selectedCountryCode.value = getCountryCodeFromCallingCode(callingCode);
    }
  });
});
</script>

<style lang="scss" scoped>
.phone-field-container-wrapper--disabled {
  cursor: not-allowed;
  opacity: 0.75;
}

.phone-field-container {
  position: relative;
  text-align: left;

  &.phone-field-container--disabled {
    pointer-events: none;
  }
}

.phone-field-box {
  @include typo-body;

  display: flex;
  align-items: center;

  width: 100%;
  height: 36px;

  color: $gray1000;

  background-color: white;
  border: 1px solid $gray100;
  border-radius: 12px;

  &:hover {
    border-color: $gray200;
  }

  &:focus-within {
    border-color: $blue500;
    outline: 2px solid $blue150;
  }
}

.select-country-button {
  cursor: pointer;
  user-select: none;

  display: inline-flex;
  flex-shrink: 0;
  align-items: center;

  height: 100%;
  padding-left: 12px;

  border-radius: 3px;
}

.select-country-button-calling-code {
  margin-left: 6px;
}

.select-country-button-empty-flag {
  flex-shrink: 0;

  width: 18px;
  height: 18px;

  background-color: $gray100;
  border: 1px solid $gray200;
  border-radius: 50%;
}

.phone-field-input {
  cursor: text;

  width: 100%;
  height: 100%;
  padding-right: 12px;
  padding-left: 6px;

  outline: none;
}

.countries-dropdown {
  position: absolute;
  z-index: 1;
  top: 36px + 8px;
  right: 0;
  left: 0;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 3px 14px rgba(black, 0.2);
}

.countries-search-container {
  position: relative;
  width: 100%;
  height: 36px;
}

.countries-search-icon {
  position: absolute;
  top: 8px;
  left: 12px;
}

.countries-search-input {
  @include typo-body;

  cursor: text;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  padding-right: 12px;
  padding-left: 12px + 20px + 8px;

  font-weight: 300;
  color: $gray1000;

  outline: none;
  box-shadow: 0 2px 8px rgba($gray1000, 0.08);
}

.countries-options-container {
  overflow-y: auto;
  max-height: 180px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.countries-option {
  @include typo-body;

  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;

  padding: 10px 14px;

  color: $dark1000;

  > p {
    @include text-nowrap;

    margin-left: 8px;
  }

  &.countries-option--is-selected {
    color: $blue500;
  }

  &:hover,
  &.countries-option--is-focused {
    background-color: $blue50;
  }
}

.countries-no-results {
  @include typo-body;
  @include text-nowrap;

  user-select: none;
  padding: 10px 16px;
  color: $dark700;
}
</style>
