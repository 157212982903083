<template>
  <div
    :class="{
      'ds-counter': true,
      'ds-counter-menu-extended': props.menuExtended,
      'ds-counter-active': props.isActive,
      'ds-counter-active-not-extended': props.isActive && !props.menuExtended,
      'ds-counter-active-extended': props.isActive && props.menuExtended
    }"
  >
    <div class="ds-counter-text">
      {{ value || 0 }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  value?: number;
  isActive?: boolean;
  menuExtended?: boolean;
}>();

const textColor = computed(() => {
  return props.isActive ? '#6B81FF' : '#83869A';
});

// when menu is extended:
const borderValue = computed(() => {
  return props.menuExtended ? '2px solid $blue200' : 'none';
});

const boxShadowValue = computed(() => {
  // replace border 2px with box-shadow: inset 0 0 0 2px #D3D4DB (gray200) for default, and #BDC7FF (blue200) when menu item is active
  if (props.menuExtended) {
    if (props.isActive) {
      return 'inset 0 0 0 2px #BDC7FF';
    } else {
      return 'inset 0 0 0 2px #D3D4DB';
    }
  } else {
    return 'none';
  }
});
</script>

<style lang="scss" scoped>
.ds-counter {
  position: absolute;
  // top right when collapsed
  top: 3px;
  right: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 18px;
  height: 18px;
  padding: 4px 2px;

  background-color: $gray150;
  border: v-bind(borderValue);
  border-radius: 50%;
  box-shadow: v-bind(boxShadowValue);

  .ds-counter-text {
    width: 14px;

    font-family: Inter, sans-serif;
    font-size: 8px;
    font-weight: 800;
    line-height: 10px;
    color: v-bind(textColor);
    text-align: center;
    letter-spacing: 0.16px;
  }
}

.ds-counter-menu-extended {
  position: static;
  margin-left: 8px;
  background-color: transparent;
  border: none;
}

.ds-counter-active-not-extended,
.ds-counter-active {
  background-color: $blue150;
}

.ds-counter-active-extended {
  background-color: transparent;
}
</style>
