<template>
  <div
    class="transaction-category-dropdown"
    @click.stop
  >
    <header class="dropdown-header">
      <div
        id="search-input"
        class="search-input"
      >
        <DsAnalyticalAxesList
          :model-value="modelValue"
          editable
          @update:model-value="$emit('update:modelValue', $event)"
        />
        <input
          ref="searchInput"
          v-model.trim="searchInputValue"
          type="search"
          :placeholder="$t('analytical-axes.dropdownSelector.search')"
          @click.stop
        />
      </div>
    </header>
    <div class="dropdown-body">
      <div
        v-for="(axis, index) in filteredAxes"
        :key="'axis-' + index"
        class="accordion-container"
      >
        <div
          class="axis-name-container"
          @click.stop="isExpanded[index] = !isExpanded[index]"
        >
          <label>{{ axis.name?.toUpperCase() }}</label>
          <DsIcon
            class="chevron"
            :name="isExpanded[index] ? 'chevron-up' : 'chevron-down'"
            size="small"
            color="gray600"
          />
        </div>
        <AnalyticalAxisTreeAccordion
          v-show="isExpanded[index]"
          :ref="(elt) => setItemRef(elt, axis.id)"
          :axis-data="axis"
          :axes-checked="[]"
          :expand-all="!!searchInputValue"
          single-select
          @update:analytical-axis-selected="
            (data) => {
              onAxisSelected(data, axis.id, index);
            }
          "
          @on-close-axis="isExpanded[index] = false"
        />
      </div>
      <EmptyState
        v-if="!filteredAxes.length"
        :message="$t('common.no-results')"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { cloneDeep } from 'lodash-es';
import { computed, nextTick, onMounted, ref, watch } from 'vue';

import { AnalyticalAxisAssoc } from '@/api/schemas/common';
import AnalyticalAxisTreeAccordion from '@/components/Transactions/TransactionCategory/AnalyticalAxisTreeAccordion.vue';
import {
  AnalyticalAxesChildModel,
  AnalyticalAxesModel
} from '@/custom-types/analytical-axes';
import { useAnalyticalAxesStore } from '@/stores/data-models/analytical-axes';

import EmptyState from '../Table/EmptyState.vue';
import DsAnalyticalAxesList from './DsAnalyticalAxesList.vue';

const props = defineProps<{
  modelValue: AnalyticalAxisAssoc[];
  showApply?: boolean;
}>();
const analyticalAxesStore = useAnalyticalAxesStore();

const searchInputValue = ref('');
const isExpanded = ref<boolean[]>([]);
const searchInput = ref<HTMLElement>(null);

const emit = defineEmits<{
  'update:modelValue': [modelValue: AnalyticalAxisAssoc[]];
  hide: [void];
}>();

const nodeRefs = ref([]);

const setItemRef = (elt: any, id: number) => {
  const current = nodeRefs.value.find((node) => node.id === id);
  if (!current) {
    nodeRefs.value.push({
      id,
      elt
    });
  }
};

const fillExpandedArray = (allOpened = false) => {
  isExpanded.value = new Array(
    analyticalAxesStore.analyticalAxesArray.length
  ).fill(allOpened);

  isExpanded.value[0] = true;
};

const onAxisSelected = (
  data: { id: number; name: string }[],
  analyticalAxisId: number,
  index: number
) => {
  const res = props.modelValue.filter(
    (x) => x.analytical_axis_id !== analyticalAxisId
  );
  res.push({
    analytical_axis_id: analyticalAxisId,
    analytical_axis_node_ids: [data[0].id]
  });
  emit('update:modelValue', res);

  if (isExpanded.value.length > index + 1) {
    isExpanded.value[index + 1] = true;
  } else {
    emit('hide');
  }
};

const matchTitle = (
  node: AnalyticalAxesModel | AnalyticalAxesChildModel,
  searchText: string
) => {
  const nodes = [];
  const isMatching = node.name.toLowerCase().includes(searchText.toLowerCase());

  if (isMatching) {
    return true;
  }

  node.nodes.forEach((element) => {
    if (matchTitle(element, searchText)) {
      nodes.push(element);
    }
  });

  if (nodes.length > 0) {
    node.nodes = nodes;
    return true;
  }

  return false;
};

const filteredAxes = computed(() => {
  if (searchInputValue.value) {
    const copy = cloneDeep(analyticalAxesStore.analyticalAxesArray);
    return copy.filter((node) => matchTitle(node, searchInputValue.value));
  }

  return analyticalAxesStore.analyticalAxesArray;
});

watch(searchInputValue, () => {
  if (searchInputValue.value) {
    nextTick(() => {
      fillExpandedArray(true);
      nodeRefs.value.forEach((node) => node.elt.deployAll());
    });
  } else {
    nextTick(() => {
      fillExpandedArray();
    });
  }
});

watch(
  () => analyticalAxesStore.analyticalAxesArray,
  () => {
    fillExpandedArray();
  },
  { immediate: true }
);

onMounted(() => {
  searchInput.value.focus();
  isExpanded.value[0] = true;
});
</script>

<style lang="scss" scoped>
.transaction-category-dropdown {
  width: 350px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(20, 23, 37, 0.08);
}

.dropdown-header {
  display: flex;
}

.search-input {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;

  width: 100%;
  margin: 4px;
  padding: 8px 12px 8px 4px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;

  border-radius: 8px;
  outline: none;
  box-shadow: inset 0 0 0 1px $gray100;
  caret-color: $blue500;
  input {
    flex-grow: 1;
    min-width: 56px;
    margin-left: 4px;
    outline: none;
  }
  div {
    cursor: default;
    width: fit-content;
    max-width: 100%;
    & :deep(label) {
      cursor: default;
    }
  }
}

.dropdown-body {
  overflow-y: auto;

  height: 282px;
  padding: 0 4px 4px 4px;

  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.accordion-container {
  padding-bottom: 10px;
  .axis-name-container {
    cursor: pointer;

    position: relative;

    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    width: 100%;
    margin-bottom: 4px;
    padding: 4px 32px 4px 12px;

    background-color: $gray150;
    border-radius: 8px;
    label {
      font-size: 10px;
      font-weight: 600;
      color: $gray700;
      letter-spacing: 0.1px;
    }
    .chevron {
      position: absolute;
      right: 13px;
    }
  }
}
</style>
