<template>
  <DsMultiAutocomplete
    v-if="multiple"
    v-model="computedValue"
    :options="options"
    :label="label"
    :no-selector="noSelector"
    :select-placeholder="$t('components.DsCurrencyField.placeholder.multiple')"
    :search-placeholder="$t('components.DsCurrencyField.placeholder.searchBox')"
    :all-selected-wording="$t('components.DsCurrencyField.allCurrencies')"
  />
  <DsNeoDropdown
    v-else
    v-model="computedValue"
    :options="options"
    :label="label"
    search-box
    only-one
    has-no-result-option
    :disabled="disabled"
    :placeholder="$t('components.DsCurrencyField.placeholder.single')"
    :required="required"
    @hide="emit('hide')"
  >
    <template #no-result-option>
      {{ $t('common.no-results') }}
    </template>
  </DsNeoDropdown>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import CURRENCIES from '../../helpers/currencies.json';
import DsMultiAutocomplete from './DsMultiAutocomplete.vue';
import DsNeoDropdown from './DsNeoDropdown.vue';

const props = defineProps({
  modelValue: { type: [String, Array, null], required: true },
  label: { type: String, default: '' },
  multiple: { type: Boolean, default: false },
  showCurrenciesNames: { type: Boolean, default: false },
  showCurrenciesFlag: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  decimalPlaces: { type: Boolean, default: false },
  onlyEUR: { type: Boolean, default: false },
  noSelector: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false }
});

const emit = defineEmits(['update:modelValue', 'hide']);

const computedValue = computed<any>({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

const options = computed(() => {
  return props.onlyEUR
    ? [
        {
          value: `EUR${props.decimalPlaces ? '/2' : ''}`,
          wording: 'EUR'
        }
      ]
    : CURRENCIES.map((currency) => ({
        value: `${currency.cc}${props.decimalPlaces ? '/2' : ''}`,
        wording: (function (showCurrenciesNames, showCurrenciesFlag) {
          if (showCurrenciesFlag) {
            return currency.symbol + ' ' + currency.cc;
          } else {
            if (showCurrenciesNames) {
              return (
                currency.symbol + ' ' + currency.cc + ' - ' + currency.name
              );
            } else {
              return `${currency.symbol} ${currency.cc}`;
            }
          }
        })(props.showCurrenciesNames, props.showCurrenciesFlag)
      }));
});
</script>
