import { captureException } from '@sentry/browser';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import { ref } from 'vue';

const cleanRequestData = (request) => {
  try {
    const parsedRequest = JSON.parse(request);
    return parsedRequest;
  } catch (err) {
    return request;
  }
};

const getErrorInfo = (error: unknown) => {
  if (!error) return {};

  if (axios.isAxiosError(error)) {
    return {
      message: error.response?.data?.message || error.message,
      request_url: error.config?.url,
      status: error.response?.status,
      request: cleanRequestData(error.response?.config?.data),
      response: error.response?.data || {
        type: error.message
      }
    };
  }

  if (error instanceof Error) {
    return {
      message: error.toString()
    };
  }

  if (error instanceof Event && error.type === 'error') {
    return {
      message: (error.target as Element)?.outerHTML
    };
  }

  if (typeof error === 'object' && error.message) {
    return {
      message: error.message
    };
  }

  return {
    message:
      error && typeof error === 'object' ? error.toString() : 'undefined error'
  };
};

export default {
  install(app) {
    const integrations = [Sentry.browserTracingIntegration()];

    if (import.meta.env.VITE_IS_AMPLIFY === 'true')
      integrations.push(Sentry.replayIntegration());

    app.config.globalProperties.showRefreshModal = ref(false);

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DOMAIN,
      environment:
        import.meta.env.VITE_IS_MAIN === 'true' ? 'production' : 'development',
      integrations,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: [
        'Network Error',
        'Non-Error promise rejection captured' // https://stackoverflow.com/questions/75536442/troubleshooting-non-error-promise-rejection-captured-with-value-object-not-fou
      ],
      beforeSend(event, hint) {
        const originalException: any = hint?.originalException;
        const message = originalException?.message;
        if (
          message?.includes('Failed to fetch dynamically imported module') ||
          message?.includes('error loading dynamically imported module') ||
          message?.includes('Unable to preload CSS') ||
          message?.includes('Importing a module script failed')
        ) {
          app.config.globalProperties.showRefreshModal.value = true;
        }

        console.error(originalException);
        if (import.meta.env.VITE_IS_AMPLIFY !== 'true') return null;

        const detail = getErrorInfo(originalException);

        return {
          ...event,
          message: detail.message,
          contexts: {
            ...event.contexts,
            detail
          }
        };
      }
    });

    app.config.errorHandler = (error, vm, info) => {
      captureException(error, {
        extra: {
          info
        }
      });
    };
  }
};
