<template>
  <span>
    <slot />
  </span>
</template>

<style lang="scss" scoped>
span {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $gray1000;
  letter-spacing: -0.16px;
}
</style>
