<template>
  <component
    :is="multiple ? 'DsCheckbox' : 'div'"
    readonly
    :checked="checks[option.value]"
    class="ds-choice"
    :class="{ checked: checks[option.value], disabled: option.disabled }"
    :style="{ paddingLeft: 12 + level * 24 + 'px' }"
    :disabled="option.disabled"
    :title="typeof option.disabled === 'string' ? option.disabled : ''"
    :data-test="dataTest"
    @click.stop.prevent="$emit('click', option)"
  >
    <slot
      v-if="$slots.default"
      v-bind="option"
    />
    <template v-else>
      <div style="display: flex; gap: 8px; align-items: center">
        <DsIcon
          v-if="option.icon"
          :name="option.icon"
        />
        <div style="display: flex; flex-direction: column">
          <div>
            {{ option.wording || option.value || option }}
          </div>
          <div class="secondary-wording">
            {{ option.secondaryWording }}
          </div>
        </div>
      </div>
    </template>
    <DsIcon
      v-if="option.items?.length > 0"
      :name="expanded ? 'chevron-up' : 'chevron-down'"
      size="small"
      color="gray400"
      @click.stop.prevent="emit('update:expanded', !expanded)"
    />
  </component>
  <template v-if="option.items && expanded">
    <DsNeoDropdownItem
      v-for="item of option.items"
      :key="'sub-' + item.value"
      v-model:expanded="expandedOption[item.value]"
      :multiple="multiple"
      :option="item"
      :checks="checks"
      :level="level + 1"
      @click="option.disabled ? null : $emit('click', $event)"
    >
      <template
        v-if="$slots.default"
        #default="slotProps"
      >
        <slot v-bind="slotProps" />
      </template>
    </DsNeoDropdownItem>
  </template>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import DsIcon from './DsIcon.vue';
import { NeoDropDownOption } from './DsNeoDropdown.vue';

const props = withDefaults(
  defineProps<{
    multiple?: boolean;
    option: NeoDropDownOption;
    expanded?: boolean;
    checks?: boolean[] | object;
    level?: number;
    dataTest?: string;
  }>(),
  {
    multiple: false,
    level: 0,
    expanded: false,
    checks: () => []
  }
);

const emit = defineEmits<{
  (e: 'click', item: any);
  (e: 'update:expanded', v: boolean);
}>();

const expandedOption = ref({});
</script>

<style scoped lang="scss">
.ds-choice {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  width: 100%;
  padding: 8px 12px;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.14px;
  white-space: nowrap;

  border-radius: 8px;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }

  &:not(.disabled):hover {
    background-color: $gray50;
  }
}

.secondary-wording {
  font-size: 12px;
  line-height: 16px;
  color: $gray400;
  letter-spacing: -0.12px;
}
</style>
