import { API } from '@aws-amplify/api';
import { addDays, format } from 'date-fns';
import { cloneDeep } from 'lodash-es';

import { RetrieveAccountsMetricsOutput } from '@/api/schemas/metrics';
import { AccountType } from '@/custom-types/account';
import {
  AutomaticCategorizationModel,
  GetAutomaticCategorizationQueryStringParameters,
  GetAutomaticCategorizationsOutput
} from '@/custom-types/automatic-categorization';
import {
  CashflowForecastModel,
  CashflowForecastTemplateQueryModel
} from '@/custom-types/cashflows';
import { ClientId } from '@/custom-types/client';
import {
  assignCounterpartyToAnalyticalAxesInput,
  RetrieveAllCounterpartiesIdOutput,
  RetrieveCounterpartiesIdInput,
  RetrieveCounterparty
} from '@/custom-types/counterparties';
import { IntervalType } from '@/custom-types/date';
import {
  CreateUserNodePermissionInput,
  DeleteUserNodePermissionInput,
  RetrieveMultiUsersAnalyticalAxesOutput,
  RetrieveUserNodesPermissionsOutput
} from '@/custom-types/permissions-user-nodes';
import { ProviderCustomFieldUpdate } from '@/custom-types/providers';
import { getTenantId, getUserJwtToken } from '@/helpers/amplify';

import {
  AWSDocument,
  FilterPresetInput,
  FilterPresetOutput,
  Pagination,
  RetrieveFilterPresetsOutput
} from './schemas/common';
import {
  FawkesProviderCustomFieldQuery,
  ImportAnalyticalAxisNodeInput,
  RetrieveAccountOutput,
  RetrieveAccountsOutput,
  RetrieveBalancesOutput,
  Splits
} from './schemas/fawkes';
import { GetCashflowOutput } from './schemas/fawkes/cashflows';
import {
  DeleteExpectedTransactionBody,
  ExpectedTransactionBatchBody,
  ExpectedTransactionBody,
  ExpectedTransactionResponse,
  RecurringExpectedTransactionOutput,
  UpdateExpectedTransactionBody
} from './schemas/fawkes/cashflows-previsions';

export async function getAccounts(query?): Promise<RetrieveAccountsOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const queryStringParameters = query
    ? JSON.parse(JSON.stringify(query))
    : undefined;

  return API.get('fawkes', `/rest/v1/${tenantId}/accounts`, {
    queryStringParameters,
    headers
  });
}

export async function getAccount(accountId): Promise<RetrieveAccountOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('fawkes', `/rest/v1/${tenantId}/accounts/${accountId}`, {
    headers
  });
}

export async function getPublicAccounts() {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('fawkes', `/rest/v1/${tenantId}/accounts/list`, { headers });
}

export async function getAccountsFromAccountIds(accountIds) {
  if (accountIds.length === 0) {
    return Promise.resolve({
      accounts: []
    });
  }

  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = {
    account_ids: accountIds
  };

  return API.get('fawkes', `/rest/v1/${tenantId}/accounts`, {
    headers,
    queryStringParameters
  });
}

export async function putAccount(accountId, body) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.put('fawkes', `/rest/v1/${tenantId}/accounts/${accountId}`, {
    headers,
    body
  });
}

export async function patchAccount(accountId, body) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.patch('fawkes', `/rest/v1/${tenantId}/accounts/${accountId}`, {
    headers,
    body
  });
}

export async function renameAccountName(accountId, newAccountName) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = { name: newAccountName };

  return API.put('fawkes', `/rest/v1/${tenantId}/accounts/${accountId}`, {
    headers,
    body
  });
}

export async function toggleAccount(accountId, newValue) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = { is_active: newValue };

  return API.post('fawkes', `/rest/v1/${tenantId}/accounts/${accountId}`, {
    headers,
    body
  });
}

export async function getBalancesAvailable(
  balancesQuery = {
    type: null,
    baseCurrency: null
  }
): Promise<RetrieveBalancesOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const query = balancesQuery;
  return API.get(
    'fawkes',
    `/rest/v1/${tenantId}/balances?base_currency=EUR&type=EXPECTED `,
    { headers }
  );
}

export interface GetBalanceOutputBalancesBalanceCurrency {
  amount: number;
  currency: string;
  date: string;
}

export enum GetBalanceOutputBalancesType {
  EFFECTIVE = 'EFFECTIVE'
}

export interface GetBalanceOutputBalances {
  balance_currencies: GetBalanceOutputBalancesBalanceCurrency[];
  account_id: number;
  amount: number;
  currency: string;
  type: GetBalanceOutputBalancesType;
  date: string;
}

export interface GetBalanceOutput {
  balances: GetBalanceOutputBalances[];
}

export async function getBalance(accountId: number): Promise<GetBalanceOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('fawkes', `/rest/v1/${tenantId}/balances/${accountId}`, {
    headers
  });
}

export async function getBalances(): Promise<RetrieveBalancesOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('fawkes', `/rest/v1/${tenantId}/balances`, { headers });
}

export async function getTransactionProofOfPayment(transactionId) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'fawkes',
    `/rest/v1/${tenantId}/transactions/${transactionId}/proof-of-payment`,
    { headers }
  );
}

export async function getTransaction(transactionId) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'fawkes',
    `/rest/v1/${tenantId}/transactions/${transactionId}`,
    { headers }
  );
}

export async function getTransactionActivityLog(transactionId) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const queryStringParameters = {
    page: 1,
    page_size: 10,
    resource_type: 'transactions',
    resource_id: transactionId
  };

  return API.get('audit_trail', `/rest/v2/${tenantId}/activity-items`, {
    queryStringParameters,
    headers
  });
}

export enum TransactionState {
  BOOKED = 'BOOKED',
  PENDING = 'PENDING',
  FAILED = 'FAILED'
}

export enum TransactionMethod {
  UNKNOWN = 'UNKNOWN',
  CARD = 'CARD',
  CHECK = 'CHECK',
  CREDIT_TRANSFER = 'CREDIT_TRANSFER',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  FEE = 'FEE',
  RECALL = 'RECALL',
  CONVERSION = 'CONVERSION'
}

export enum TransactionScheme {
  UNKNOWN = 'UNKNOWN',
  ACH = 'ACH',
  AMEX = 'AMEX',
  AUTOGIRO = 'AUTOGIRO',
  BACS = 'BACS',
  BECS = 'BECS',
  BECS_NZ = 'BECS_NZ',
  BETAILINGSSERVICE = 'BETAILINGSSERVICE',
  MASTERCARD = 'MASTERCARD',
  PAD = 'PAD',
  SEPA = 'SEPA',
  STRIPE = 'STRIPE',
  SWIFT = 'SWIFT',
  VISA = 'VISA',
  SPENDESK = 'SPENDESK'
}

export enum OrderingMethod {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface RetrieveTransactionsQuery {
  text_search?: string | null;
  with_pending?: boolean;
  state_in?: TransactionState[] | null;
  with_uncategorized?: boolean | null;
  connector_transaction_id_in?: string[] | null;
  connector_transaction_id_contains_in?: string[] | null;
  account_id_in?: number[] | null;
  amount_gte?: number | null;
  amount_lt?: number | null;
  amount_regex?: string | null;
  analytical_axis_id_uncategorized_in?: number[] | null;
  analytical_axis_node_id_in?: number[] | null;
  category_id_in?: number[] | null;
  counterparty_holder_name_in?: string[] | null;
  counterparty_iban_in?: string[] | null;
  counterparty_id_in?: number[] | null;
  counterparty_payment_id_in?: number[] | null;
  currency_in?: string[];
  date_gte?: string | null;
  date_lt?: string | null;
  booking_date_gte?: string | null;
  booking_date_lt?: string | null;
  value_date_gte?: string | null;
  value_date_lt?: string | null;
  full_reference_contain?: string[] | null;
  full_reference_end_with?: string | null;
  full_reference_not_contain?: string[] | null;
  full_reference_start_with?: string | null;
  is_inflow?: boolean | null;
  is_outflow?: boolean | null;
  method_in?: TransactionMethod[];
  with_no_method?: boolean | null;
  scheme_in?: TransactionScheme[];
  with_no_scheme?: boolean | null;
  parent_id_in?: number[] | null;
  sub_category_id_in?: number[] | null;
  transaction_id_in?: number[] | null;
  bank_order_id_in?: number[] | null;
  with_counterparty_payment_id?: boolean | null;
  error_code_in?: string[];

  by_state?: OrderingMethod | null;
  by_date?: OrderingMethod | null;
  by_amount?: OrderingMethod | null;
  by_account?: OrderingMethod | null;
  by_counterparty?: OrderingMethod | null;
  by_method?: OrderingMethod | null;

  nb_transactions?: number | null;
  page_nb?: number | null;
  created_before?: Date | null;
  created_at_gte?: string | null;
  created_at_lt?: string | null;

  type_in?: string[] | null;

  already_exported?: boolean | null;
}

export async function getTransactions(
  createdAt,
  filtering,
  sorting,
  page = 1,
  nbTransactions = 40
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const parameters = {
    created_before: createdAt,
    ...filtering,
    ...sorting,
    page_nb: page,
    nb_transactions: nbTransactions
    // with_counterparty_payment_id: false
    // no_parent: noParent || Object.keys(filtering).length === 0
  };

  parameters.with_no_method =
    parameters?.method_in?.includes('UNKNOWN') || undefined;
  parameters.with_no_scheme =
    parameters?.scheme_in?.includes('UNKNOWN') || undefined;

  parameters.method_in = parameters?.method_in?.filter(
    (method) => method !== 'UNKNOWN'
  );
  parameters.scheme_in = parameters?.scheme_in?.filter(
    (scheme) => scheme !== 'UNKNOWN'
  );
  parameters.amount_gte = parameters.amount_gte
    ? parameters.amount_gte
    : undefined;
  parameters.amount_lt = parameters.amount_lt
    ? parameters.amount_lt
    : undefined;
  const queryStringParameters = JSON.parse(JSON.stringify(parameters));

  return API.get('fawkes', `/rest/v1/${tenantId}/transactions`, {
    queryStringParameters,
    headers
  });
}

export interface RetrieveTransactionOutput {
  account_holder_name: string;
  account_id: number;
  account_name: string;
  amount: number;
  amount_original_currency: null | number;
  analytical_axis_assoc: {
    analytical_axis_id: number;
    analytical_axis_node_ids: number[];
  }[];
  booking_date: string;
  connector_transaction_id: string;
  counterparty_id?: number;
  counterparty_account_number: null | string;
  counterparty_bank_code: null | string;
  counterparty_bic: null | string;
  counterparty_email: null | string;
  counterparty_holder_address: null | string;
  counterparty_holder_name: null | string;
  counterparty_iban: null | string;
  counterparty_name: null | string;
  counterparty_payment_id: null | string;
  counterparty_phone: null | string;
  created_by: null | string;
  currency: string;
  date: string;
  deduplication_uuid: null | string;
  end_to_end_id: null | string;
  full_reference: string;
  fx_applied_rate: null | number;
  fx_currency: null | string;
  fx_gross_amount: null | number;
  gross_amount: number;
  id: number;
  is_manual_import: boolean;
  is_splitted: boolean;
  logo_link: string;
  method: null | string;
  mother_full_ref: null | string;
  original_currency: null | string;
  parent_id: null | number;
  reference: string;
  remittance_information: string;
  scheme: null | string;
  state: TransactionState;
  type: null | string;
  value_date: string;
  created_at: string;
  error_code: string;
  transaction_file_id?: number;
  already_exported?: boolean | null;
}

export interface RetrieveTransactionsOutput {
  has_more: boolean;
  next_page: number | null;
  previous_page: number | null;
  transactions: RetrieveTransactionOutput[];
}

let getTransactionsCleanPromise: Promise<RetrieveTransactionsOutput>;
export async function getTransactionsClean(
  queryStringParameters: RetrieveTransactionsQuery
): Promise<RetrieveTransactionsOutput> {
  if (getTransactionsCleanPromise) {
    API.cancel(getTransactionsCleanPromise);
  }

  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  getTransactionsCleanPromise = API.get(
    'fawkes',
    `/rest/v1/${tenantId}/transactions`,
    {
      queryStringParameters,
      headers
    }
  );

  return getTransactionsCleanPromise;
}

export const getOneTransaction = async (
  transactionId: number
): Promise<RetrieveTransactionOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'fawkes',
    `/rest/v1/${tenantId}/transactions/${transactionId}`,
    { headers }
  );
};

export interface TransactionRow {
  transaction: RetrieveTransactionOutput;
  counterparty?: RetrieveCounterparty | null;
}

export async function getSchemes(searchQuery = '') {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const queryStringParameters: Record<string, any> = {};

  if (searchQuery !== '') {
    queryStringParameters.text = searchQuery;
  }

  return API.get('fawkes', `/rest/v1/${tenantId}/transactions/scheme`, {
    queryStringParameters,
    headers
  });
}

export async function getMethods(searchQuery = '') {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const queryStringParameters: Record<string, any> = {};

  if (searchQuery !== '') {
    queryStringParameters.text = searchQuery;
  }

  return API.get('fawkes', `/rest/v1/${tenantId}/transactions/method`, {
    queryStringParameters,
    headers
  });
}

export enum ExtractType {
  ACCOUNTING = 'ACCOUNTING',
  FINANCIAL = 'FINANCIAL'
}

export interface ExtractTransactionsQuery {
  text_search?: string;
  with_pending?: boolean; // DEPRECATED: Should use state_in parameter
  state_in?: TransactionState[];
  with_uncategorized?: boolean;
  extract_type?: ExtractType; // Assuming ExtractType is a TypeScript type you have defined

  account_id_in?: number[];
  amount_gte?: number;
  amount_lt?: number;
  amount_regex?: string;
  analytical_axis_id_uncategorized_in?: number[];
  analytical_axis_node_id_in?: number[];
  counterparty_holder_name_in?: string[];
  counterparty_iban_in?: string[];
  counterparty_id_in?: number[];
  counterparty_payment_id?: number[];
  currency_in?: string[];
  date_gte?: string;
  date_lt?: string;
  booking_date_gte?: string | null;
  booking_date_lt?: string | null;
  value_date_gte?: string | null;
  value_date_lt?: string | null;
  full_reference_contain?: string[];
  full_reference_end_with?: string;
  full_reference_not_contain?: string[];
  full_reference_start_with?: string;
  is_inflow?: boolean;
  is_outflow?: boolean;
  method_in?: TransactionMethod[];
  with_no_method?: boolean;
  scheme_in?: TransactionScheme[];
  transaction_id_in?: number[];
  parent_id_in?: number[];
  with_no_scheme?: boolean;

  by_state?: OrderingMethod;
  by_date?: OrderingMethod;
  by_amount?: OrderingMethod;
  by_account?: OrderingMethod;
  by_counterparty?: OrderingMethod;
  by_method?: OrderingMethod;

  nb_transactions?: number;
  page_nb?: number;
  created_before?: Date;

  already_exported?: boolean | null;

  no_parent?: boolean;
  fileformat?: 'csv' | 'xml' | 'xlsx';
}

export async function getExtractTransactions(query: ExtractTransactionsQuery) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('fawkes', `/rest/v1/${tenantId}/transactions/extract`, {
    headers,
    queryStringParameters
  });
}

export async function getCounterparties(createdAt, searchQuery = '', page = 1) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const queryStringParameters = {
    created_at: createdAt,
    text: searchQuery,
    page
  };

  return API.get('fawkes', `/rest/v1/${tenantId}/transactions/counterparties`, {
    queryStringParameters,
    headers
  });
}

export interface GetCounterpartyNamesQuery {
  created_at: string;
  text: string;
  page: number;
}

export interface GetCounterpartyNamesOutput {
  has_more: boolean;
  next_page: number | null;
  previous_page: number | null;
  names: string[];
}

export async function getCounterpartyNames(
  query: GetCounterpartyNamesQuery
): Promise<GetCounterpartyNamesOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('fawkes', `/rest/v1/${tenantId}/transactions/counterparties`, {
    queryStringParameters,
    headers
  });
}

export async function getMetricsAllAccounts(
  beginDate,
  endDate,
  period,
  baseCurrency,
  filtering
): Promise<RetrieveAccountsMetricsOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  let query = `/rest/v1/${tenantId}/accounts/metrics`;
  const parameters = {
    start_time: beginDate,
    end_time: endDate,
    aggregation_period: period,
    base_currency: baseCurrency,
    ...filtering
  };

  if (parameters.currencies) {
    const currencies = parameters.currencies;
    delete parameters.currencies;
    for (const currency of currencies) {
      query += '&currencies=' + currency;
    }
  }

  if (parameters.account_ids) {
    const accountIds = parameters.account_ids;
    delete parameters.account_ids;
    for (const accountId of accountIds) {
      query += '&account_ids=' + accountId;
    }
  }

  if (parameters.counterparty_names) {
    const counterpartyNames = parameters.counterparty_names;
    delete parameters.counterparty_names;
    for (const counterpartyName of counterpartyNames) {
      query += '&counterparty_names=' + counterpartyName;
    }
  }

  if (parameters.category_ids) {
    const categoryIds = parameters.category_ids;
    delete parameters.category_ids;
    for (const categoryId of categoryIds) {
      query += '&category_ids=' + categoryId;
    }
  }

  if (parameters.sub_category_ids) {
    const subCategoryIds = parameters.sub_category_ids;
    delete parameters.sub_category_ids;
    for (const subCategoryId of subCategoryIds) {
      query += '&sub_category_ids=' + subCategoryId;
    }
  }

  Object.keys(parameters).forEach((key) => {
    query += '&' + key + '=' + parameters[key];
  });
  query = query.replace('&', '?');
  return await API.get('fawkes', query, { headers });
}

export async function getProviders() {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('fawkes', `/rest/v1/${tenantId}/accounts/providers`, {
    headers
  });
}

export async function getProviderCategories(providerName = 'Revolut Bank') {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'fawkes',
    `/rest/v1/${tenantId}/provider-categories?provider_name=${providerName}`,
    { headers }
  );
}

export async function getProviderCustomFields(
  providerName: string
): Promise<FawkesProviderCustomFieldQuery> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'fawkes',
    `/rest/v1/${tenantId}/provider-custom-fields?provider_name=${providerName}`,
    { headers }
  );
}

export async function updateProviderCustomFields(
  fields: ProviderCustomFieldUpdate | ProviderCustomFieldUpdate[]
): Promise<FawkesProviderCustomFieldQuery> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.patch('fawkes', `/rest/v1/${tenantId}/provider-custom-fields`, {
    headers,
    body: {
      provider_custom_fields: (Array.isArray(fields) ? fields : [fields]).map(
        (field) => ({
          provider_custom_field_id: field.providerCustomFieldId,
          analytical_axis_node_id: field.analyticalAxisNodeId
        })
      )
    }
  });
}

// https://www.notion.so/payflows/Fawkes-API-v1-399438a222f14f3abd55fe08539a6f1a#3b34e0df31d741e9bb57fccc93a7e02a
export async function updateProviderCategories(body) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  // example body

  // const body = {
  //   categories: [
  //     {
  //       provider_category_id: 100,
  //       category_id: 1,
  //       sub_category_id: null
  //     }
  //   ],
  //   sub_categories: [
  //     {
  //       provider_sub_category_id: 200,
  //       sub_category_id: 2,
  //       category_id: null
  //     }
  //   ]
  // }
  return API.patch('fawkes', `/rest/v1/${tenantId}/provider-categories`, {
    headers,
    body
  });
}

export async function categorizeTransactions() {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  return API.patch('fawkes', `/rest/v1/${tenantId}/transactions/categorize`, {
    headers
  });
}

export async function getCategories() {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  throw new Error('Deprecated');

  return API.get('fawkes', `/rest/v1/${tenantId}/transactions/categories`, {
    headers
  });
}

export async function createCategory(name, color, type, subCategoryNames = []) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    categories: [
      {
        category_name: name,
        category_color: color,
        category_type: type,
        category_is_deletable: true,
        sub_category_names: subCategoryNames,
        sub_category_is_deletable: Array(subCategoryNames.length).fill(true)
      }
    ]
  };

  return API.post('fawkes', `/rest/v1/${tenantId}/transactions/categories`, {
    headers,
    body
  });
}

export async function deleteCategories(categoryIds = [], subCategoryIds = []) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    category_ids: categoryIds,
    sub_category_ids: subCategoryIds
  };

  return API.del('fawkes', `/rest/v1/${tenantId}/transactions/categories`, {
    headers,
    body
  });
}

export async function createSubcategory(categoryId, subcategoryName) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    categories: [
      {
        category_id: categoryId,
        sub_category_names: [subcategoryName],
        sub_category_is_deletable: [true]
      }
    ]
  };

  return API.post('fawkes', `/rest/v1/${tenantId}/transactions/categories`, {
    headers,
    body
  });
}

export async function updateSubcategory(subcategoryId, subcategoryName) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    sub_category: {
      id: subcategoryId,
      name: subcategoryName
    }
  };

  return API.patch('fawkes', `/rest/v1/${tenantId}/transactions/categories`, {
    headers,
    body
  });
}

export async function updateCategory(category) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    category // id, name, type, color,
  };

  return API.patch('fawkes', `/rest/v1/${tenantId}/transactions/categories`, {
    headers,
    body
  });
}

/**
 * @param {number|number[]}} transactionIds
 * @param {number} categoryId
 * @param {number} subcategoryId
 *
 * @returns {Promise<any>}
 */
export async function assignCategoryToTransactions(
  transactionIds,
  categoryId,
  subcategoryId
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  transactionIds = Array.isArray(transactionIds)
    ? transactionIds
    : [transactionIds];

  const body = {
    transactions: transactionIds.map((transactionId) => ({
      transaction_id: transactionId,
      category_id: categoryId,
      sub_category_id: subcategoryId
    }))
  };

  return API.patch('fawkes', `/rest/v1/${tenantId}/transactions`, {
    headers,
    body
  });
}

/**
 * @param {number|number[]}} transactionIds
 * @param {number} categoryId
 * @param {number} subcategoryId
 *
 * @returns {Promise<any>}
 */
export async function assignAnalyticalAxesToTransactions(
  transactionIds,
  axisIdList
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body = {
    transactions: (() => {
      return transactionIds.map((trxId) => {
        const transaction = {
          transaction_id: trxId,
          analytical_axis_assoc: []
        };

        if (axisIdList !== undefined && !!axisIdList.length) {
          axisIdList.forEach((axis) => {
            transaction.analytical_axis_assoc.push({
              analytical_axis_id: axis.analytical_axis_id,
              analytical_axis_node_id: axis.analytical_axis_node_ids[0]
            });
          });
        }
        return transaction;
      });
    })()
  };

  return API.patch(
    'fawkes',
    `/rest/v1/${tenantId}/transactions/analytical-axes`,
    { body, headers }
  );
}

export async function applyOneCategorization(automaticCategorization) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body = automaticCategorization;

  if (body.date_lte) {
    body.date_lt = format(addDays(new Date(body.date_lte), 1), 'yyyy-MM-dd');

    delete body.date_lte;
  }

  return API.patch(
    'fawkes',
    `/rest/v1/${tenantId}/transactions/apply-one-categorization`,
    { headers, body }
  );
}

export async function getAutomaticCategorization(id) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = {
    auto_cat_id_in: [id]
  };

  return API.get(
    'fawkes',
    `/rest/v2/${tenantId}/automatic-categorisation/transaction`,
    { headers, queryStringParameters }
  );
}

export async function getAutomaticCategorizations(
  queryStringParameters: GetAutomaticCategorizationQueryStringParameters
): Promise<GetAutomaticCategorizationsOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const q = Object.fromEntries(
    Object.entries(queryStringParameters).filter(([k, v]) => v !== undefined)
  );

  return API.get(
    'fawkes',
    `/rest/v2/${tenantId}/automatic-categorisation/transaction`,
    { queryStringParameters: q, headers }
  );
}

export async function putAutomaticCategorization(
  newAutomaticCategorizationsList
  //   = {
  //   id: 0,
  //   account_id_in: [2, 3], // or []
  //   name: '',
  //   counterparty_iban_in: ['fr7630006000011234567890189', 'fr7630006000011234567890189'], // or []
  //   counterparty_holder_name_in: ['John Doe', 'Jane Doe'], // or []
  //   currency_in: ['EUR', 'USD'], // or []
  //   date_gte: '2022-01-01', // or null
  //   date_lt: '2022-01-02', // or null
  //   cutoff_date_gte: '2022-01-01', // or null
  //   cutoff_date_lt: '2022-01-02', // or null
  //   amount_gte: 0, // or null
  //   amount_lt: 0, // or null
  //   is_inflow: true, // or null
  //   is_outflow: true, // or null
  //   full_reference_contain: ['airbnb'], // or []
  //   full_reference_not_contain: ['accors'], // or []
  //   full_reference_start_with: ['air'], // or []
  //   full_reference_end_start_with: ['bnb'], // or []
  //   counterparty_holdern_name_or_full_ref_contain: ['test'], // or []
  //   category_id: 0, // or null
  //   sub_category_id: 0, // or null
  //   enabled: true
  // }
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body = newAutomaticCategorizationsList.map(
    (newAutomaticCategorization) => {
      const copyOfnewAutomaticCategorization = cloneDeep(
        newAutomaticCategorization
      );

      copyOfnewAutomaticCategorization.analytical_axis_assoc =
        copyOfnewAutomaticCategorization.analytical_axis_assoc.map((elt) => {
          const res = {
            analytical_axis_id: elt.analytical_axis_id,
            analytical_axis_node_id: undefined
          };

          if (elt.analytical_axis_node_ids) {
            res.analytical_axis_node_id =
              elt.analytical_axis_node_ids.slice(-1)[0];
          } else {
            res.analytical_axis_node_id = elt.analytical_axis_node_id;
          }

          return res;
        });

      return copyOfnewAutomaticCategorization;
    }
  );

  // const body = copyOfnewAutomaticCategorization

  return API.patch(
    'fawkes',
    `/rest/v2/${tenantId}/automatic-categorisation/transaction`,
    { headers, body: { data: body } }
  );
}

export const analyzeAutomaticCategorization = async (
  automaticCategorization,
  analysisType
) => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body = {
    ...automaticCategorization,
    analysis_type: analysisType
  };

  return API.post(
    'fawkes',
    `/rest/v2/${tenantId}/automatic-categorisation/transaction/analysis`,
    { body, headers }
  );
};

export const parseAutomaticCategorizationFromTransactionQuery = async (
  query: RetrieveTransactionsQuery
) => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body = query;

  return API.post(
    'fawkes',
    `/rest/v2/${tenantId}/automatic-categorisation/transaction/parse`,
    { body, headers }
  );
};

export const createAutomaticCategorization = async (
  automaticCategorization: AutomaticCategorizationModel
) => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body = automaticCategorization;

  return API.post(
    'fawkes',
    `/rest/v2/${tenantId}/automatic-categorisation/transaction`,
    { headers, body }
  );
};

export const deleteAutomaticCategorization = async (ids: number[]) => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = { ids };

  return API.del(
    'fawkes',
    `/rest/v2/${tenantId}/automatic-categorisation/transaction`,
    { headers, queryStringParameters }
  );
};

export const applyAutomaticCategorization = async (
  automaticCategorizationId
) => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'fawkes',
    `/rest/v2/${tenantId}/automatic-categorisation/${automaticCategorizationId}/apply`,
    { headers }
  );
};

export async function createCurrentAccount(account) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    accounts: [
      {
        account_type: 'CLASSIC_MANUAL',
        institution_name: account.institutionName,
        name: account.accountName,
        manual_account: {
          currency: account.currencyCode,
          amount: account.balance
        },
        iban: undefined
      }
    ]
  };

  if (account.iban) {
    body.accounts[0].iban = account.iban;
  }

  return API.post('fawkes', `/rest/v1/${tenantId}/accounts`, { headers, body });
}

export async function createInvestmentAccount(account) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    accounts: [
      {
        account_type: 'INVESTMENT',
        institution_id: account.institutionId,
        institution_name: account.institutionName,
        name: account.accountName,
        manual_account: {
          currency: account.currencyCode,
          amount: account.balance,
          opening_date: account.openingDate,
          terms: account.termDate,
          interest_rate: account.interestRate,
          interest_payment_terms: account.paymentTerms
        },
        iban: undefined
      }
    ]
  };

  if (account.iban) {
    body.accounts[0].iban = account.iban;
  }

  return API.post('fawkes', `/rest/v1/${tenantId}/accounts`, { headers, body });
}

export async function createTransactionSplits(transactionId, splits: Splits) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = splits;

  return API.post(
    'fawkes',
    `/rest/v1/${tenantId}/transactions/${transactionId}/splits`,
    { headers, body }
  );
}

export async function getTransactionSplits(transactionId) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'fawkes',
    `/rest/v1/${tenantId}/transactions/${transactionId}/splits`,
    { headers }
  );
}

export async function extractFieldsForTransactionSplits(
  transactionId,
  delimiter,
  bucket,
  key
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    delimiter,
    document_url: { bucket, key }
  };

  return API.post(
    'fawkes',
    `/rest/v1/${tenantId}/transactions/${transactionId}/splits/bulk/extract-fields`,
    { headers, body }
  );
}

export async function previewFieldsForTransactionSplits(
  transactionId,
  delimiter,
  bucket,
  key,
  defaultCurrency,
  mappingFields
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    delimiter,
    document_url: { bucket, key },
    default_currency: defaultCurrency,
    mapping_fields: mappingFields
  };

  return API.post(
    'fawkes',
    `/rest/v1/${tenantId}/transactions/${transactionId}/splits/bulk/preview`,
    { headers, body }
  );
}

export async function extractFieldsForTransactionsImport(
  AWSDocument,
  delimiter
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    delimiter,
    file: AWSDocument
  };

  return API.post(
    'fawkes',
    `/rest/v1/${tenantId}/transactions/import/extract-fields`,
    { headers, body }
  );
}

export async function importTransactions(body) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('fawkes', `/rest/v1/${tenantId}/transactions/import`, {
    headers,
    body
  });
}

let getCashflowPromise: Promise<GetCashflowOutput>;
export async function getCashflow(
  queryStringParameters: Record<string, any>,
  viewId = 35
): Promise<GetCashflowOutput> {
  if (getCashflowPromise) {
    API.cancel(getCashflowPromise);
  }

  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/cashflow-views/${viewId}/cashflow`;

  getCashflowPromise = API.get('fawkes', path, {
    headers,
    queryStringParameters
  });
  return getCashflowPromise;
}

export async function getCashflowViews() {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('fawkes', `/rest/v1/${tenantId}/cashflow-views`, { headers });
}

export async function createCashflowView(body) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  body = {
    name: body.name,
    dimensions: body.dimensions
  };

  return API.post('fawkes', `/rest/v1/${tenantId}/cashflow-views`, {
    headers,
    body
  });
}

export async function cashflowsSaveEditViewName(viewId, newName) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.patch('fawkes', `/rest/v1/${tenantId}/cashflow-views/${viewId}`, {
    headers,
    body: { name: newName }
  });
}

export async function updateCashflowView(viewId, body) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.put('fawkes', `/rest/v1/${tenantId}/cashflow-views/${viewId}`, {
    headers,
    body
  });
}

export async function deleteCashflowView(viewId) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.del('fawkes', `/rest/v1/${tenantId}/cashflow-views/${viewId}`, {
    headers
  });
}

export async function getCashflowForecastTemplateS3File(
  cashflowForecastTemplateQuery: CashflowForecastTemplateQueryModel
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  let path = `/rest/v1/${tenantId}/cashflow-forecast-template?`;
  path = path.concat(
    `cashflow_view_id=${cashflowForecastTemplateQuery.cashflow_view_id}`
  );
  path = path.concat(
    `&cashflow_view_dimension_id=${cashflowForecastTemplateQuery.cashflow_view_dimension_id}`
  );
  path = path.concat(
    `&file_format=${cashflowForecastTemplateQuery.file_format}`
  );

  if (cashflowForecastTemplateQuery.level) {
    path = path.concat(`&level=${cashflowForecastTemplateQuery.level}`);
  }

  return API.get('fawkes', path, { headers });
}

export async function createCashflowForecast(body: CashflowForecastModel) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/cashflow-forecasts`;

  return API.post('fawkes', path, { headers, body });
}

export async function getCashflowForecasts() {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/cashflow-forecasts`;

  return API.get('fawkes', path, { headers });
}

export async function deleteCashflowForecast(cashflowForecastId) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const path = `/rest/v1/${tenantId}/cashflow-forecasts/${cashflowForecastId}`;
  return API.del('fawkes', path, { headers });
}

export async function exportCashflow(
  queryStringParameters: Record<string, any>,
  viewId: number
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/cashflow-views/${viewId}/cashflow-export`;

  return API.get('fawkes', path, { headers, queryStringParameters });
}

export const getUserNodesPermissions = async (
  userId: ClientId
): Promise<RetrieveUserNodesPermissionsOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/users/${userId}/analytical-axes`;

  return API.get('fawkes', path, { headers });
};

export const getNodesPermissionsAnalyticalAxesAllUsers =
  async (): Promise<RetrieveMultiUsersAnalyticalAxesOutput> => {
    const tenantId = await getTenantId();
    const headers = { token: await getUserJwtToken() };

    const path = `/rest/v1/${tenantId}/users/analytical-axes`;

    return API.get('fawkes', path, { headers });
  };

export const createUserNodePermission = async (
  userId: ClientId,
  permissions: CreateUserNodePermissionInput
) => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/users/${userId}/analytical-axes`;

  return API.post('fawkes', path, { headers, body: permissions });
};

export const deleteUserNodePermission = async (
  userId: ClientId,
  permissions: DeleteUserNodePermissionInput
) => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/users/${userId}/analytical-axes`;

  return API.del('fawkes', path, { headers, body: permissions });
};

export async function getCounterpartiesAnalyticalAxes(
  counterpartiesIdList: RetrieveCounterpartiesIdInput
): Promise<RetrieveAllCounterpartiesIdOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  let query = `/rest/v1/${tenantId}/analytical-axis/association/counterparty`;

  counterpartiesIdList.counterparties_id.forEach((ctId, index) => {
    if (index === 0) {
      query += '?';
    } else {
      query += '&';
    }
    query += `counterparty_ids=${ctId}`;
  });

  return API.get('fawkes', query, { headers });
}

export async function assignCounterpartyToAnalyticalAxes(
  assignCounterparties: assignCounterpartyToAnalyticalAxesInput[]
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = { counterparties: assignCounterparties };

  return API.patch(
    'fawkes',
    `/rest/v1/${tenantId}/analytical-axis/association/counterparty`,
    { headers, body }
  );
}

export interface AssignCounterpartyBody {
  transaction_id: number;
  // counterparty_payment_id: number // deprecated
  counterparty_id: number;
  create_mapping: boolean;
}

export interface PostTransactionsAssignCounterpartyInput {
  transactions_counterparty_payments_associations: AssignCounterpartyBody[];
}

export async function postTransactionsAssignCounterparty(
  body: PostTransactionsAssignCounterpartyInput
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  return API.post(
    'fawkes',
    `/rest/v1/${tenantId}/transactions/assign-counterparty`,
    { headers, body }
  );
}

export enum ViewSettingsParam {
  BOOKED = 'BOOKED',
  VALUE = 'VALUE'
}

export enum ViewSettingsParamSplitting {
  STATEMENT = 'STATEMENT',
  SPLITTED = 'SPLITTED'
}

export enum PrevisionsSettingsParamSplitting {
  GROUPED = 'GROUPED',
  SPLITTED = 'SPLITTED'
}

export enum ViewSettingsItem {
  BALANCE = 'BALANCE',
  TRANSACTION = 'TRANSACTION'
}

export interface PostViewSettingsInput {
  param?: ViewSettingsParam;
  item: ViewSettingsItem;
  param_splitting?: ViewSettingsParamSplitting;
  prevision_splitting?: PrevisionsSettingsParamSplitting;
}

export interface PostViewSettingsOutput extends PostViewSettingsInput {
  id: number;
  user_id: number;
}

export interface GetViewSettingsInput {
  item: ViewSettingsItem;
}

export async function getViewSettings(
  queryStringParameters: GetViewSettingsInput
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('fawkes', `/rest/v1/${tenantId}/view-settings`, {
    queryStringParameters,
    headers
  });
}

export async function postViewSettings(
  body: PostViewSettingsInput
): Promise<PostViewSettingsOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  return API.post('fawkes', `/rest/v1/${tenantId}/view-settings`, {
    headers,
    body
  });
}

export interface PostBalancesExportAsyncInput {
  institution_id_in?: number[] | null;
  account_id_in?: number[] | null;
  currency_in?: string[] | null;
  entity_id_in?: number[] | null;
  date_gte?: Date | null;
  date_lte?: Date | null;
  aggregation_period?: IntervalType | null;
}

export async function postBalancesExportAsync(
  body: PostBalancesExportAsyncInput
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  return API.post('fawkes', `/rest/v1/${tenantId}/balances/export-async`, {
    headers,
    body
  });
}

interface AnalyticalAxis {
  analytical_axis_id: number;
  analytical_axis_node_ids?: number[];
  with_no_analytical_axis_node?: boolean;
}

export interface Permission {
  entity_ids?: number[];
  group_ids?: number[];
  account_ids?: number[];
  transaction_ids?: number[];
  analytical_axes?: AnalyticalAxis[];
  axis_id?: number[];
  all_access?: boolean;
  all_access_account?: boolean;
}

export interface PostUserPermissionsInput {
  user_id: number;
  permission_actions: {
    ALLOW?: Permission;
    DENY?: Permission;
  };
  analytics_role: 'VIEWER' | 'CONTRIBUTOR';
}

export interface GetUserPermissionsOutput {
  user_id: number;
  permission_actions: {
    ALLOW?: Permission;
    DENY?: Permission;
  };
  analytics_role: 'VIEWER' | 'CONTRIBUTOR';
}

export async function getUserPermissions(
  userId: ClientId,
  analyticsRole: 'VIEWER' | 'CONTRIBUTOR' = 'VIEWER'
): Promise<GetUserPermissionsOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/permissions/users/${userId}?analytics_role=${analyticsRole}`;

  return API.get('fawkes', path, { headers });
}

export async function postUserPermissions(
  body: PostUserPermissionsInput
): Promise<GetUserPermissionsOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/permissions/users/${body.user_id}`;

  return API.post('fawkes', path, { headers, body });
}

export async function deleteUserPermissions(
  body: GetUserPermissionsOutput
): Promise<any> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/permissions/users/${body.user_id}`;

  return API.del('fawkes', path, { headers, body });
}

export interface ResetUsersPermissionsInput {
  user_ids: number[];
}

export async function resetUsersPermissions(
  body: ResetUsersPermissionsInput
): Promise<any> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/permissions/reset/users`;

  return API.del('fawkes', path, { headers, body });
}

export enum ViewType {
  HOME_PAGE = 'HOME_PAGE',
  TRANSACTION = 'TRANSACTION',
  FLOW = 'FLOW'
}
export interface ViewsRefreshInput {
  view_type: ViewType;
}

export async function getLastViewRefreshInfos(
  body: ViewsRefreshInput
): Promise<any> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/views/refresh?view_type=${body.view_type}`;

  return API.get('fawkes', path, { headers });
}

export async function setRefreshToView(body: ViewsRefreshInput): Promise<any> {
  const tenantId = await getTenantId();
  const headers = {
    token: await getUserJwtToken(),
    'Content-Type': 'application/json; charset=UTF-8'
  };

  const path = `/rest/v1/${tenantId}/views/refresh?view_type=${body.view_type}`;

  return API.post('fawkes', path, { headers, body: {} });
}

export interface CodeError {
  code_value: string;
  description: string;
}
export interface getListTransactionErrorCodeOutput {
  error_codes: CodeError[];
}

export async function getListTransactionErrorCode(): Promise<getListTransactionErrorCodeOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/error-codes`;

  return API.get('fawkes', path, { headers });
}

export interface RetrieveGLAccountOutput {
  id: number;
  created_at: string;
  updated_at: string;
  internal_id?: string;
  active: boolean;
  type: AccountType;
  number?: string;
  name: string;
  name_lower: string;
  include_children: boolean;
  subsidiaries_ids?: number[];
}
export interface GetGLAccountOutput {
  accounts: RetrieveGLAccountOutput[];
  pagination: Pagination;
}

export enum ERPAccountType {
  ACCOUNTS_PAYABLE = 'ACCOUNTS_PAYABLE',
  ACCOUNTS_RECEIVABLE = 'ACCOUNTS_RECEIVABLE',
  BANK = 'BANK',
  COST_OF_GOOD_SOLD = 'COST_OF_GOOD_SOLD',
  CREDIT_CARD = 'CREDIT_CARD',
  DEFFERED_EXPENSE = 'DEFFERED_EXPENSE',
  DEFFERED_REVENUR = 'DEFFERED_REVENUR',
  EQUITY = 'EQUITY',
  EXPENSE = 'EXPENSE',
  FIXED_ASSET = 'FIXED_ASSET',
  INCOME = 'INCOME',
  LONG_TERM_LIABILITY = 'LONG_TERM_LIABILITY',
  NON_POSTING = 'NON_POSTING',
  OTHER_ASSET = 'OTHER_ASSET',
  OTHER_CURRENT_ASSET = 'OTHER_CURRENT_ASSET',
  OTHER_CURRENT_LIABILITY = 'OTHER_CURRENT_LIABILITY',
  OTHER_EXPENSE = 'OTHER_EXPENSE',
  OTHER_INCOME = 'OTHER_INCOME',
  STATISTICAL = 'STATISTICAL',
  UNBILLED_RECEIVABLE = 'UNBILLED_RECEIVABLE'
}

export interface RetrieveERPAccountsQuery {
  entity_id: number;
  name_search?: string;
  number_search?: string;
  page: number;
  page_size: number;
  name_and_number_search?: string;
  type_in?: ERPAccountType;
}

export async function getGLAccount(
  query: RetrieveERPAccountsQuery
): Promise<GetGLAccountOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = query
    ? JSON.parse(JSON.stringify(query))
    : undefined;

  const path = `/rest/v1/${tenantId}/accounts/erp-accounts`;

  return API.get('fawkes', path, { headers, queryStringParameters });
}

export interface LinkAccountERPAccountInput {
  id: number;
  erp_account_id: number;
  erp_account_name: string;
  erp_account_number: number;
}

export interface PostLinkAccountsERPAccountBody {
  accounts: LinkAccountERPAccountInput[];
}

export async function postLinkAccountsERPAccount(
  body: PostLinkAccountsERPAccountBody
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/accounts/erp-accounts`;

  return API.post('fawkes', path, { headers, body });
}

export interface DeleteLinkAccountsERPAccountBody {
  account_ids: number[];
}

export async function deleteLinkAccountsERPAccount(
  body: DeleteLinkAccountsERPAccountBody
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/accounts/erp-accounts`;

  return API.del('fawkes', path, { headers, body });
}

let getExpectedTransactionsPromise: Promise<ExpectedTransactionResponse>;
export async function getExpectedTransactions(
  queryStringParameters: Record<string, any>
): Promise<ExpectedTransactionResponse> {
  if (getExpectedTransactionsPromise) {
    API.cancel(getExpectedTransactionsPromise);
  }

  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/expected-transactions`;

  getExpectedTransactionsPromise = API.get('fawkes', path, {
    headers,
    queryStringParameters
  });

  return getExpectedTransactionsPromise;
}

export async function postExpectedTransaction(
  body: ExpectedTransactionBody
): Promise<ExpectedTransactionResponse> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/expected-transactions`;

  return API.post('fawkes', path, { body, headers });
}

export async function updateExpectedTransaction(
  transactionId: number,
  body: UpdateExpectedTransactionBody
): Promise<ExpectedTransactionResponse> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/expected-transactions/${transactionId}`;

  return API.put('fawkes', path, { body, headers });
}

export async function deleteExpectedTransaction(
  transactionId: number,
  body: DeleteExpectedTransactionBody
): Promise<void> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/expected-transactions/${transactionId}/delete`;

  return API.post('fawkes', path, { body, headers });
}

export async function getExpectedTransactionsTemplate(): Promise<AWSDocument> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/expected-transactions/template`;

  return API.get('fawkes', path, { headers });
}

export async function postExpectedTransactionsBatch(
  body: ExpectedTransactionBatchBody
): Promise<void> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/expected-transactions/batch`;

  return API.post('fawkes', path, { body, headers });
}

export async function getRecurringExpectedTransaction(
  reccuringTransactionId: number
): Promise<RecurringExpectedTransactionOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const path = `/rest/v1/${tenantId}/recurring-expected-transactions/${reccuringTransactionId}`;

  return API.get('fawkes', path, { headers });
}

export const getTransactionPresets =
  async (): Promise<RetrieveFilterPresetsOutput> => {
    const tenantId = await getTenantId();
    const headers = { token: await getUserJwtToken() };
    return API.get(
      'fawkes',
      `/rest/v1/${tenantId}/transaction-filter-presets`,
      {
        headers
      }
    );
  };

export const createTransactionPreset = async (
  body: FilterPresetInput
): Promise<FilterPresetOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  return API.post('fawkes', `/rest/v1/${tenantId}/transaction-filter-presets`, {
    headers,
    body
  });
};

export const updateTransactionPreset = async (
  presetId: number,
  body: FilterPresetInput
): Promise<FilterPresetOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  return API.put(
    'fawkes',
    `/rest/v1/${tenantId}/transaction-filter-presets/${presetId}`,
    {
      headers,
      body
    }
  );
};

export const deleteTransactionPreset = async (
  presetId: number
): Promise<void> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  return API.del(
    'fawkes',
    `/rest/v1/${tenantId}/transaction-filter-presets/${presetId}`,
    {
      headers
    }
  );
};

export const getCashflowPresets =
  async (): Promise<RetrieveFilterPresetsOutput> => {
    const tenantId = await getTenantId();
    const headers = { token: await getUserJwtToken() };
    return API.get('fawkes', `/rest/v1/${tenantId}/cashflow-filter-presets`, {
      headers
    });
  };

export const createCashflowPreset = async (
  body: FilterPresetInput
): Promise<FilterPresetOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  return API.post('fawkes', `/rest/v1/${tenantId}/cashflow-filter-presets`, {
    headers,
    body
  });
};

export const updateCashflowPreset = async (
  presetId: number,
  body: FilterPresetInput
): Promise<FilterPresetOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  return API.put(
    'fawkes',
    `/rest/v1/${tenantId}/cashflow-filter-presets/${presetId}`,
    {
      headers,
      body
    }
  );
};

export const deleteCashflowPreset = async (presetId: number): Promise<void> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  return API.del(
    'fawkes',
    `/rest/v1/${tenantId}/cashflow-filter-presets/${presetId}`,
    {
      headers
    }
  );
};

export const importERPAnalyticalAxis = async (
  payload: ImportAnalyticalAxisNodeInput
): Promise<void> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'fawkes',
    `/rest/v1/${tenantId}/analytical-axes/tree/from-erp-analytical-axis`,
    {
      headers,
      body: payload
    }
  );
};
