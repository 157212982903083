<template>
  <InlineSvg
    :src="iconSrc"
    :width="size"
    :height="size"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import InlineSvg from 'vue-inline-svg';

const props = defineProps<{
  countryCode: string;
  size: number | string;
}>();

const iconSrc = computed(() => {
  return new URL(
    `/src/assets/img/design-system/flag-icon/${props.countryCode?.toLowerCase()}.svg`,
    import.meta.url
  )?.href;
});
</script>
