<template>
  <div class="ds-chip">
    <p class="slot-text">
      <slot />
    </p>
    <DsIconButton
      v-if="noRemove === false"
      class="remove-button"
      name="cross"
      size="small"
      color="#4c5066"
      @click="$emit('remove')"
    />
  </div>
</template>

<script>
import DsIconButton from './DsIconButton.vue';

export default {
  name: 'DsChip',
  components: {
    DsIconButton
  },
  props: {
    noRemove: {
      type: Boolean,
      default: false
    }
  },
  emits: ['remove']
};
</script>

<style lang="scss" scoped>
.ds-chip {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 7px 13px;

  background-color: rgba(65, 87, 255, 0.08);
  border-radius: 16px;
}

.slot-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #4c5066;
  letter-spacing: -0.14px;

  :deep(strong) {
    font-weight: 500;
  }
}

.remove-button {
  margin-left: 8px;
}
</style>
