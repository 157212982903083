import { cloneDeep, findIndex, isEqual } from 'lodash';
import { computed, onMounted, ref, watch } from 'vue';

export const useCheckbox = (
  props: Readonly<{
    modelValue: any;
    value: any;
    trueValue: any;
    falseValue: any;
  }>,
  emit: any
) => {
  const modelValueCopy = ref(null as any);

  const isChecked = computed<boolean>(() =>
    modelValueCopy.value instanceof Array
      ? (modelValueCopy.value as Array<any>).find((item) =>
          isEqual(item, props.value)
        )
      : isEqual(modelValueCopy.value, props.trueValue)
  );

  const onCheckboxChecked = (event: Event) => {
    const isCheckboxChecked = (event.target as HTMLInputElement).checked;

    if (modelValueCopy.value instanceof Array) {
      const newModelValue = cloneDeep(modelValueCopy.value);
      if (isCheckboxChecked) {
        newModelValue.push(props.value);
      } else {
        newModelValue.splice(
          findIndex(newModelValue, (item: any) => isEqual(item, props.value)),
          1
        );
      }

      modelValueCopy.value = newModelValue;
      emit('update:modelValue', newModelValue);
    } else {
      modelValueCopy.value = isCheckboxChecked
        ? props.trueValue
        : props.falseValue;
      emit('update:modelValue', modelValueCopy.value);
    }
  };

  watch(
    () => props.modelValue,
    () => {
      modelValueCopy.value = props.modelValue;
    },
    { immediate: true, deep: true }
  );

  onMounted(() => {
    modelValueCopy.value = props.modelValue;
  });

  return { modelValueCopy, isChecked, onCheckboxChecked };
};
