import { DropdownOption } from '@/components/NewDesignSystem/DropdownOption';
import { AddressDetailedObject } from '@/custom-types/address';
import { ISODateString } from '@/custom-types/common';
import {
  AccountIdentifierType,
  BankIdentifierType,
  BeneficiaryEntityType,
  CounterpartyAddress,
  CounterpartyContacts,
  CounterpartyStatus,
  CounterpartyType,
  CountryCode,
  ERPCounterpartyOutput,
  VendorFormInstance,
  VendorOnboardingOutput,
  VendorPaymentMethod
} from '@/custom-types/counterparties';
import { LanguageCode } from '@/custom-types/languages';

import {
  ContactInformationType,
  FormStep,
  FormStepCounterpartyCounterpartyStatus,
  FormStepCounterpartyCounterpartyType,
  FormStepUnion
} from '../../intake/form-steps';
import { AWSDocument, Pagination } from '../common';

export enum CounterpartyDocumentType {
  INCORPORATION_CERTIFICATE = 'INCORPORATION_CERTIFICATE', // KBIS
  PAYMENT_DETAILS = 'PAYMENT_DETAILS', // RIB
  SECURITY_POLICY = 'SECURITY_POLICY', // Politique de sécurité
  TAX_CERTIFICATE = 'TAX_CERTIFICATE', // Certificat fiscal
  ENVIRONMENTAL_POLICY = 'ENVIRONMENTAL_POLICY', // Politique environnementale
  CSR_POLICY = 'CSR_POLICY', // Politique CSR
  DIVERSITY_INCLUSION_POLICY = 'DIVERSITY_INCLUSION_POLICY', // Politique d'inclusion
  DATA_PROTECTION_AUTHORITY = 'DATA_PROTECTION_AUTHORITY', // Autorité de protection des données
  STATEMENT_OF_WORK = 'STATEMENT_OF_WORK', // Devis
  INSURANCE_CERTIFICATE = 'INSURANCE_CERTIFICATE', // Certificat d'assurance
  CONTRACT = 'CONTRACT', // Contrat
  OTHER = 'OTHER' // Autre
}

export enum ContactType {
  PRIMARY = 'PRIMARY',
  ACCOUNTING = 'ACCOUNTING',
  PROCUREMENT = 'PROCUREMENT',
  COMPLIANCE = 'COMPLIANCE',
  SALES = 'SALES',
  OTHER = 'OTHER',
  RSE = 'RSE',
  BILLING = 'BILLING'
}

export enum IdentifierType {
  ACCOUNT_IDENTIFIER = 'ACCOUNT_IDENTIFIER',
  BANK_IDENTIFIER = 'BANK_IDENTIFIER'
}

export enum GetExportCounterpartiesQueryFormat {
  CSV = 'CSV'
}

export enum PaymentType {
  SWIFT = 'SWIFT',
  LOCAL = 'LOCAL'
}

export enum CreationSource {
  PAYMENT = 'PAYMENT',
  VENDOR_REQUEST = 'VENDOR_REQUEST',
  INTAKE = 'INTAKE',
  ERP = 'ERP',
  MANUAL = 'MANUAL',
  OTHER = 'OTHER'
}

export enum VendorRequestFormStepType {
  COMPANY_ID = 'COMPANY_ID',
  ADDRESS = 'ADDRESS',
  AMOUNT_CURRENCY = 'AMOUNT_CURRENCY',
  ANALYTICAL_AXIS = 'ANALYTICAL_AXIS',

  BILLING_INFO = 'BILLING_INFO',
  PAYMENT_DETAILS = 'PAYMENT_DETAILS',
  DATE = 'DATE',
  EMAIL = 'EMAIL',
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
  FREE_TEXT = 'FREE_TEXT',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  YES_NO = 'YES_NO',
  ENTITY = 'ENTITY',
  POINT_OF_CONTACT = 'POINT_OF_CONTACT',
  PAYMENT_TERMS = 'PAYMENT_TERMS',

  BANK_DETAILS = 'BANK_DETAILS',

  CHOOSE_FORM_ID = 'CHOOSE_FORM_ID' // synthetic step
}

export enum EnrichmentCustomSectionType {
  CUSTOM_FIELDS = 'CUSTOM_FIELDS',
  DOCUMENTS = 'DOCUMENTS'
}

export enum EnrichmentCustomStepType {
  YES_NO = 'YES_NO',
  FREE_TEXT = 'FREE_TEXT',
  BANK_DETAILS = 'BANK_DETAILS',
  ADDRESS = 'ADDRESS',
  SINGLE_CHOICE = 'SINGLE_CHOICE',

  COMMERCIAL_NAME = 'COMMERCIAL_NAME',
  LEGAL_NAME = 'LEGAL_NAME',
  COUNTRY = 'COUNTRY',
  REGISTRATION_NUMBER = 'REGISTRATION_NUMBER',
  TAX_ID = 'TAX_ID',
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
  CONTACT = 'CONTACT',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  ANALYTICAL_AXIS = 'ANALYTICAL_AXIS',
  DOCUMENT_SIGN = 'DOCUMENT_SIGN',
  CUSTOM_ADDRESS = 'CUSTOM_ADDRESS',
  CUSTOM_BANK_DETAILS = 'CUSTOM_BANK_DETAILS',
  DATE = 'DATE',
  AMOUNT_CURRENCY = 'AMOUNT_CURRENCY',
  CUSTOM_DOCUMENT_UPLOAD = 'CUSTOM_DOCUMENT_UPLOAD'
}

export enum EnrichmentDocumentStatus {
  UNOPENED = 'UNOPENED',
  OPENED = 'OPENED',
  SIGNED = 'SIGNED'
}

export enum EnrichmentSource {
  VENDOR = 'VENDOR',
  UNRESTRICTED = 'UNRESTRICTED',
  REQUESTOR = 'REQUESTOR',
  APPROVER = 'APPROVER'
}

export enum EnrichmentSectionType {
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  BANK_DETAILS = 'BANK_DETAILS',
  DOCUMENTS = 'DOCUMENTS',
  CONTACTS = 'CONTACTS',
  CUSTOM = 'CUSTOM',
  CUSTOM_DOCUMENT = 'CUSTOM_DOCUMENT'
}

export enum EnrichmentStepConditionOperator {
  CHOICE_EQUAL = 'CHOICE_EQUAL',
  BOOLEAN_EQUAL = 'BOOLEAN_EQUAL',
  CHOICE_IN = 'CHOICE_IN',
  CHOICE_NOT_IN = 'CHOICE_NOT_IN',
  REQUEST_CHOICE_EQUAL = 'REQUEST_CHOICE_EQUAL',
  REQUEST_BOOLEAN_EQUAL = 'REQUEST_BOOLEAN_EQUAL',
  REQUEST_CHOICE_IN = 'REQUEST_CHOICE_IN',
  REQUEST_CHOICE_NOT_IN = 'REQUEST_CHOICE_NOT_IN',
  REQUEST_AMOUNT_GREATER_EQUAL = 'REQUEST_AMOUNT_GREATER_EQUAL',
  REQUEST_AMOUNT_LESS_EQUAL = 'REQUEST_AMOUNT_LESS_EQUAL',
  REQUEST_PAYMENT_METHOD_EQUAL = 'REQUEST_PAYMENT_METHOD_EQUAL',
  REQUEST_ANALYTICAL_AXIS_NODE_EQUAL = 'REQUEST_ANALYTICAL_AXIS_NODE_EQUAL',
  REQUEST_ANALYTICAL_AXIS_NODE_IN = 'REQUEST_ANALYTICAL_AXIS_NODE_IN',
  REQUEST_COUNTRY_EQUAL = 'REQUEST_COUNTRY_EQUAL',
  REQUEST_COUNTRY_IN = 'REQUEST_COUNTRY_IN',
  REQUEST_COUNTRY_NOT_IN = 'REQUEST_COUNTRY_NOT_IN'
}

export enum RejectMotiveEnum {
  EXISTING_SUPPLIER = 'EXISTING_SUPPLIER',
  INCORRECTLY_FILLED = 'INCORRECTLY_FILLED',
  BANNED_SUPPLIER = 'BANNED_SUPPLIER',
  FORBIDDEN_SUPPLIER = 'FORBIDDEN_SUPPLIER',
  OTHER = 'OTHER'
}

export interface PaymentDetail {
  id: number;
  identifier_type: IdentifierType;
  type: string;
  value: string;
}

export interface CounterpartyPayment {
  id: number;
  is_active: boolean;
  verified: boolean;
  currency_code: string;
  automatically_created: boolean;
  counterparty_id: number;
  display_name: string;
  account_country_code?: string;
  payment_type?: PaymentType;
  payment_details?: PaymentDetail[];
  account_holder_name?: string;
  created_by?: number;
  source?: string;
  address?: string;
  registration_number?: string;
  is_duplicated?: boolean;
  relationship?: CounterpartyType;
  erp_counterparty?: ERPCounterpartyOutput;
}

export interface CounterpartyDetails {
  name: string;
  id: number;
  counterparty_payments: CounterpartyPayment[];
}

export interface Counterparty {
  counterparty: any;
  counterparty_details: CounterpartyDetails;
}

export interface CounterpartiesOutput {
  counterparties: Counterparty[];
  has_more: boolean;
  next_page: number;
  previous_page: number;
}

export interface VendorContactBody {
  type: ContactType;
  first_name?: string;
  last_name?: string;
  email: string;
  phone_number?: string;
  counterparty_id: number;
}

export interface VendorContactOutput extends VendorContactBody {
  id: number;
}

export interface VendorEnrichmentDocumentOutput {
  id: number;
  bucket: string;
  key: string;
  status: EnrichmentDocumentStatus;
  opened_at?: ISODateString;
  signed_at?: ISODateString;
}

export interface VendorEnrichmentCustomField {
  id: number;
  type: EnrichmentCustomStepType;
  label: string;
  description: string;
  position: number;
  answer?: any;
  choices?: DropdownOption[];
}

export interface VendorEnrichmentCustomFieldYesNo
  extends VendorEnrichmentCustomField {
  type: EnrichmentCustomStepType.YES_NO;
  answer?: boolean;
}

export interface VendorEnrichmentCustomFieldFreeText
  extends VendorEnrichmentCustomField {
  type: EnrichmentCustomStepType.FREE_TEXT | EnrichmentCustomStepType.ADDRESS;
  answer?: string;
}

export interface VendorEnrichmentBankDetails {
  account_holder_name: string;
  bic: string;
  country_code: string;
  iban: string;
}

export interface VendorEnrichmentCustomFieldBankDetails
  extends VendorEnrichmentCustomField {
  type: EnrichmentCustomStepType.BANK_DETAILS;
  answer?: VendorEnrichmentBankDetails;
}

export const isVendorEnrichmentCustomFieldYesNo = (
  field: VendorEnrichmentCustomField
): field is VendorEnrichmentCustomFieldYesNo =>
  field.type === EnrichmentCustomStepType.YES_NO;

export interface VendorEnrichmentCustomSectionOutput {
  id: number;
  type: EnrichmentCustomSectionType;
  label: string;
  description: string;
  documents?: VendorEnrichmentDocumentOutput[];
  fields?: VendorEnrichmentCustomField[];
}

export interface CounterpartyUpdateEnrichmentDocumentBody {
  id: number;
  status: EnrichmentDocumentStatus;
  opened_at?: ISODateString;
  signed_at?: ISODateString;
}

export interface CounterpartyUpdateEnrichmentCustomFieldBody {
  id: number;
  answer?: string | boolean;
}

export interface CounterpartyUpdateEnrichmentSectionBody {
  documents?: CounterpartyUpdateEnrichmentDocumentBody[];
  custom_fields?: CounterpartyUpdateEnrichmentCustomFieldBody[];
}

export interface VendorRequestFormStepAndValueOutput {
  form_step: FormStepUnion;
  form_values: Record<string, any>;
}

export interface PappersCompanyInfo {
  name: string;
  field_of_activity: string;
  registration_number: string;
}

export interface SearchCompanyInfoByNameOutput {
  company_infos: PappersCompanyInfo[];
}

export interface CompanyAddress {
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  country?: string;
}

export interface GetFullCompanyInfoBySiretOutput {
  commercial_name: string;
  legal_name: string;
  field_of_activity: string;
  registration_number: string;
  tax_id: string;
  address?: CompanyAddress;
  code_naf?: string;
  legal_form_type?: string;
  siren?: string;
}

export interface VendorManagementForm {
  created_at: Date;
  created_by: number;
  description: string;
  id: number;
  name: string;
  updated_at: Date;
  updated_by: number;
  entities: number[];
}

export interface VendorManagementFormsOutput {
  forms: VendorManagementForm[];
}

export interface VendorManagementFormInput {
  description: string;
  name: string;
  default_language: LanguageCode;
  alternative_languages: LanguageCode[];
  entities: number[];
}

export interface VendorEnrichmentChoice {
  name: string;
  description: string;
  position: number;
}

export interface VendorEnrichmentStep {
  id?: number;
  form_section_id?: number;
  name: string;
  description: string;
  position: number;
  mandatory: boolean;
  type: VendorRequestFormStepType | EnrichmentCustomStepType;

  document_type?: CounterpartyDocumentType;

  contact_type?: ContactType;
  required_information?: ContactInformationType[];

  choices?: VendorEnrichmentChoice[];

  signature_required?: boolean;
}

export interface VendorEnrichmentStepDocumentSign extends VendorEnrichmentStep {
  key: string;
  bucket: string;
  label?: string;
  value: {
    opened_at: ISODateString;
    signed_at: ISODateString;
    status: EnrichmentDocumentStatus;
  };
}

export const isVendorEnrichmentStepDocumentSign = (
  step: VendorEnrichmentStep
): step is VendorEnrichmentStepDocumentSign =>
  step.type === EnrichmentCustomStepType.DOCUMENT_SIGN;

export interface VendorEnrichmentStepCustom extends VendorEnrichmentStep {
  label: string;
  value: any;
  options?: string[];
}

export interface VendorEnrichmentConditionValue {
  operator: EnrichmentStepConditionOperator;
  value?: any;
}

export interface VendorEnrichmentSection {
  id?: number;
  name: string;
  description: string;
  position: number;
  type: EnrichmentSectionType;
  steps: VendorEnrichmentStep[];
  source: EnrichmentSource[];
}

export interface VendorEnrichmentCondition {
  step_position?: number;
  section_position: number;
  condition_on_step_position?: number;
  condition_on_request_step_position?: number;
  condition_value: VendorEnrichmentConditionValue;
}

export interface VendorEnrichmentOutput {
  sections: VendorEnrichmentSection[];
  step_conditions: VendorEnrichmentCondition[];
  section_conditions: VendorEnrichmentCondition[];
}

export interface VendorEnrichmentInput {
  sections: VendorEnrichmentSection[];
}

export interface VendorRequestStepFormIdChoice extends FormStep {
  options: DropdownOption[];
}

export interface AdeoErpcDuplicateCheck {
  vendor_exists_in_erp: boolean;
  status_codes: string[];
  vendor_exists_for_business_unit: boolean;
}

export interface VendorOnboardingSectionData {
  id?: number;
  name: string;
  description: string;
  position: number;
  type: EnrichmentSectionType;
  marked_complete?: boolean;
}

export interface VendorOnboardingSectionsOutput {
  sections: VendorOnboardingSectionData[];
}

export interface VendorOnboardingSectionByIdOutput
  extends VendorOnboardingSectionData {
  id?: number;
  name: string;
  description: string;
  position: number;
  type: EnrichmentSectionType;
  conditions: VendorEnrichmentCondition[];
  steps: VendorEnrichmentStep[];
}

export interface VendorOnboardingInput {
  company_details?: any[];
  bank_accounts?: any[];
  documents?: any[];
  contacts?: any[];
  addresses?: any[];
  steps?: VendorEnrichmentStep[];
  sections?: any[];
}

export interface RetrieveCounterpartyToApproveNumberOutput {
  count: number;
}

export interface GetCounterpartyPaymentInput {
  search_account_holder_name?: string;
  name?: string;
  number: number;
}

export interface GetCounterpartyPaymentOutput {
  counterparties_payment: CounterpartyPayment[];
  pagination: Pagination;
}

export interface InviteVendorOnboardingBody {
  counterparty_id: number;
  to_email: string;
  need_incorporation_certificate?: boolean;
  need_payment_details?: boolean;
  need_security_policy?: boolean;
  front_url?: string;
  source?: CreationSource;
}

export interface SubmitVendorOnboardingBody {
  counterparty_address: string;
  counterparty_country_code: string;
  counterparty_display_name: string;
  counterparty_legal_name: string;
  counterparty_email: string;
  counterparty_first_name: string;
  counterparty_last_name: string;
  counterparty_phone_number: string;
  counterparty_registration_number: string;
  counterparty_tax_id: string;
  ctp_payment_bank_details: PaymentDetail[];
  ctp_payment_currency: string;
  ctp_payment_holder_name: string;
  document_incorporation_certificate?: AWSDocument | null;
  document_payment_details?: AWSDocument | null;
  document_security_policy?: AWSDocument | null;
}

export interface RetrieveVendorOnboardingOutput {
  counterparty_display_name?: string;
  counterparty_email?: string;
  need_document_incorporation_certificate?: boolean;
  need_document_payment_details?: boolean;
  need_document_security_policy?: boolean;
  need_document_tax_certificate?: boolean;
  created_by_id?: number;
  created_by_email: string;
  created_by_first_name: string;
  created_by_last_name: string;
  created_by_tenant_id?: number;
  created_by_tenant_name: string;
}

export interface CreateCounterpartyForOnboarding {
  display_name: string;
  beneficiary_entity_type: FormStepCounterpartyCounterpartyStatus;
  relationship: FormStepCounterpartyCounterpartyType;
  entity_ids: number[];
  erp_tax_item_id: number;
  source?: 'INTAKE' | 'MANUAL';
  email?: string;
}

export interface PostVendorOnboardingCopyLinkBody {
  vendor_onboarding_id: number;
}

export interface PostVendorOnboardingCopyLinkOutput {
  token: string;
}

export interface ExportCounterpartiesToERPInput {
  counterparty_ids: number[];
}

export interface PostRetryExportCounterpartiesToERPInput {
  counterparty_ids: number[];
}

export interface PostBicInput {
  iban: string;
}

export interface PostBicOutput {
  bic: string;
}

export interface GetExportCounterpartiesQuery {
  counterparty_ids: number[];
  format: GetExportCounterpartiesQueryFormat;
}

export interface GetExportCounterpartiesOutput {
  bucket: string;
  key: string;
}

export interface CounterpartyDocumentFromORM {
  id: number;
  counterparty_id: number;
  type: CounterpartyDocumentType;
  bucket: string;
  key: string;
}

export interface CounterpartyServiceFromORM {
  address?: string | null;
  approval_status: CounterpartyStatus;
  automatically_created: boolean;
  beneficiary_entity_type?: BeneficiaryEntityType | null;
  coordinates_to_update: boolean;
  relationship?: CounterpartyType | null;
  country_code?: CountryCode | null;
  created_by?: number | null;
  source?: CreationSource | null;
  default_currency: string; // Assuming CurrencyCode is a string type
  display_name: string;
  email?: string | null;
  entity_ids: number[];
  erp_tax_item_id?: number | null;
  erp_term_id?: number | null;
  first_name?: string | null;
  id: number;
  is_active: boolean;
  last_name?: string | null;
  latitude?: number | null;
  legal_name?: string | null;
  longitude?: number | null;
  phone_number?: string | null;
  registration_number?: string | null;
  tax_id?: string | null;
  updated_at?: Date | null;
  vendor_onboarding?: VendorOnboardingOutput | null;
  payment_methods?: VendorPaymentMethod[];
  estimated_amount?: number | null;
}

export interface GetVendorOnboardingCounterpartyOutput {
  addresses: CounterpartyAddress[];
  contacts: CounterpartyContacts[];
  counterparty: CounterpartyServiceFromORM;
  counterparty_payments: CounterpartyPayment[];
  document_incorporation_certificate?: CounterpartyDocumentFromORM;
  document_payment_details?: CounterpartyDocumentFromORM;
  document_security_policy?: CounterpartyDocumentFromORM;
  document_tax_certificate?: CounterpartyDocumentFromORM;
  documents: CounterpartyDocumentFromORM[];
  vendor_enrichment_custom_sections?: VendorEnrichmentCustomSectionOutput[];
  vendor_form_instances: VendorFormInstance[];
}

export interface GetVendorOnboardingCounterpartyV2Output {
  addresses: CounterpartyAddress[];
  contacts: CounterpartyContacts[];
  counterparty: CounterpartyServiceFromORM;
  counterparty_payments: CounterpartyPayment[];
  documents?: CounterpartyDocumentFromORM[];
  vendor_onboarding?: RetrieveVendorOnboardingOutput;
}

export interface PaymentDetailsBody {
  id?: number;
  type: AccountIdentifierType | BankIdentifierType | string;
  value: string;
}

export interface CounterpartyDocumentBody {
  id?: number;
  type: CounterpartyDocumentType;
  bucket: string;
  key: string;
}

export interface CounterpartyPaymentBody {
  id?: number;
  currency_code: string;
  account_holder_name: string;
  bank_details: PaymentDetailsBody[];
}

export interface CompanyDetailsBody {
  business_name: string;
  business_address: string;
  country_code: CountryCode | string;
  tax_id: string;
  registration_number: string;
  legal_name?: string;
}

export interface ContactBody {
  id?: number | null;
  type: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

export interface BankDetailsBody {
  id?: number | null;
  type: string;
  value: string;
}

export interface BankAccountBody {
  id?: number | null;
  currency_code: string;
  account_country_code?: CountryCode;
  account_holder_name: string;
  bank_details: BankDetailsBody[];
  bank_address?: AddressDetailedObject;
  bank_name?: string;
  source?: string;
}

export interface DocumentBody {
  id?: number | null;
  type: string;
  bucket: string;
  key: string;
}

export interface DocumentsBody {
  incorporation_certificate?: DocumentBody | null;
  payment_details?: DocumentBody | null;
  security_policy?: DocumentBody | null;
  tax_certificate?: DocumentBody | null;
}

export interface PatchVendorOnboardingBody {
  addresses?: CounterpartyAddress[] | null;
  company_details?: CompanyDetailsBody | null;
  contacts?: ContactBody[] | null; // If not undefined then non-empty array
  bank_accounts?: BankAccountBody[] | null; // If not undefined then non-empty array
  documents?: DocumentsBody | null;
  vendor_enrichment_custom_sections?: CounterpartyUpdateEnrichmentSectionBody;
}

export interface CompanyDetailsBodyV2 {
  display_name: string;
  legal_name: string;
  country_code: CountryCode;
  tax_id: string;
  registration_number: string;
}

export interface EnrichmentSectionInput {
  id: number;
  marked_complete: boolean;
}

export interface PatchVendorOnboardingBodyV2 {
  addresses?: CounterpartyAddress[];
  company_details?: CompanyDetailsBodyV2;
  contacts?: ContactBody[];
  bank_accounts?: BankAccountBody[];
  documents?: CounterpartyDocumentBody[];
  steps?: VendorEnrichmentStep[];
  sections?: EnrichmentSectionInput[];
}

export interface VendorEnrichmentSingleSection {
  id: number;
  name: string;
  description: string;
  position: number;
  type: EnrichmentSectionType;
  filled_by?: EnrichmentSource[];
  steps?: VendorEnrichmentStep[];
  step_instances?: (
    | VendorEnrichmentStepDocumentSign
    | VendorEnrichmentStepCustom
  )[];
  conditions: VendorEnrichmentCondition[];
}

export interface VendorEnrichmentSectionsOutput {
  sections: VendorEnrichmentSingleSection[];
}
