<template>
  <div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
div {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 24px 12px 24px;
}
</style>
