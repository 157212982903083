<template>
  <div
    ref="selectContainer"
    class="select-container"
  >
    <button
      v-if="!hideButton"
      ref="selectButton"
      :class="{
        'select-button': true,
        'select-button--with-border': withBorder,
        small: small
      }"
      type="button"
      :disabled="disabled"
      name="Actions menu"
      @click.prevent.stop="onClickOnButton"
      @keydown="onKeydownOnButton"
      @keyup.prevent
    >
      <DsIcon
        :name="icon"
        :size="iconSize"
        color="gray1000"
      />
    </button>
    <div
      v-show="isDropdownShown"
      ref="list"
      class="select-dropdown"
      :class="{
        'select-dropdown--right': placement === 'right',
        'select-dropdown--left': placement === 'left',
        'select-dropdown--middle': verticalPlacement === 'middle'
      }"
    >
      <template
        v-for="(action, index) in actions"
        :key="action"
      >
        <div
          v-if="action.separator"
          class="separator"
        />
        <div
          v-else
          :ref="'item-' + index"
          :class="{
            'select-action': true,
            'select-action--danger': action.danger,
            'select-action--is-focused': index === focusedActionIndex,
            'select-action--is-disabled': action.disabled
          }"
          @click.prevent.stop="
            !action.disabled ? onClickOnAction(action.emit) : null
          "
        >
          <DsIcon
            v-if="action.icon"
            :name="action.icon"
            :color="action.danger ? 'red400' : 'gray800'"
          />
          <p>
            {{ action.wording }}
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import DsIcon from './DsIcon.vue';

export default {
  name: 'DsDropdown',
  components: {
    DsIcon
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
      validator(actions) {
        return actions.every((action) => {
          return (
            Object.prototype.hasOwnProperty.call(action, 'separator') ||
            (Object.prototype.hasOwnProperty.call(action, 'emit') &&
              Object.prototype.hasOwnProperty.call(action, 'wording'))
          );
        });
      }
    },
    icon: {
      type: String,
      default: 'small-dots'
    },
    iconColor: {
      type: String,
      default: 'gray400'
    },
    iconSize: {
      type: String,
      default: 'big'
    },
    hideVerticalSeparator: {
      type: Boolean,
      default: false
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    withBorder: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: true
    },
    placement: {
      type: String,
      default: 'right',
      validator(value) {
        return ['right', 'left'].includes(value);
      }
    },
    verticalPlacement: {
      type: String,
      default: 'bottom',
      validator(value) {
        return ['top', 'middle', 'bottom'].includes(value);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'open', 'close'],
  data() {
    return {
      isDropdownShown: false,
      focusedActionIndex: null
    };
  },
  watch: {
    isDropdownShown(value) {
      if (value === true) {
        window.document.addEventListener('mousedown', this.handleMousedown);
      } else {
        window.document.removeEventListener('mousedown', this.handleMousedown);
      }
    }
  },
  unmounted() {
    window.document.removeEventListener('mousedown', this.handleMousedown);
  },
  methods: {
    handleMousedown(event) {
      if (this.$refs.selectContainer.contains(event.target) === false) {
        this.isDropdownShown = false;
        this.$emit('close');
      }
    },
    onClickOnButton() {
      if (this.isDropdownShown === false) {
        this.isDropdownShown = true;
        this.focusedActionIndex = null;
        this.$emit('open');
      } else {
        this.isDropdownShown = false;
        this.$emit('close');
      }
      this.focusSelectButton(); // Needed for Firefox and Safari
    },
    onClickOnAction(emit) {
      this.isDropdownShown = false;
      this.$emit('input', emit);
      this.$emit(emit);
      this.focusSelectButton();
    },
    handleKeyArrowDown() {
      if (this.isDropdownShown === false) {
        this.isDropdownShown = true;
        this.focusedActionIndex = 0;
      } else {
        if (this.focusedActionIndex === null) {
          this.focusedActionIndex = 0;
        } else if (this.focusedActionIndex < this.actions.length - 1) {
          this.focusedActionIndex += 1;
        }
        this.$nextTick(() => {
          const list = this.$refs.list;
          const node = this.$refs['item-' + this.focusedActionIndex];
          if (
            list &&
            list instanceof HTMLElement &&
            node &&
            node[0] &&
            node[0] instanceof HTMLElement
          ) {
            if (node[0].offsetTop < list.scrollTop) {
              node[0].scrollIntoView({
                block: 'start',
                inline: 'nearest',
                behavior: 'smooth'
              });
            }
          }
        });
      }
    },
    handleKeyArrowUp() {
      if (this.isDropdownShown === false) {
        this.isDropdownShown = true;
        this.focusedActionIndex = 0;
      } else {
        if (this.focusedActionIndex === null) {
          this.focusedActionIndex = 0;
        } else if (this.focusedActionIndex > 0) {
          this.focusedActionIndex -= 1;
        }
        this.$nextTick(() => {
          const list = this.$refs.list;
          const node = this.$refs['item-' + this.focusedActionIndex];
          if (
            list &&
            list instanceof HTMLElement &&
            node &&
            node[0] &&
            node[0] instanceof HTMLElement
          ) {
            if (
              node[0].offsetTop + node[0].clientHeight >=
              list.clientHeight + list.scrollTop
            ) {
              node[0].scrollIntoView({
                block: 'end',
                inline: 'nearest',
                behavior: 'smooth'
              });
            }
          }
        });
      }
    },
    handleKeyEnter() {
      if (this.isDropdownShown === true && this.focusedActionIndex !== null) {
        this.isDropdownShown = false;
        this.$emit('input', this.actions[this.focusedActionIndex].emit);
        this.$emit(this.actions[this.focusedActionIndex].emit);
      }
    },
    handleKeySpace() {
      if (this.isDropdownShown === false) {
        this.isDropdownShown = true;
        this.focusedActionIndex = null;
      } else {
        this.isDropdownShown = false;
        if (this.focusedActionIndex !== null) {
          this.$emit(this.actions[this.focusedActionIndex].emit);
        }
      }
    },
    onKeydownOnButton(event) {
      switch (event.code) {
        case 'ArrowDown':
          event.preventDefault();
          this.handleKeyArrowDown();
          break;
        case 'ArrowUp':
          event.preventDefault();
          this.handleKeyArrowUp();
          break;
        case 'Enter':
          event.preventDefault();
          this.handleKeyEnter();
          break;
        case 'Space':
          event.preventDefault();
          this.handleKeySpace();
          break;
        case 'Escape':
        case 'Tab':
          this.isDropdownShown = false;
          break;
      }
    },
    show() {
      this.isDropdownShown = true;
    },
    focusSelectButton() {
      if (this.$refs.selectButton) {
        this.$refs.selectButton.focus();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.select-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.select-button {
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;

  border-radius: 12px;

  &.small {
    width: 20px;
    height: 20px;
    border-radius: 8px;
  }

  &.select-button--with-border {
    background-color: white;
    border: 1px solid $gray100;

    &.small {
      width: 24px;
      height: 24px;
    }

    &:hover {
      border-color: $gray200;
    }

    &:focus {
      border-color: $gray200;
      box-shadow: 0 0 0 2px $gray300;
    }
  }
}

.select-dropdown {
  position: absolute;
  z-index: 100;
  top: 100%;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 4px;

  min-width: 120px;
  padding: 4px;

  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba($gray1000, 0.08);
}

.select-dropdown--right {
  right: 0;
}

.select-dropdown--left {
  left: 0;
}

.select-dropdown--middle {
  top: 40%;
  transform: translateY(-50%);
}

.select-action {
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;

  box-sizing: content-box;
  min-width: 192px;
  height: 20px;
  padding: 8px 12px;

  color: $gray1000;
  text-align: left;

  border-top: 0;
  border-radius: 8px;

  > svg {
    margin-right: 9px;
  }

  > p {
    overflow: hidden;

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-overflow: ellipsis;
    letter-spacing: -0.14px;
    white-space: nowrap;
  }

  &.select-action--danger {
    color: $red500;
  }

  &:hover,
  &.select-action--is-focused {
    background-color: $gray50;
  }
  &.select-action--is-disabled {
    cursor: not-allowed;
    color: $gray400;
  }
}

.separator {
  height: 1px;
  margin: 0px 12px;
  background: $gray150;
}
</style>
