<template>
  <div class="ds-table-entities-cell">
    <DsTag
      v-for="entity in entityList"
      :key="entity.id"
      left-icon="briefcase"
      color="gray"
    >
      {{ entity.name }}
    </DsTag>
    <DsTag
      v-if="overflowCount > 0"
      color="white"
      >+{{ overflowCount }}</DsTag
    >
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from 'vue';

import { EntityModel } from '@/api/schemas/mystix';
import { useEntitiesStore } from '@/stores/data-models/entities';

import DsTag from './DsTag.vue';

const entitiesStore = useEntitiesStore();

const props = defineProps<{
  modelValue: number[];
  maxToDisplay?: number;
}>();

const entityList = computed<EntityModel[]>(() => {
  const res = props.modelValue
    .map((entityId) => entitiesStore.getEntityById(entityId))
    .filter((x) => !!x);

  return props.maxToDisplay ? res.slice(0, props.maxToDisplay) : res;
});

const overflowCount = computed(() =>
  props.maxToDisplay ? props.modelValue.length - props.maxToDisplay : undefined
);

onBeforeMount(() => {
  if (!entitiesStore.hasGetEntitiesBeenCalled) {
    entitiesStore.callGetEntities();
  }
});
</script>

<style scoped lang="scss">
.ds-table-entities-cell {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
</style>
