<template>
  <div
    class="tabs"
    :style="{
      cursor: disabled ? 'not-allowed' : 'initial'
    }"
    :data-value="modelValue"
  >
    <DsButton
      v-for="(tab, index) in options"
      :id="tab?.id"
      :key="index"
      :loading="modelValue !== tab.value && loading"
      :class="{
        tab: true,
        'tab--is-active': modelValue === tab.value
      }"
      :style="{
        pointerEvents: disabled ? 'none' : 'initial',
        height: '32px'
      }"
      :tabindex="disabled ? -1 : null"
      type="button"
      :data-tab="JSON.stringify(tab)"
      :data-test="`${dataTest}-tab-${index}`"
      :icon="tab.icon"
      :custom-icon-color="modelValue === tab.value ? '#ffffff' : '#6A6E85'"
      :color="modelValue === tab.value ? 'blue' : 'gray'"
      @click="onClickOnTab(tab.value)"
    >
      <span class="text">
        {{ tab.wording }}
      </span>
      <span
        v-if="tab.showCount"
        class="count"
      >
        {{
          !!tab.hasLimitCount
            ? tab.count < 10
              ? tab.count
              : '9+'
            : n(tab.count)
        }}
      </span>
      <DsButton
        v-for="(a, aI) in tab.actions"
        :key="aI"
        style="gap: 0px; padding-right: 0px"
        :icon="a.icon"
        :custom-icon-color="modelValue === tab.value ? '#ffffff' : '#6A6E85'"
        small
        @click.stop="$emit(a.name as any, tab as any)"
      />
    </DsButton>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import DsButton from './DsButton.vue';

const { n } = useI18n();

const props = defineProps<{
  modelValue?: string | number | null;
  disabled?: boolean;
  options: {
    wording: string;
    value: string | number;
    count?: number;
    hasLimitCount?: boolean;
    showCount?: boolean;
    icon?: string;
    id?: string;
    actions?: {
      name: string;
      icon: string;
    }[];
  }[];
  loading?: boolean;
  dataTest?: string;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

function onClickOnTab(tabValue) {
  if (props.disabled === false) {
    emit('update:modelValue', tabValue);
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/field-label.scss';

.tabs {
  overflow: hidden;
  display: flex;
  gap: 2px;
  align-items: center;

  width: fit-content;
  padding: 2px;

  background-color: $gray100;
  border-radius: 12px;
}

:deep(.slot-container) {
  display: flex;
  align-items: center;
}

:deep(.text) {
  color: $gray600;
}

.tab--is-active :deep(.text) {
  color: white;
}

.count {
  min-width: 18px;
  margin-left: 8px;
  padding: 2px;

  font-size: 8px;
  font-weight: 700;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.16px;

  border: 2px solid $gray200;
  border-radius: 10px;
}
.tab--is-active :deep(.count) {
  border: 2px solid $blue200;
}

.tab--is-active {
  box-shadow: 0 6px 12px rgb(0 0 0 / 24%);
}
</style>
