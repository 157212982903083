<template>
  <div class="drag-and-drop-area-wrapper">
    <label class="DS_field-label">
      {{ label }}
      <span
        v-if="required"
        class="DS_field-label--required"
        >*</span
      >
    </label>
    <div
      v-if="loading"
      style="text-align: center"
    >
      <DsLoader :size="48" />
      <div>
        {{ $t('dsFileUpload.uploading') }}
      </div>
    </div>
    <label
      v-if="!loading && (!embedded || files?.length > 0)"
      class="uploader"
      for="file-upload"
      @drop="handleDrop"
      @dragover.prevent="isDraggedOver = true"
      @dragenter.prevent="isDraggedOver = true"
      @dragleave="isDraggedOver = false"
      @dragexit="isDraggedOver = false"
      @click.stop.prevent
    >
      <template v-if="!embedded && (!files?.length || multiple)">
        <div
          v-if="!small"
          :class="{
            'drag-and-drop-area': true,
            'dragged-over': isDraggedOver,
            'drag-and-drop-area--compact': isDragAndDropAreaCompact
          }"
        >
          <DsIcon
            name="upload"
            color="blue500"
            :scale="2"
          />
          <div class="area-description">
            <div class="drag-and-drop-text">
              <DsButton
                :data-test="`${dataTest}-choose-file-button`"
                color="blue"
                @click="input?.click()"
              >
                {{ $t('dsFileUpload.choose_file' + (multiple ? 's' : '')) }}
              </DsButton>
              {{ $t('dsFileUpload.cta' + (multiple ? 's' : '')) }}
            </div>
            <div class="file-formats">
              {{ $t('dsFileUpload.availableFormats') }}
              <span>
                {{ allowedFormatDescription ?? fileFormats.join(', ') }}
              </span>
              <div v-if="maxFileSizeInBytes">
                {{ $t('dsFileUpload.maxFileSize') }}
                {{ formatFileSize(maxFileSizeInBytes, 'binary') }}
              </div>
              <div
                v-if="files?.length > 0 && showSelectedFiled"
                style="width: 100%"
              >
                <span v-if="!small">
                  {{ files?.length }}
                  {{ $t('dsFileUpload.selectedFiles', files.length) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <DsButton
          v-else-if="multiple || !files || files.length === 0"
          :disabled="disabled"
          icon="upload"
          color="white"
          class="file-button"
          style="cursor: pointer"
          @click="input?.click()"
        >
          {{ $t('dsFileUpload.click-to-choose-file') }}
        </DsButton>
      </template>
      <template v-if="files">
        <DsButton
          v-for="(f, fI) in files"
          :key="fI"
          :disabled="disabled"
          :right-icon="
            uploadStatus[uploadKey(f?.file)] < 100 ? 'loader' : 'cross'
          "
          custom-icon-color="gray700"
          color="white"
          class="file-button"
          :class="{
            uploading:
              uploadStatus[uploadKey(f?.file)] >= 0 &&
              uploadStatus[uploadKey(f?.file)] < 100,
            uploaded: uploadStatus[uploadKey(f?.file)] >= 100
          }"
          :is-right-icon-disabled="disabled"
          @right-icon-action="removeFile(f)"
        >
          <DsIcon
            :name="mimeIcon(f)"
            color="gray700"
            style="margin-right: 8px"
          />
          <div
            class="loader"
            :style="{ width: uploadStatus[uploadKey(f?.file)] + '%' }"
          />
          <span
            class="filename"
            @click="onDowloadFile(f)"
          >
            {{ filename(f) }}
          </span>
        </DsButton>
      </template>
    </label>
    <input
      id="file-upload"
      ref="input"
      style="display: none"
      type="file"
      :multiple="multiple"
      :accept="fileFormats.join(',')"
      @change="handleChange"
    />
  </div>
</template>

<script setup lang="ts">
import { cloneDeep } from 'lodash';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { AWSDocument } from '@/api/schemas/common';
import { getFile, mimeIcon, uploadFiles } from '@/api/storage';
import { AWSDocumentWithFile } from '@/custom-types/storage';
import { useToastStore } from '@/stores/toast';

import { formatFileSize } from '../../helpers/file';
import DsButton from './DsButton.vue';
import DsIcon from './DsIcon.vue';
import DsLoader from './DsLoader.vue';

const props = withDefaults(
  defineProps<{
    modelValue?: Array<AWSDocumentWithFile | AWSDocument>;
    label?: string;
    bucket: string;
    bucketPath?: string;
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    small?: boolean;
    embedded?: boolean;
    fileFormats?: string[];
    allowedFormatDescription?: string;
    maxFileSizeInBytes?: number;
    showSelectedFiled?: boolean;
    isDragAndDropAreaCompact?: boolean;
    dataTest?: string;
    virusCheck?: boolean;
  }>(),
  {
    modelValue: null,
    label: null,
    bucketPath: undefined,
    loading: false,
    disabled: false,
    required: false,
    multiple: false,
    small: false,
    embedded: false,
    fileFormats: () => [
      '.pdf',
      '.png',
      '.jpg',
      '.jpeg',
      '.doc',
      '.docx',
      '.xls',
      '.xlsx',
      '.ppt',
      '.pptx'
    ],
    allowedFormatDescription: undefined,
    maxFileSizeInBytes: 16 * 1024 * 1024,
    showSelectedFiled: true,
    isDragAndDropAreaCompact: false,
    dataTest: undefined
  }
);

const emit = defineEmits<{
  (e: 'update:model-value', files: AWSDocumentWithFile[]): void;
}>();

const toast = useToastStore();
const { t } = useI18n();

const input = ref<HTMLInputElement>(null);
const files = ref<AWSDocumentWithFile[]>(props.modelValue || []);
const uploadStatus = ref<{ [key: string]: number }>({});
const loading = ref(false);

const isDraggedOver = ref(false);
const quarantineBucket = 'payflows-upload-quarantine';

const bucketName = computed(() =>
  props.virusCheck ? props.bucket + '-unsafe' : props.bucket
);

const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const reset = () => {
  (input.value as any).value = '';
  files.value = [];
  uploadStatus.value = {};
};

const handleChange = (e) => {
  addFiles(e.target.files);
};

const handleDrop = (e) => {
  e.preventDefault();
  e.stopPropagation();

  const array = e.dataTransfer.files;

  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (props.maxFileSizeInBytes && element.size > props.maxFileSizeInBytes) {
      toast.showError(new Error(`File ${element.name} is too big !`));
      return;
    }
    if (!props.multiple) {
      break;
    }
  }

  addFiles(e.dataTransfer.files);
};

const addFiles = async (newFiles: File[]) => {
  const filesToUpload: File[] = [];
  const limit = props.multiple ? newFiles.length : 1;

  for (let i = 0; i < limit; i++) {
    if (
      props.maxFileSizeInBytes &&
      newFiles[i].size > props.maxFileSizeInBytes
    ) {
      toast.showError(
        new Error(t('dsFileUpload.tooBig', { file: newFiles[i].name }))
      );
      continue;
    }
    if (
      props.fileFormats &&
      !props.fileFormats.includes(
        `.${newFiles[i].name.split('.').pop().toLocaleLowerCase()}`
      )
    ) {
      toast.showError(
        new Error(t('dsFileUpload.wrongFormat', { file: newFiles[i].name }))
      );
      continue;
    }
    if (
      files.value?.find(
        (file) =>
          file.file.name === newFiles[i].name &&
          file.file.lastModified === newFiles[i].lastModified
      )
    ) {
      continue;
    }
    filesToUpload.push(newFiles[i]);
  }
  if (!props.multiple) {
    reset();
  }
  if (!filesToUpload.length) return;
  try {
    loading.value = true;
    const s3Ret = await doUploadFiles(filesToUpload);
    files.value.push(
      ...Array.from(s3Ret).map((s3) => ({
        bucket: props.bucket,
        key: s3.key,
        file: s3.file
      }))
    );

    if (props.virusCheck) {
      let checkResults = null;
      if (props.multiple) {
        checkResults = await checkFiles(files.value);
      } else {
        checkResults = await checkFiles([files.value[0]]);
      }

      if (checkResults.allCheckFinished) {
        if (checkResults.fileVerified.length) {
          emit(
            'update:model-value',
            props.multiple
              ? checkResults.fileVerified.map((res) => res.file)
              : [checkResults.fileVerified[0]?.file]
          );
        }
        if (checkResults.fileQuarantine.length) {
          toast.show(
            'error',
            t('dsFileUpload.virusDetected', {
              number: checkResults.fileQuarantine.length
            })
          );
        }
      } else {
        toast.showError(new Error(t('dsFileUpload.virusCheckFailed')));
      }
    } else {
      emit(
        'update:model-value',
        props.multiple ? files.value : [files.value[0]]
      );
    }

    isDraggedOver.value = false;
  } catch (e) {
    toast.showError(e);
  } finally {
    loading.value = false;
  }
};

const checkFiles = async (files) => {
  let fileQuarantine = null;
  let fileVerified = null;

  const maxCall = 15;
  const delayBetweenCall = 1000;
  let fileToCheck = cloneDeep(files);

  for (let i = 0; i < maxCall && fileToCheck.length; i++) {
    const checkVerified = fileToCheck.map(async (file) => {
      return { file, exists: await isFileS3Exist(file.key, props.bucket) };
    });

    const checkQuarantine = fileToCheck.map(async (file) => {
      return { file, exists: await isFileS3Exist(file.key, quarantineBucket) };
    });

    const resCheckVerified = await Promise.all(checkVerified);
    const resCheckQuarantine = await Promise.all(checkQuarantine);

    fileVerified = resCheckVerified.filter((fileInfo) => fileInfo.exists);
    fileQuarantine = resCheckQuarantine.filter((fileInfo) => fileInfo.exists);

    fileToCheck = files.filter(
      (fileInfo) =>
        ![...fileQuarantine, ...fileVerified].find(
          (fileProcessInfo) => fileProcessInfo.file.key === fileInfo.key
        )
    );

    if (i + 1 < maxCall && fileToCheck.length) {
      await delay(delayBetweenCall);
    }
  }

  return {
    allCheckFinished: !fileToCheck.length,
    fileQuarantine,
    fileVerified
  };
};

const isFileS3Exist = async (key, bucket) => {
  try {
    await getFile({
      bucket,
      key
    });
    return true;
  } catch (error) {
    return false;
  }
};

const doUploadFiles = async (filesToUpload: File[]) => {
  try {
    const res = await uploadFiles(
      filesToUpload,
      bucketName.value,
      (progress: number, file: File) => {
        uploadStatus.value[uploadKey(file)] = Math.min(99, progress);
      },
      props.bucketPath
    );
    for (const f of res) {
      uploadStatus.value[uploadKey(f.file)] = 100;
    }
    return res;
  } catch (e) {
    toast.showError(e, 'Error uploading file');
  }
};

const removeFile = (file: AWSDocumentWithFile) => {
  if (!file) {
    return;
  }
  const idx = files.value.findIndex((f) => f.key === file.key);
  if (idx >= 0) {
    if (props.multiple) {
      files.value.splice(idx, 1);
      emit('update:model-value', files.value);
    } else {
      files.value = null;
      emit('update:model-value', []);
    }
  }
};

const filename = (file: AWSDocumentWithFile) => {
  if (!file) {
    return null;
  }
  return file.file ? file.file?.name : file.key?.split('/').pop();
};

const uploadKey = (file: File) => {
  if (!file) {
    return null;
  }
  return file.name + '_' + file.lastModified;
};

const onDowloadFile = async (document: AWSDocument) => {
  const getFileResponse = await getFile(document);

  const objectUrl = URL.createObjectURL((getFileResponse as any).Body);

  window.open(objectUrl, '_blank');
};

defineExpose({
  addFiles,
  reset,
  show: () => {
    input.value?.click();
  }
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/design-system/field-label.scss';

.drag-and-drop-area-wrapper {
  display: flex;
  flex-direction: column;
  // gap: 4px;
  .uploader {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }
}

.drag-and-drop-area {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 192px;
  padding: 10px;

  border: 2px dashed $gray200;
  border-radius: 12px;

  &.dragged-over {
    background-color: $gray25;
  }

  &.drag-and-drop-area--compact {
    height: 164px;
  }
}

.area-description {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  padding: 0px;
}

.drag-and-drop-text {
  display: flex;
  gap: 8px;
  align-items: center;
}

.file-formats {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $gray1000;
  text-align: center;
  letter-spacing: -0.12px;

  opacity: 0.6;
}

.file-formats span {
  margin-right: 4px;
}

.file-button {
  cursor: default;

  position: relative;

  justify-content: start;

  width: 100%;
  min-height: 36px;

  & > :deep(.slot-container) {
    justify-content: start;
    width: 100%;
  }

  & > :deep(*) {
    z-index: 1;
  }

  .filename {
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      // text-decoration: underline;
      cursor: pointer;
    }
  }

  .loader {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;

    overflow: hidden;
    display: none;

    width: 0%;

    background-color: white;

    transition: width 0.5s ease-in-out;
    animation: move 2s linear infinite;
  }

  &.uploading {
    background-color: $gray150 !important;

    .loader {
      display: block;
    }

    :deep(.ds-icon:last-child) {
      animation: rotation 2s linear infinite;
    }
  }

  &.uploaded {
    .loader {
      display: none;
    }
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 0;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
