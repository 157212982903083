<template>
  <div
    class="main-calendar"
    @mouseleave="mouseleave()"
    @mouseenter="mouseenter()"
  >
    <div class="header-calendar">
      <DsSelect
        class="period"
        :options="yearOptions"
        :model-value="currentYear"
        @update:modelValue="(val) => updateYear(val)"
      />
      <div class="month">
        {{ months[month] }}
      </div>
      <div class="arrows-move-month">
        <!-- <div
          :style="[disableArrowDown() ? {'pointer-events': 'none'} : null]"
          @click="monthDown()"
        >
          <i
            class="arrow left"
            :style="[disableArrowDown() ? {'border-color':'#989CB2'} : null]"
          />
        </div> -->
        <div @click="monthDown()">
          <i class="arrow left" />
        </div>
        <div
          :style="[disableArrowUp() ? { 'pointer-events': 'none' } : null]"
          @click="monthUp()"
        >
          <i
            class="arrow right"
            :style="[disableArrowUp() ? { 'border-color': '#989CB2' } : null]"
          />
        </div>
      </div>
    </div>
    <article class="body-calendar">
      <section>
        <div class="weekdays">
          <div
            v-for="(weekday, index) in weekdays"
            :key="index"
            class="weekday"
          >
            {{ weekday }}
          </div>
        </div>
        <div
          class="date"
          :data-disables-dates-before-today="disablesDatesBeforeToday"
        >
          <div
            v-for="(n, index) in firstMonthDay -
            (sundayIsFirstDayOfTheWeek ? 0 : 1)"
            :key="'prev' + index"
            class="day-hidden"
          >
            <!-- {{ (prevMonthDays +1) - firstMonthDay + n }} -->
          </div>
          <div
            v-for="(n, index) in currentMonthDays"
            :key="'day' + index"
            class="day"
            :data-is-date-less-than-today="isDateLessThanToday(index)"
            :class="{
              active: isDateEnd(n) || isDateBegin(n),
              disableOutDate:
                (!allowDatesAfterToday && isDateGreaterThanToday(index)) ||
                (disablesDatesBeforeToday && isDateLessThanToday(index)),
              hoverDate: fillDateAfterAndBefore(index) || fillDateBetween(index)
            }"
            @click="selectDate(n)"
            @mouseover="dateHover = index + 1"
          >
            {{ n }}
          </div>
          <div
            v-for="(n, index) in 43 - (currentMonthDays + firstMonthDay)"
            :key="'next' + index"
            class="day-hidden"
          >
            <!-- {{ n }} -->
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
// import DsAutocomplete from './DsAutocomplete.vue'
import DsSelect from './DsSelect.vue';

export default {
  name: 'DsCalendar',
  components: {
    // DsAutocomplete,
    DsSelect
  },
  props: {
    disablesDatesBeforeToday: {
      type: Boolean,
      required: false,
      default: false
    },
    allowDatesAfterToday: {
      type: Boolean,
      required: false,
      default: false
    },
    sundayIsFirstDayOfTheWeek: {
      type: Boolean,
      required: false,
      default: false
    },
    position: {
      type: String,
      required: false,
      default: ''
    },
    dateBegin: {
      type: Object,
      required: true
    },
    dateEnd: {
      type: Object,
      required: true
    },
    month: {
      type: Number,
      required: false,
      default: new Date().getMonth()
    },
    year: {
      type: Number,
      required: false,
      default: new Date().getFullYear()
    },
    exit: {
      type: Boolean,
      required: false
    },
    period: {
      type: String,
      required: false,
      default: 'today'
    },
    yearOptions: {
      type: Array,
      required: true
    }
  },
  emits: [
    'updateYear',
    'updateMonth',
    'updateDate',
    'enterLeft',
    'enterRight',
    'exitLeft',
    'exitRight'
  ],
  data() {
    return {
      // weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
      // months: [
      //   'January', 'February', 'March', 'April', 'May', 'June', 'July',
      //   'August', 'September', 'October', 'November', 'December'
      // ],
      dateHover: null,
      mouseLeave: false,
      today: new Date(),
      currentYear: this.year
    };
  },
  computed: {
    weekdays() {
      return this.getWeekdaysLocale();
    },
    months() {
      return this.getMonthsLocale();
    },
    prevMonthDays() {
      const year = this.month === 0 ? this.year - 1 : this.year;
      const month = this.month === 0 ? 12 : this.month;
      return new Date(year, month, 0).getDate();
    },
    firstMonthDay() {
      let firstDay = new Date(this.year, this.month, 1).getDay();
      if (firstDay === 0) firstDay = 7;
      return firstDay;
    },
    currentMonthDays() {
      return new Date(this.year, this.month + 1, 0).getDate();
    }
  },
  watch: {
    year(newValue) {
      this.currentYear = newValue;
    },
    period() {
      this.mouseLeave = false;
    }
  },
  methods: {
    getWeekdaysLocale(localeName = 'en', weekday = 'short') {
      const locale = ['en', 'pt', 'fr'].includes(this.$i18n.locale)
        ? this.$i18n.locale
        : 'en';
      const format = new Intl.DateTimeFormat(locale, { weekday }).format;
      return [...Array(7).keys()]
        .map((day) =>
          format(
            new Date(
              Date.UTC(2021, 1, day + (this.sundayIsFirstDayOfTheWeek ? 0 : 1))
            )
          )
        )
        .map((l) => l.slice(0, 2));
    },
    getMonthsLocale(localeName = 'en', month = 'long') {
      const locale = ['en', 'pt', 'fr'].includes(this.$i18n.locale)
        ? this.$i18n.locale
        : 'en';
      const format = new Intl.DateTimeFormat(locale, { month }).format;
      return [...Array(12).keys()].map((m) =>
        format(new Date(Date.UTC(2021, m, 1)))
      );
    },
    updateYear(val) {
      this.currentYear = val;
      this.$emit('updateYear', val);
    },
    mouseleave() {
      if (this.period === 'custom') {
        this.dateHover = null;
        this.mouseLeave = true;
        if (this.position === 'calendarLeft') {
          if (this.period === 'custom') {
            this.$emit('exitLeft');
          }
        }
        if (this.position === 'calendarRight') {
          if (this.period === 'custom') {
            this.$emit('exitRight');
          }
        }
      }
    },
    mouseenter() {
      if (this.period === 'custom') {
        if (this.position === 'calendarLeft') {
          this.$emit('enterLeft');
        }
        if (this.position === 'calendarRight') {
          this.$emit('enterRight');
        }
        this.mouseLeave = false;
      }
    },
    selectDate(n) {
      const date = {
        date: n,
        month: this.month,
        year: this.year
      };
      this.dateHover = null;
      this.$emit('updateDate', date);
    },
    monthUp() {
      this.$emit('updateMonth', this.month + 1);
      // if (this.month === 11) {
      //   this.$emit('updateYear', this.year + 1)
      //   this.$emit('updateMonth', 0)
      // } else {
      //   this.$emit('updateMonth', this.month + 1)
      // }
    },
    monthDown() {
      this.$emit('updateMonth', this.month - 1);
      // if (this.month === 0) {
      //   this.$emit('updateYear', this.year - 1)
      //   this.$emit('updateMonth', 11)
      // } else {
      //   this.$emit('updateMonth', this.month - 1)
      // }
    },
    fillDateBetween(index) {
      if (this.period !== 'today') {
        if (this.dateEnd.date !== 0 && this.dateBegin.date !== 0) {
          const d = new Date(this.year, this.month + 1, 0);
          if (this.dateEnd.month !== this.dateBegin.month) {
            if (
              this.position === 'calendarLeft' &&
              this.dateBegin.month === this.month &&
              this.dateBegin.year === this.year
            ) {
              return (
                index <= parseInt(d.getDate()) && index >= this.dateBegin.date
              );
            }
            if (
              this.position === 'calendarRight' &&
              this.dateEnd.month === this.month &&
              this.dateEnd.year === this.year
            ) {
              return index >= 0 && index + 1 < this.dateEnd.date;
            }
          } else {
            if (this.dateBegin.year !== this.dateEnd.year) {
              if (
                this.position === 'calendarLeft' &&
                this.dateBegin.month === this.month &&
                this.dateBegin.year === this.year
              ) {
                return (
                  index <= parseInt(d.getDate()) && index >= this.dateBegin.date
                );
              }
              if (
                this.position === 'calendarRight' &&
                this.dateEnd.month === this.month &&
                this.dateEnd.year === this.year
              ) {
                return index >= 0 && index + 1 < this.dateEnd.date;
              }
            } else {
              return (
                this.dateEnd.date !== 0 &&
                this.dateBegin.date !== 0 &&
                index + 1 < this.dateEnd.date &&
                index + 1 > this.dateBegin.date &&
                this.month === this.dateEnd.month &&
                this.year === this.dateEnd.year
              );
            }
          }
        }
      }
    },
    fillDateAfterAndBefore(index) {
      if (this.period === 'custom') {
        if (this.dateEnd.date === 0 && this.dateBegin.date !== 0) {
          if (this.dateHover !== null) {
            const d = new Date(this.year, this.month + 1, 0);
            if (this.dateBegin.month !== this.month) {
              if (this.position === 'calendarLeft') {
                return (
                  index <= parseInt(d.getDate()) && index >= this.dateHover
                );
              }
              if (this.position === 'calendarRight') {
                return index >= 0 && index < this.dateHover;
              }
            }
            if (this.dateBegin.month === this.month) {
              if (this.dateBegin.year === this.year) {
                if (this.dateEnd.date !== 0) {
                  return (
                    this.dateHover != null &&
                    ((index + 1 < this.dateEnd.date &&
                      index + 1 > this.dateHover) ||
                      (index + 1 > this.dateEnd.date &&
                        index + 1 < this.dateHover))
                  );
                }
                if (this.dateBegin.date !== 0) {
                  return (
                    this.dateHover != null &&
                    ((index + 1 < this.dateBegin.date &&
                      index + 1 > this.dateHover) ||
                      (index + 1 > this.dateBegin.date &&
                        index + 1 < this.dateHover))
                  );
                }
              } else {
                if (this.position === 'calendarLeft') {
                  return (
                    index <= parseInt(d.getDate()) && index >= this.dateHover
                  );
                }
                if (this.position === 'calendarRight') {
                  return (
                    index >= 0 &&
                    index < this.dateHover &&
                    this.year === this.dateBegin.year + 1
                  );
                }
              }
            }
          }
          if (this.mouseLeave) {
            if (
              this.month === this.dateBegin.month &&
              this.year === this.dateBegin.year
            ) {
              if (this.position === 'calendarLeft') {
                const d = new Date(this.year, this.month, 0);
                return (
                  index >= this.dateBegin.date && index <= parseInt(d.getDate())
                );
              }
              if (this.position === 'calendarRight') {
                return index >= 0 && index + 1 < this.dateBegin.date;
              }
            }
          }
        }
      }
    },
    disableArrowUp() {
      if (this.allowDatesAfterToday) {
        return false;
      }
      const today = new Date();
      // if (this.period !== 'custom') {
      //   return true
      // }
      if (this.position === 'calendarRight') {
        return (
          this.month === today.getMonth() && this.year === today.getFullYear()
        );
      }
      if (this.position === 'calendarLeft') {
        return (
          (this.month + 1 === today.getMonth() ||
            (this.month === 11 && today.getMonth() === 0)) &&
          (this.year === today.getFullYear() ||
            this.year + 1 === today.getFullYear())
        );
      }
      return false;
    },
    disableArrowDown() {
      if (this.period !== 'custom') {
        return true;
      }
      if (this.position === 'calendarRight') {
        return this.month === 1 && this.year === 2000;
      }
      if (this.position === 'calendarLeft') {
        return this.month === 0 && this.year === 2000;
      }
      return false;
    },
    isDateBegin(n) {
      return (
        this.dateBegin != null &&
        n === this.dateBegin.date &&
        this.month === this.dateBegin.month &&
        this.year === this.dateBegin.year
      );
    },
    isDateEnd(n) {
      return (
        this.dateEnd != null &&
        n === this.dateEnd.date &&
        this.month === this.dateEnd.month &&
        this.year === this.dateEnd.year
      );
    },
    isDateGreaterThanToday(index) {
      return (
        this.month === this.today.getMonth() &&
        this.year === this.today.getFullYear() &&
        index >= this.today.getDate()
      );
    },
    isDateLessThanToday(index) {
      // if the year is in the future return false
      if (this.year > this.today.getFullYear()) {
        return false;
      }
      // if the month is in the future return false
      if (
        this.month > this.today.getMonth() &&
        this.year === this.today.getFullYear()
      ) {
        return false;
      }
      // if the day is in the future return false
      if (index + 2 > this.today.getDate()) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin calendar-layout($property) {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 0;
  grid-template-columns: repeat(auto-fit, 20px);

  padding: $property;
  div {
    display: flex;
    align-items: center;
    justify-content: center;

    aspect-ratio: 1;
    height: 30px;

    color: $gray600;

    border-radius: 5px;
  }
  div:hover:not(.active) {
    color: $gray1000;
  }
}

@mixin arrow-style() {
  cursor: pointer;

  width: 0;
  height: 0;

  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

.main-calendar {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  .header-calendar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .year {
      width: 20%;
    }
    .month {
      cursor: pointer;

      display: flex;
      justify-content: center;

      width: 40%;

      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.14px;
    }
    .arrows-move-month {
      display: flex;
      width: 20%;
      .arrow {
        cursor: pointer;

        display: inline-block;

        padding: 4px;

        border: solid black;
        border-width: 0 1px 1px 0;
      }
      .right {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        margin-right: 7px;
        margin-left: 2px;
      }
      .left {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        margin-right: 2px;
        margin-left: 7px;
      }
    }
  }
  .body-calendar {
    user-select: none;

    overflow: hidden;

    width: fit-content;

    font-weight: 500;

    background-size: cover;
    border-radius: 15px;
    section {
      width: 210px;
    }
    header {
      overflow: hidden;
      display: flex;
      align-items: top;
      justify-content: center;

      height: 400px;
      padding: 20px 0 0;

      color: #efefef;
      text-align: center;
      text-shadow:
        1px 1px 1px #222,
        1px -1px 1px #222,
        -1px 1px 1px #222,
        -1px -1px 1px #222;
      .current-date {
        width: 300px;
      }
      .arrow-up {
        @include arrow-style();

        border-bottom: 10px solid white;
        &:hover {
          border-bottom: 10px solid rgba(0, 0, 0, 0.4);
        }
      }
      .arrow-down {
        @include arrow-style();

        border-top: 10px solid white;
        &:hover {
          border-top: 10px solid rgba(0, 0, 0, 0.4);
        }
      }
      .current-day {
        font-size: 56px;
      }
      .today {
        display: grid;
        grid-gap: 0;
        grid-template-columns: 40px auto 70px;
        font-size: 28px;
        div {
          display: flex;
          justify-content: center;
        }
      }
    }
    .weekdays {
      @include calendar-layout(10px 0 0 0);
      div:nth-child(7n) {
        color: $gray600;
      }
    }
    .date {
      @include calendar-layout(0 0 0 0);
      .active {
        font-weight: 500;
        color: white;
        background-color: $blue500;
        border-radius: 12px;
      }
      .disableOutDate {
        pointer-events: none;
        &:not(.active) {
          color: $gray150;
        }
      }
      .hoverDate {
        color: $gray1000;
        background-color: rgba(65, 87, 255, 0.1);
        border-radius: 12px;
      }
      .day {
        cursor: pointer;

        aspect-ratio: 1;

        border: solid;
        border-color: white !important;
        border-width: 1px;
        &:hover {
          color: $gray1000;
        }
        &:hover:not(.active) {
          background-color: rgba(65, 87, 255, 0.1);
          border-radius: 12px;
        }
      }
      .day-hidden {
        opacity: 0.4;
        &:nth-child(7n) {
          color: $gray600;
        }
      }
    }
  }
}
</style>
