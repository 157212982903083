<template>
  <div class="ds-iban-field">
    <label
      v-if="label"
      class="DS_field-label"
      @click="focusModelInput"
    >
      {{ label }}
    </label>
    <input
      ref="modelInput"
      :name="name"
      type="text"
      :value="modelValue"
      :class="{
        'field-input': true,
        'field-input--is-error-shown': isErrorShown
      }"
      :placeholder="placeholder"
      @input="onInput"
      @blur="onBlur"
    />
    <p
      v-show="isErrorShown"
      class="field-error"
    >
      {{ $t('designSystem.ibanField.ibanError') }}
    </p>
  </div>
</template>

<script>
import IBAN from 'iban';

export default {
  name: 'DsIbanField',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue', 'isValid'],
  data() {
    return {
      isErrorShown: false
    };
  },
  watch: {
    modelValue() {
      this.isErrorShown = false;

      const trimmedValue = this.modelValue.trim();
      this.$emit('isValid', IBAN.isValid(trimmedValue));
    }
  },
  mounted() {
    if (this.autofocus === true) {
      this.focusModelInput();
    }
  },
  methods: {
    onInput(event) {
      const trimmedValue = event.target.value.trim();

      this.$emit('update:modelValue', trimmedValue);
      this.$emit('isValid', IBAN.isValid(trimmedValue));
    },
    onBlur(event) {
      const trimmedValue = event.target.value.trim();

      if (trimmedValue && !IBAN.isValid(trimmedValue)) {
        this.isErrorShown = true;
      }
    },
    focusModelInput() {
      this.$refs.modelInput.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/field-label.scss';

.ds-iban-field {
  flex-grow: 1;
}

.field-input {
  cursor: text;

  display: block;

  width: 100%;
  height: 36px;
  padding: 0 11px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $gray1000;
  text-align: left;
  letter-spacing: -0.14px;

  background-color: white;
  border: 1px solid $gray100;
  border-radius: 12px;

  &:hover {
    border-color: $gray200;
  }

  &:focus {
    border-color: $blue500;
  }
}

.field-error {
  margin-top: 4px;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $red500;
  letter-spacing: -0.12px;
}
</style>
