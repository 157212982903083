import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useRouterStore = defineStore('routerStore', () => {
  const previousVisitedRouteName = ref(null);

  return {
    previousVisitedRouteName
  };
});
