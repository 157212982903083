import { isEqual, isObject, transform } from 'lodash-es';

export const objectDiff = (object: object, base: object): object => {
  function changes(object: object, base: object): object {
    return transform(base, (result, value, key) => {
      if (!isEqual(value, object[key])) {
        result[key] =
          isObject(value) && isObject(object[key])
            ? changes(object[key], value)
            : value;
      }
    });
  }
  return changes(object, base);
};

export const arrayToObject = (arr, keyName) => {
  return arr.reduce((obj, item) => {
    obj[item[keyName]] = JSON.parse(JSON.stringify(item));
    return obj;
  }, {});
};

export const removeUndefinedNullFromObject = <T>(obj: T): T => {
  Object.keys(obj).forEach(
    (key) => (obj[key] === undefined || obj[key] === null) && delete obj[key]
  );
  return obj;
};

export const removeKeysFromObject = <T>(obj: T, keysToDelete: string[]): T => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => removeKeysFromObject(item, keysToDelete)) as T;
  }

  const result = { ...obj };
  for (const key in result) {
    if (keysToDelete.includes(key)) {
      delete result[key];
    } else if (Array.isArray(result[key])) {
      result[key] = result[key].map((item) =>
        typeof item === 'object' && item !== null
          ? removeKeysFromObject(item, keysToDelete)
          : item
      ) as T[Extract<keyof T, string>];
    } else if (typeof result[key] === 'object' && result[key] !== null) {
      result[key] = removeKeysFromObject(result[key], keysToDelete);
    }
  }

  return result as T;
};
