<template>
  <div>
    <div v-if="label">
      <label
        class="DS_field-label"
        @click="focusModelInput"
      >
        {{ label }}
      </label>
    </div>
    <input
      ref="modelInput"
      :value="modelValue"
      type="time"
      step="900"
      class="field-input"
      @input="onInput"
    />
  </div>
</template>

<script>
export default {
  name: 'DsTimeField',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  mounted() {
    if (this.autofocus === true) {
      this.focusModelInput();
    }
  },
  methods: {
    onInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    focusModelInput() {
      this.$refs.modelInput.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/field-label.scss';

.field-input {
  cursor: text;

  height: 36px;
  padding: 0 11px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $dark800;
  text-align: left;
  letter-spacing: -0.14px;

  background-color: $blue25;
  border: 1px solid $blue150;
  border-radius: 6px;

  &:focus {
    border-color: $blue500;
  }
}
</style>
