<template>
  <PfIcon
    v-if="iconSvg"
    :svg="iconSvg"
    :color="props.color"
    :hovered-color="props.colorHover"
    :rotate="props.rotate"
    :size="computedSize"
  />
</template>

<script lang="ts" setup>
import { capitalize, computed, onMounted } from 'vue';

import { iconSet } from '@/components/icons';
import { IconSvg } from '@/helpers/design-system';

import PfIcon from '../NewDesignSystem/PfIcon/PfIcon.vue';

const props = defineProps({
  name: { type: String, required: true },
  size: { type: String, default: 'big' },
  color: { type: String, default: '' },
  colorHover: { type: String, default: '' },
  rotate: { type: [Number, String], default: 0 },
  circle: { type: Boolean, default: false },
  scale: { type: Number, default: 1 }
});

const computedSize = computed(
  () => (props.size === 'big' ? 20 : 12) * props.scale
);

const normalizedName = computed(() =>
  props.name
    ?.split('-')
    .map((x) => capitalize(x))
    .join('')
);

const iconSvg = computed<IconSvg>(() =>
  normalizedName.value ? iconSet[`Icon${normalizedName.value}`] : undefined
);

onMounted(() => {
  if (props.name && !iconSvg.value) {
    console.warn(`cannot find "${props.name}" icon`);
  }
});
</script>

<style lang="scss" scoped></style>
