import { API } from 'aws-amplify';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import {
  getCounterparties,
  getCounterpartyToApproveNumber,
  getExportCounterparties
} from '@/api/counterparty';
import { getCounterpartiesAnalyticalAxes } from '@/api/fawkes';
import { GetExportCounterpartiesQueryFormat } from '@/api/schemas/counterparty';
import { getFile } from '@/api/storage';
import {
  IconBank,
  IconBriefcase,
  IconCart,
  IconShop
} from '@/components/icons';
import {
  AccountIdentifierType,
  BankIdentifierType,
  BeneficiaryEntityType,
  CounterpartyStatus,
  CounterpartyType,
  CreateCounterpartyDetailsInput,
  CreateCounterpartyInput,
  CurrencyCode,
  RetrieveCounterpartiesIdOutput,
  RetrieveCounterpartiesInput,
  RetrieveCounterparty
} from '@/custom-types/counterparties';
import { ErpSyncStatus } from '@/custom-types/erp';
import { SortByOrder } from '@/custom-types/sorting';
import {
  getArrayNumberQueryValue,
  getArrayQueryValue,
  getNumberQueryValue
} from '@/helpers/query';
import { notAuthenticatedRouteNames } from '@/router/routes';
import { useToastStore } from '@/stores/toast';

import { useTenantStore } from './data-models/tenant';

export const useCounterpartiesStore = defineStore(
  'counterparties',
  function () {
    interface ParamsConfirmModal {
      title: string;
      description: string;
      validBtnText: string;
      cancelBtnText?: string;
      validAction: () => void;
      cancelAction: () => void;
    }

    const toast = useToastStore();
    const route = useRoute();
    const tenantStore = useTenantStore();

    const isLoading = ref(false);
    const isLoadingAA = ref(false);

    const hasAlreadyCalledGetCounterparties = ref<boolean>(false);
    const arrayOfCounterpartiesActive = ref<RetrieveCounterparty[]>([]);
    const arrayOfCounterpartiesAll = ref<RetrieveCounterparty[]>([]);
    const saveArrayOfCounterpartiesAll = ref<RetrieveCounterparty[]>([]);
    const showingModalToCreateCounterparty = ref<boolean>(
      route.query.openModalToAddCounterparty === 'true'
    );
    const showingModalToCreateBankDetails = ref<boolean>(false);
    const showingModalManageBankDetails = ref<boolean>(false);
    const showingModalAssignBankDetails = ref<boolean>(false);
    const showCounterpartyConfirmActionModal = ref<boolean>(false);
    const parametersConfirmActionModal = ref<ParamsConfirmModal>(
      {} as ParamsConfirmModal
    );
    const currentTab = ref<
      'ALL' | 'VENDOR' | 'CUSTOMER' | 'EMPLOYEE' | 'NOT_APPROVED'
    >('ALL');
    const counterpartiesNumberToApprove = ref(0);
    // TODO make a function to reset formCreateCounterparty when opening modal to create new counterparty
    const formCreateCounterparty = ref<CreateCounterpartyInput>({
      beneficiary_entity_type: null,
      display_name: null,
      relationship: null
    } as CreateCounterpartyInput);
    const formCreateCounterpartyDetails = ref<CreateCounterpartyDetailsInput>(
      {} as CreateCounterpartyDetailsInput
    );
    const formCreateCounterpartyDetailsAccountHolderNameChanged =
      ref<boolean>(false);
    const selectedCounterparties = ref<RetrieveCounterparty[]>([]);
    const selectedCounterpartiesIds = computed(
      () =>
        new Set(
          selectedCounterparties.value.map(
            (counterparty) => counterparty.counterparty.id
          )
        )
    );
    const selectedBankDetailsToAssign = ref({ id: null, name: null });
    const orderByName = ref<SortByOrder>();
    const orderByUpdatedAt = ref<SortByOrder>(SortByOrder.DESC);
    const orderByStatus = ref<SortByOrder>();
    const counterpartiesActiveInput = ref<Partial<RetrieveCounterpartiesInput>>(
      {
        counterparty_is_active: true,
        counterparty_payment_is_active: true,
        page_nb: 1,
        number: 20,
        by_updated_at: orderByUpdatedAt.value
      }
    );
    const counterpartiesAllInput = ref<Partial<RetrieveCounterpartiesInput>>({
      counterparty_is_active: true,
      counterparty_payment_is_active: true,
      page_nb: 1,
      number: 20,
      by_updated_at: orderByUpdatedAt.value
    });
    const idEditCounterparty = ref<number>(null);
    const AnalyticalAxesAssocCT = ref<RetrieveCounterpartiesIdOutput[]>([]);
    const hasMore = ref<boolean>(false);
    const nextPage = ref<number>(null);
    const AAFetchedIds = ref<number[]>([]);
    const preventFetching = ref<boolean>(true);

    interface ParamsConfirmModal {
      title: string;
      description: string;
      validBtnText: string;
      cancelBtnText?: string;
      validAction: () => void;
      cancelAction: () => void;
    }

    const fetchCounterpartiesToApproveNumber = async () => {
      try {
        const responseCounterpartyToApproveNumber =
          await getCounterpartyToApproveNumber();
        counterpartiesNumberToApprove.value =
          responseCounterpartyToApproveNumber.count;
      } catch {
        counterpartiesNumberToApprove.value = 0;
      }
    };

    const fetchAnalyticalAxes = async () => {
      isLoadingAA.value = true;
      const allIDs = extractCounterpartiesIds(
        arrayOfCounterpartiesActive.value
      );
      const idsToFetch = allIDs.filter(
        (id) => !AAFetchedIds.value.includes(id)
      );
      if (idsToFetch.length === 0) {
        return;
      }
      try {
        const response = await getCounterpartiesAnalyticalAxes({
          counterparties_id: idsToFetch
        });
        AnalyticalAxesAssocCT.value = [
          ...AnalyticalAxesAssocCT.value,
          ...response.counterparties
        ];
        AAFetchedIds.value = [...AAFetchedIds.value, ...allIDs];
      } catch (error) {
        toast.showError(error);
      } finally {
        isLoadingAA.value = false;
      }
    };

    const callGetCounterparties = async () => {
      try {
        isLoading.value = true;

        hasAlreadyCalledGetCounterparties.value = true;
        const responseCounterpartiesActive = await getCounterparties(
          counterpartiesActiveInput.value
        );

        if (counterpartiesActiveInput.value.page_nb === 1) {
          arrayOfCounterpartiesActive.value = cloneDeep(
            responseCounterpartiesActive.counterparties
          );
        } else {
          arrayOfCounterpartiesActive.value =
            arrayOfCounterpartiesActive.value.concat(
              responseCounterpartiesActive.counterparties
            );
        }
        if (responseCounterpartiesActive.has_more) {
          hasMore.value = true;
          nextPage.value = responseCounterpartiesActive.next_page;
        } else {
          hasMore.value = false;
          nextPage.value = null;
          counterpartiesActiveInput.value.page_nb = 1;
        }
        fetchAnalyticalAxes();
      } catch (error) {
        if (!API.isCancel(error)) toast.showError(error);
      } finally {
        isLoading.value = false;
      }
    };

    const fetchCounterpartiesListing = async () => {
      if (preventFetching.value) {
        return;
      }
      if (
        counterpartiesActiveInput.value.page_nb === 1 &&
        !hasAlreadyCalledGetCounterparties.value
      ) {
        fetchCounterpartiesToApproveNumber();
      }
      await callGetCounterparties();
    };

    const updateFilter = () => {
      if (orderByUpdatedAt.value) {
        counterpartiesActiveInput.value.by_updated_at = orderByUpdatedAt.value;
      } else {
        delete counterpartiesActiveInput.value.by_updated_at;
      }
      if (orderByName.value) {
        counterpartiesActiveInput.value.by_name = orderByName.value;
      } else {
        delete counterpartiesActiveInput.value.by_name;
      }
      if (orderByStatus.value) {
        counterpartiesActiveInput.value.by_display_status = orderByStatus.value;
      } else {
        delete counterpartiesActiveInput.value.by_display_status;
      }
    };

    const findAnalyticalAxesAssocByCTId = (idCT) => {
      return AnalyticalAxesAssocCT.value?.find(
        (elt) => Number(elt.counterparty_id) === Number(idCT)
      );
    };

    const toggleSelectAll = () => {
      if (
        selectedCounterparties.value.length ===
        arrayOfCounterpartiesActive.value.length
      ) {
        selectedCounterparties.value = [];
        return;
      }
      selectedCounterparties.value = arrayOfCounterpartiesActive.value.slice();
    };

    const clearSelectedCounterparties = () => {
      selectedCounterparties.value = [];
    };

    const extractCounterpartiesIds = (arr) => {
      return arr.map((ctp) => ctp.counterparty.id);
    };

    const initFormCreateCounterparty = (
      beneficiaryEntityType = BeneficiaryEntityType.COMPANY
    ) => {
      Object.keys(formCreateCounterparty.value).forEach(
        (key) => delete formCreateCounterparty.value[key]
      );
      formCreateCounterparty.value.beneficiary_entity_type =
        beneficiaryEntityType;
      formCreateCounterparty.value.currency_code = CurrencyCode.EUR;
      formCreateCounterparty.value.erp_tax_item_id = null;
      formCreateCounterparty.value.erp_term_id = null;
    };

    const initFormCreateCounterpartyDetails = () => {
      // Object.keys(formCreateCounterpartyDetails.value).forEach((key) => delete formCreateCounterpartyDetails.value[key])
      formCreateCounterpartyDetails.value.currency_code = CurrencyCode.EUR;
    };

    const initParametersConfirmActionModal = () => {
      // Object.keys(parametersConfirmActionModal.value).forEach((key) => delete parametersConfirmActionModal.value[key])
    };

    const filteringArrayOfCounterparties = (searchQuery) => {
      if (!searchQuery) {
        delete counterpartiesActiveInput.value.name;
        callGetCounterparties();
      } else {
        counterpartiesActiveInput.value.name = searchQuery;
        callGetCounterparties();
        // arrayOfCounterpartiesActive.value = cloneDeep(saveArrayOfCounterpartiesActive.value)
        // arrayOfCounterpartiesActive.value = arrayOfCounterpartiesActive.value.filter((elt) => elt.counterparty.display_name.toLocaleLowerCase().includes(searchQuery))
      }
    };

    const filteringArrayOfAllCounterparties = (searchQuery) => {
      if (!searchQuery) {
        arrayOfCounterpartiesAll.value = cloneDeep(
          saveArrayOfCounterpartiesAll.value
        );
      } else {
        arrayOfCounterpartiesAll.value = cloneDeep(
          saveArrayOfCounterpartiesAll.value
        );
        arrayOfCounterpartiesAll.value = arrayOfCounterpartiesAll.value.filter(
          (elt) =>
            elt.counterparty.display_name
              .toLocaleLowerCase()
              .includes(searchQuery)
        );
      }
    };

    const resetFiltering = () => {
      orderByUpdatedAt.value = SortByOrder.DESC;

      counterpartiesActiveInput.value = {
        counterparty_is_active: true,
        counterparty_payment_is_active: true,
        page_nb: 1,
        number: 20,
        by_updated_at: orderByUpdatedAt.value
      };

      counterpartiesAllInput.value = {
        counterparty_is_active: true,
        counterparty_payment_is_active: true,
        page_nb: 1,
        number: 20,
        by_updated_at: orderByUpdatedAt.value
      };
    };

    watch(showCounterpartyConfirmActionModal, () => {
      if (!showCounterpartyConfirmActionModal.value) {
        initParametersConfirmActionModal();
      }
    });

    onMounted(() => {
      if (route.query.relationship_in) {
        const temp = getArrayQueryValue(
          route.query.relationship_in
        ) as CounterpartyType[];
        counterpartiesActiveInput.value.relationship_in = temp;
        counterpartiesAllInput.value.relationship_in = temp;
      }

      if (route.query.approval_status_in) {
        const temp = getArrayQueryValue(
          route.query.approval_status_in
        ) as CounterpartyStatus[];
        counterpartiesActiveInput.value.approval_status_in = temp;
        counterpartiesAllInput.value.approval_status_in = temp;
      }

      if (route.query.erp_sync_status_in) {
        const temp = getArrayQueryValue(
          route.query.erp_sync_status_in
        ) as ErpSyncStatus[];
        counterpartiesActiveInput.value.erp_sync_status_in = temp;
        counterpartiesAllInput.value.erp_sync_status_in = temp;
      }

      if (route.query.entity_id_in) {
        const temp = getArrayNumberQueryValue(route.query.entity_id_in);
        counterpartiesActiveInput.value.entity_id_in = temp;
        counterpartiesAllInput.value.entity_id_in = temp;
      }

      if (route.query.analytical_axis_node_id_in) {
        const temp = getArrayNumberQueryValue(
          route.query.analytical_axis_node_id_in
        );
        counterpartiesActiveInput.value.analytical_axis_node_id_in = temp;
        counterpartiesAllInput.value.analytical_axis_node_id_in = temp;
      }
    });

    const isAdeoTenantId = (tenantId: number): boolean => {
      const isMain = import.meta.env.VITE_IS_MAIN === 'true';

      return (!isMain && tenantId === 123) || (isMain && tenantId === 144);
    };

    const optionsIdentifier = computed(() => {
      // we can't use features because they try to get authSession during the initialization,
      // causing the redirect to the login page, which ruins the vendor onboarding
      // (even if not used and just imported)
      const isAdeo = notAuthenticatedRouteNames.includes(route.name?.toString())
        ? isAdeoTenantId(getNumberQueryValue(route.query.tenant_id))
        : isAdeoTenantId(Number(localStorage.getItem('tenantId')));

      return [
        {
          value: AccountIdentifierType.IBAN,
          wording: 'IBAN'
        },
        {
          value: AccountIdentifierType.ACCOUNT_NUMBER,
          wording: 'Account number',
          hidden: isAdeo
        },
        {
          value: BankIdentifierType.BRANCH_CODE,
          wording: 'Branch code',
          hidden: isAdeo
        },
        {
          value: BankIdentifierType.SORT_CODE,
          wording: 'SORT code',
          hidden: isAdeo
        },
        {
          value: BankIdentifierType.ABA,
          wording: 'ABA',
          hidden: isAdeo
        },
        {
          value: BankIdentifierType.BIC_SWIFT,
          wording: 'BIC/SWIFT'
        },
        {
          value: BankIdentifierType.CNAPS,
          wording: 'CNAPS',
          hidden: isAdeo
        },
        {
          value: BankIdentifierType.BSB_CODE,
          wording: 'BSB code',
          hidden: isAdeo
        },
        {
          value: BankIdentifierType.CLABE,
          wording: 'CLABE',
          hidden: isAdeo
        },
        {
          value: BankIdentifierType.ACCOUNT_ID,
          wording: 'Account id',
          hidden: isAdeo
        }
      ].filter((x) => !x.hidden);
    });

    const exportCounterpartiesLoading = ref(false);
    const callExportCounterparties = async function () {
      try {
        exportCounterpartiesLoading.value = true;
        const getExportCounterpartiesRes = await getExportCounterparties({
          counterparty_ids: Array.from(selectedCounterpartiesIds.value),
          format: GetExportCounterpartiesQueryFormat.CSV
        });
        const getFileRes = await getFile(
          {
            key: getExportCounterpartiesRes.key,
            bucket: getExportCounterpartiesRes.bucket
          },
          false
        );
        window.open(getFileRes, '_blank');
      } catch (error) {
        toast.showError(error);
      } finally {
        exportCounterpartiesLoading.value = false;
      }
    };

    const getCounterpartyIcon = (relationship) => {
      switch (relationship) {
        case CounterpartyType.CUSTOMER:
          return 'cart';
        case CounterpartyType.EMPLOYEE:
          return 'briefcase';
        case CounterpartyType.VENDOR:
          return 'shop';
        default:
          return 'bank';
      }
    };

    const getCounterpartySvg = (relationship: CounterpartyType) => {
      switch (relationship) {
        case CounterpartyType.CUSTOMER:
          return IconCart;
        case CounterpartyType.EMPLOYEE:
          return IconBriefcase;
        case CounterpartyType.VENDOR:
          return IconShop;
        default:
          return IconBank;
      }
    };

    return {
      exportCounterpartiesLoading,
      callExportCounterparties,
      callGetCounterparties,
      arrayOfCounterpartiesActive,
      hasAlreadyCalledGetCounterparties,
      showingModalToCreateCounterparty,
      formCreateCounterparty,
      selectedCounterparties,
      toggleSelectAll,
      selectedCounterpartiesIds,
      initFormCreateCounterparty,
      showingModalToCreateBankDetails,
      optionsIdentifier,
      filteringArrayOfCounterparties,
      showingModalManageBankDetails,
      formCreateCounterpartyDetails,
      formCreateCounterpartyDetailsAccountHolderNameChanged,
      showingModalAssignBankDetails,
      selectedBankDetailsToAssign,
      counterpartiesActiveInput,
      arrayOfCounterpartiesAll,
      filteringArrayOfAllCounterparties,
      initFormCreateCounterpartyDetails,
      showCounterpartyConfirmActionModal,
      parametersConfirmActionModal,
      initParametersConfirmActionModal,
      isLoading,
      idEditCounterparty,
      findAnalyticalAxesAssocByCTId,
      AnalyticalAxesAssocCT,
      hasMore,
      nextPage,
      isLoadingAA,
      orderByName,
      orderByUpdatedAt,
      orderByStatus,
      updateFilter,
      clearSelectedCounterparties,
      currentTab,
      counterpartiesNumberToApprove,
      getCounterpartyIcon,
      getCounterpartySvg,
      resetFiltering,
      fetchCounterpartiesListing,
      preventFetching,
      AAFetchedIds
    };
  }
);
