export enum Typo {
  Headline = 'headline',
  Body = 'body',
  BodyBold = 'body-bold',
  SmallBody = 'small-body',
  SmallBodyBold = 'small-body-bold',
  Title = 'title',
  SmallTitle = 'small-title',
  Overline = 'overline',
  Digits = 'digits',
  DigitsBold = 'digits-bold',
  ExtraSmall = 'extra-small'
}
