<template>
  <main>
    <RouterView />
    <div
      v-if="
        clientStore.isLoadingClient &&
        !firstAppLoadDone &&
        !isSignup &&
        !isVendorOnboarding &&
        !isAddingNewBankAccount
      "
      class="app__loader"
    >
      <DsLoader />
    </div>
    <DsToast
      v-if="toast.isShown"
      class="ds-toast-component"
      :type="toast.type"
      :message="toast.message"
      @hide="toast.hide"
    />
    <ConfirmActionModal
      v-model:visible="confirmModal.isConfirmModalVisible"
      v-bind="confirmModal.confirmModalData"
    />
  </main>
</template>

<script lang="ts" setup>
import { useTitle } from '@vueuse/core';
import { Auth } from 'aws-amplify';
import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import ConfirmActionModal from '@/components/Modals/ConfirmActionModal.vue';
import { useConfirmModal } from '@/composables/useConfirmModal';
import { useToastStore } from '@/stores/toast';

import { RouteNames } from './router/routes';
import { useClientStore } from './stores/data-models/client';

const toast = useToastStore();
const i18n = useI18n();
const router = useRouter();
const route = useRoute();
const confirmModal = useConfirmModal();
const clientStore = useClientStore();
const { appContext } = getCurrentInstance();

const shouldShowRefreshModal =
  appContext.config.globalProperties.showRefreshModal;
const firstAppLoadDone = ref(false);

const isSignup = computed(() =>
  [RouteNames.SIGNUP, RouteNames.WITH_GOOGLE, RouteNames.WITH_OKTA].includes(
    route.name as RouteNames
  )
);

const isVendorOnboarding = computed(
  () =>
    route.name === RouteNames.VENDOR_ONBOARDING ||
    route.name === RouteNames.VENDOR_ONBOARDING_V2
);

const isAddingNewBankAccount = computed(
  () => route.name === RouteNames.CONNECTOR_CREDS
);

onMounted(async () => {
  const title = useTitle();
  title.value =
    import.meta.env.VITE_IS_MAIN === 'true'
      ? 'Payflows'
      : `Payflows ${window.location.hostname?.split('.')[0]}`;
  document.documentElement.setAttribute('lang', i18n.locale.value);

  console.log(
    `%cCommit ID: ${import.meta.env.VITE_COMMIT_ID || 'Not available'}`,
    'color: #00a8e8; font-size: 12px; letter-spacing: -0.12px;'
  );

  /* Hotjar Tracking Code for https://app.payflows.io */
  if (import.meta.env.VITE_IS_HOTJAR_ENABLED === 'true') {
    /* eslint-disable */
    (function (h: any, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3195076, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    /* eslint-enable */
  }
  try {
    await Auth.currentSession();
  } catch {
    firstAppLoadDone.value = true;
  }
  await router.isReady();
  localStorage.setItem(
    'debugClientRoles',
    String(route.query.debugClientRoles)
  );
});

watch(
  () => clientStore.isLoadingClient,
  (newValue) => {
    if (!newValue) {
      firstAppLoadDone.value = true;
    }
  }
);

watch(shouldShowRefreshModal, (newValue) => {
  if (newValue) {
    confirmModal.prompt({
      title: i18n.t('newVersionAvailable.title'),
      description: i18n.t('newVersionAvailable.description'),
      validAction: () => {
        window.location.reload();
      },
      validBtnText: i18n.t('newVersionAvailable.validBtnText'),
      hideCancel: true
    });
  }
});
</script>

<style lang="scss" scoped>
.app__loader {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background: $gray50;
}
.ds-toast-component {
  position: fixed;
  z-index: 1001;
  right: 30px;
  bottom: 30px;

  .body-ds-full-modal-is-opened & {
    right: 50px;
    bottom: 50px;
  }

  @media screen and (max-width: 768px) {
    right: 16px;
    left: 16px;

    :deep(.ds-toast) {
      width: auto;
    }
  }
}
</style>
