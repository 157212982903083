import { captureException } from '@sentry/vue';

import { getFile } from '@/api/storage';

export const formatFileSize = (
  bytes: number,
  system: 'metric' | 'binary' = 'metric',
  numberOfDecimals = 2
) => {
  const threshold = system === 'metric' ? 1000 : 1024;

  if (Math.abs(bytes) < threshold) {
    return bytes + ' B';
  }

  const units =
    system === 'metric'
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let currentUnit = -1;
  const result = 10 ** numberOfDecimals;

  do {
    bytes /= threshold;
    ++currentUnit;
  } while (
    Math.round(Math.abs(bytes) * result) / result >= threshold &&
    currentUnit < units.length - 1
  );

  return bytes.toFixed(numberOfDecimals) + ' ' + units[currentUnit];
};

export const getFileNameFromAWSKey = (key: string) => {
  return key ? key.split('/').pop() : '';
};

export const downloadFile = async (
  bucket: string,
  key: string,
  download = true
) => {
  try {
    return getFile(
      {
        key,
        bucket
      },
      download
    );
  } catch (error) {
    captureException(error);
  }
};
