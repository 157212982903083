<template>
  <div class="ds-switch">
    <div
      :class="{
        'switch-container': true,
        'switch-container--is-disabled': disabled
      }"
      @click="clickModelInput"
    >
      <input
        ref="modelInput"
        type="checkbox"
        class="switch-model-input"
        :checked="modelValue"
        :disabled="disabled"
        @change="$emit('update:modelValue', $event.target.checked)"
      />
      <div class="switch-box" />
      <div class="switch-disc" />
    </div>
    <label
      v-if="label"
      class="switch-label"
      @click="focusModelInput"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'DsSwitch',
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  mounted() {
    if (this.autofocus === true) {
      this.focusModelInput();
    }
  },
  methods: {
    clickModelInput() {
      this.$refs.modelInput.click();
      this.focusModelInput();
    },
    focusModelInput() {
      this.$refs.modelInput.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.ds-switch {
  display: flex;
  align-items: center;
}

.switch-label {
  cursor: default;

  display: inline-block;

  margin-left: 8px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $dark800;
  text-align: left;
  letter-spacing: -0.14px;
}

.switch-container {
  position: relative;
  flex-shrink: 0;
  width: 32px;
  height: 20px;
}

.switch-model-input {
  position: absolute;
  z-index: -1;

  overflow: hidden;

  width: 1px;
  height: 1px;

  opacity: 0;
}

.switch-box {
  cursor: pointer;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: $gray150;
  border-radius: 10px;

  .switch-model-input:focus + & {
    outline: 2px solid $blue150;
  }

  .switch-model-input:checked + & {
    background-color: $blue500;
  }

  .switch-model-input:disabled + & {
    cursor: not-allowed;
  }
  .switch-model-input:disabled:not(:checked) + & {
    background-color: $gray150;
  }

  .switch-model-input:checked:disabled + & {
    background-color: $gray300;
  }
}

.switch-disc {
  cursor: pointer;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  background-color: white;
  border: 2px solid $gray150;
  border-radius: 50%;

  .switch-model-input:checked ~ & {
    left: 12px;
    border-color: $blue500;
  }

  .switch-model-input:disabled ~ & {
    cursor: not-allowed;
  }

  .switch-model-input:checked:disabled ~ & {
    background: $gray150;
    border-color: $gray300;
  }

  .switch-model-input:disabled:not(:checked) ~ & {
    background: $gray300;
    border-color: $gray200;
  }
}
</style>
