<template>
  <div
    ref="containerView"
    class="values-container"
    :class="{
      ellipses: currentLineComputed < 1,
      'prevent-flex-wrap': preventFlexWrapInField
    }"
  >
    <div
      v-for="option in values.filter((_, i) => !ellipses || i <= ellipseLimit)"
      :key="option"
      :ref="
        (el) => {
          itemViews.push(el as HTMLElement);
        }
      "
      style="width: fit-content; max-width: 100%"
    >
      <slot
        v-if="slots['value']"
        name="value"
        v-bind="option"
      />
      <div
        v-else
        class="value"
      >
        <p>
          {{ option?.wording || option?.value || option }}
        </p>
        <DsIcon
          name="cross"
          color="#000b4d"
          size="small"
          @click.stop="emit('remove', option?.value || option)"
        />
      </div>
    </div>
    <template v-if="ellipses && values.length > ellipseLimit">
      <slot
        v-if="slots['ellipse']"
        name="ellipse"
        v-bind="{ value: values.length - ellipseLimit - 1 }"
      />
      <div
        v-else-if="onlyOne && values.length > 1"
        class="value"
        v-html="`+ ${values.length - ellipseLimit - 1}`"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, onUnmounted, ref, useSlots, watch } from 'vue';

import DsIcon from './DsIcon.vue';

const props = withDefaults(
  defineProps<{
    values: any[];
    ellipses?: boolean;
    onlyOne?: boolean;
    nbOfLine?: number;
    preventFlexWrapInField?: boolean;
  }>(),
  {
    onlyOne: false,
    ellipses: false,
    nbOfLine: 1
  }
);

const emit = defineEmits(['remove']);

const valuesCache = ref<any[]>(null);
const containerView = ref<HTMLElement>(null);
const itemViews = ref<HTMLElement[]>([]);
const ellipseLimit = ref<number>(16);
const slots = useSlots();
const currentLineComputed = ref<number>(0);
const isBreak = ref(false);

const updateContentWidthOverflows = () => {
  ellipseLimit.value = 16;
  itemViews.value = [];
  currentLineComputed.value = 0;
  isBreak.value = false;
  nextTick(() => {
    nextTick(() => {
      if (itemViews.value && containerView.value) {
        let accum = 0;
        for (let i = 0; i < itemViews.value.length; i++) {
          const elem = itemViews.value[i];
          const elemNext =
            i < itemViews.value.length - 1 ? itemViews.value[i + 1] : null;
          if (elem) {
            const elemWidth = ((elem as HTMLElement).offsetWidth || 0) + 4;
            const nextWidth = (elemNext as HTMLElement)?.offsetWidth || 0;
            accum += elemWidth;
            if (accum + nextWidth >= containerView.value.offsetWidth) {
              if (!props.onlyOne) {
                ellipseLimit.value = i - 1;
              } else {
                ellipseLimit.value = 0;
              }
              currentLineComputed.value += 1;
              accum = elemWidth;
              if (currentLineComputed.value === props.nbOfLine) {
                isBreak.value = true;
                break;
              }
            }
          }
        }
        if (!isBreak.value) {
          if (props.onlyOne) {
            ellipseLimit.value = 0;
          } else {
            ellipseLimit.value = itemViews.value.length;
          }
        }
      }
    });
  });
};

onMounted(() => {
  window.addEventListener('resize', updateContentWidthOverflows);
  watch(props, (newValue) => {
    if (newValue.values !== valuesCache.value) {
      nextTick(() => {
        updateContentWidthOverflows();
      });
    }
  });
  updateContentWidthOverflows();
});

onUnmounted(() => {
  window.removeEventListener('resize', updateContentWidthOverflows);
});

defineExpose({
  forceUpdateEllipsis: () => updateContentWidthOverflows()
});
</script>

<style scoped lang="scss">
.values-container {
  overflow: hidden;
  display: flex;
  gap: 4px;
  align-items: center;

  width: 100%;

  &:not(.ellipses) {
    flex-wrap: wrap;
  }

  &.prevent-flex-wrap {
    flex-wrap: nowrap;
  }
}

.value {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  padding: 4px 8px;

  font-size: 12px;
  font-weight: 600;
  line-height: initial;
  line-height: 16px; /* 133.333% */
  color: $dark800;
  text-align: center;
  letter-spacing: -0.12px;
  white-space: pre;
  // background-color: $blue150;
  border: 1px solid $gray150;
  border-radius: 12px;
  > svg {
    margin-left: 4px;
  }
}
</style>
