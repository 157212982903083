<template>
  <div
    class="ds-file-overlay-wrapper"
    @dragover.stop.prevent="dragging = true"
    @dragenter.stop.prevent="dragAccumulator++"
    @dragend.stop.prevent="dragging = false"
    @dragleave.stop.prevent="dragAccumulator = Math.max(0, dragAccumulator - 1)"
    @drop.stop.prevent="handleDrop"
  >
    <slot />
    <label
      class="ds-file-overlay"
      :class="{ dragging: dragging && dragAccumulator > 0 }"
    >
      <input
        type="file"
        :multiple="multiple"
        :accept="fileFormats.join(',')"
        style="display: none"
      />
      <div class="box">
        <DsIcon
          name="upload"
          size="big"
          :scale="2"
        />
        <div class="text">
          Drop files here.
          <div class="supports">Supports : {{ formatsList }}</div>
        </div>
      </div>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';

const emit = defineEmits<{
  (e: 'change', FileList): void;
}>();

const props = defineProps<{
  fileFormats: string[];
  multiple?: boolean;
}>();

const dragging = ref(false);
const dragAccumulator = ref(0);
const timeout = ref(null);

const formatsList = computed(() => {
  return props.fileFormats.join(', ');
});

const handleDrop = (e) => {
  dragging.value = false;
  emit('change', e.dataTransfer.files);
};

watch(dragging, (v) => {
  if (v) {
    clearTimeout(timeout.value);
    timeout.value = setTimeout(() => {
      dragging.value = false;
      dragAccumulator.value = 0;
    }, 3000);
  }
});
</script>

<style scoped lang="scss">
.ds-file-overlay-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  min-height: 0;
  & > :deep(*:first-child) {
    overflow: auto;
  }
}

.ds-file-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  display: none;
  align-items: center;
  justify-content: center;

  backdrop-filter: blur(5px);

  .box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;

    width: 422px;
    height: 240px;
    padding: 10px;

    border: 2px dashed $gray400;
    border-radius: 12px;
    .text {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      .supports {
        font-size: 12px;
        line-height: 16px;
        color: $gray500;
        letter-spacing: -0.12px;
      }
    }
  }

  &.dragging {
    display: flex;
  }
}
</style>
