<template>
  <div
    :class="{
      'ds-select': true,
      'ds-select--is-disabled': disabled,
      ellipses: ellipses
    }"
  >
    <label
      v-if="label"
      class="DS_field-label"
      @click="focusSelectButton"
    >
      {{ label
      }}<span
        v-if="required"
        class="label-asterisk"
        >*</span
      >
    </label>
    <div
      ref="selectContainer"
      class="select-container"
    >
      <button
        ref="selectButton"
        class="select-button"
        type="button"
        :disabled="disabled || options.length === 0"
        @click="onClickOnButton"
        @keydown="onKeydownOnButton"
        @keyup.prevent
      >
        <slot />
        <span
          v-if="modelValue.length === 0"
          class="select-button-placeholder"
        >
          {{ placeholder }}
        </span>
        <div
          v-else
          class="select-button-left"
        >
          <DsInlineList
            ref="selectButtonLeft"
            class="select-button-values-container"
            :ellipses="ellipses"
            :values="modelValue.map((v) => options.find((o) => o.value === v))"
            @remove="toggleValue($event.value || $event)"
          >
            <template
              v-if="slots['value']"
              #value="slotProps"
            >
              <slot
                name="value"
                v-bind="slotProps"
                :remove="() => toggleValue(slotProps.value)"
              />
            </template>
          </DsInlineList>
        </div>

        <DsIcon
          class="select-button-chevron-icon"
          :name="isDropdownShown ? 'chevron-up' : 'chevron-down'"
          size="small"
          :color="isDropdownShown ? 'blue500' : 'gray500'"
        />
      </button>
      <div
        v-show="isDropdownShown"
        ref="selectDropdown"
        class="select-dropdown"
      >
        <div
          v-for="(option, index) in options"
          :key="option.value"
          :class="{
            'select-option': true,
            'select-option--is-selected': modelValue.includes(option.value),
            'select-option--is-focused': index === focusedOptionIndex,
            'select-option--is-disabled': option.disabled
          }"
          @click="onClickOnOption(option.value)"
        >
          <slot
            v-if="slots['option']"
            name="option"
            v-bind="option"
          />
          <template v-else>
            {{ option.wording || option.value }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  useSlots,
  watch
} from 'vue';

import { COLORS_PALETTE } from '../../helpers/design-system';
import DsIcon from './DsIcon.vue';

export default {
  name: 'DsMultiSelect',
  components: {
    DsIcon
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array as PropType<any[]>,
      required: true,
      validator(options: any[]) {
        return options.every((option) => {
          return (
            Object.prototype.hasOwnProperty.call(option, 'value') &&
            Object.prototype.hasOwnProperty.call(option, 'wording')
          );
        });
      }
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    ellipses: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    /**
     * General
     */

    const isDropdownShown = ref(false);
    const focusedOptionIndex = ref(null);
    const selectedValues = ref([]);
    const valuesContainer = ref(null);
    const slots = useSlots();

    const chevronIconColor = computed(() => {
      return isDropdownShown.value
        ? COLORS_PALETTE.blue500
        : COLORS_PALETTE.dark700;
    });

    /**
     * Focus select button
     */

    const selectButton = ref(null);
    const selectButtonLeft = ref(null);

    function focusSelectButton() {
      selectButton.value.focus();
    }

    onMounted(() => {
      if (props.autofocus === true) {
        focusSelectButton();
      }
    });

    /**
     * Helpers
     */

    const selectDropdown = ref(null);

    function updateDropdownScroll() {
      nextTick(() => {
        const selectDropdownElement = selectDropdown.value;

        if (focusedOptionIndex.value > 2) {
          const optionHeight =
            selectDropdownElement.firstElementChild.offsetHeight;
          selectDropdownElement.scrollTop =
            (focusedOptionIndex.value - 2) * optionHeight;
        } else {
          selectDropdownElement.scrollTop = 0;
        }
      });
    }

    function getWordingFromValue(value) {
      return props.options.find((option) => option.value === value)?.wording;
    }

    function toggleValue(toggledValue) {
      const selected = Array.from(props.modelValue);
      const foundIndex = selected.findIndex(
        (selectedValue) => selectedValue === toggledValue
      );

      if (foundIndex === -1) {
        selected.push(toggledValue);
      } else {
        selected.splice(foundIndex, 1);
      }
      selectedValues.value = selected;
      emit('update:modelValue', selected);
    }

    /**
     * Click events
     */

    function onClickOnButton() {
      if (isDropdownShown.value === false) {
        isDropdownShown.value = true;
        focusedOptionIndex.value = null;
        updateDropdownScroll();
      } else {
        isDropdownShown.value = false;
      }
      focusSelectButton(); // Needed for Firefox and Safari
    }

    function onClickOnOption(value) {
      toggleValue(value);
      focusSelectButton();
    }

    /**
     * Mousedown event
     */

    const selectContainer = ref(null);

    function handleMousedown(event) {
      if (selectContainer.value.contains(event.target) === false) {
        isDropdownShown.value = false;
      }
    }

    watch(isDropdownShown, (value) => {
      if (value === true) {
        window.document.addEventListener('mousedown', handleMousedown);
      } else {
        window.document.removeEventListener('mousedown', handleMousedown);
      }
    });

    onUnmounted(() => {
      window.document.removeEventListener('mousedown', handleMousedown);
    });

    /**
     * Keyboard navigation
     */

    function onKeydownOnButton(event) {
      function handleKeyArrowDown() {
        if (isDropdownShown.value === false) {
          isDropdownShown.value = true;
          focusedOptionIndex.value = 0;
        } else {
          if (focusedOptionIndex.value === null) {
            focusedOptionIndex.value = 0;
          } else if (focusedOptionIndex.value < props.options.length - 1) {
            focusedOptionIndex.value += 1;
          }
        }
        updateDropdownScroll();
      }

      function handleKeyArrowUp() {
        if (isDropdownShown.value === false) {
          isDropdownShown.value = true;
          focusedOptionIndex.value = 0;
        } else {
          if (focusedOptionIndex.value === null) {
            focusedOptionIndex.value = 0;
          } else if (focusedOptionIndex.value > 0) {
            focusedOptionIndex.value -= 1;
          }
        }
        updateDropdownScroll();
      }

      function handleKeyEnter() {
        if (
          isDropdownShown.value === true &&
          focusedOptionIndex.value !== null
        ) {
          toggleValue(props.options[focusedOptionIndex.value].value);
        }
      }

      function handleKeySpace() {
        if (isDropdownShown.value === false) {
          isDropdownShown.value = true;
          focusedOptionIndex.value = null;
          updateDropdownScroll();
        } else {
          if (focusedOptionIndex.value !== null) {
            toggleValue(props.options[focusedOptionIndex.value].value);
          } else {
            isDropdownShown.value = false;
          }
        }
      }

      switch (event.code) {
        case 'ArrowDown':
          event.preventDefault();
          handleKeyArrowDown();
          break;
        case 'ArrowUp':
          event.preventDefault();
          handleKeyArrowUp();
          break;
        case 'Enter':
          event.preventDefault();
          handleKeyEnter();
          break;
        case 'Space':
          event.preventDefault();
          handleKeySpace();
          break;
        case 'Escape':
        case 'Tab':
          isDropdownShown.value = false;
          break;
      }
    }

    const showDropDown = () => {
      isDropdownShown.value = true;
    };

    /**
     * Expose
     */

    return {
      /* template refs */
      selectContainer,
      selectButton,
      selectButtonLeft,
      selectDropdown,
      showDropDown,
      valuesContainer,
      selectedValues,
      slots,

      /* state */
      isDropdownShown,
      focusedOptionIndex,
      chevronIconColor,

      /* functions */
      focusSelectButton,
      getWordingFromValue,
      toggleValue,
      onClickOnButton,
      onClickOnOption,
      onKeydownOnButton
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/field-label.scss';

.select-button-placeholder {
  @include typo-body;

  overflow: hidden;

  color: $gray400;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ds-select {
  flex-grow: 1;

  &.ds-select--is-disabled {
    opacity: 1;

    .select-button-placeholder {
      color: $gray300;
    }
  }
}

.select-container {
  position: relative;
}

.select-button {
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;

  width: 100%;
  min-height: 36px;
  padding: 0 11px;

  background-color: white;
  border: 1px solid $gray100;
  border-radius: 12px;

  &:hover {
    border-color: $gray200;
  }

  &:focus {
    border-color: $blue500;
    outline: 2px solid $blue150;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $gray50;
  }
}

.select-button-values-container {
  display: flex;
  gap: 4px;
  align-items: center;
}

.ds-select:not(.ellipses) .select-button-values-container {
  flex-wrap: wrap;
}

.select-button-left {
  display: flex;
  gap: 8px;
  align-items: center;
}

.ds-select.ellipses {
  .select-button-left {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    margin-right: 8px;
  }
  &.overflows .select-button-left::after {
    content: '';

    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 100%;

    width: 0;

    clip-path: inset(100% 32px);
    box-shadow: 0px 0 16px 16px white;
  }
  .select-button-values-container {
    white-space: nowrap;
  }
}

.select-button-value {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 4px 6px 4px 8px;

  font-size: 12px;
  font-weight: 500;
  line-height: initial;
  color: $dark800;
  text-align: center;
  letter-spacing: -0.12px;

  background-color: $blue150;
  border-radius: 3px;

  > svg {
    margin-left: 4px;
  }
}

.select-button-chevron-icon {
  margin-left: auto;
}

.select-dropdown {
  position: absolute;
  z-index: 1;
  top: calc(100% + 8px);
  right: 0;
  left: 0;

  overflow-y: auto;

  max-height: 184px;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.2);
}

.select-option {
  cursor: pointer;
  user-select: none;

  overflow: hidden;

  padding: 11px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $dark700;
  text-align: left;
  text-overflow: ellipsis;
  letter-spacing: -0.14px;
  white-space: nowrap;

  &:not(:last-child) {
    border-bottom: 1px solid $blue50;
  }

  &.select-option--is-selected {
    color: $blue500;
  }

  &.select-option--is-disabled {
    pointer-events: none;
    color: $gray400;
  }

  &:hover,
  &.select-option--is-focused {
    background-color: $blue50;
  }
}

.label-asterisk {
  color: $red500;
}
</style>
