<template>
  <div
    class="ds-table-amount-cell"
    :class="{
      neg: amountData.amount < 0,
      pos: amountData.amount > 0,
      zeo: amountData.amount === 0
    }"
  >
    {{ amountData.currency }}{{ amountData.amount?.toFixed(2) }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import CURRENCIES from '@/helpers/currencies.json';
import { useClientStore } from '@/stores/data-models/client';

const clientStore = useClientStore();

const props = defineProps<{
  modelValue: any;
}>();

const amountData = computed(() => {
  let amount = null;
  let currency: string = null;
  if (typeof props.modelValue === 'number') {
    amount = props.modelValue;
    currency = clientStore.client.default_currency;
  } else {
    amount = props.modelValue?.amount || props.modelValue?.value;
    currency =
      props.modelValue?.currency || clientStore.client?.default_currency;
  }
  const split = currency?.split('/');
  if (split?.length > 0) {
    currency = split[0];
  }
  return {
    amount,
    currency: CURRENCIES.find((c) => c.cc === currency)?.symbol
  };
});
</script>

<style scoped lang="scss">
.ds-table-amount-cell {
  text-align: right;

  &.neg {
    color: $red500;

    &:before {
      content: '(';
    }

    &:after {
      content: ')';
    }
  }

  &.pos {
    color: $green500;
  }
}
</style>
