export const enum PolicyAction {
  READ = 'READ',
  WRITE = 'WRITE'
}

export const enum PolicyEffect {
  ALLOW = 'ALLOW',
  DENY = 'DENY'
}

export const enum FilterType {
  TENANT_ID = 'TENANT_ID',
  ENTITY_ID = 'ENTITY_ID',
  GROUP_ID = 'GROUP_ID',
  ACCOUNT_ID = 'ACCOUNT_ID',
  CATEGORY_ID = 'CATEGORY_ID',
  SUBCATEGORY_ID = 'SUBCATEGORY_ID',
  TRANSACTION_ID = 'TRANSACTION_ID',
  WITH_UNCATEGORIZED = 'WITH_UNCATEGORIZED'
}

export const enum RessourceType {
  ACCOUNT = 'ACCOUNT',
  TRANSACTION = 'TRANSACTION'
}

export interface PolicyModel {
  id?: number;
  client_id: number;
  ressource_type: RessourceType;
  filter_type?: FilterType;
  filter_value?: number;
  action: PolicyAction;
  effect: PolicyEffect;
}

export const enum Role {
  ADMIN = 'ADMIN',
  VIEWER = 'VIEWER'
}
