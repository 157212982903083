import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import {
  createAxe,
  createTree,
  deleteAxe,
  deleteChildNode,
  getAllAnalyticalAxes,
  getAnalyticalTreeById,
  updateAxe,
  updateChildNode
} from '@/api/analyticalAxes';
import {
  AnalyticalAxesChildModel,
  AnalyticalAxesModel,
  AnalyticalAxisCategory,
  AnalyticalAxisType
} from '@/custom-types/analytical-axes';
import { removeFromArrayObject, sortArrayOfObject } from '@/helpers/array';

import { useClientStore } from '../data-models/client';
import { useToastStore } from '../toast';

export const useSettingsAnalyticalAxesStore = defineStore(
  'settingsAnalyticalAxes',
  function () {
    const clientStore = useClientStore();
    const route = useRoute();
    const router = useRouter();

    const analyticalAxesArray = ref<AnalyticalAxesModel[]>([]);
    const isLoading = ref(true);
    const { t } = useI18n();

    const currentAxisIdEditing = ref(null);
    const isCreatingChildNode = ref(false);
    const isUpdatingChildNode = ref(false);
    const isDeletingChildNode = ref(false);

    const editingNewAnalyticalAxe = ref(false);
    const newAnalyticalAxe = ref({
      name: '',
      color: null,
      type: null,
      categories: [
        AnalyticalAxisCategory.PURCHASE,
        AnalyticalAxisCategory.TREASURY
      ],
      can_have_members: false
    });

    const creatingNewAnalyticalAxe = ref(false);
    const forbiddenName = ref([]);
    const forbiddenSubName = ref([]);

    const newChildNode = ref({
      id: null,
      name: '',
      color: '',
      entity_ids: [],
      parent_node_id: null,
      nodes: null
    });
    const creatingNewChildNode = ref(false);

    const editChildNode = ref<AnalyticalAxesChildModel>({
      id: null,
      color: '',
      nodes: null,
      name: '',
      entity_ids: [],
      parent_node_id: null,
      owner: null
    });
    const editingChildNode = ref(false);

    const childNodeCreationParent = ref<AnalyticalAxesChildModel>(null);
    const nodeSubItems = ref(['']);
    const isRootNode = ref(false);
    const isDeployed = ref([]);
    const colorsToPick = [
      {
        color: '#f9d4cf',
        colorNamed: 'red150',
        border: '#F19C90',
        checkColor: 'red400',
        tone: 'default'
      },
      {
        color: '#fde7cb',
        colorNamed: 'orange150',
        border: '#F8C586',
        checkColor: 'orange400',
        tone: 'default'
      },
      {
        color: '#fdf3d1',
        colorNamed: 'yellow150',
        border: '#F8D974',
        checkColor: 'yellow600',
        tone: 'default'
      },
      {
        color: '#d5eacb',
        colorNamed: 'green150',
        border: '#9ECF86',
        checkColor: 'green400',
        tone: 'default'
      },
      {
        color: '#d8f2fa',
        colorNamed: 'cyan150',
        border: '#A4E0F3',
        checkColor: 'cyan500',
        tone: 'default'
      },
      {
        color: '#d2d9ff',
        colorNamed: 'blue150',
        border: '#94A4FF',
        checkColor: 'blue400',
        tone: 'default'
      },
      {
        color: '#eed1fc',
        colorNamed: 'purple150',
        border: '#D792F8',
        checkColor: 'purple400',
        tone: 'default'
      },
      {
        color: '#f8d5f0',
        colorNamed: 'pink150',
        border: '#EE9CDC',
        checkColor: 'pink400',
        tone: 'default'
      },
      {
        color: '#e0e1e6',
        colorNamed: 'gray150',
        border: '#B8BAC5',
        checkColor: 'gray700',
        tone: 'default'
      },
      {
        color: '#ffffff',
        colorNamed: 'white',
        border: '#B8BAC5',
        checkColor: 'gray700',
        tone: 'default'
      },
      {
        color: '#e54f3a',
        colorNamed: 'red500',
        border: '#F9D4CF',
        checkColor: 'red200',
        tone: 'primary'
      },
      {
        color: '#f29829',
        colorNamed: 'orange500',
        border: '#FBDBB4',
        checkColor: 'orange200',
        tone: 'primary'
      },
      {
        color: '#f5ce4e',
        colorNamed: 'yellow500',
        border: '#FCEEBF',
        checkColor: 'yellow200',
        tone: 'primary'
      },
      {
        color: '#54ab2a',
        colorNamed: 'green500',
        border: '#C3E1B4',
        checkColor: 'green200',
        tone: 'primary'
      },
      {
        color: '#60c8e9',
        colorNamed: 'cyan500',
        border: '#C6ECF8',
        checkColor: 'cyan200',
        tone: 'primary'
      },
      {
        color: '#425eff',
        colorNamed: 'blue500',
        border: '#BDC7FF',
        checkColor: 'blue200',
        tone: 'primary'
      },
      {
        color: '#b940f1',
        colorNamed: 'purple500',
        border: '#E7BCFB',
        checkColor: 'purple200',
        tone: 'primary'
      },
      {
        color: '#e150c1',
        colorNamed: 'pink500',
        border: '#F5C2EA',
        checkColor: 'pink200',
        tone: 'primary'
      },
      {
        color: '#83869a',
        colorNamed: 'gray500',
        border: '#D3D4DB',
        checkColor: 'gray200',
        tone: 'primary'
      },
      {
        color: '#ffffff',
        colorNamed: 'white',
        border: 'white',
        checkColor: 'white',
        tone: 'primary'
      },
      {
        color: '#a63f34',
        colorNamed: 'red700',
        border: '#F9D4CF',
        checkColor: 'red300',
        tone: 'dark'
      },
      {
        color: '#b07229',
        colorNamed: 'orange700',
        border: '#FBDBB4',
        checkColor: 'orange300',
        tone: 'dark'
      },
      {
        color: '#b29842',
        colorNamed: 'yellow700',
        border: '#FCEEBF',
        checkColor: 'yellow500',
        tone: 'dark'
      },
      {
        color: '#447f28',
        colorNamed: 'green700',
        border: '#C3E1B4',
        checkColor: 'green300',
        tone: 'dark'
      },
      {
        color: '#4a93ae',
        colorNamed: 'cyan700',
        border: '#C6ECF8',
        checkColor: 'cyan400',
        tone: 'dark'
      },
      {
        color: '#3549be',
        colorNamed: 'blue700',
        border: '#BDC7FF',
        checkColor: 'blue300',
        tone: 'dark'
      },
      {
        color: '#8834b4',
        colorNamed: 'purple700',
        border: '#E7BCFB',
        checkColor: 'purple300',
        tone: 'dark'
      },
      {
        color: '#a43f93',
        colorNamed: 'pink700',
        border: '#F5C2EA',
        checkColor: 'pink300',
        tone: 'dark'
      },
      {
        color: '#55596d',
        colorNamed: 'gray700',
        border: '#D3D4DB',
        checkColor: 'gray400',
        tone: 'dark'
      },
      {
        color: '#14172',
        colorNamed: 'gray1000',
        border: '#D3D4DB',
        checkColor: 'gray400',
        tone: 'dark'
      }
    ];

    // Mock, prepare for api call
    const types = [
      {
        value: AnalyticalAxisType.TEAM,
        wording: t('analytical-axes.types.teams'),
        icon: 'team'
      },
      {
        value: AnalyticalAxisType.BUSINESS_UNIT,
        wording: t('analytical-axes.types.business-units'),
        icon: 'briefcase'
      },
      {
        value: AnalyticalAxisType.GEOGRAPHY,
        wording: t('analytical-axes.types.geographies'),
        icon: 'earth'
      },
      {
        value: AnalyticalAxisType.CATEGORY,
        wording: t('analytical-axes.types.categories'),
        icon: 'category'
      },
      {
        value: AnalyticalAxisType.PROJECT,
        wording: t('analytical-axes.types.projects'),
        icon: 'document'
      },
      {
        value: AnalyticalAxisType.PRODUCT_AND_SERVICE,
        wording: t('analytical-axes.types.product-services'),
        icon: 'tag'
      },
      {
        value: AnalyticalAxisType.COST_CENTER,
        wording: t('analytical-axes.types.cost-centers'),
        icon: 'pin'
      },
      {
        value: AnalyticalAxisType.PURCHASE_CATEGORY,
        wording: t('analytical-axes.types.PURCHASE_CATEGORY'),
        icon: 'cart'
      },
      {
        value: AnalyticalAxisType.OTHER,
        wording: t('analytical-axes.types.other'),
        icon: 'axis'
      }
    ];

    const onlyOnceType = [
      AnalyticalAxisType.TEAM,
      AnalyticalAxisType.BUSINESS_UNIT,
      AnalyticalAxisType.GEOGRAPHY,
      AnalyticalAxisType.CATEGORY,
      AnalyticalAxisType.PROJECT,
      AnalyticalAxisType.PRODUCT_AND_SERVICE,
      AnalyticalAxisType.COST_CENTER,
      AnalyticalAxisType.PURCHASE_CATEGORY
    ];

    const getTypesUnused = computed(() => {
      const usedType = analyticalAxesArray.value
        .map((axis) => {
          return axis.axis_type;
        })
        .filter((elt) => !!elt);

      const res = types.filter((type) => {
        return !(
          onlyOnceType.includes(type.value) && usedType.includes(type.value)
        );
      });

      return sortArrayOfObject(res, 'value');
    });

    const searchQuery = ref(route.query.search || '');

    watch(searchQuery, () => {
      router.push({
        query: {
          ...route.query,
          search: searchQuery.value
        }
      });
    });
    const selectedMap = ref(null);

    const colorsAlreadyUsed = () => {
      const foundAxe = analyticalAxesArray.value.find(
        (axe) => axe.name === childNodeCreationParent.value?.name
      );

      if (foundAxe) {
        return foundAxe.nodes.map((node) => node.color);
      }
      return [];
    };

    const addSubItemToNode = () => {
      nodeSubItems.value.push('');
    };

    const removeSubItemToNode = (index) => {
      nodeSubItems.value.splice(index, 1);
    };

    // Analytical Axes
    const callGetAnalyticalAxes = async () => {
      isLoading.value = true;
      if (
        ['VIEWER', 'CONTRIBUTOR'].includes(
          clientStore.clientRoles?.analytics_role
        ) ||
        clientStore.isAdminOrOwner
      ) {
        await getAllAnalyticalAxes()
          .then(async (response) => {
            analyticalAxesArray.value = response.analytical_axes_trees;

            isDeployed.value = new Array(analyticalAxesArray.value.length).fill(
              false
            );
          })
          .catch((error) => {
            toast.showError(error);
          })
          .finally(() => {
            isLoading.value = false;
          });
      } else {
        analyticalAxesArray.value = [];
        isDeployed.value = [];
        isLoading.value = false;
      }
    };

    const callCreateAnalyticalAxes = async () => {
      const body = {
        name: newAnalyticalAxe.value.name,
        color: newAnalyticalAxe.value.color,
        axis_type: newAnalyticalAxe.value.type,
        categories: newAnalyticalAxe.value.categories,
        can_have_members: newAnalyticalAxe.value.can_have_members
      };

      if (newAnalyticalAxe.value.type === AnalyticalAxisType.OTHER) {
        body.axis_type = null;
      }

      await createAxe(body)
        .then((res) => {
          analyticalAxesArray.value.push({
            ...body,
            id: res.id,
            nodes: []
          });
          isDeployed.value.push(true);

          confirmAllowUnassignedForAllUsersAnalyticalAxis.value.id = res.id;
          confirmAllowUnassignedForAllUsersAnalyticalAxis.value.name =
            newAnalyticalAxe.value.name;
          confirmAllowUnassignedForAllUsersAnalyticalAxis.value.color =
            newAnalyticalAxe.value.color;
          isConfirmAllowUnassignedForAllUsersModalVisible.value = true;
        })
        .catch((error) => {
          toast.showError(error);
        })
        .finally(() => {
          creatingNewAnalyticalAxe.value = false;
          newAnalyticalAxe.value.name = '';
          newAnalyticalAxe.value.type = null;
          newAnalyticalAxe.value.color = null;
          newAnalyticalAxe.value.categories = null;
          newAnalyticalAxe.value.can_have_members = false;
        });
    };

    const callUpdateAnalyticalAxes = async (id: number) => {
      const body = {
        name: newAnalyticalAxe.value.name,
        color: newAnalyticalAxe.value.color,
        axis_type: newAnalyticalAxe.value.type,
        categories: newAnalyticalAxe.value.categories,
        can_have_members: newAnalyticalAxe.value.can_have_members
      };

      if (newAnalyticalAxe.value.type === AnalyticalAxisType.OTHER) {
        body.axis_type = null;
      }

      updateAxe(id, body)
        .then(() => {
          const axeToUpdate = analyticalAxesArray.value.find(
            (elt) => currentAxisIdEditing.value === elt.id
          );
          axeToUpdate.name = newAnalyticalAxe.value.name;
          axeToUpdate.color = newAnalyticalAxe.value.color;
          axeToUpdate.axis_type = newAnalyticalAxe.value.type;
          axeToUpdate.categories = newAnalyticalAxe.value.categories;
          axeToUpdate.can_have_members =
            newAnalyticalAxe.value.can_have_members;
        })
        .catch((error) => {
          toast.showError(error);
        })
        .finally(() => {
          editingNewAnalyticalAxe.value = false;
          currentAxisIdEditing.value = null;
        });
    };

    const callDeleteAnalyticalAxes = async (id, index) => {
      deleteAxe(id)
        .then((res) => {
          removeFromArrayObject(analyticalAxesArray.value, 'id', id);
          isDeployed.value.splice(index, 1);
        })
        .catch((error) => {
          toast.showError(error);
        });
    };

    const getAnalyticalAxisInfo = (id) => {
      const axe = analyticalAxesArray.value.find((elt) => elt.id === id);
      return axe;
    };

    const updateAnalyticalAxeTree = (id, tree) => {
      const axe = analyticalAxesArray.value.find((elt) => elt.id === id);
      axe.nodes = tree;
    };

    const toast = useToastStore();

    // Child nodes
    const callCreateChildNode = async (parentId) => {
      let axeId = parentId;

      if (isRootNode.value) {
        axeId = null;
      }

      const body = {
        nodes: [
          {
            parent_node_id: axeId,
            nodes: [
              {
                name: newChildNode.value.name,
                color: newChildNode.value.color
                  ? newChildNode.value.color
                  : childNodeCreationParent.value.color,
                entity_ids: newChildNode.value.entity_ids,
                nodes: nodeSubItems.value
                  .filter((e) => !!e)
                  .map((elt) => {
                    return {
                      name: elt,
                      entity_ids: newChildNode.value.entity_ids,
                      color: newChildNode.value.color
                        ? newChildNode.value.color
                        : childNodeCreationParent.value.color,
                      nodes: []
                    };
                  })
              }
            ]
          }
        ]
      };

      try {
        isCreatingChildNode.value = true;

        const createTreeResponse = await createTree(
          currentAxisIdEditing.value,
          body
        );
        const getTreeResponse = await getAnalyticalTreeById(
          currentAxisIdEditing.value
        );
        updateAnalyticalAxeTree(getTreeResponse.id, getTreeResponse.nodes);

        if (isRootNode.value) {
          newRootNodeThatNeedsToBeAllowed.value.id = getTreeResponse.nodes.find(
            (elt) => elt.name === newChildNode.value.name
          ).id;
          newRootNodeThatNeedsToBeAllowed.value.name = newChildNode.value.name;
          newRootNodeThatNeedsToBeAllowed.value.color =
            childNodeCreationParent.value.color;
          newRootNodeThatNeedsToBeAllowed.value.parentId =
            childNodeCreationParent.value.id;
          newRootNodeThatNeedsToBeAllowed.value.parentName =
            childNodeCreationParent.value.name;
          newRootNodeThatNeedsToBeAllowed.value.entity_ids =
            childNodeCreationParent.value.entity_ids;
        }
      } catch (error) {
        toast.showError(error);
      } finally {
        if (isRootNode.value) {
          isConfirmAllowForNewRootNodeModalVisible.value = true;
        }

        childNodeCreationParent.value = null;

        creatingNewChildNode.value = false;
        newChildNode.value = {
          id: null,
          name: '',
          color: '',
          parent_node_id: null,
          nodes: null,
          entity_ids: []
        };

        currentAxisIdEditing.value = null;
        nodeSubItems.value = [''];

        isCreatingChildNode.value = false;
      }
    };

    const callDeleteChildNode = async (id) => {
      isDeletingChildNode.value = true;
      deleteChildNode(id)
        .then((res) => {
          removeFromArrayObject(childNodeCreationParent.value.nodes, 'id', id);
        })
        .catch((error) => {
          toast.showError(error);
        })
        .finally(() => {
          editingChildNode.value = false;
          currentAxisIdEditing.value = null;
          isDeletingChildNode.value = false;
        });
    };

    const callUpdateChildNode = async (id) => {
      try {
        isUpdatingChildNode.value = true;
        await updateChildNode(id, {
          name: editChildNode.value.name,
          color: editChildNode.value.color,
          owner: editChildNode.value.owner,
          entity_ids: editChildNode.value.entity_ids
        });
        callGetAnalyticalAxes();
      } catch (error) {
        toast.showError(error);
      } finally {
        editingChildNode.value = false;
        currentAxisIdEditing.value = null;
        isUpdatingChildNode.value = false;
      }
    };

    watch(editingNewAnalyticalAxe, () => {
      if (editingNewAnalyticalAxe.value === false) {
        newAnalyticalAxe.value.name = '';
        newAnalyticalAxe.value.color = null;
        newAnalyticalAxe.value.type = null;
      }
    });

    watch(creatingNewAnalyticalAxe, () => {
      if (creatingNewAnalyticalAxe.value === false) {
        newAnalyticalAxe.value.name = '';
        newAnalyticalAxe.value.color = null;
        newAnalyticalAxe.value.type = null;
      }
    });

    watch(creatingNewChildNode, () => {
      if (creatingNewChildNode.value === false) {
        newChildNode.value = {
          id: null,
          name: '',
          color: '',
          parent_node_id: null,
          nodes: null,
          entity_ids: []
        };
        childNodeCreationParent.value = null;
        isRootNode.value = false;
        forbiddenName.value = [];
        forbiddenSubName.value = [];
        nodeSubItems.value = [''];
      }
    });

    const confirmAllowUnassignedForAllUsersAnalyticalAxis = ref({
      name: '',
      color: '',
      id: null,
      entity_ids: []
    });

    const newRootNodeThatNeedsToBeAllowed = ref({
      name: '',
      color: '',
      id: null,
      parentId: null,
      parentName: '',
      entity_ids: []
    });

    const findNodeById = (id: number): AnalyticalAxesChildModel => {
      const recurse = (nodes: AnalyticalAxesChildModel[]) => {
        for (const node of nodes) {
          if (node.id === id) {
            return node;
          }
          if (node.nodes) {
            const ret = recurse(node.nodes);
            if (ret) {
              return ret;
            }
          }
        }
      };
      for (const axis of analyticalAxesArray.value) {
        const ret = recurse(axis.nodes);
        if (ret) {
          return ret;
        }
      }
      return null;
    };

    const findAxisByNodeId = (nodeId: number): AnalyticalAxesModel => {
      const recurse = (
        axis: AnalyticalAxesModel,
        nodes: AnalyticalAxesChildModel[]
      ) => {
        for (const node of nodes) {
          if (node.id === nodeId || (node.nodes && recurse(axis, node.nodes))) {
            return axis;
          }
        }
      };
      for (const axis of analyticalAxesArray.value) {
        if (recurse(axis, axis.nodes)) {
          return axis;
        }
      }
      return null;
    };

    const isConfirmAllowUnassignedForAllUsersModalVisible = ref(false);
    const isConfirmAllowForNewRootNodeModalVisible = ref(false);

    return {
      analyticalAxesArray,
      findNodeById,
      findAxisByNodeId,
      callGetAnalyticalAxes,
      editingNewAnalyticalAxe,
      newAnalyticalAxe,
      callCreateAnalyticalAxes,
      creatingNewAnalyticalAxe,
      callUpdateAnalyticalAxes,
      currentAxisIdEditing,
      getAnalyticalAxisInfo,
      callDeleteAnalyticalAxes,
      creatingNewChildNode,
      newChildNode,
      isDeployed,
      colorsToPick,
      colorsAlreadyUsed,
      childNodeCreationParent,
      callCreateChildNode,
      callDeleteChildNode,
      callUpdateChildNode,
      isCreatingChildNode,
      isUpdatingChildNode,
      isDeletingChildNode,
      searchQuery,
      selectedMap,
      nodeSubItems,
      addSubItemToNode,
      removeSubItemToNode,
      isRootNode,
      editingChildNode,
      editChildNode,
      isLoading,
      forbiddenName,
      forbiddenSubName,
      types,
      getTypesUnused,
      onlyOnceType,
      confirmAllowUnassignedForAllUsersAnalyticalAxis,
      isConfirmAllowUnassignedForAllUsersModalVisible,
      isConfirmAllowForNewRootNodeModalVisible,
      newRootNodeThatNeedsToBeAllowed
    };
  }
);
