<template>
  <div class="tabulations-container">
    <button
      v-for="tabulation in tabulations"
      :key="`tabulation-${tabulation.name}`"
      :class="{
        'tabulation-button': true,
        'tabulation-button--is-active': tabulation.name === modelValue
      }"
      type="button"
      @click="$emit('update:modelValue', tabulation.name)"
    >
      <p class="tabulation-button-label">
        {{ tabulation.label }}
      </p>
      <div
        v-if="tabulation.count || tabulation.count === 0"
        class="tabulation-button-count"
      >
        {{ tabulation.count }}
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'DsTabulations',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    tabulations: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue']
};
</script>

<style lang="scss" scoped>
.tabulations-container {
  display: flex;
  border-bottom: 1px solid $blue150;
}

.tabulation-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 8px;

  &:not(:first-child) {
    margin-left: 8px;
  }

  &.tabulation-button--is-active {
    &::before {
      content: '';

      position: absolute;
      right: 0;
      bottom: -1px;
      left: 0;

      width: 100%;
      height: 2px;

      background-color: $blue500;
    }
  }
}

.tabulation-button-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $dark700;
  letter-spacing: -0.14px;

  .tabulation-button--is-active & {
    color: $blue500;
  }
}

.tabulation-button-count {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  margin-left: 8px;
  padding: 0 6px;

  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  color: white;
  text-align: center;
  letter-spacing: 0.1px;

  background-color: $dark700;
  border-radius: 5px;

  .tabulation-button--is-active & {
    background-color: $blue500;
  }
}
</style>
