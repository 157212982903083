<template>
  <div
    class="ds-checkbox"
    :style="{
      cursor: disabled ? 'not-allowed' : 'pointer'
    }"
    :class="{ disabled }"
    @click.stop="clickModelInput"
  >
    <input
      :id="id"
      ref="modelInput"
      type="checkbox"
      class="checkbox-model-input"
      :checked="isChecked || checked"
      :disabled="disabled"
      :tabindex="readonly ? -1 : null"
      :value="value"
      @change="onCheckboxChecked"
    />
    <div class="checkbox-box" />
    <label
      v-if="label"
      :class="{
        'checkbox-label': true,
        'checkbox-label--capitalized': capitalized
      }"
    >
      {{ label }}
      <div class="label-below">
        <slot name="below" />
      </div>
    </label>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';

import { useCheckbox } from '../../composables/useCheckbox';

const modelInput = ref<HTMLElement>(null);

const props = withDefaults(
  defineProps<{
    modelValue?: any;
    value?: any;
    trueValue?: any;
    falseValue?: any;
    id?: string;
    label?: string;
    readonly?: boolean;
    capitalized?: boolean;
    disabled?: boolean;
    autofocus?: boolean;
    indeterminate?: boolean;
    checked?: boolean;
  }>(),
  {
    modelValue: null,
    value: null,
    id: null,
    trueValue: true,
    falseValue: false,
    label: null
  }
);

const emit = defineEmits(['update:modelValue']);

const { onCheckboxChecked, isChecked } = useCheckbox(props, emit);

onMounted(() => {
  if (props.autofocus) {
    focusModelInput();
  }
});

const focusModelInput = () => {
  modelInput.value.focus();
};

const clickModelInput = () => {
  if (!props.readonly) {
    modelInput.value.click();
    focusModelInput();
  }
};

watch(
  () => props.indeterminate,
  () => {
    (modelInput.value as HTMLInputElement).indeterminate = props.indeterminate;
  }
);
</script>

<style lang="scss" scoped>
.ds-checkbox {
  user-select: none;

  position: relative;

  display: flex;
  gap: 8px;
  align-items: center;
}

.checkbox-model-input {
  position: absolute;
  z-index: -1;
  top: 16px;
  left: 8px;

  overflow: hidden;

  width: 1px;
  height: 1px;

  opacity: 0;
}

.checkbox-box {
  cursor: pointer;

  display: inline-block;
  flex-shrink: 0;

  width: 16px;
  height: 16px;

  background: white url(../../assets/img/design-system/checkbox/checkmark.svg)
    no-repeat -999999999px -999999999px;
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px $gray200;

  &:hover {
    box-shadow: inset 0 0 0 1px $gray300;
  }

  .checkbox-model-input:indeterminate + & {
    background-color: $blue500;
    background-image: url(../../assets/img/design-system/checkbox/minus.svg);
    background-position: center;
    box-shadow: inset 0 0 0 1px $blue500;
  }

  .checkbox-model-input:checked + & {
    background-color: $blue500;
    background-position: center;
    box-shadow: inset 0 0 0 1px $blue500;
  }

  .checkbox-model-input:focus + & {
    border-color: $blue500;
    // outline: 2px solid $blue150;
  }

  .checkbox-model-input:disabled + & {
    cursor: not-allowed;
    background-color: $dark100;
    border-color: $dark100;
  }
}

.checkbox-label {
  cursor: pointer;

  display: inline-block;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $gray1000;
  text-align: left;
  letter-spacing: -0.14px;

  .checkbox-model-input:disabled ~ & {
    cursor: not-allowed;
    color: $gray500;
  }

  &.checkbox-label--capitalized {
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.label-below {
  /* Small Body */
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: var(--gray-700, #55596d);
  letter-spacing: -0.12px;
}
</style>
