<template>
  <DsNeoDropdown
    :label="label"
    :required="required"
    :options="options"
    :loading="counterpartiesStore.isLoading"
    search-box
    fixed-width
    :model-value="modelValue"
    @update:model-value="emit('update:modelValue', $event)"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';

import DsNeoDropdown, {
  NeoDropDownOption
} from '@/components/DesignSystem/DsNeoDropdown.vue';
import {
  AccountIdentifierType,
  RetrieveCounterparty
} from '@/custom-types/counterparties';
import { useCounterpartiesStore } from '@/stores/counterparties';

const counterpartiesStore = useCounterpartiesStore();
const { t } = useI18n();

const props = defineProps<{
  counterparty?: RetrieveCounterparty;
  modelValue?: number;
  label?: string;
  required?: boolean;
  allowNew?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', v: number): void;
}>();

onBeforeMount(() => {
  if (!counterpartiesStore.hasAlreadyCalledGetCounterparties) {
    counterpartiesStore.callGetCounterparties();
  }
});

const options = computed(() => {
  if (!props.counterparty?.counterparty_payments) {
    return [];
  }
  const ret: NeoDropDownOption[] = props.counterparty.counterparty_payments.map(
    (ctp) => {
      const ibanDetails = ctp?.payment_details.find(
        (paymentDetail) => paymentDetail.type === AccountIdentifierType.IBAN
      );
      const iban = ibanDetails?.value || ''; // No Iban
      return {
        value: ctp.id,
        wording: iban,
        secondaryWording: ctp.account_holder_name,
        icon: null
      };
    }
  );
  if (props.allowNew) {
    ret.push({
      value: -1,
      wording: 'Request new bank details',
      alwaysVisible: true,
      icon: 'plus'
    });
  }
  return ret;
});
</script>

<style scoped lang="scss">
.wordings {
  display: flex;
  flex-direction: column;
  &.plus {
    color: $blue500;
  }
}
</style>
