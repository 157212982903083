import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { getIntakeTenantParam, GetIntakeTenantParamOutput } from '@/api/intake';
import { getTenant } from '@/api/mystix';
import { GetTenantOutput, TenantModule } from '@/api/schemas/mystix';

import { useToastStore } from '../toast';

export const useTenantStore = defineStore('tenant', () => {
  const toast = useToastStore();

  const getTenantRes = ref<GetTenantOutput>();
  const getTenantLoading = ref(false);
  const getTenantParametersResponse = ref<GetIntakeTenantParamOutput>();

  const callGetTenant = async function () {
    try {
      getTenantLoading.value = true;

      const calls = [getTenant(), getIntakeTenantParam()];

      const [tenantRes, tenantParameters] = (await Promise.all(calls)) as [
        GetTenantOutput,
        GetIntakeTenantParamOutput
      ];

      getTenantRes.value = tenantRes;
      getTenantParametersResponse.value = tenantParameters;
    } catch (e) {
      toast.showError(e, 'getTenant error');
    } finally {
      getTenantLoading.value = false;
    }
  };

  const showErpRelatedThingsAnywaysForDebugging = ref(false);

  const hasErpConnected = computed(() => {
    return (
      getTenantParametersResponse.value?.has_erp_connected ||
      showErpRelatedThingsAnywaysForDebugging.value
    );
  });

  const hasTreasury = computed(() =>
    getTenantRes.value?.modules?.includes(TenantModule.TREASURY)
  );

  const hasProcurement = computed(() => {
    return getTenantRes.value?.modules?.includes(TenantModule.PROCUREMENT);
  });

  return {
    getTenantRes,
    getTenantLoading,
    getTenantParametersResponse,
    callGetTenant,
    hasErpConnected,
    showErpRelatedThingsAnywaysForDebugging,
    hasTreasury,
    hasProcurement
  };
});
