<template>
  <div class="tooltip">
    <slot name="content" />
    <div
      :class="{
        tooltip__text: props.position === 'right',
        tooltip__text__left: props.position === 'left',
        tooltip__text__top: props.position === 'top'
      }"
    >
      <span>
        <slot name="tooltip-text" />
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
type Position = 'right' | 'left' | 'top';

const props = withDefaults(
  defineProps<{
    position?: Position;
    theme?: 'light' | 'dark';
  }>(),
  {
    position: 'right',
    theme: 'dark'
  }
);
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: flex;
  width: fit-content;

  .tooltip__text {
    isolation: isolate;
    position: absolute;
    z-index: 99;
    top: 0;
    right: auto;
    bottom: 0;
    left: calc(100% + 8px);

    display: flex;
    align-items: center;

    visibility: hidden;

    &::after {
      content: '';

      position: absolute;
      top: 50%;
      right: 99%;

      margin-top: -5px;

      border-color: transparent $gray1000 transparent transparent;
      border-style: solid;
      border-width: 5px;
    }

    > span {
      padding: 4px 8px;

      font-size: 12px;
      font-weight: 500;
      color: white;
      text-align: center;
      letter-spacing: -0.12px;
      white-space: nowrap;

      background-color: $gray1000;
      border-radius: 6px;
    }
  }

  .tooltip__text__left {
    position: absolute;
    z-index: 99;
    top: 0;
    right: calc(100% + 8px);
    bottom: 0;

    display: flex;
    align-items: center;

    visibility: hidden;

    &::after {
      content: '';

      position: absolute;
      top: 50%;
      left: 100%;
      transform: rotate(-0.5turn);

      margin-top: -5px;

      border-color: transparent $gray1000 transparent transparent;
      border-style: solid;
      border-width: 5px;
    }

    > span {
      padding: 4px 8px;

      font-size: 12px;
      font-weight: 500;
      color: white;
      text-align: center;
      letter-spacing: -0.12px;
      white-space: nowrap;

      background-color: $gray1000;
      border-radius: 6px;
    }
  }
  &.left {
    .tooltip__text {
      right: calc(100% + 8px);
      left: auto;

      &::after {
        right: 99%;
        left: 99%;
        border-color: transparent transparent transparent $gray1000;
        border-width: 5px 0 5px 5px;
      }
    }
  }

  &.right {
    .tooltip__text {
      right: auto;
      left: calc(100% + 8px);

      &::after {
        right: 99%;
        left: auto;
      }
    }
  }
}

.tooltip__text__top {
  isolation: isolate;
  position: absolute;
  z-index: 99;
  bottom: calc(100% + 8px);

  display: none;

  padding: 4px 8px;

  color: white;
  text-align: center;

  visibility: hidden;
  opacity: 1;
  background-color: black;
  border-radius: 6px;
}

.tooltip:hover .tooltip__text {
  visibility: visible;
}

.tooltip:hover .tooltip__text__left {
  visibility: visible;
}

.tooltip:hover .tooltip__text__top {
  display: block;
  visibility: visible;
}

.tooltip:hover .tooltip__text__top:has(:empty) {
  display: none;
  visibility: hidden;
}

.tooltip:hover .tooltip__text__left:has(:empty) {
  display: none;
  visibility: hidden;
}

.tooltip:hover .tooltip__text:has(:empty) {
  display: none;
  visibility: hidden;
}
</style>
