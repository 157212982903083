import { StepInstanceStatus } from '@/api/approval';
import {
  FormStepCounterpartyCounterpartyStatus,
  FormStepCounterpartyCounterpartyType
} from '@/api/intake/form-steps';
import { Pagination } from '@/api/schemas/common';
import {
  ContactType,
  CounterpartyDocumentBody,
  CreationSource,
  EnrichmentSectionType,
  EnrichmentSource,
  RejectMotiveEnum,
  VendorEnrichmentStep
} from '@/api/schemas/counterparty';
import {
  AnalyticalAxisAssocInput,
  AnalyticalAxisAssocOutput
} from '@/custom-types/analytical-axes';

import { AddressDetailedObject } from './address';
import { ErpSync, ErpSyncStatus } from './erp';
import { SortByOrder } from './sorting';
import {
  VendorCustomField,
  VendorEnrichmentCustomSection,
  VendorEnrichmentCustomSectionUpdate,
  VendorMissingFields
} from './vendor';

export enum CountryCode {
  AF = 'AF',
  AX = 'AX',
  AL = 'AL',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BQ = 'BQ',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  IO = 'IO',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  CV = 'CV',
  KY = 'KY',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CX = 'CX',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CG = 'CG',
  CD = 'CD',
  CK = 'CK',
  CR = 'CR',
  CI = 'CI',
  HR = 'HR',
  CU = 'CU',
  CW = 'CW',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  TF = 'TF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  VA = 'VA',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MK = 'MK',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  MP = 'MP',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  BL = 'BL',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  MF = 'MF',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SX = 'SX',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  GS = 'GS',
  SS = 'SS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SZ = 'SZ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TW = 'TW',
  TJ = 'TJ',
  TZ = 'TZ',
  TH = 'TH',
  TL = 'TL',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  GB = 'GB',
  US = 'US',
  UM = 'UM',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  VG = 'VG',
  VI = 'VI',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW'
}

export enum CurrencyCode {
  AFN = 'AFN',
  EUR = 'EUR',
  ALL = 'ALL',
  DZD = 'DZD',
  USD = 'USD',
  AOA = 'AOA',
  XCD = 'XCD',
  ARS = 'ARS',
  AMD = 'AMD',
  AWG = 'AWG',
  AUD = 'AUD',
  AZN = 'AZN',
  BSD = 'BSD',
  BHD = 'BHD',
  BDT = 'BDT',
  BBD = 'BBD',
  BYN = 'BYN',
  BZD = 'BZD',
  XOF = 'XOF',
  BMD = 'BMD',
  INR = 'INR',
  BTN = 'BTN',
  BOB = 'BOB',
  BOV = 'BOV',
  BAM = 'BAM',
  BWP = 'BWP',
  NOK = 'NOK',
  BRL = 'BRL',
  BND = 'BND',
  BGN = 'BGN',
  BIF = 'BIF',
  CVE = 'CVE',
  KHR = 'KHR',
  XAF = 'XAF',
  CAD = 'CAD',
  KYD = 'KYD',
  CLP = 'CLP',
  CLF = 'CLF',
  CNY = 'CNY',
  COP = 'COP',
  COU = 'COU',
  KMF = 'KMF',
  CDF = 'CDF',
  NZD = 'NZD',
  CRC = 'CRC',
  HRK = 'HRK',
  CUP = 'CUP',
  CUC = 'CUC',
  ANG = 'ANG',
  CZK = 'CZK',
  DKK = 'DKK',
  DJF = 'DJF',
  DOP = 'DOP',
  EGP = 'EGP',
  SVC = 'SVC',
  ERN = 'ERN',
  SZL = 'SZL',
  ETB = 'ETB',
  FKP = 'FKP',
  FJD = 'FJD',
  XPF = 'XPF',
  GMD = 'GMD',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GTQ = 'GTQ',
  GBP = 'GBP',
  GNF = 'GNF',
  GYD = 'GYD',
  HTG = 'HTG',
  HNL = 'HNL',
  HKD = 'HKD',
  HUF = 'HUF',
  ISK = 'ISK',
  IDR = 'IDR',
  XDR = 'XDR',
  IRR = 'IRR',
  IQD = 'IQD',
  ILS = 'ILS',
  JMD = 'JMD',
  JPY = 'JPY',
  JOD = 'JOD',
  KZT = 'KZT',
  KES = 'KES',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KGS = 'KGS',
  LAK = 'LAK',
  LBP = 'LBP',
  LSL = 'LSL',
  ZAR = 'ZAR',
  LRD = 'LRD',
  LYD = 'LYD',
  CHF = 'CHF',
  MOP = 'MOP',
  MKD = 'MKD',
  MGA = 'MGA',
  MWK = 'MWK',
  MYR = 'MYR',
  MVR = 'MVR',
  MRU = 'MRU',
  MUR = 'MUR',
  XUA = 'XUA',
  MXN = 'MXN',
  MXV = 'MXV',
  MDL = 'MDL',
  MNT = 'MNT',
  MAD = 'MAD',
  MZN = 'MZN',
  MMK = 'MMK',
  NAD = 'NAD',
  NPR = 'NPR',
  NIO = 'NIO',
  NGN = 'NGN',
  OMR = 'OMR',
  PKR = 'PKR',
  PAB = 'PAB',
  PGK = 'PGK',
  PYG = 'PYG',
  PEN = 'PEN',
  PHP = 'PHP',
  PLN = 'PLN',
  QAR = 'QAR',
  RON = 'RON',
  RUB = 'RUB',
  RWF = 'RWF',
  SHP = 'SHP',
  WST = 'WST',
  STN = 'STN',
  SAR = 'SAR',
  RSD = 'RSD',
  SCR = 'SCR',
  SLL = 'SLL',
  SGD = 'SGD',
  XSU = 'XSU',
  SBD = 'SBD',
  SOS = 'SOS',
  SSP = 'SSP',
  LKR = 'LKR',
  SDG = 'SDG',
  SRD = 'SRD',
  SEK = 'SEK',
  CHE = 'CHE',
  CHW = 'CHW',
  SYP = 'SYP',
  TWD = 'TWD',
  TJS = 'TJS',
  TZS = 'TZS',
  THB = 'THB',
  TOP = 'TOP',
  TTD = 'TTD',
  TND = 'TND',
  TRY = 'TRY',
  TMT = 'TMT',
  UGX = 'UGX',
  UAH = 'UAH',
  AED = 'AED',
  USN = 'USN',
  UYU = 'UYU',
  UYI = 'UYI',
  UYW = 'UYW',
  UZS = 'UZS',
  VUV = 'VUV',
  VES = 'VES',
  VND = 'VND',
  YER = 'YER',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
  XBA = 'XBA',
  XBB = 'XBB',
  XBC = 'XBC',
  XBD = 'XBD',
  XTS = 'XTS',
  XXX = 'XXX',
  XAU = 'XAU',
  XPD = 'XPD',
  XPT = 'XPT',
  XAG = 'XAG',
  AFA = 'AFA',
  FIM = 'FIM',
  ALK = 'ALK',
  ADP = 'ADP',
  ESP = 'ESP',
  FRF = 'FRF',
  AOK = 'AOK',
  AON = 'AON',
  AOR = 'AOR',
  ARA = 'ARA',
  ARP = 'ARP',
  ARY = 'ARY',
  RUR = 'RUR',
  ATS = 'ATS',
  AYM = 'AYM',
  AZM = 'AZM',
  BYB = 'BYB',
  BYR = 'BYR',
  BEC = 'BEC',
  BEF = 'BEF',
  BEL = 'BEL',
  BOP = 'BOP',
  BAD = 'BAD',
  BRB = 'BRB',
  BRC = 'BRC',
  BRE = 'BRE',
  BRN = 'BRN',
  BRR = 'BRR',
  BGJ = 'BGJ',
  BGK = 'BGK',
  BGL = 'BGL',
  BUK = 'BUK',
  HRD = 'HRD',
  CYP = 'CYP',
  CSJ = 'CSJ',
  CSK = 'CSK',
  ECS = 'ECS',
  ECV = 'ECV',
  GQE = 'GQE',
  EEK = 'EEK',
  XEU = 'XEU',
  GEK = 'GEK',
  DDM = 'DDM',
  DEM = 'DEM',
  GHC = 'GHC',
  GHP = 'GHP',
  GRD = 'GRD',
  GNE = 'GNE',
  GNS = 'GNS',
  GWE = 'GWE',
  GWP = 'GWP',
  ITL = 'ITL',
  ISJ = 'ISJ',
  IEP = 'IEP',
  ILP = 'ILP',
  ILR = 'ILR',
  LAJ = 'LAJ',
  LVL = 'LVL',
  LVR = 'LVR',
  LSM = 'LSM',
  ZAL = 'ZAL',
  LTL = 'LTL',
  LTT = 'LTT',
  LUC = 'LUC',
  LUF = 'LUF',
  LUL = 'LUL',
  MGF = 'MGF',
  MVQ = 'MVQ',
  MLF = 'MLF',
  MTL = 'MTL',
  MTP = 'MTP',
  MRO = 'MRO',
  MXP = 'MXP',
  MZE = 'MZE',
  MZM = 'MZM',
  NLG = 'NLG',
  NIC = 'NIC',
  PEH = 'PEH',
  PEI = 'PEI',
  PES = 'PES',
  PLZ = 'PLZ',
  PTE = 'PTE',
  ROK = 'ROK',
  ROL = 'ROL',
  STD = 'STD',
  CSD = 'CSD',
  SKK = 'SKK',
  SIT = 'SIT',
  RHD = 'RHD',
  ESA = 'ESA',
  ESB = 'ESB',
  SDD = 'SDD',
  SDP = 'SDP',
  SRG = 'SRG',
  CHC = 'CHC',
  TJR = 'TJR',
  TPE = 'TPE',
  TRL = 'TRL',
  TMM = 'TMM',
  UGS = 'UGS',
  UGW = 'UGW',
  UAK = 'UAK',
  SUR = 'SUR',
  USS = 'USS',
  UYN = 'UYN',
  UYP = 'UYP',
  VEB = 'VEB',
  VEF = 'VEF',
  VNC = 'VNC',
  YDD = 'YDD',
  YUD = 'YUD',
  YUM = 'YUM',
  YUN = 'YUN',
  ZRN = 'ZRN',
  ZRZ = 'ZRZ',
  ZMK = 'ZMK',
  ZWC = 'ZWC',
  ZWD = 'ZWD',
  ZWN = 'ZWN',
  ZWR = 'ZWR',
  XFO = 'XFO',
  XRE = 'XRE',
  XFU = 'XFU'
}

export enum BankIdentifierErrorsEnum {
  SORT_CODE = 'sort_code is missing or wrongly formatted',
  ABA = 'aba is missing or wrongly formatted',
  BIC_SWIFT = 'bic_swift is wrongly formatted',
  CNAPS = 'cnaps is missing or wrongly formatted',
  BSBCODE = 'bsbcode is missing or wrongly formatted',
  CLABE = 'clabe is missing or wrongly formatted',
  IBAN = 'iban is missing or wrongly formatted',
  IBAN_WRONG = 'iban is wrongly formatted',
  ACCOUNT_NUMBER = 'account_number is missing or wrongly formatted',
  ACCOUNT_ID = 'account identifier and bank identifier not found',
  PAYMENT_ID = 'payment identifier already present',
  PAYMENT_DETAILS = 'payment details already exist',
  REGISTRATION_NUMBER = 'a counterparty with the same registration number already exists',
  COUNTRY_CODE = 'country code not supported',
  NOT_MATCH = 'payment details were not matched',
  BANKING_NETWORK = 'banking network not found'
}

export enum BeneficiaryEntityType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY'
}

export enum CounterpartyStatus {
  INCOMPLETE = 'INCOMPLETE',
  APPROVAL = 'APPROVAL',
  APPROVED = 'APPROVED',
  DELETED = 'DELETED',
  REJECTED = 'REJECTED'
}

// @deprecated, for backwards compatibility only
export enum CounterpartyDisplayStatus {
  PENDING = 'PENDING',
  NOT_APPROVED = 'NOT_APPROVED',
  APPROVED = 'APPROVED'
}

export enum CounterpartyType {
  VENDOR = 'VENDOR',
  CUSTOMER = 'CUSTOMER',
  EMPLOYEE = 'EMPLOYEE',
  BANK_ACCOUNT = 'BANK_ACCOUNT'
}

export enum PaymentType {
  SWIFT = 'SWIFT',
  LOCAL = 'LOCAL'
}

export enum AccountIdentifierType {
  IBAN = 'IBAN',
  ACCOUNT_NUMBER = 'ACCOUNT_NUMBER'
}

export enum BankIdentifierType {
  SORT_CODE = 'SORT_CODE',
  ABA = 'ABA',
  BIC_SWIFT = 'BIC_SWIFT',
  CNAPS = 'CNAPS',
  BSB_CODE = 'BSBCODE',
  CLABE = 'CLABE',
  BRANCH_CODE = 'BRANCH_CODE',
  ACCOUNT_ID = 'ACCOUNT_ID',
  REGISTRATION_NUMBER = 'REGISTRATION_NUMBER',
  TAX_ID = 'TAX_ID'
}

export enum PaymentIdentifierType {
  ACCOUNT_IDENTIFIER = 'ACCOUNT_IDENTIFIER',
  BANK_IDENTIFIER = 'BANK_IDENTIFIER'
}

export enum VendorOnboardingStatus {
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  SUBMITTED = 'SUBMITTED'
}

export enum VendorPaymentMethod {
  CARD = 'CARD',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  TRANSFER = 'TRANSFER'
}

export enum VendorPurchaseFrequency {
  ONCE = 'ONCE',
  RECURRING = 'RECURRING'
}

export interface VendorOnboardingOutput {
  id: number;
  status: VendorOnboardingStatus;
}

export interface CounterpartyAddress {
  street: string;
  city: string;
  postal_code?: string;
  country: CountryCode;
  street_number?: string;
  state?: string;
  additional_info?: string;
  is_default?: boolean;
}

export interface VendorFilloutRights {
  addresses: EnrichmentSource[];
  analytical_axis_association: EnrichmentSource[];
  counterparty_payments: EnrichmentSource[];
  display_name: EnrichmentSource[];
  contacts: EnrichmentSource[];
  country_code: EnrichmentSource[];
  documents: EnrichmentSource[];
  legal_name: EnrichmentSource[];
  registration_number: EnrichmentSource[];
  tax_id: EnrichmentSource[];
}

export interface EnrichmentCustomSection {
  description: string;
  filled_by: EnrichmentSource[];
  id?: number;
  label: string;
  position: number;
  step_instances: VendorEnrichmentStep[];
  type: EnrichmentSectionType;
}

export interface VendorFormInstance {
  id: number;
  count_all: number;
  count_approved: number;
  to_decide_ids: number[];
  request_custom_fields?: VendorCustomField[];
  fillout_rights?: VendorFilloutRights;
  enrichment_custom_sections?: EnrichmentCustomSection[];
}
export interface WorkflowInstance {
  id: number;
  status: StepInstanceStatus;
  step_instances: VendorFormInstance;
  motive?: RejectMotiveEnum;
  details?: string;
}

export interface RetrieveCounterpartyInfo {
  id: number;
  country_code: CountryCode;
  beneficiary_entity_type: BeneficiaryEntityType;
  is_active: boolean;
  created_by?: number;
  default_currency: CurrencyCode;
  registration_number?: string;
  tax_id?: string;
  address?: string;
  place_id?: string;
  display_name: string;
  legal_name: string;
  account_holder_name?: string;
  relationship_in: CounterpartyType[];
  updated_at: string;
  approval_status: CounterpartyStatus;
  display_status: CounterpartyStatus; // deprecated
  erp_tax_item_id: number;
  erp_term_id: number;
  erp_counterparty: ErpSync;
  entity_ids: number[];
  vendor_onboarding: VendorOnboardingOutput;
  erp_vendor_id?: number;
  relationship?: CounterpartyType;
  email?: string;
  phone_number?: string;
  erp_vendor_sync_status?: ErpSyncStatus;
  payment_methods?: VendorPaymentMethod[];
  source: CreationSource;
  document_incorporation_certificate?: string;
  document_payment_details?: string;
  document_security_policy?: string;
  document_tax_certificate?: string;
  documents?: CounterpartyDocumentBody[];
  vendor_request_custom_fields: VendorCustomField[];
  vendor_enrichment_custom_sections: VendorEnrichmentCustomSectionUpdate;
  estimated_amount: number;
  addresses?: CounterpartyAddress[];
  incorporation_type?: string;
  external_id?: string;
  external_url?: string;
  workflow_instance: WorkflowInstance;
}

export interface PaymentDetails {
  id: number;
  identifier_type: PaymentIdentifierType;
  type: AccountIdentifierType | BankIdentifierType;
  value: string;
}

export interface CounterpartyPayments {
  account_holder_name: string;
  automatically_created: boolean;
  currency_code: CurrencyCode;
  id: number;
  is_active: boolean;
  payment_details: PaymentDetails[];
  payment_type: PaymentType;
  verified: boolean;
  bank_name?: string;
  bank_address?: AddressDetailedObject;
}
export interface CounterpartyContacts {
  id?: number;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  type: ContactType;
}

export interface RetrieveCounterparty {
  contacts: CounterpartyContacts[];
  addresses?: CounterpartyAddress[];
  counterparty: RetrieveCounterpartyInfo;
  counterparty_payments: CounterpartyPayments[];
  document_incorporation_certificate: string;
  document_payment_details: string;
  document_security_policy: string;
  document_tax_certificate: string;
  documents: CounterpartyDocumentBody[];
  vendor_enrichment_custom_sections: VendorEnrichmentCustomSection[];
  vendor_request_custom_fields: VendorCustomField[];
  vendor_form_instances: VendorFormInstance[];
  analytical_axis_association: AnalyticalAxisAssocInput[];
  workflow_instance: WorkflowInstance;
}

export enum OrderingMethod {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface RetrieveCounterpartiesInput {
  address?: string;
  beneficiary_entity_type_in?: BeneficiaryEntityType[];
  beneficiary_entity_type_null?: boolean;
  counterparty_ids?: number[];
  counterparty_is_active?: boolean;
  counterparty_payment_ids?: number[];
  counterparty_payment_is_active?: boolean;
  relationship_in?: CounterpartyType[];
  country_code?: CountryCode;
  currency_code?: CurrencyCode;
  default_currency_code?: CurrencyCode;
  in_erp?: boolean;
  name?: string;
  payment_details_type?: BankIdentifierType | AccountIdentifierType;
  payment_details_value?: string;
  payment_type?: PaymentType;
  registration_number_in?: string[];
  search_account_holder_name?: string;
  search_account_holder?: string;
  with_payment_details?: boolean;
  by_name?: SortByOrder;
  by_updated_at?: SortByOrder;
  by_display_status?: SortByOrder;
  is_active?: boolean;
  number?: number;
  page_nb?: number;
  entity_id_in?: number[];
  erp_sync_status_in?: ErpSyncStatus[];
  email_domain?: string;
  approval_status_in?: CounterpartyStatus[];
  counterparty_source_in?: CreationSource[];
  tax_id_in?: string[];
  analytical_axis_node_id_in?: number[];
}

export interface RetrieveCounterpartiesOutput {
  counterparties: RetrieveCounterparty[];
  pagination: Pagination;
  has_more?: boolean;
  next_page?: number;
  previous_page?: number;
}

export interface RetrieveCounterpartyOutput {
  counterparties: RetrieveCounterparty[];
}

export interface RetrieveCounterpartyOutputV2 {
  counterparty: RetrieveCounterparty;
  is_updatable: boolean;
  approval_missing_conditions: VendorMissingFields[];
}

export interface Identifier {
  type: AccountIdentifierType | BankIdentifierType;
  value: string;
}

export interface CreateCounterpartyInput {
  payment_identifiers?: Identifier[];
  account_country_code?: CountryCode;
  country_code?: CountryCode;
  beneficiary_entity_type:
    | BeneficiaryEntityType
    | FormStepCounterpartyCounterpartyStatus;
  currency_code?: CurrencyCode;
  account_holder_name?: string;
  display_name: string;
  legal_name?: string;
  tax_id?: string;
  address?: string;
  place_id?: string;
  registration_number?: string;
  erp_tax_item_id?: number;
  erp_term_id?: number;
  entity_ids?: number[];
  relationship?: CounterpartyType | FormStepCounterpartyCounterpartyType;
  source?: 'INTAKE' | 'MANUAL';
  email?: string;
  payment_methods?: VendorPaymentMethod[];
}

export interface ReassignCounterpartyDetailsInput {
  counterparty_id: number;
}

export interface CreateCounterpartyDetailsInput {
  account_holder_name?: string;
  payment_identifiers: Identifier[];
  currency_code: CurrencyCode;
  name: string;
  country_code?: CountryCode;
  beneficiary_entity_type?: BeneficiaryEntityType;
  is_active: boolean;
  source?: 'INTAKE' | 'MANUAL';
  relationship?: CounterpartyType;
}

export interface CreateCounterpartyDetailsOutput {
  status: string[];
  counterparty_payment_id: number;
  error_statuses: string[];
}

export interface CreateCounterpartyOutput {
  counterparty_id: number;
  counterparty_payment_id: number;
  status: string[];
  error_statuses?: string[];
}

export interface ERPCounterpartyOutput {
  id?: number;
  sync_status: ErpSyncStatus;
  fail_reason?: string;
  internal_id?: string;
}

export interface UpdateCounterpartyInput {
  default_currency_code?: CurrencyCode;
  display_name?: string;
  registration_number?: string;
  tax_id?: string;
  address?: string;
  beneficiary_entity_type?: BeneficiaryEntityType;
  country_code?: CountryCode;
  account_holder_name?: string;
  relationship?: CounterpartyType;
  place_id?: string;
  email?: string;
  status?: CounterpartyStatus;
  erp_counterparty?: ErpSync;
  erp_tax_item_id?: number;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  document_incorporation_certificate?: string;
  document_payment_details?: string;
  document_security_policy?: string;
  documents?: CounterpartyDocumentBody[];
  counterparty_payments?: CounterpartyPayments[];
  legal_name?: string;
  erp_term_id?: number;
  entity_ids?: number[];
  items?: string[];
  payment_method?: VendorPaymentMethod[];
  vendor_enrichment_custom_steps?: VendorEnrichmentStep[];
}

export interface RetrieveForms {
  country_code: string;
  individual: boolean;
  company: boolean;
  payment_type?: boolean;
  account_number?: boolean;
  bic_swift?: boolean;
  beneficiary_address?: boolean;
  beneficiary_city?: boolean;
  beneficiary_country?: boolean;
  beneficiary_postcode?: boolean;
  beneficiary_state_or_province?: boolean;
  beneficiary_first_name?: boolean;
  beneficiary_last_name?: boolean;
  beneficiary_entity_type?: boolean;
  beneficiary_company_name?: boolean;
  sort_code?: boolean;
  aba?: boolean;
  iban?: boolean;
  bsb_code?: boolean;
  institution_no?: boolean;
  bank_code?: boolean;
  branch_code?: boolean;
  clabe?: boolean;
  cnaps?: boolean;
}

export interface RetrieveCounterpartiesIdInput {
  counterparties_id: number[];
}

export interface RetrieveCounterpartiesIdOutput {
  counterparty_id: number;
  analytical_axis_assoc: AnalyticalAxisAssocOutput[];
}

export interface RetrieveAllCounterpartiesIdOutput {
  counterparties: RetrieveCounterpartiesIdOutput[];
}

export interface assignCounterpartyToAnalyticalAxesInput {
  counterparty_id: number;
  analytical_axis_assoc: AnalyticalAxisAssocInput[];
}

export interface CounterpartiesFilters {
  analytical_axis_id_uncategorized_in: number[];
  analytical_axis_node_id_in: number[];
  beneficiary_entity_type_in: string[];
  with_payment_details: boolean;
  relationship_in?: string[];
  country_code: string[];
  default_currency_code: string[];
  beneficiary_entity_type_null: boolean;
  approval_status_in?: CounterpartyStatus[];
  erp_sync_status_in: ErpSyncStatus[];
  entity_id_in: number[];
  counterparty_source_in?: CreationSource[];
  created_by_in?: number[];
  to_approve?: boolean;
}

export interface VendorInvitation {
  to_email: string;
  front_url: string;
  need_incorporation_certificate: boolean;
  need_payment_details: boolean;
  need_security_policy: boolean;
  counterparty_id: number;
}

export interface AmountAndCurrency {
  amount: number;
  currency_code: CurrencyCode;
}
