<template>
  <DsSkeleton
    v-if="
      accountsStore.getConnectorAccountsResLoading || accountsStore.isPending
    "
  />
  <div
    v-else-if="value"
    :class="{ 'account-data': true, outlined: outlined }"
  >
    <img
      v-if="!!icon"
      :src="icon"
      width="12"
      height="12"
    />
    <DsIcon
      v-else-if="value >= 0"
      name="bank"
      size="small"
    />

    <span>
      {{ accountName }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue';

import DsIcon from '@/components/DesignSystem/DsIcon.vue';
import DsSkeleton from '@/components/DesignSystem/DsSkeleton.vue';
import { useAccountsStore } from '@/stores/data-models/accounts';

const props = defineProps({
  value: { type: Number, default: undefined },
  outlined: { type: Boolean, default: true }
});

const accountsStore = useAccountsStore();

const accountName = computed(
  () =>
    accountsStore.accountsArray.find((x) => x.id === props.value)?.name ??
    props.value
);

const icon = computed(() => {
  return accountsStore.getConnectorAccountsRes?.accounts?.find(
    (x) => x.id === props.value
  )?.logo_link;
});

onMounted(async () => {
  const promises = [];

  if (!accountsStore.hasGetAccountsBeenCalled) {
    promises.push(accountsStore.callGetAccounts());
  }
  if (!accountsStore.getConnectorAccountsResHasBeenCalled) {
    promises.push(accountsStore.callGetConnectorAccounts());
  }
  await Promise.all(promises);
});
</script>

<style lang="scss" scoped>
.account-data {
  @include typo-small-body;

  display: inline-flex;
  gap: 8px;
  align-items: center;
  color: $gray1000;

  &.outlined {
    @include typo-small-body-bold;

    padding: 4px 8px;
    background-color: $gray100;
    border-radius: 12px;
  }

  > span {
    overflow-x: hidden;
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
