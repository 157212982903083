<template>
  <div
    :class="{
      'ds-text-field': true,
      'ds-text-field--is-disabled': disabled
    }"
  >
    <PfLabel
      :model-value="label"
      :required="required"
      @click="focusModelInput"
    />

    <input
      ref="modelInput"
      v-model="modelValue"
      type="email"
      class="field-input"
      :class="{
        error: hasError,
        warning: hasWarning
      }"
      :placeholder="disabled ? '' : placeholder"
      :disabled="disabled"
      :required="required"
      @blur="$emit('blur')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue';

import { EMAIL_REGEXP } from '@/helpers/regexp';

import PfLabel from '../NewDesignSystem/PfLabel/PfLabel.vue';

const modelValue = defineModel<string>();

const props = defineProps({
  label: { type: String, default: undefined },
  placeholder: { type: String, default: undefined },
  disabled: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  autofocus: { type: Boolean, default: false },
  hasError: { type: Boolean, default: false },
  hasWarning: { type: Boolean, default: false }
});

const emit = defineEmits(['blur']);

const isEmailValid = function (email): boolean {
  return EMAIL_REGEXP.test(email);
};

const isEmailValidComputed = computed(() => {
  return isEmailValid(modelValue.value);
});

const modelInput = ref<HTMLInputElement | null>(null);

const focusModelInput = () => {
  modelInput.value?.focus();
};

onMounted(() => {
  if (props.autofocus) {
    nextTick(focusModelInput);
  }
});

defineExpose({
  isEmailValidComputed
});
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/text-field.scss';
</style>
