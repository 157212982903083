<template>
  <span
    :class="{
      'ds-amount': true,
      positive: colored && amountInCents && amountInCents > 0,
      negative: colored && amountInCents && amountInCents < 0,
      pointer: !!$attrs.onClick
    }"
  >
    {{ formattedAmount }}
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { formatLargeNumber } from '@/helpers/number';

const props = defineProps({
  amountInCents: { type: [Number, null, undefined], default: undefined },
  bold: { type: Boolean, default: false },
  colored: { type: Boolean, default: false },
  currency: { type: String, default: undefined }
});

const { locale } = useI18n();

const fontWeight = computed(() => (props.bold ? 600 : 500));

const formattedAmount = computed(() => {
  if (isNaN(props.amountInCents)) {
    return '—';
  }

  const formatted = formatLargeNumber(
    Math.floor(props.amountInCents / 100),
    locale.value,
    props.currency
  );
  return formatted;
});
</script>

<style lang="scss" scoped>
.ds-amount {
  @include typo-digits;

  font-weight: v-bind('fontWeight');
  color: $gray1000;
  white-space: nowrap;

  &.positive {
    color: $green500;
  }

  &.negative {
    color: $red500;
  }

  &.pointer {
    cursor: pointer;
  }
}
</style>
