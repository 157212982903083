import { LocationQueryValue } from 'vue-router';

export const getSingleQueryValue = (
  queryValue?: LocationQueryValue | LocationQueryValue[]
): string => {
  if (!queryValue) return undefined;
  return Array.isArray(queryValue) ? queryValue[0] : queryValue;
};

export const getBooleanQueryValue = (
  queryValue?: LocationQueryValue | LocationQueryValue[]
): boolean => {
  if (!queryValue) return undefined;
  return getSingleQueryValue(queryValue) === 'true';
};

export const getNumberQueryValue = (
  queryValue?: LocationQueryValue | LocationQueryValue[]
): number => {
  if (!queryValue) return undefined;
  return Number(getSingleQueryValue(queryValue));
};

export const getDateQueryValue = (
  queryValue?: LocationQueryValue | LocationQueryValue[]
): Date => {
  if (!queryValue) return undefined;
  return new Date(getSingleQueryValue(queryValue));
};

export const getArrayQueryValue = (
  queryValue?: LocationQueryValue | LocationQueryValue[]
): string[] => {
  if (!queryValue) return [];

  return Array.isArray(queryValue) ? queryValue : [queryValue];
};

export const getArrayNumberQueryValue = (
  queryValue?: LocationQueryValue | LocationQueryValue[]
): number[] => {
  if (!queryValue) return [];

  return Array.isArray(queryValue)
    ? queryValue.map((x) => parseInt(x))
    : [parseInt(queryValue)];
};
