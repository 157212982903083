<template>
  <div class="client-selector">
    <p
      v-if="!!label"
      class="label"
    >
      {{ label }}
      <span
        v-if="required"
        class="asterisk"
      >
        *
      </span>
    </p>
    <button
      :disabled="disabled"
      class="client-selector__button"
      :class="{ focused: isDropdownVisible }"
      @click="onOpenDropdown"
    >
      <div>
        <ClientTag
          v-if="selectedUser"
          :client="selectedUser.user"
          :settings-role="selectedUser?.workspaceScopes?.SETTINGS"
          :has-remove-button="!disabled && clearable"
          @on-remove="onSelectUser(null)"
        />
        <DsLoader
          v-else-if="loading"
          :size="20"
        />
        <span
          v-else
          class="input__placeholder"
        >
          {{ placeholder }}
        </span>
      </div>

      <div style="display: flex; gap: 8px; align-items: center">
        <DsIcon
          v-show="false"
          v-if="selectedUser && clearable && !disabled"
          :name="isDropdownVisible ? 'cross' : 'cross'"
          color="gray400"
          size="small"
          @click.stop="onSelectUser(null)"
        />
        <DsIcon
          v-if="!loading"
          :name="isDropdownVisible ? 'chevron-up' : 'chevron-down'"
          color="gray400"
          size="small"
        />
      </div>
      <slot />
    </button>
    <div
      v-if="isDropdownVisible"
      ref="dropdownRef"
      class="client-selector__dropdown"
    >
      <DsSearchField
        v-model="searchQuery"
        :placeholder="$t('userPermissionsClientSelector.searchPlaceholder')"
        clearable
        autofocus
        @keyup.esc="isDropdownVisible = false"
      />
      <div class="clients-list">
        <button
          v-for="row in filteredRows"
          :key="`client-row-${row.user.id}`"
          type="button"
          :disabled="isUserDisabled(row)"
          @click="onSelectUser(row)"
        >
          <ClientTag
            :client="row.user"
            :settings-role="row?.workspaceScopes?.SETTINGS"
          />
          <span
            v-if="
              markNonApprovers &&
              !row?.workspaceScopes.REQUESTS.includes(RequestRole.APPROVER)
            "
            style="margin-left: auto"
            class="non-approver"
          >
            {{ $t('client-selector.not-an-approver') }}
          </span>

          <span
            v-if="
              markNonRequesters &&
              !row?.workspaceScopes.REQUESTS.includes(RequestRole.REQUESTOR)
            "
            style="margin-left: auto"
            class="non-approver"
          >
            {{ $t('client-selector.not-a-requester') }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// SIMILAR components ID 0790811c-0f91-4ae8-a3d4-d3f7fe08f397
// Search this id to find similar components
import { onClickOutside } from '@vueuse/core';
import { useFuse } from '@vueuse/integrations/useFuse';
import { computed, nextTick, ref } from 'vue';

import ClientTag from '@/components/Client/ClientTag.vue';
import DsIcon from '@/components/DesignSystem/DsIcon.vue';
import DsSearchField from '@/components/DesignSystem/DsSearchField.vue';
import { ClientWithRoleModel, RequestRole } from '@/custom-types/client';

import DsLoader from './DsLoader.vue';

const props = defineProps<{
  currentUserId?: number;
  userRows: ClientWithRoleModel[];
  disabledUserIds?: number[];
  disabled?: boolean;
  required?: boolean;
  label?: string;
  placeholder?: string;
  hasSearchField?: boolean;
  clearable?: boolean;
  markNonApprovers?: boolean;
  markNonRequesters?: boolean;
  loading?: boolean;
}>();

const modelValue = defineModel<number>();

const isUserDisabled = (row: ClientWithRoleModel) => {
  return props.disabledUserIds?.includes(row.user.id) || false;
};

const isUserCurrentUser = (row: ClientWithRoleModel) => {
  return row.user.id === props.currentUserId;
};

const isDropdownVisible = ref(false);

const onOpenDropdown = async () => {
  isDropdownVisible.value = !isDropdownVisible.value;
  await nextTick();
  (
    document.querySelector(
      '.client-selector__dropdown input'
    ) as HTMLInputElement
  )?.focus();
};

const onCloseDropdown = () => {
  isDropdownVisible.value = false;
};

const onSelectUser = (row: ClientWithRoleModel | null) => {
  modelValue.value = row?.user?.id;
  onCloseDropdown();
};

const selectedUser = computed(() => {
  return props.userRows.find((row) => row.user.id === modelValue.value);
});

const dropdownRef = ref<HTMLElement>(null);

onClickOutside(dropdownRef, () => {
  onCloseDropdown();
});

const searchQuery = ref('');

const fullTextSearch = useFuse(
  searchQuery,
  () => props.userRows,
  () => {
    return {
      fuseOptions: {
        threshold: 0.45,
        keys: ['user.first_name', 'user.last_name', 'user.email', 'user.phone']
      }
    };
  }
);

const filteredRows = computed(() => {
  if (searchQuery.value.length > 0) {
    return fullTextSearch.results.value
      .map((result) => result.item)
      .slice()
      .sort((a, b) => {
        if (isUserDisabled(a) && !isUserDisabled(b)) {
          return 1;
        }
        if (!isUserDisabled(a) && isUserDisabled(b)) {
          return -1;
        }
        return 0;
      });
  }
  return props.userRows
    .slice()
    .sort((a, b) => {
      if (isUserCurrentUser(a) && !isUserCurrentUser(b)) {
        return -1;
      }
      if (!isUserCurrentUser(a) && isUserCurrentUser(b)) {
        return 1;
      }
      return 0;
    })
    .sort((a, b) => {
      if (isUserDisabled(a) && !isUserDisabled(b)) {
        return 1;
      }
      if (!isUserDisabled(a) && isUserDisabled(b)) {
        return -1;
      }
      return 0;
    });
});
</script>

<style lang="scss" scoped>
.client-selector {
  position: relative;

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 36px;
    padding: 6px 12px;
    padding-left: 6px;

    border: 1px solid $gray100;
    border-radius: 12px;

    &:hover {
      border-color: $gray200;
    }

    &:focus,
    &.focused {
      border-color: $blue500;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      :deep(button) {
        cursor: not-allowed;
      }
    }
  }

  &__dropdown {
    position: absolute;
    z-index: 99;
    top: calc(100% + 4px);
    right: 0;
    left: 0;

    padding: 4px;

    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 4px 16px 0px #14172514;
  }
}

.clients-list {
  overflow-y: auto;
  max-height: 200px;

  button {
    display: flex;
    align-items: center;

    width: 100%;
    margin-top: 4px;
    padding: 4px 8px;

    text-align: left;

    border-radius: 8px;

    &:hover {
      background-color: $gray50;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.label {
  @include typo-small-body;

  margin-bottom: 4px;
  color: $gray1000;

  .asterisk {
    color: $red500;
  }
}

.input__placeholder {
  @include typo-body;

  overflow: hidden;
  color: $gray500;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
