import { RetrieveAccountOutput } from '@/api/schemas/fawkes';
import { AccountModel } from '@/custom-types/account';

export function formatAccountName(
  account: RetrieveAccountOutput | AccountModel
): string {
  let str = '';

  if (account.institution_name) {
    str = account.institution_name + ' ';
  }
  if (account.name) {
    str += account.name;
  } else if (account.iban) {
    str += account.iban;
  }
  return str.trim() || '-';
}
