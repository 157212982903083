import { keyBy } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import {
  getAccounts as getConnectorAccounts,
  getInstitutions
} from '@/api/connector';
import {
  getAccounts,
  getBalances,
  getBalancesAvailable,
  getPublicAccounts
} from '@/api/fawkes';
import { getPaymentAccounts } from '@/api/payments';
import { RetrieveInstitutionOutput } from '@/api/schemas/connector';
import { RetrieveAccountOutput } from '@/api/schemas/fawkes';
import { useCallApi } from '@/composables/useCallApi';
import {
  AccountOptions,
  BalanceModel,
  PublicAccountModel
} from '@/custom-types/account';
import { formatAccountName } from '@/helpers/account';
import {
  BankOrdersCreateAccountSelectionFieldItem,
  joinProviderAccountsWithFawkesAccountAndBalanceAndInstitution
} from '@/helpers/bank-orders';

import { useToastStore } from '../toast';
import { useClientStore } from './client';

export const useAccountsStore = defineStore('accounts', () => {
  const toast = useToastStore();
  const { t } = useI18n();
  const route = useRoute();
  const router = useRouter();
  const clientStore = useClientStore();
  const debugging = ref(route.query.debugging === 'true');
  const statusOptions = computed(() => [
    {
      value: null,
      wording: t('accounts.statusOptions.all')
    },
    {
      value: 'EXPIRED',
      wording: t('accounts.statusOptions.expired')
    },
    {
      value: 'ACTIVE',
      wording: t('accounts.statusOptions.active')
    },
    {
      value: 'CLOSED',
      wording: t('accounts.statusOptions.closed')
    }
  ]);
  const filters = reactive({
    searchQuery: ref<string>(route.query.searchQuery?.toString() || ''),
    status: ref<string>(route.query.status?.toString() || null)
  });

  watch(filters, async () => {
    router.push({
      query: {
        ...route.query,
        ...filters
      }
    });
  });
  const accountsArray = ref<RetrieveAccountOutput[]>([]);

  const publicAccountsArray = ref<PublicAccountModel[]>([]);

  const hasGetAccountsBeenCalled = ref(false);

  const warnAboutGetAccounts = () => {
    if (!hasGetAccountsBeenCalled.value) {
      console.warn(
        "Accounts store methods are being used but callGetAccounts API hasn't been called yet"
      );
    }
  };

  const accountsIds = computed(() =>
    accountsArray.value.map((account) => account.id)
  );

  const accountsByIdDictionnary = computed(
    (): { [id: number]: RetrieveAccountOutput } =>
      keyBy(accountsArray.value, 'id')
  );

  const { isPending, hasError, errorMessage, callApi } = useCallApi(
    async () => {
      hasGetAccountsBeenCalled.value = true;
      if (
        ['VIEWER', 'CONTRIBUTOR'].includes(
          clientStore.clientRoles?.analytics_role
        )
      ) {
        const response = await getAccounts();
        accountsArray.value = response.accounts;
      } else {
        accountsArray.value = [];
      }
    }
  );

  const callGetAccounts = callApi;

  const callGetPublicAccounts = useCallApi(async () => {
    const response = await getPublicAccounts();
    publicAccountsArray.value = response.accounts;
  });

  const balances = ref<BalanceModel[]>([]);
  const gettingBalances = ref(false);
  const callGetBalances = async () => {
    try {
      gettingBalances.value = true;
      if (
        ['VIEWER', 'CONTRIBUTOR'].includes(
          clientStore.clientRoles?.analytics_role
        )
      ) {
        const response = await getBalances();
        balances.value = response.balances;
      } else {
        balances.value = [];
      }
    } catch (error) {
      console.error(error);
    } finally {
      gettingBalances.value = false;
    }
  };

  const balancesAvailable = ref<BalanceModel[]>([]);
  const gettingBalancesAvailable = ref(false);

  const callGetBalancesAvailable = async () => {
    try {
      gettingBalancesAvailable.value = true;
      if (
        ['VIEWER', 'CONTRIBUTOR'].includes(
          clientStore.clientRoles?.analytics_role
        )
      ) {
        const response = await getBalancesAvailable();
        balancesAvailable.value = response.balances;
      } else {
        balancesAvailable.value = [];
      }
    } catch (error) {
      console.error(error);
    } finally {
      gettingBalancesAvailable.value = false;
    }
  };

  const findAccountById = (accountId: number) => {
    warnAboutGetAccounts();

    return accountsByIdDictionnary.value[accountId];
  };

  const accountsWithBalances = computed(() => {
    return accountsArray.value.map((account) => {
      const balance = balances.value.find(
        (balance) => balance.account_id === account.id
      );
      return {
        ...account,
        balance
      };
    });
  });

  const accountsWithBalancesAvailable = computed(() => {
    return accountsArray.value.map((account) => {
      if (account.id === 5017) {
        console.log(account);
      }
      const balance =
        balancesAvailable.value.find(
          (balance) => balance.account_id === account.id
        ) || null;
      return {
        ...account,
        balance
      };
    });
  });

  const findAccountWithBalancesById = (accountId: number) => {
    return accountsWithBalances.value.find(
      (account) => account.id === accountId
    );
  };

  const findAccountWithBalancesAvailableById = (accountId: number) => {
    return accountsWithBalancesAvailable.value.find(
      (account) => account.id === accountId
    );
  };

  const institutionsArray = ref<RetrieveInstitutionOutput[]>([]);

  const callGetInstitutions = async () => {
    try {
      const response = await getInstitutions();
      institutionsArray.value = response.institutions;
    } catch (error) {
      console.error(error);
    }
  };

  const ebicsAccounts = ref<BankOrdersCreateAccountSelectionFieldItem[]>([]);
  const sftpAccounts = ref<BankOrdersCreateAccountSelectionFieldItem[]>([]);
  const paymentAccounts = ref<BankOrdersCreateAccountSelectionFieldItem[]>([]);
  const connectorAccounts = ref<BankOrdersCreateAccountSelectionFieldItem[]>(
    []
  );

  const allAccounts = ref<BankOrdersCreateAccountSelectionFieldItem[]>([]);
  const allAccountsLoading = ref(false);
  const allAccountsHasBeenCalled = ref(false);
  const getAllAccounts = async () => {
    try {
      allAccountsHasBeenCalled.value = true;
      allAccountsLoading.value = true;
      let institutions = null;
      const callGetInstitutions = async () => {
        institutions = await getInstitutions();
      };
      let accounts = null;
      const callGetAccounts = async () => {
        accounts = await getAccounts();
      };
      let availableBalances = null;
      const callGetBalancesAvailable = async () => {
        availableBalances = await getBalances();
      };
      let providerAccounts = null;
      const callGetProviderAccounts = async () => {
        providerAccounts = await getPaymentAccounts();
      };

      await Promise.all([
        callGetInstitutions(),
        callGetAccounts(),
        callGetBalancesAvailable(),
        callGetProviderAccounts()
      ]);

      const list =
        joinProviderAccountsWithFawkesAccountAndBalanceAndInstitution(
          providerAccounts.accounts,
          accounts.accounts,
          availableBalances.balances,
          institutions.institutions
        );
      const all = [
        ...list.connectorAccounts,
        ...list.paymentAccounts,
        ...list.ebicsAccounts
      ];

      if (list.sftpAccounts?.length) {
        all.push(...list.sftpAccounts);
      }

      allAccounts.value = all;

      connectorAccounts.value = list.connectorAccounts;
      paymentAccounts.value = list.paymentAccounts;
      ebicsAccounts.value = list.ebicsAccounts;
      sftpAccounts.value = list.sftpAccounts;

      return all;
    } catch (error) {
      toast.showError(error, JSON.stringify(error.response.data));
    } finally {
      allAccountsLoading.value = false;
    }
  };

  const getConnectorAccountsRes = ref(null);
  const getConnectorAccountsResLoading = ref(false);
  const getConnectorAccountsResHasBeenCalled = ref(false);
  const callGetConnectorAccounts = async function () {
    try {
      getConnectorAccountsResHasBeenCalled.value = true;
      getConnectorAccountsResLoading.value = true;
      getConnectorAccountsRes.value = await getConnectorAccounts();
    } catch (error) {
      toast.showError(error, JSON.stringify(error.response?.data));
    } finally {
      getConnectorAccountsResLoading.value = false;
    }
  };

  const accountsOptions = computed<AccountOptions[]>(() =>
    accountsArray.value.map((account) => ({
      value: account.id,
      wording: formatAccountName(account),
      entityId: account.entity_id,
      groupId: account.group_id,
      institutionName: account.institution_name,
      iban: account.iban
    }))
  );

  return {
    callGetConnectorAccounts,
    getConnectorAccountsRes,
    getConnectorAccountsResLoading,
    getConnectorAccountsResHasBeenCalled,
    allAccountsHasBeenCalled,
    accountsByIdDictionnary,
    debugging,
    accountsArray,
    balances,
    accountsIds,
    isPending,
    hasError,
    errorMessage,
    callGetAccounts,
    hasGetAccountsBeenCalled,
    gettingBalances,
    callGetBalances,
    balancesAvailable,
    callGetBalancesAvailable,
    findAccountById,
    filters,
    statusOptions,
    accountsWithBalances,
    accountsWithBalancesAvailable,
    callGetPublicAccounts,
    publicAccountsArray,
    findAccountWithBalancesById,
    findAccountWithBalancesAvailableById,
    institutionsArray,
    callGetInstitutions,
    getAllAccounts,
    allAccounts,
    allAccountsLoading,
    ebicsAccounts,
    sftpAccounts,
    connectorAccounts,
    paymentAccounts,
    accountsOptions
  };
});
