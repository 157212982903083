<template>
  <PfLoader
    :size="Number(size)"
    :class="{ 'ds-loader': true, centered: centered }"
    :color="color"
  />
</template>

<script lang="ts" setup>
import PfLoader from '@/components/NewDesignSystem/PfLoader/PfLoader.vue';
import { COLORS_PALETTE } from '@/helpers/design-system';

defineProps({
  size: { type: [Number, String], default: 36 },
  centered: { type: Boolean, default: false }
});

const color = COLORS_PALETTE.blue300;
</script>

<style lang="scss" scoped>
.ds-loader {
  display: inline-block;
  animation: rotation 750ms linear infinite;

  &.centered {
    margin: auto;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
