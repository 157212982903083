<template>
  <div
    :class="{
      'ds-avatar': true,
      'ds-avatar--tiny': tiny,
      'ds-avatar--medium': medium
    }"
    v-bind="$attrs"
  >
    {{ initials }}
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  fullname: { type: String, default: null },
  medium: { type: Boolean, default: false },
  tiny: { type: Boolean, default: false }
});

const initials = computed(() => {
  if (!props.fullname) {
    return '';
  }
  if (props.fullname.trim() === '') {
    return '?';
  }

  return props.fullname
    .split(' ')
    .map((x) => x[0] ?? '')
    .slice(0, 2)
    .join('')
    .toUpperCase();
});

const randomColor = computed(() => {
  if (!props.fullname) {
    return '';
  }
  const colors = [
    '#6DCCB1',
    '#79AAD9',
    '#EE789D',
    '#F1D86F',
    '#A987D1',
    '#E4A6C7',
    '#D2C0A0',
    '#F5A35C',
    '#C47C6C',
    '#FF7E62'
  ];

  return colors[props.fullname.length % colors.length];
});
</script>

<style lang="scss" scoped>
.ds-avatar {
  @include typo-body-bold;

  cursor: default;

  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;

  color: $dark800;
  text-align: center;

  background-color: v-bind(randomColor);
  border-radius: 50%;

  &.ds-avatar--tiny {
    width: 20px;
    height: 20px;
    font-size: 10px;
    letter-spacing: 0.1px;
  }
  &.ds-avatar--medium {
    width: 28px;
    height: 28px;
    font-size: 12px;
    letter-spacing: -0.12px;
  }
}
</style>
