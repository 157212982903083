<template>
  <button
    :class="{
      button: true,
      'button--mini': !circle && Number(size) <= 12,
      'button--circle': circle
    }"
    type="button"
    :disabled="disabled"
  >
    <DsIcon
      :name="name"
      :size="size"
      :scale="!!scale ? scale : 1"
      :color="color"
      :rotate="rotate"
      :circle="circle"
    />
  </button>
</template>

<script>
import DsIcon from './DsIcon.vue';

export default {
  name: 'DsIconButton',
  components: {
    DsIcon
  },
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: [Number, String],
      required: true
    },
    color: {
      type: String,
      default: ''
    },
    rotate: {
      type: [Number, String],
      default: 0
    },
    circle: {
      type: Boolean,
      default: false
    },
    scale: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  cursor: pointer;

  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  padding: 4px;

  border-radius: 5px;

  &.button--mini {
    padding: 2px;
    border-radius: 50%;
  }

  &.button--circle {
    padding: 0;
    border-radius: 50%;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
