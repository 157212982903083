<template>
  <div
    :class="{
      'ds-textarea': true,
      'ds-textarea--is-disabled': disabled,
      'ds-textarea--no-resize': preventResize
    }"
  >
    <PfLabel
      :model-value="label"
      :required="required"
      @click="focusTextarea"
    />
    <label
      v-if="showNumberOfCharacters"
      class="DS_field-label"
      style="float: right"
    >
      {{ (modelValue?.length || 0) + '/' + maxlength }}
    </label>
    <textarea
      ref="textareaRef"
      :value="modelValue"
      :data-test="dataTest"
      :class="{
        'field-textarea': true,
        'field-textarea-limit-reached': modelValue?.length === maxlength
      }"
      :rows="rows"
      :maxlength="maxlength"
      :placeholder="disabled ? '' : placeholder"
      :disabled="disabled"
      @input="onInput"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    ></textarea>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, onMounted, ref } from 'vue';

import PfLabel from '@/components/NewDesignSystem/PfLabel/PfLabel.vue';

const props = defineProps({
  modelValue: { type: String, default: '' },
  label: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  autofocus: { type: Boolean, default: false },
  maxlength: { type: Number, default: -1 },
  rows: { type: Number, default: 3 },
  preventResize: { type: Boolean, default: false },
  showNumberOfCharacters: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  dataTest: { type: String, default: '' }
});

const emit = defineEmits(['update:modelValue', 'focus', 'blur']);

const textareaRef = ref<HTMLTextAreaElement>();

const onInput = (event) => {
  emit('update:modelValue', event.target.value);
};

const focusTextarea = () => {
  textareaRef.value.focus();
};

onMounted(() => {
  if (props.autofocus) {
    nextTick(() => {
      focusTextarea();
    });
  }
});
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/field-label.scss';

.ds-textarea {
  flex-grow: 1;

  .field-textarea {
    @include typo-body;

    cursor: text;
    resize: vertical;

    display: block;

    width: 100%;
    min-height: 36px;
    padding: 7px 11px;

    color: $gray1000;
    text-align: left;

    background-color: white;
    border: 1px solid $gray100;
    border-radius: 12px;

    &:hover {
      border-color: $gray200;
    }

    &:focus {
      border-color: $blue500;
      &.field-textarea-limit-reached {
        border: 1px solid $red500;
        outline: none;
        box-shadow: 0px 0px 0px 2px #f9d4cf;
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &.ds-textarea--is-disabled {
    label {
      color: $gray500;
    }

    .field-textarea {
      color: $gray500;
      background-color: $gray50;
    }
  }

  &.ds-textarea--no-resize {
    textarea {
      resize: none;
    }
  }
}
</style>
