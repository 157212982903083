<template>
  <div class="ds-table-users-cell">
    <template v-for="client in usersList">
      <ClientTag
        v-if="client"
        :key="'tag-' + client.id"
        :client="client"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { ClientModel } from '@/custom-types/client';
import { useClientsStore } from '@/stores/data-models/clients';

import ClientTag from '../Client/ClientTag.vue';

const clientsStore = useClientsStore();

const props = defineProps<{
  modelValue: number | number[] | ClientModel | ClientModel[] | undefined;
}>();

const usersList = computed<ClientModel[]>(() => {
  if (Array.isArray(props.modelValue)) {
    return props.modelValue.map((client) => {
      if (typeof client === 'number') {
        return clientsStore.findClientById(client);
      }
      return client;
    });
  }
  if (typeof props.modelValue === 'number') {
    return [clientsStore.findClientById(props.modelValue)];
  }
  return [props.modelValue as ClientModel];
});
</script>

<style scoped lang="scss">
.ds-table-date-time-cell {
  @include typo-small-body;

  color: $gray700;
}
</style>
