<template>
  <div class="ds-password-field">
    <DsTextField
      v-model="inputValue"
      :label="label"
      :type="showPassword ? 'text' : 'password'"
      :placeholder="placeholder"
      :disabled="disabled"
      :required="required"
      :autofocus="autofocus"
      :has-error="hasError"
      :error-message="errorMessage"
      :message="message"
      :name="name"
      prevent-trim
      @keyup="$emit('keyup')"
    >
      <slot />
    </DsTextField>

    <div
      class="ds-password-field-toggle-show"
      :class="{ 'has-label': !!label.length }"
    >
      <DsIconButton
        :name="showPassword ? 'eye-open' : 'eye-closed'"
        size="big"
        color="blue500"
        @click="onClick"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

import DsIconButton from './DsIconButton.vue';
import DsTextField from './DsTextField.vue';

const props = withDefaults(
  defineProps<{
    modelValue: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    autofocus?: boolean;
    hasError?: boolean;
    errorMessage?: string;
    message?: string;
    hasIcon?: boolean;
    name?: string;
  }>(),
  {
    label: '',
    placeholder: '',
    disabled: false,
    required: false,
    autofocus: false,
    hasError: false,
    errorMessage: '',
    message: '',
    hasIcon: false,
    name: ''
  }
);

const emit = defineEmits(['update:modelValue', 'keyup']);

const showPassword = ref(false);

const inputValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});

const onClick = () => {
  showPassword.value = !showPassword.value;
};
</script>

<style lang="scss" scoped>
.ds-password-field {
  position: relative;
}

.ds-password-field-toggle-show {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;

  height: 36px;
  padding: 8px 12px;

  &.has-label {
    top: 20px;
  }
}
</style>
