<template>
  <div class="ds-multi-autocomplete">
    <label
      v-if="label"
      class="DS_field-label"
      @click="focusAutocompleteButton"
    >
      {{ label }}
      <span v-if="required"> * </span>
    </label>
    <div
      ref="autocompleteContainer"
      class="autocomplete-container"
    >
      <button
        v-if="!noSelector"
        ref="autocompleteButtonRef"
        type="button"
        :class="{
          'autocomplete-button': true,
          'autocomplete-button--is-dropdown-shown': isDropdownShown,
          'autocomplete-button--is-placeholder': modelValue.length === 0
        }"
        @click="isDropdownShown = !isDropdownShown"
      >
        <slot />
        <p class="autocomplete-button-text">
          {{ autocompleteButtonText }}
        </p>
        <DsIcon
          class="autocomplete-button-chevron-icon"
          :name="isDropdownShown ? 'chevron-up' : 'chevron-down'"
          size="small"
          :color="isDropdownShown ? 'blue500' : 'gray500'"
        />
      </button>
      <div
        v-show="isDropdownShown || noSelector"
        class="dropdown-box"
      >
        <div class="search-box">
          <DsIcon
            name="search"
            color="dark700"
          />
          <input
            ref="searchInputRef"
            v-model.trim="searchInputValue"
            type="search"
            class="search-input"
            :placeholder="
              searchPlaceholder ||
              $t('components.DsMutliAutocomplete.searchPlaceholder')
            "
            @focus="onFocus"
            @input="onInput"
            @keydown="onKeydown"
          />
          <DsCheckbox
            :model-value="isSelectAllChecked"
            readonly
            :label="$t('components.DsMutliAutocomplete.selectAll')"
            @click="onClickOnSelectAllCheckbox"
          />
        </div>
        <div
          ref="dropdownOptionsContainer"
          class="dropdown-options-container"
        >
          <div
            v-for="(option, index) in filteredOptions"
            :key="option.value"
            :class="{
              'dropdown-option': true,
              'dropdown-option--is-focused': index === focusedOptionIndex,
              'dropdown-option--is-indented': option.indent
            }"
            @mousedown.prevent.stop="
              option.disabled ? null : onMousedownOnOption(option.value)
            "
          >
            <DsCheckbox
              :disabled="option.disabled"
              :model-value="modelValue.includes(option.value)"
              :label="option.wording"
              readonly
            />
          </div>
          <p
            v-show="filteredOptions.length === 0"
            class="dropdown-no-results"
          >
            {{ $t('components.DsMutliAutocomplete.noResults') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { matchSorter } from 'match-sorter';
import { computed, nextTick, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { IDropdownOption } from '@/custom-types/dropdown';

import DsCheckbox from './DsCheckbox.vue';
import DsIcon from './DsIcon.vue';

const props = defineProps({
  modelValue: { type: Array, default: () => [] },
  options: { type: Array<IDropdownOption>, required: true },
  label: { type: String, default: '' },
  selectPlaceholder: { type: String, default: '' },
  searchPlaceholder: { type: String, default: '' },
  allSelectedWording: { type: String, default: '' },
  required: { type: Boolean, default: false },
  noSelector: { type: Boolean, default: false }
});

const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();

const isDropdownShown = ref(false);
const isSelectAllChecked = ref(false);
const searchInputValue = ref('');
const focusedOptionIndex = ref(null);

const autocompleteContainer = ref<HTMLElement>();
const dropdownOptionsContainer = ref<HTMLElement>();
const autocompleteButtonRef = ref<HTMLElement>();
const searchInputRef = ref<HTMLElement>();

const firstNonDisabledOptionIndex = computed(() =>
  filteredOptions.value.findIndex((option) => !option.disabled)
);

const lastNonDisabledOptionIndex = computed(
  () =>
    filteredOptions.value.length -
    1 -
    filteredOptions.value
      .slice()
      .reverse()
      .findIndex((option) => !option.disabled)
);

const selectedOption = computed(() => {
  if (props.modelValue === null) {
    return null;
  }
  return props.options.find((option) => option.value === props.modelValue);
});

const autocompleteButtonText = computed(() => {
  if (props.modelValue.length === 0) {
    return (
      props.selectPlaceholder ||
      t('components.DsMutliAutocomplete.selectPlaceholder')
    );
  }
  if (isSelectAllChecked.value && props.allSelectedWording) {
    return props.allSelectedWording;
  }
  return props.options
    .filter((option) => props.modelValue.includes(option.value))
    .map((option) => option.wording)
    .sort()
    .join(', ');
});

const filteredOptions = computed(() => {
  const term = searchInputValue.value.replaceAll(' ', '').replaceAll("'", '');
  if (term === '') {
    return props.options
      .slice()
      .sort((a, b) => (a.wording < b.wording ? -1 : 1));
  }
  return matchSorter(props.options, term, { keys: ['wording'] });
});

watch(
  props.modelValue,
  (selectedValues) => {
    isSelectAllChecked.value =
      selectedValues.length === props.options.filter((o) => !o.disabled).length;
  },
  { immediate: true }
);

watch(isDropdownShown, (value) => {
  if (value) {
    focusSearchInput();
    window.document.addEventListener('mousedown', handleMousedown);
  } else {
    searchInputValue.value = '';
    window.document.removeEventListener('mousedown', handleMousedown);
  }
});

onUnmounted(() => {
  window.document.removeEventListener('mousedown', handleMousedown);
});

const handleMousedown = (event) => {
  if (autocompleteContainer.value.contains(event.target) === false) {
    isDropdownShown.value = false;
  }
};

const onClickOnSelectAllCheckbox = () => {
  const value = !isSelectAllChecked.value;
  if (value) {
    emit(
      'update:modelValue',
      props.options.filter((o) => !o.disabled).map((option) => option.value)
    );
  } else {
    emit('update:modelValue', []);
  }
  isSelectAllChecked.value = value;
};

const toggleValue = (value) => {
  const selectedValues = Array.from(props.modelValue);
  const foundIndex = selectedValues.findIndex(
    (selectedValue) => selectedValue === value
  );

  if (foundIndex === -1) {
    selectedValues.push(value);
  } else {
    selectedValues.splice(foundIndex, 1);
  }
  emit('update:modelValue', selectedValues);
};

const onFocus = () => {
  focusedOptionIndex.value = null;
};

const onInput = () => {
  focusedOptionIndex.value = null;
};

const onKeydown = (event) => {
  switch (event.code) {
    case 'ArrowDown':
      event.preventDefault();
      handleKeyArrows(false);
      break;
    case 'ArrowUp':
      event.preventDefault();
      handleKeyArrows(true);
      break;
    case 'Enter':
      event.preventDefault();
      handleKeyEnter();
      break;
    case 'Escape':
      event.preventDefault();
      event.stopPropagation();
      handleKeyEscape();
      break;
  }
};

const handleKeyArrows = (isUp = true) => {
  if (!isDropdownShown.value && !props.noSelector) return;
  if (filteredOptions.value.length === 0) return;
  if (focusedOptionIndex.value === null) {
    focusedOptionIndex.value = firstNonDisabledOptionIndex.value;
    updateDropdownScroll();
    return;
  }
  if (focusedOptionIndex.value === lastNonDisabledOptionIndex.value) {
    focusedOptionIndex.value = firstNonDisabledOptionIndex.value;
    updateDropdownScroll();
    return;
  }

  focusedOptionIndex.value += isUp ? -1 : 1;

  while (filteredOptions.value[focusedOptionIndex.value].disabled) {
    if (focusedOptionIndex.value === lastNonDisabledOptionIndex.value) {
      focusedOptionIndex.value = firstNonDisabledOptionIndex.value;
      updateDropdownScroll();
      return;
    }
    focusedOptionIndex.value += isUp ? -1 : 1;
  }
  updateDropdownScroll();
};

const handleKeyEnter = () => {
  if (isDropdownShown.value && focusedOptionIndex.value !== null) {
    const focusedOptionValue =
      filteredOptions.value[focusedOptionIndex.value].value;

    toggleValue(focusedOptionValue);
  }
};

const handleKeyEscape = () => {
  searchInputValue.value = '';
  focusedOptionIndex.value = null;
  updateDropdownScroll();
};

const updateDropdownScroll = () => {
  nextTick(() => {
    const containerElement = dropdownOptionsContainer.value;

    if (focusedOptionIndex.value > 2) {
      const optionHeight = (containerElement.firstElementChild as HTMLElement)
        .offsetHeight;
      containerElement.scrollTop =
        (focusedOptionIndex.value - 2) * optionHeight;
    } else {
      containerElement.scrollTop = 0;
    }
  });
};

const onMousedownOnOption = (value) => {
  toggleValue(value);
  focusSearchInput();
};

const focusAutocompleteButton = () => {
  autocompleteButtonRef.value?.focus();
};

const focusSearchInput = () => {
  nextTick(() => {
    searchInputRef.value?.focus();
  });
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/field-label.scss';

$button-height: v-bind('noSelector ? "0px" : "36px"');

.ds-multi-autocomplete {
  flex-grow: 1;
}

.autocomplete-container {
  position: relative;
}

.autocomplete-button {
  cursor: pointer;

  position: initial;

  display: flex;
  align-items: center;

  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-available;
  width: -ms-fill-available;
  width: -o-fill-available;
  height: $button-height;
  padding-right: 38px;
  padding-left: 11px;

  background-color: white;
  border: 1px solid $gray100;
  border-radius: 12px;

  &:hover {
    border-color: $gray200;
  }

  &.autocomplete-button--is-dropdown-shown,
  &:focus {
    border-color: $blue500;
    outline: 2px solid $blue150;
  }
}

.autocomplete-button-text {
  overflow: hidden;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $gray1000;
  text-align: left;
  text-overflow: ellipsis;
  letter-spacing: -0.14px;
  white-space: nowrap;

  .autocomplete-button--is-placeholder & {
    color: $gray400;
  }
}

.autocomplete-button-chevron-icon {
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
}

.dropdown-box {
  position: absolute;
  z-index: 1;
  top: $button-height + 8px;
  right: 0;
  left: 0;

  width: max-content;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.2);
}

.search-box {
  display: flex;
  align-items: center;

  height: 44px;
  padding: 0 12px;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
}

.search-input {
  cursor: text;

  flex-grow: 1;

  height: 100%;
  margin-right: 12px;
  margin-left: 12px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $dark800;
  text-align: left;
  letter-spacing: -0.14px;

  outline: none;
}

.dropdown-options-container {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;

  max-height: 247px;
  padding: 4px;

  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.dropdown-option {
  @include typo-body;

  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;

  &:hover,
  &.dropdown-option--is-focused {
    background-color: $gray50;
  }

  &.dropdown-option--is-indented {
    padding-left: 21px;
  }
}

.dropdown-no-results {
  @include typo-body;

  user-select: none;

  overflow: hidden;

  padding: 8px 12px;

  color: $dark700;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
