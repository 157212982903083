<template>
  <DsAutocomplete
    v-model="modelValue"
    :options="options"
    :label="label"
    :placeholder="placeholder"
    :upward="upward"
    @pressed-enter="$emit('pressedEnter')"
  />
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue';

import defaultCountries from '../../constants/countries';
import DsAutocomplete from './DsAutocomplete.vue';

const modelValue = defineModel<string>();

const props = defineProps({
  countries: {
    type: Array as PropType<Array<any>>,
    default: () => defaultCountries
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: 'Select a country'
  },
  upward: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['pressedEnter']);

const options = computed(() => {
  return props.countries.map((country) => ({
    value: country.code,
    wording: country.name,
    imgSrc: `https://hatscripts.github.io/circle-flags/flags/${country.code?.toLowerCase()}.svg`
  }));
});
</script>
