import { captureException } from '@sentry/vue';
import { defineStore } from 'pinia';
import { getCurrentInstance, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export const useToastStore = defineStore('toast', function () {
  const isShown = ref(false);
  const type = ref('info');
  const message = ref('');
  const { proxy } = getCurrentInstance();

  const { t } = useI18n();

  let timeout;

  function show(newType = 'info', newMessage = '') {
    type.value = newType;
    message.value = newMessage;
    isShown.value = true;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      isShown.value = false;
    }, 5000);
  }

  function hide() {
    isShown.value = false;
  }

  function showError(err: any, customUserMessage?: string) {
    captureException(err);
    show(
      'error',
      customUserMessage ||
        err.response?.data?.message ||
        t('common.genericError')
    );
    if (proxy && proxy.$gtag) {
      proxy.$gtag.event('error_seen', {
        error_code: err.response?.data?.type || err.code || 'unknown',
        error_message: err.message || err.response?.data?.message || 'unknown',
        user_message:
          customUserMessage || err.response?.data?.message || 'An Error Occured'
      });
    }
  }

  return {
    isShown,
    type,
    message,
    show,
    hide,
    showError
  };
});
