import { isEmpty } from 'lodash';

import { objectDiff } from './object';

export function ellipseArray(array: any[], shownLength: number): string {
  if (array.length > shownLength) {
    const hiddenLength = array.length - shownLength;
    array = array.slice(0, shownLength);
    array.push(`+${hiddenLength}`);
  }
  return array.join(', ');
}

export function toArray(value: any): any[] {
  if (Array.isArray(value)) {
    return value;
  }
  return [value];
}

export function toArrayOfNumbers(value: any): number[] {
  if (Array.isArray(value)) {
    return value.map((item) => Number(item));
  }
  return [Number(value)];
}

export function isArrayInArray(
  sourceArray: any[],
  targetArray: any[]
): boolean {
  return targetArray.every((item) => sourceArray.includes(item));
}

export function isObjectInArrayByField(
  sourceArray: any[],
  field: string,
  value: any
): boolean {
  if (sourceArray) {
    const res = sourceArray.find((elt) => elt[field] === value);
    return !!res;
  }

  return false;
}

export function removeFromArray(sourceArray: any[], value: any): boolean {
  const index = sourceArray.indexOf(value);
  if (index >= 0) {
    sourceArray.splice(index, 1);
    return true;
  }
  return false;
}

export function removeFromArrayObject(
  sourceArray: any[],
  field: string,
  value: any
): void {
  const index = sourceArray.map((e) => e[field]).indexOf(value);
  sourceArray.splice(index, 1);
}

export function sortArrayOfObject(sourceArray: any[], field: string): any[] {
  return sourceArray.sort((val1, val2) => {
    if (val1[field] > val2[field]) {
      return 1;
    }

    if (val1[field] < val2[field]) {
      return -1;
    }

    return 0;
  });
}

export function getElementInArrayOfObject(
  key: string,
  value: any,
  arr: any[]
): any {
  return arr.find((o) => o[key] === value);
}

export const arrayDiff = (baseArray, newArray) => {
  const maxLength = Math.max(baseArray.length, newArray.length);
  const diffArray = [];

  for (let i = 0; i < maxLength; i++) {
    if (baseArray[i] !== newArray[i]) {
      diffArray.push(newArray[i]);
    } else {
      diffArray.push(undefined);
    }
  }

  return diffArray;
};

export const countDifferentElements = (array1, array2) => {
  const set1 = new Set(array1);
  const set2 = new Set(array2);

  const diff1 = [...set1].filter((element) => !set2.has(element));
  const diff2 = [...set2].filter((element) => !set1.has(element));

  return diff1.length + diff2.length;
};

export const sortArrayByTextField = (arr, field) => {
  return arr.sort((a, b) => {
    if (!a[field]) return 1;
    if (!b[field]) return -1;
    return a[field].localeCompare(b[field]);
  });
};

export const isArrayDeepEqual = (x: object[], y: object[]) => {
  if (x.length !== y.length) {
    return false;
  }

  return x.every((_, index) => isEmpty(objectDiff(x[index], y[index])));
};
