import { API } from '@aws-amplify/api';

import {
  AnalyticsRole,
  ClientId,
  PaymentsRole,
  RequestRole,
  RetrieveRoles,
  RetrieveUserRoleOutput,
  SettingsRole,
  WorkspaceScope
} from '@/custom-types/client';
import {
  CreateAnalyticsMultiUsersPermissionsInput,
  CreateAnalyticsPermissionsInput,
  DeleteAnalyticsPermissionsInput,
  RetrieveAnalyticsPermissionsOutput
} from '@/custom-types/permissions';
import { PolicyModel } from '@/custom-types/policies';
import { getTenantId, getUserJwtToken } from '@/helpers/amplify';

export async function getPoliciesByClientId(
  clientId: number
): Promise<{ policies: PolicyModel[] }> {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.get(
    'bouncer',
    `/rest/v1/${tenantId}/policies?client_id=${clientId}`,
    { headers: header }
  );
}

export async function createPolicies(policies: PolicyModel[]) {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  const body = {
    policies
  };

  return API.post('bouncer', `/rest/v1/${tenantId}/policies`, {
    body,
    headers: header
  });
}

export async function deletePolicies(policiesIds: number[]) {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  const body = {
    policy_ids: policiesIds
  };

  return API.del('bouncer', `/rest/v1/${tenantId}/policies`, {
    body,
    headers: header
  });
}

export const createUserRole = async (
  userId: number,
  workspace: WorkspaceScope,
  role: SettingsRole | AnalyticsRole | PaymentsRole | RequestRole
) => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  const body = {
    user_id: userId,
    workspace,
    role
  };

  return API.post('bouncer', `/rest/v1/${tenantId}/user_roles`, {
    body,
    headers: header
  });
};

export const getUserRoles = async (
  userId?: number
): Promise<{ user_roles: RetrieveUserRoleOutput[] }> => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  if (userId) {
    const [d1, d2, d3]: RetrieveRoles[] = await Promise.all([
      API.get('bouncer', `/rest/v1/${tenantId}/user_settings_roles`, {
        headers: header,
        queryStringParameters: { user_id: userId }
      }),
      API.get('bouncer', `/rest/v1/${tenantId}/user_analytics_roles`, {
        headers: header,
        queryStringParameters: { user_id: userId }
      }),
      API.get('bouncer', `/rest/v1/${tenantId}/user_payments_roles`, {
        headers: header,
        queryStringParameters: { user_id: userId }
      })
    ]);
    return {
      user_roles: [
        {
          user_id: userId,
          roles: {
            settings_role: d1.settings_role,
            analytics_role: d2.analytics_role,
            payments_roles: d3.payments_roles
          }
        }
      ]
    };
  }
  return API.get('bouncer', `/rest/v1/${tenantId}/user_roles`, {
    headers: header
  });
};

export const deleteUserRole = async (
  userId: number,
  workspace: WorkspaceScope,
  role: SettingsRole | AnalyticsRole | PaymentsRole | RequestRole
) => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  const body = {
    user_id: userId,
    workspace,
    role
  };

  return API.del('bouncer', `/rest/v1/${tenantId}/user_roles`, {
    body,
    headers: header
  });
};

export const getAnalyticsPermissions = async (
  userId: ClientId,
  analyticsRole: AnalyticsRole
): Promise<RetrieveAnalyticsPermissionsOutput> => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.get(
    'bouncer',
    `/rest/v1/${tenantId}/permissions/analytics/users/${userId}?analytics_role=${analyticsRole}`,
    { headers: header }
  );
};

export const createAnalyticsPermissions = async (
  input: CreateAnalyticsPermissionsInput
) => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  const body = input;

  return API.post(
    'bouncer',
    `/rest/v1/${tenantId}/permissions/analytics/users/${input.user_id}`,
    { body, headers: header }
  );
};

export const bulkCreateAnalyticsPermissions = async (
  input: CreateAnalyticsMultiUsersPermissionsInput
) => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  const body = input;

  return API.post(
    'bouncer',
    `/rest/v1/${tenantId}/permissions/analytics/users`,
    { body, headers: header }
  );
};

export const deleteAnalyticsPermissions = async (
  input: DeleteAnalyticsPermissionsInput
) => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  const body = input;

  return API.del(
    'bouncer',
    `/rest/v1/${tenantId}/permissions/analytics/users/${input.user_id}`,
    { body, headers: header }
  );
};

export const copyRoles = async (
  sourceUserId: number,
  targetUserIds: number[]
) => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  const body = {
    from_user_id: sourceUserId,
    user_ids: targetUserIds
  };

  return API.post('bouncer', `/rest/v1/${tenantId}/user_roles/users/copy`, {
    body,
    headers: header
  });
};

export const copyPermissions = async (
  sourceUserId: number,
  targetUserIds: number[]
) => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  const body = {
    from_user_id: sourceUserId,
    user_ids: targetUserIds
  };

  return API.post(
    'bouncer',
    `/rest/v1/${tenantId}/permissions/analytics/users/copy`,
    { body, headers: header }
  );
};
