<template>
  <div
    ref="infiniteScrollRef"
    class="payflows-table"
  >
    <table>
      <thead>
        <tr>
          <th style="width: 56px">
            <DsCheckbox
              v-if="!isSetInsteadOfArrayForRowSelection"
              v-model="allChecked"
              :class="{ invisible: hideCheckboxes }"
            />
            <DsCheckbox
              v-else
              readonly
              :model-value="
                selectionSet.size === props.rows.length && props.rows.length > 0
              "
              :class="{ invisible: hideCheckboxes }"
              @click="onToggleAllSet"
            />
          </th>
          <th
            v-for="header in headers"
            :key="'head-' + header.field"
            v-bind="header.headerProps"
          >
            <component
              :is="header.sortable ? DsTableHeadSortable : 'span'"
              @click="header.sortable ? emit('sort', header.field) : undefined"
            >
              {{ header.wording }}
            </component>
          </th>
          <th
            name="actions"
            style="width: 56px"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, iRow) in rows"
          :key="'flow-' + iRow"
          :class="{
            'ds-table__row--disabled': !!row.disabledReason,
            'ds-table__row--clickable': isRowClickable
          }"
          @mouseenter="
            !!row.disabledReason
              ? setDisabledTooltip($event, row.disabledReason)
              : undefined
          "
          @mouseleave="
            !!row.disabledReason
              ? setDisabledTooltip($event, undefined)
              : undefined
          "
        >
          <td>
            <DsCheckbox
              v-if="!isSetInsteadOfArrayForRowSelection"
              v-model="row.selected"
              :class="{ invisible: hideCheckboxes }"
              :disabled="!!row.disabledReason"
              @update:model-value="
                emit(
                  'update:selection',
                  rows.filter((row) => row.selected)
                )
              "
            />
            <DsCheckbox
              v-else
              :model-value="selectionSet.has(row.data.id)"
              :class="{ invisible: hideCheckboxes }"
              :disabled="!!row.disabledReason"
              readonly
              @click="onToggleRowSelectionWithSet(row.data.id)"
            />
          </td>
          <td
            v-for="[key, value, cellProps] in getColumnData(row)"
            :key="'cell-' + iRow + '-' + key"
            @click="$emit('onRowClicked', row)"
          >
            <template v-if="fieldComponents[key]">
              <component
                :is="fieldComponents[key]"
                :model-value="value"
                v-bind="cellProps"
              />
            </template>
            <template v-else-if="slots[key]">
              <slot
                :name="key"
                v-bind="{ rowData: row.data }"
              ></slot>
            </template>
            <template v-else>
              {{ value }}
            </template>
          </td>
          <td>
            <div style="width: fit-content">
              <DsDropdown
                v-if="row.actions?.length && !row.disabledReason"
                :actions="row.actions"
                icon-size="small"
                @input="emit('action', row, $event)"
              />
            </div>
          </td>
        </tr>
        <template v-if="loading">
          <tr
            v-for="i of 16"
            :key="'skel-row-' + i"
          >
            <td></td>
            <td
              v-for="header in headers"
              :key="'skel-' + i + '-' + header.field"
            >
              <DsSkeleton />
            </td>
            <td></td>
          </tr>
        </template>
      </tbody>
    </table>

    <PfFixedTooltip
      :model-value="floatingTooltip"
      :left="floatingTooltipLeft"
      :top="floatingTooltipTop"
    />
  </div>
</template>

<script setup lang="ts">
import { useInfiniteScroll } from '@vueuse/core';
import { computed, ref, useSlots } from 'vue';

import PfFixedTooltip from '@/components/NewDesignSystem/PfFixedTooltip/PfFixedTooltip.vue';
import { IDropdownOption } from '@/custom-types/dropdown';

import DsCheckbox from './DsCheckbox.vue';
import DsDropdown from './DsDropdown.vue';
import DsSkeleton from './DsSkeleton.vue';
import DsTableHeadSortable from './DsTableHeadSortable.vue';

export interface DsTableHeader {
  field: string | number;
  wording: string;
  sortable?: boolean;
  component?: any;
  headerProps?: Record<string, any>;
  cellProps?: Record<string, any>;
}

export interface DsTableRow {
  data: any;
  actions?: IDropdownOption[];
  selected?: boolean;
  disabledReason?: string;
}

const slots = useSlots();

const props = defineProps<{
  headers: DsTableHeader[];
  rows: DsTableRow[];
  loading?: boolean;
  isRowClickable?: boolean;
  isSetInsteadOfArrayForRowSelection?: boolean;
  hasMore?: boolean;
  hideCheckboxes?: boolean;
}>();

const selectionSet = ref(new Set());
const infiniteScrollRef = ref<HTMLElement>();

const floatingTooltip = ref<string>();
const floatingTooltipLeft = ref<number>();
const floatingTooltipTop = ref<number>();

const onToggleRowSelectionWithSet = (id: number) => {
  if (selectionSet.value.has(id)) {
    selectionSet.value.delete(id);
  } else {
    selectionSet.value.add(id);
  }
  emit('onSelectionSetUpdated', selectionSet.value);
};

const onToggleAllSet = () => {
  selectionSet.value.size === props.rows.length
    ? selectionSet.value.clear()
    : props.rows.forEach((row) => selectionSet.value.add(row.data.id));
  emit('onSelectionSetUpdated', selectionSet.value);
};

const emit = defineEmits<{
  (e: 'sort', columnValue: string | number): void;
  (e: 'action', row: DsTableRow, action: any): void;
  (e: 'update:selection', selection: DsTableRow[]): void;
  (e: 'onSelectionSetUpdated', selectionSet: Set<unknown>): void;
  (e: 'onRowClicked', row: DsTableRow): void;
  (e: 'loadMore'): void;
}>();

const fieldComponents = computed(() => {
  return Object.fromEntries(
    props.headers.map((header) => [header.field, header.component])
  );
});

const getColumnData = (row: DsTableRow) => {
  return props.headers.map((header) => [
    header.field,
    row.data[header.field],
    header.cellProps
  ]);
};

const allChecked = computed({
  get: () => {
    return props.rows?.length && props.rows.every((row) => row.selected);
  },
  set: (value) => {
    props.rows.forEach((row) => {
      row.selected = value;
    });
    emit(
      'update:selection',
      props.rows.filter((row) => row.selected)
    );
  }
});

const setDisabledTooltip = (event: MouseEvent, reason?: string) => {
  floatingTooltip.value = reason;
  floatingTooltipLeft.value = event.clientX;
  floatingTooltipTop.value = event.clientY;
};

useInfiniteScroll(
  infiniteScrollRef,
  () => {
    if (props.hasMore) {
      emit('loadMore');
    }
  },
  { distance: 48, canLoadMore: () => props.hasMore }
);

defineExpose({
  clearSelection: () => {
    if (props.isSetInsteadOfArrayForRowSelection) {
      selectionSet.value.clear();
      emit('onSelectionSetUpdated', selectionSet.value);
      return;
    }

    props.rows.forEach((row) => {
      row.selected = false;
    });
    emit('update:selection', []);
  }
});
</script>

<style scoped lang="scss">
.payflows-table {
  overflow: scroll;
  flex-grow: 1;

  width: 100%;
  height: 100%;

  background-color: white;
  border-top-left-radius: $view-corner-radius;

  table {
    border-collapse: collapse;
    min-width: 100%;
  }

  thead {
    display: table-header-group;

    tr {
      position: sticky;
      z-index: 1;
      top: 0px;

      background-color: white;
      border-top-left-radius: $view-corner-radius;
      box-shadow: 0px 1px 0px rgba(20, 23, 37, 0.05);
    }

    th {
      @include typo-small-body-bold;

      position: relative;
      padding: 8px;
      text-align: left;

      &:first-child {
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 24px;
      }

      &.pf-table-header-sortable {
        > * {
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        span {
          overflow: hidden;
          display: inline;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        button {
          display: inline-flex;
          margin-left: 4px;
          padding: 0;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $gray100;

      &:last-child {
        border-bottom: none;
      }

      &.ds-table__row--clickable {
        cursor: pointer;
      }

      &.ds-table__row--disabled {
        cursor: not-allowed;
        background-color: $gray25;

        > * {
          opacity: 0.5;
        }
      }

      > td {
        height: 52px;
        padding: 8px;

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }
      }
    }
  }
}

.invisible {
  visibility: hidden;
}
</style>
