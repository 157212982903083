<template>
  <div class="main-calendars">
    <div
      v-if="showPresets"
      class="periods"
      @mouseover="canUpdateDate = true"
    >
      <!-- <DsButton
        class="button-period"
        :class="{active: period == 'today'}"
        type="button"
        @click="period='today'"
      >
        {{ $t('components.dsCalendars.periods.yesterday') }}
      </DsButton> -->
      <!-- <DsButton
        v-if="(new Date()).getDay() !== 1"
        class="button-period"
        :class="{active: period == 'week'}"
        type="button"
        @click="period='week'"
      >
        {{ $t('components.dsCalendars.periods.thisWeek') }}
      </DsButton> -->

      <!-- <DsButton
        class="button-period"
        :class="{active: period == 'lastweek'}"
        type="button"
        @click="period='lastweek'"
      >
        {{ $t('components.dsCalendars.periods.lastWeek') }}
      </DsButton> -->
      <!-- <DsButton
        class="button-period"
        :class="{active: period == 'month'}"
        type="button"
        @click="period='month'"
      >
        {{ $t('components.dsCalendars.periods.lastMonth') }}
      </DsButton> -->
      <DsButton
        class="button-period"
        :class="{ active: period == 'lastThirtyDays' }"
        type="button"
        @click="setPredefinedPeriod('lastThirtyDays')"
      >
        {{ $t('components.dsCalendars.periods.lastThirtyDays') }}
      </DsButton>
      <DsButton
        class="button-period"
        :class="{ active: period == 'lastSevenDays' }"
        type="button"
        @click="setPredefinedPeriod((period = 'lastSevenDays'))"
      >
        {{ $t('components.dsCalendars.periods.lastSevenDays') }}
      </DsButton>
      <DsButton
        class="button-period"
        :class="{ active: period == 'yearToDate' }"
        type="button"
        @click="setPredefinedPeriod('yearToDate')"
      >
        {{ $t('components.dsCalendars.periods.yearToDate') }}
      </DsButton>
      <DsButton
        class="button-period"
        :class="{ active: period == 'quarterToDate' }"
        type="button"
        @click="setPredefinedPeriod('quarterToDate')"
      >
        {{ $t('components.dsCalendars.periods.quarterToDate') }}
      </DsButton>
      <!-- <DsButton
        class="button-period"
        :class="{active: period == 'custom'}"
        @click="setPredefinedPeriod('custom')"
      >
        {{ $t('components.dsCalendars.periods.custom') }}
      </DsButton> -->
    </div>
    <div
      class="calendars"
      :class="{ 'calendars--no-periods': !showPresets }"
    >
      <DsCalendar
        id="calendarLeft"
        position="calendarLeft"
        :date-begin="dateBegin"
        :date-end="dateEnd"
        :month="monthLeft"
        :year="yearLeft"
        :exit="exitRight"
        :period="period"
        :year-options="leftYearOptions"
        :sunday-is-first-day-of-the-week="sundayIsFirstDayOfTheWeek"
        :allow-dates-after-today="allowDatesAfterToday"
        @updateDate="(date) => updateDate(date)"
        @updateMonth="(month) => updateMonthLeft(month)"
        @updateYear="(year) => updateYearLeft(year)"
        @exitLeft="
          () => {
            exitLeft = true;
          }
        "
        @enterLeft="
          () => {
            exitLeft = false;
            canUpdateDate = true;
          }
        "
      />
      <DsCalendar
        id="calendarRight"
        position="calendarRight"
        :date-begin="dateBegin"
        :date-end="dateEnd"
        :month="monthRight"
        :year="yearRight"
        :exit="exitLeft"
        :period="period"
        :year-options="rightYearOptions"
        :sunday-is-first-day-of-the-week="sundayIsFirstDayOfTheWeek"
        :allow-dates-after-today="allowDatesAfterToday"
        @updateDate="(date) => updateDate(date)"
        @updateMonth="(month) => updateMonthRight(month)"
        @updateYear="(year) => updateYearRight(year)"
        @exitRight="
          () => {
            exitRight = true;
          }
        "
        @enterRight="
          () => {
            exitLeft = false;
            canUpdateDate = true;
          }
        "
      />
    </div>
    <div
      class="grey-background"
      :class="{ 'grey-background--no-periods': !showPresets }"
    />
  </div>
</template>

<script>
import DsButton from './DsButton.vue';
import DsCalendar from './DsCalendar.vue';

export default {
  name: 'DsCalendars',
  components: {
    DsCalendar,
    DsButton
  },
  props: {
    allowDatesAfterToday: {
      type: Boolean,
      required: false,
      default: false
    },
    sundayIsFirstDayOfTheWeek: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultDateBegin: {
      type: Object,
      required: false,
      default: null
    },
    defaultDateEnd: {
      type: Object,
      required: false,
      default: null
    },
    defaultPeriod: {
      type: String,
      required: false,
      default: 'custom'
    },
    showPresets: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['updateDate'],
  data() {
    return {
      monthRight: null,
      yearRight: null,
      monthLeft: null,
      yearLeft: null,
      exitLeft: null,
      exitRight: null,
      dateBegin: {
        date: 0,
        month: 0,
        year: 0
      },
      dateEnd: {
        date: 0,
        month: 0,
        year: 0
      },
      period: 'custom',
      isApplyButtonDisabled: false,

      canUpdateDate: false
    };
  },
  computed: {
    rightYearOptions() {
      if (this.allowDatesAfterToday) {
        return this.getYearOptions(this.yearLeft, 2050);
      }

      return this.getYearOptions(this.yearLeft, new Date().getFullYear());
    },
    leftYearOptions() {
      return this.getYearOptions(2000, this.yearRight);
    }
  },

  watch: {
    // period (newValue) {
    //   this.dateEnd = this.clearDate()
    //   this.setBeginEndDate(newValue)
    //   this.leftYearOptions = this.getYearOptions(2000, this.yearRight)
    //   this.rightYearOptions = this.getYearOptions(this.yearLeft, new Date().getFullYear())
    // },
    dateBegin() {
      if (
        this.checkDateSet(this.dateBegin) &&
        !this.checkDateSet(this.dateEnd) &&
        this.canUpdateDate
      ) {
        this.$emit('updateDate', {
          dateBegin: this.dateBegin,
          dateEnd: null
        });
      }
    },
    dateEnd() {
      if (
        this.checkDateSet(this.dateBegin) &&
        this.checkDateSet(this.dateEnd) &&
        this.canUpdateDate
      ) {
        this.$emit('updateDate', {
          dateBegin: this.dateBegin,
          dateEnd: this.dateEnd
        });
      }
    }
  },
  mounted() {
    if (this.defaultPeriod !== 'custom') {
      this.setPredefinedPeriod(this.defaultPeriod);
      this.$emit('updateDate', {
        dateBegin: this.dateBegin,
        dateEnd: this.dateEnd
      });
    }
  },
  created() {
    if (this.defaultDateBegin !== null && this.defaultDateEnd !== null) {
      this.dateBegin = this.defaultDateBegin;
      this.dateEnd = this.defaultDateEnd;
      this.monthLeft = this.safeMonth(this.dateEnd.month);
      this.monthRight = this.dateEnd.month;
      this.yearLeft = this.safeYear(this.monthRight, this.dateEnd.year);
      this.yearRight = this.dateEnd.year;
    } else {
      this.dateBegin = this.getTodayDate();
      this.monthRight = this.dateBegin.month;
      this.monthLeft = this.safeMonth(this.dateBegin.month);
      this.yearLeft = this.safeYear(this.monthRight, this.dateBegin.year);
      this.yearRight = this.dateBegin.year;
    }
  },
  methods: {
    setPredefinedPeriod(period) {
      this.period = period;
      this.setBeginEndDate(period);

      this.monthLeft = this.safeMonth(this.dateEnd.month);
      this.monthRight = this.dateEnd.month;
      if (period !== 'custom') {
        this.yearLeft = this.safeYear(this.monthRight, this.dateEnd.year);
        this.yearRight = this.dateEnd.year;
      }
    },
    updateDate(date) {
      this.period = 'custom';
      if (!this.checkDateSet(this.dateBegin)) {
        this.dateBegin = date;
      } else {
        if (!this.checkDateSet(this.dateEnd)) {
          if (this.compareDate(date, this.dateBegin)) {
            this.dateEnd = this.dateBegin;
            this.dateBegin = date;
          } else {
            this.dateEnd = date;
          }
        } else {
          this.dateBegin = date;
          this.dateEnd = this.clearDate();
        }
      }
    },
    compareDate(date1, date2) {
      if (date1 == null || !this.checkDateSet(date1)) {
        return false;
      }
      if (date2 == null || !this.checkDateSet(date2)) {
        return true;
      }
      if (date1.year < date2.year) {
        return true;
      }
      if (date1.year > date2.year) {
        return false;
      }
      if (date1.month < date2.month) {
        return true;
      }
      if (date1.month > date2.month) {
        return false;
      }
      if (date1.date < date2.date) {
        return true;
      }
      if (date1.date > date2.date) {
        return false;
      }
      return 'equal';
    },
    getTodayDate() {
      const today = new Date();
      return {
        date: today.getDate(),
        month: today.getMonth(),
        year: today.getFullYear()
      };
    },
    clearDate() {
      return {
        date: 0,
        month: 0,
        year: 0
      };
    },
    checkDateSet(date) {
      return date.date !== 0 || date.month !== 0 || date.year !== 0;
    },
    setBeginEndDate(period) {
      const today = new Date();
      const begin = new Date();
      const end = new Date();
      switch (period) {
        case 'lastThirtyDays':
          begin.setDate(today.getDate() - 30);
          this.dateBegin = {
            date: begin.getDate(),
            month: begin.getMonth(),
            year: begin.getFullYear()
          };
          this.dateEnd = {
            date: today.getDate(),
            month: today.getMonth(),
            year: today.getFullYear()
          };
          break;
        case 'lastSevenDays':
          begin.setDate(today.getDate() - 6);
          this.dateBegin = {
            date: begin.getDate(),
            month: begin.getMonth(),
            year: begin.getFullYear()
          };
          this.dateEnd = {
            date: today.getDate(),
            month: today.getMonth(),
            year: today.getFullYear()
          };
          break;
        case 'yearToDate':
          this.dateBegin = {
            date: 1,
            month: 0,
            year: today.getFullYear()
          };
          this.dateEnd = {
            date: today.getDate(),
            month: today.getMonth(),
            year: today.getFullYear()
          };
          break;
        case 'quarterToDate':
          this.dateBegin = {
            date: 1,
            month: today.getMonth() - (today.getMonth() % 3),
            year: today.getFullYear()
          };
          this.dateEnd = {
            date: today.getDate(),
            month: today.getMonth(),
            year: today.getFullYear()
          };
          break;
        case 'today':
          this.monthLeft = this.safeMonth(today.getMonth());
          this.monthRight = today.getMonth();
          this.yearLeft = this.safeYear(today.getMonth(), today.getFullYear());
          this.yearRight = today.getFullYear();
          this.dateBegin = {
            date: today.getDate(),
            month: today.getMonth(),
            year: today.getFullYear()
          };
          this.dateEnd = this.dateBegin;
          break;
        case 'week':
          this.dateBegin = this.getFirstDayOfWeek();
          this.dateEnd = {
            date: today.getDate(),
            month: today.getMonth(),
            year: today.getFullYear()
          };
          this.monthLeft = this.safeMonth(this.dateBegin.month);
          this.monthRight = this.dateBegin.month;
          this.yearLeft = this.safeYear(this.monthRight, this.dateBegin.year);
          this.yearRight = this.dateBegin.year;
          break;
        case 'lastweek':
          begin.setDate(begin.getDate() - begin.getDay() - 6);
          end.setDate(end.getDate() - end.getDay());

          this.dateBegin = {
            date: begin.getDate(),
            month: begin.getMonth(),
            year: begin.getFullYear()
          };
          this.dateEnd = {
            date: end.getDate(),
            month: end.getMonth(),
            year: end.getFullYear()
          };
          this.monthLeft = this.safeMonth(this.dateBegin.month);
          this.monthRight = this.dateBegin.month;
          this.yearLeft = this.safeYear(this.monthRight, this.dateBegin.year);
          this.yearRight = this.dateEnd.year;
          break;
        case 'month':
          this.dateBegin = {
            date: 1,
            month: this.safeMonth(today.getMonth()),
            year: this.safeYear(today.getMonth(), today.getFullYear())
          };
          this.dateEnd = {
            date: new Date(today.getFullYear(), today.getMonth(), 0).getDate(),
            month: this.safeMonth(today.getMonth()),
            year: this.safeYear(today.getMonth(), today.getFullYear())
          };
          this.monthLeft = this.dateBegin.month;
          if (this.dateBegin.month === 11) {
            this.monthRight = 0;
          } else {
            this.monthRight = this.dateBegin.month + 1;
          }
          this.yearLeft = this.dateBegin.year;
          if (this.monthLeft === 11) {
            this.yearRight = this.dateBegin.year + 1;
          } else {
            this.yearRight = this.dateBegin.year;
          }
          break;
        case 'custom':
          this.dateBegin = {
            date: today.getDate(),
            month: today.getMonth(),
            year: today.getFullYear()
          };
          this.dateEnd = this.clearDate();
          this.monthLeft = this.safeMonth(this.dateBegin.month);
          this.monthRight = this.dateBegin.month;
          this.yearLeft = this.safeYear(
            this.dateBegin.month,
            this.dateBegin.year
          );
          this.yearRight = this.dateBegin.year;
          break;
      }
    },
    getFirstDayOfWeek() {
      const date = new Date();
      const day = date.getDay();
      const diff = date.getDate() - day + (day === 0 ? -6 : 1);

      return {
        date: diff,
        month: date.getMonth(),
        year: date.getFullYear()
      };
    },
    updateMonthLeft(newMonth) {
      if (
        this.checkDateSet(this.dateBegin) &&
        this.checkDateSet(this.dateEnd)
      ) {
        if (
          this.dateBegin.month === this.monthLeft &&
          this.dateEnd.month === this.monthLeft &&
          this.dateBegin.year === this.yearLeft &&
          this.dateEnd.year === this.yearLeft
        ) {
          this.dateBegin = this.clearDate();
          this.dateEnd = this.clearDate();
        }
        if (
          this.dateBegin.month === this.monthLeft &&
          this.dateEnd.month !== this.monthLeft &&
          this.dateBegin.year === this.yearLeft &&
          this.dateEnd.year === this.yearLeft
        ) {
          this.dateBegin = this.dateEnd;
          this.dateEnd = this.clearDate();
        }
      }
      if (
        this.checkDateSet(this.dateBegin) &&
        !this.checkDateSet(this.dateEnd)
      ) {
        if (
          this.dateBegin.month === this.monthLeft &&
          this.dateBegin.year === this.yearLeft
        ) {
          this.dateBegin = this.clearDate();
        }
      }

      if (this.monthRight === newMonth && this.yearLeft === this.yearRight) {
        this.updateMonthRight(this.monthRight + 1);
      }
      if (newMonth === -1) {
        this.updateYearLeft(this.yearLeft - 1);
        this.monthLeft = 11;
      }
      if (newMonth === 12) {
        this.updateYearLeft(this.yearLeft + 1);
        this.monthLeft = 0;
        if (this.monthRight === 0 && this.yearRight === this.yearLeft) {
          this.updateMonthRight(this.monthLeft + 1);
        }
      }
      if (newMonth <= 11 && newMonth >= 0) {
        this.monthLeft = newMonth;
      }
    },
    updateMonthRight(newMonth) {
      if (
        this.checkDateSet(this.dateBegin) &&
        this.checkDateSet(this.dateEnd)
      ) {
        if (
          this.dateBegin.month === this.monthRight &&
          this.dateEnd.month === this.monthRight &&
          this.dateBegin.year === this.yearRight &&
          this.dateEnd.year === this.yearRight
        ) {
          this.dateBegin = this.clearDate();
          this.dateEnd = this.clearDate();
        }
        if (
          this.dateEnd.month === this.monthRight &&
          this.dateBegin.month !== this.monthRight &&
          this.dateBegin.year === this.yearRight &&
          this.dateEnd.year === this.yearRight
        ) {
          this.dateEnd = this.clearDate();
        }
      }
      if (
        this.checkDateSet(this.dateBegin) &&
        !this.checkDateSet(this.dateEnd)
      ) {
        if (
          this.dateBegin.month === this.monthRight &&
          this.dateBegin.year === this.yearRight
        ) {
          this.dateBegin = this.clearDate();
        }
      }

      if (newMonth === -1) {
        this.updateYearRight(this.yearRight - 1);
        this.monthRight = 11;
        if (this.monthLeft === 11 && this.yearLeft === this.yearRight) {
          this.updateMonthLeft(this.monthLeft - 1);
        }
      }
      if (newMonth === 12) {
        this.updateYearRight(this.yearRight + 1);
        this.monthRight = 0;
      }
      if (newMonth <= 11 && newMonth >= 0) {
        if (this.monthLeft === newMonth && this.yearLeft === this.yearRight) {
          this.updateMonthLeft(this.monthLeft - 1);
        }
        this.monthRight = newMonth;
      }
    },
    updateYearLeft(newValue) {
      if (newValue <= this.yearRight) {
        this.yearLeft = newValue;
        if (
          this.yearLeft === this.yearRight &&
          this.monthLeft >= this.monthRight
        ) {
          if (this.monthRight === 0) {
            this.monthRight = 1;
            this.monthLeft = 0;
          } else {
            this.monthLeft = this.monthRight - 1;
          }
        }
      }
    },
    updateYearRight(newValue) {
      const today = new Date();

      this.yearRight = newValue;
      if (
        this.yearRight === today.getFullYear() &&
        this.monthRight + 1 > today.getMonth()
      ) {
        this.monthRight = today.getMonth();
      }
      if (
        this.yearLeft === this.yearRight &&
        this.monthLeft >= this.monthRight
      ) {
        if (this.monthLeft === 11) {
          this.monthRight = 11;
          this.monthLeft = 10;
        } else {
          this.monthRight = this.monthLeft + 1;
        }
      }
    },
    getYearOptions(from, to) {
      const arr = [];
      for (let end = to; end >= from; end--) {
        arr.push({
          value: end,
          wording: end.toString()
        });
      }
      return arr;
    },

    /**
     * Ensure that the month is between 0 and 11
     *
     * @param {number} month
     *
     * @returns {number}
     */
    safeMonth(month) {
      return month === 0 ? 11 : month - 1;
    },

    /**
     * Ensure that the year is correct.
     *
     * @param {number} month
     * @param {number} year
     *
     * @returns {number}
     */
    safeYear(month, year) {
      return month === 0 ? year - 1 : year;
    }
  }
};
</script>

<style lang="scss" scoped>
.main-calendars {
  position: relative;

  display: flex;
  flex-direction: column;

  padding: 30px;

  background-color: white;
  .periods {
    display: flex;
    justify-content: space-between;

    margin-bottom: 12px;
    padding: 2px;

    background-color: $gray50;
    border-radius: 10px;
    .button-period {
      width: fill-available;
      width: -webkit-fill-available;
      width: -moz-available;
      width: -ms-fill-available;
      width: -o-fill-available;
      height: 32px;
      min-height: 32px;
      padding-right: 5px;
      padding-left: 5px;

      font-size: 12px;
      color: $gray600;

      background-color: transparent;
      box-shadow: none;
      &:hover {
        color: $gray700;
        background-color: $gray200;
      }

      &.active {
        color: white;
        background-color: $blue500;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.24);
      }

      &:deep(span) {
        width: fill-available;
        width: -webkit-fill-available;
        width: -moz-available;
        width: -ms-fill-available;
        width: -o-fill-available;
        min-width: fit-content;

        text-overflow: clip;
      }
    }
  }

  .calendars {
    position: relative;
    z-index: 1;
    display: flex;
    #calendarLeft {
      margin-top: 26px;
    }
    #calendarRight {
      margin-top: 26px;
      margin-left: 20px;
    }

    &--no-periods {
      #calendarLeft {
        margin-top: 0px;
      }
      #calendarRight {
        margin-top: 0px;
      }
    }
  }

  .grey-background {
    position: absolute;
    top: 80px;
    left: 0;

    width: 100%;
    height: calc(100% - 50px);

    border-bottom-right-radius: 12px;
    box-shadow: inset 0 2px 8px rgb(20 23 37 / 8%);

    &--no-periods {
      box-shadow: none;
    }
  }
}
</style>
