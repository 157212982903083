<template>
  <div :class="{ '--big': isBig }">
    <label
      v-if="label"
      class="DS_field-label"
      @click="focusFirstInput"
    >
      {{ label }}
    </label>
    <div class="char-inputs-container">
      <input
        ref="firstInput"
        v-model="firstChar"
        type="text"
        maxlength="1"
        class="char-input"
        @paste="onPasteOnFirstInput"
        @input="onInputOnFirstInput"
        @keydown="onKeydownOnFirstInput"
      />
      <input
        ref="secondInput"
        v-model="secondChar"
        type="text"
        maxlength="1"
        class="char-input"
        @input="onInputOnSecondInput"
        @keydown="onKeydownOnSecondInput"
      />
      <input
        ref="thirdInput"
        v-model="thirdChar"
        type="text"
        maxlength="1"
        class="char-input"
        @input="onInputOnThirdInput"
        @keydown="onKeydownOnThirdInput"
      />
      <input
        ref="fourthInput"
        v-model="fourthChar"
        type="text"
        maxlength="1"
        class="char-input"
        @input="onInputOnFourthInput"
        @keydown="onKeydownOnFourthInput"
      />
      <input
        ref="fifthInput"
        v-model="fifthChar"
        type="text"
        maxlength="1"
        class="char-input"
        @input="onInputOnFifthInput"
        @keydown="onKeydownOnFifthInput"
      />
      <input
        ref="sixthInput"
        v-model="sixthChar"
        type="text"
        maxlength="1"
        class="char-input"
        @keydown="onKeydownOnSixthInput"
      />
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
  name: 'DsSecurityCodeField',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    isBig: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      firstChar: this.modelValue[0] || '',
      secondChar: this.modelValue[1] || '',
      thirdChar: this.modelValue[2] || '',
      fourthChar: this.modelValue[3] || '',
      fifthChar: this.modelValue[4] || '',
      sixthChar: this.modelValue[5] || ''
    };
  },
  watch: {
    firstChar() {
      this.updateModelValue();
    },
    secondChar() {
      this.updateModelValue();
    },
    thirdChar() {
      this.updateModelValue();
    },
    fourthChar() {
      this.updateModelValue();
    },
    fifthChar() {
      this.updateModelValue();
    },
    sixthChar() {
      this.updateModelValue();
    }
  },
  mounted() {
    if (this.autofocus) {
      nextTick(() => this.focusFirstInput());
    }
  },
  methods: {
    updateModelValue() {
      const code =
        this.firstChar +
        this.secondChar +
        this.thirdChar +
        this.fourthChar +
        this.fifthChar +
        this.sixthChar;

      this.$emit('update:modelValue', code);
    },
    onPasteOnFirstInput(event) {
      const text = (event.clipboardData || window.clipboardData).getData(
        'text'
      );

      if (text.length === 6) {
        event.preventDefault();
        this.firstChar = text[0] || '';
        this.secondChar = text[1] || '';
        this.thirdChar = text[2] || '';
        this.fourthChar = text[3] || '';
        this.fifthChar = text[4] || '';
        this.sixthChar = text[5] || '';
        this.focusSixthInput();
      }
    },
    onInputOnFirstInput() {
      if (this.firstChar.length === 1) {
        this.focusSecondInput();
        this.$refs.secondInput.select();
      }
    },
    onInputOnSecondInput() {
      if (this.secondChar.length === 1) {
        this.focusThirdInput();
        this.$refs.thirdInput.select();
      }
    },
    onInputOnThirdInput() {
      if (this.thirdChar.length === 1) {
        this.focusFourthInput();
        this.$refs.fourthInput.select();
      }
    },
    onInputOnFourthInput() {
      if (this.fourthChar.length === 1) {
        this.focusFifthInput();
        this.$refs.fifthInput.select();
      }
    },
    onInputOnFifthInput() {
      if (this.fifthChar.length === 1) {
        this.focusSixthInput();
        this.$refs.sixthInput.select();
      }
    },
    onKeydownOnFirstInput(event) {
      if (event.key === 'Backspace') {
        event.preventDefault();
        this.firstChar = '';
      }
    },
    onKeydownOnSecondInput(event) {
      if (event.key === 'Backspace') {
        event.preventDefault();
        this.secondChar = '';
        this.focusFirstInput();
      }
    },
    onKeydownOnThirdInput(event) {
      if (event.key === 'Backspace') {
        event.preventDefault();
        this.thirdChar = '';
        this.focusSecondInput();
      }
    },
    onKeydownOnFourthInput(event) {
      if (event.key === 'Backspace') {
        event.preventDefault();
        this.fourthChar = '';
        this.focusThirdInput();
      }
    },
    onKeydownOnFifthInput(event) {
      if (event.key === 'Backspace') {
        event.preventDefault();
        this.fifthChar = '';
        this.focusFourthInput();
      }
    },
    onKeydownOnSixthInput(event) {
      if (event.key === 'Backspace') {
        event.preventDefault();
        this.sixthChar = '';
        this.focusFifthInput();
      }
    },
    focusFirstInput() {
      this.$refs.firstInput.focus();
    },
    focusSecondInput() {
      this.$refs.secondInput.focus();
    },
    focusThirdInput() {
      this.$refs.thirdInput.focus();
    },
    focusFourthInput() {
      this.$refs.fourthInput.focus();
    },
    focusFifthInput() {
      this.$refs.fifthInput.focus();
    },
    focusSixthInput() {
      this.$refs.sixthInput.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/field-label.scss';

.DS_field-label {
  margin-bottom: 10px;
}

.char-inputs-container {
  display: flex;
  align-items: center;
}

.char-input {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 31px;
  height: 36px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $gray1000;
  text-align: center;
  letter-spacing: -0.14px;

  background-color: white;
  border: 1px solid $gray100;
  border-radius: 12px;

  &:not(:first-child) {
    margin-left: 10px;
  }

  &:nth-child(4) {
    margin-left: 30px;
  }
}

.--big {
  .char-input {
    width: 56px;
    height: 76px;
    padding: 16px;

    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    line-height: 44px;
    /* 137.5% */
    letter-spacing: -0.72px;

    &:focus {
      border: 1px solid $blue500;
      box-shadow: 0px 0px 0px 2px #d2d9ff;
    }
  }
}
</style>
