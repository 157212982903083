<template>
  <DsModal
    ref="$modal"
    v-model="open"
    v-bind="$attrs"
  >
    <header class="modal-header">
      <h2 v-text="title" />
    </header>

    <div class="modal-content">
      <slot
        :close="close"
        :pending="pending"
      />
    </div>

    <slot name="footer">
      <footer class="modal-footer">
        <DsButton
          size="sm"
          color="gray"
          @click="close"
        >
          {{ cancelText }}
        </DsButton>

        <DsLoader v-if="pending" />
        <DsButton
          v-else
          size="sm"
          :color="submitColor"
          @click="submit"
        >
          {{ submitText }}
        </DsButton>
      </footer>
    </slot>
  </DsModal>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

import DsButton from './DsButton.vue';
import DsLoader from './DsLoader.vue';
import DsModal from './DsModal.vue';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    title: string;
    submitText: string;
    // eslint-disable-next-line no-undef
    submitColor?: Components.Button.ColorName;
    cancelText?: string;
  }>(),
  {
    submitText: 'Confirm',
    submitColor: 'pink',
    cancelText: 'Cancel'
  }
);
const emit = defineEmits(['submit', 'update:modelValue']);

const $modal = ref(null);
const pending = ref(false);

const open = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});

function submit(): void {
  pending.value = true;

  emit('submit', {
    close() {
      pending.value = false;
      close();
    },
    endRequest() {
      pending.value = false;
    }
  });
}

function close(): void {
  $modal.value.closeModal();
}
</script>

<style lang="scss" scoped>
.body-ds-full-modal-is-opened {
  overflow: hidden;
}

.ds-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow-y: auto;
  display: flex;
  align-items: safe center;
  justify-content: center;

  padding: 40px;

  background: rgba(255, 255, 255, 0.7);

  .modal-header {
    padding: 24px 24px 12px;

    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: $gray1000;
    letter-spacing: -0.2px;
  }

  .modal-content {
    margin-bottom: 24px;
    padding: 0 24px;

    .chips {
      display: flex;
      gap: 4px;
      margin-top: 12px;
    }
  }

  .modal-footer {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;

    padding: 12px 24px;

    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    box-shadow: 0 2px 8px rgba(20, 23, 37, 0.08);
  }
}

.modal-container {
  width: 470px;
  height: max-content;
  margin: auto;

  border-radius: 12px;
  box-shadow: 0 16px 24px 0 rgba($gray1000, 0.16);

  &.wide {
    width: 60%;
  }
}

.modal-box {
  position: relative;

  display: flex;
  flex-direction: column;

  min-height: 64px;

  background-color: white;
  border-radius: 12px;
}

.close-button {
  position: absolute;
  top: 24px;
  right: 24px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
}
</style>
