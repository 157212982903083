<template>
  <span>
    <slot></slot>
  </span>
</template>

<style lang="scss" scoped>
span {
  @include typo-body;

  color: $gray700;
}
</style>
