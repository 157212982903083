import { API } from '@aws-amplify/api';

import { ClientModel } from '@/custom-types/client';
import { GroupModel } from '@/custom-types/group';
import {
  getSessionPayload,
  getTenantId,
  getUserJwtToken
} from '@/helpers/amplify';

import {
  EntityModel,
  GetGroupListOutput,
  GetTenantOutput,
  RetrieveClientMinimalOutput,
  RetrieveClientsMinimalOutput
} from './schemas/mystix';

export async function getEntity(entityId: number): Promise<EntityModel> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('mystix', `/rest/v1/${tenantId}/entities/${entityId}`, {
    headers
  });
}

export async function getGroup(groupId): Promise<GroupModel> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('mystix', `/rest/v1/${tenantId}/groups/${groupId}`, {
    headers
  });
}

export async function getEntities() {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('mystix', `/rest/v1/${tenantId}/entities`, { headers });
}

export async function getGroups(): Promise<GetGroupListOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('mystix', `/rest/v1/${tenantId}/groups`, { headers });
}

export async function createEntity(body) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('mystix', `/rest/v1/${tenantId}/entities`, {
    body,
    headers
  });
}

export async function postEntity(body: EntityModel): Promise<any> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('mystix', `/rest/v1/${tenantId}/entities`, {
    body,
    headers
  });
}

export async function createGroup(body) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('mystix', `/rest/v1/${tenantId}/groups`, { body, headers });
}

export async function patchEntity(entityId, body) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.patch('mystix', `/rest/v1/${tenantId}/entities/${entityId}`, {
    body,
    headers
  });
}

export async function putEntity(entityId, body) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.put('mystix', `/rest/v1/${tenantId}/entities/${entityId}`, {
    body,
    headers
  });
}

export async function patchGroup(groupId, body) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.patch('mystix', `/rest/v1/${tenantId}/groups/${groupId}`, {
    body,
    headers
  });
}

export async function deleteEntity(entityId) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.del('mystix', `/rest/v1/${tenantId}/entities/${entityId}`, {
    headers
  });
}

export async function deleteGroup(groupId) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.del('mystix', `/rest/v1/${tenantId}/groups/${groupId}`, {
    headers
  });
}

export async function getClientInfo(
  clientId
): Promise<RetrieveClientMinimalOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('mystix', `/rest/v1/${tenantId}/clients/${clientId}/minimal`, {
    headers
  });
}

export async function getClientsInfoByTenant(
  clientIdList
): Promise<RetrieveClientsMinimalOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  let parameters = '';

  if (clientIdList.length) {
    parameters = '?client_ids=' + clientIdList.join('&client_ids=');
  }

  return API.get(
    'mystix',
    `/rest/v1/${tenantId}/clients/minimal${parameters}`,
    {
      headers
    }
  );
}

export async function getCurrentUserClient(session = undefined) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  session = session || (await getSessionPayload());

  return API.get(
    'mystix',
    `/rest/v1/${tenantId}/clients/${session.client_id}`,
    { headers }
  );
}

export async function createClient(user) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body = {
    tenant_id: tenantId,
    ...user
  };

  return API.post('mystix', `/rest/v1/${tenantId}/users`, { body, headers });
}

export async function reinviteClient(userId) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('mystix', `/rest/v1/${tenantId}/users/${userId}/reinvite`, {
    headers
  });
}

export interface GetClientsQuery {
  email?: string;
  country_code?: string;
  first_name?: string;
  last_name?: string;
  job_title?: string;
  phone?: string;
  country_name?: string;
  default_currency?: string;
  preferred_language?: string;
  client_ids?: number[];
  host_client_ids?: number[];
  status?: string;
  first_connection?: boolean;
  with_deleted?: boolean;
  text_search?: string;
}

export async function getClients(
  query: GetClientsQuery = {}
): Promise<RetrieveClientsMinimalOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('mystix', `/rest/v1/${tenantId}/clients/minimal`, {
    headers,
    queryStringParameters
  });
}

export async function deleteClient(clientId) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.del('mystix', `/rest/v1/${tenantId}/clients/${clientId}`, {
    headers
  });
}

export interface PatchClientBody {
  country_code?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  job_title?: string | null;
  phone?: string | null;
  country_name?: string | null;
  default_currency?: string | null;
  preferred_language?: string | null;
  first_connection?: boolean | null;
  birth_date?: string | null;
  entity_id?: number | null;
}

export async function patchClient(
  clientId: number,
  body: PatchClientBody
): Promise<ClientModel> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.patch('mystix', `/rest/v1/${tenantId}/clients/${clientId}`, {
    body,
    headers
  });
}

export async function postFirstConnection(clientId) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('mystix', `/rest/v1/${tenantId}/clients/${clientId}/login`, {
    headers
  });
}

export async function getTenant(): Promise<GetTenantOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('mystix', `/rest/v1/${tenantId}/tenant`, { headers });
}

export async function putTenant(
  body: Partial<GetTenantOutput>
): Promise<GetTenantOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.put('mystix', `/rest/v1/${tenantId}/tenant`, { body, headers });
}

export function getGenericConnexion(idpProvider: string) {
  const clientId = import.meta.env.VITE_OAUTH_CLIENT_ID;
  const redirectUri = import.meta.env.VITE_OAUTH_REDIRECT;
  const url = import.meta.env.VITE_OAUTH_DOMAIN;

  const responseType = 'code';
  const scope = 'aws.cognito.signin.user.admin email openid phone profile';

  const buildUrlConnexion = `https://${url}/authorize?idp_identifier=${idpProvider}&response_type=${responseType}&redirect_uri=${redirectUri}&client_id=${clientId}&scope=${scope}`;

  return buildUrlConnexion;
}
