<template>
  <div class="table-head-cell">
    <span>
      <slot></slot>
    </span>
    <DsIconButton
      name="sort"
      size="small"
      color="dark700"
    />
  </div>
</template>

<style lang="scss" scoped>
.table-head-cell {
  cursor: pointer;
  display: flex;
  align-items: center;

  > span {
    @include typo-small-body-bold;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > button {
    margin-left: 4px;
  }
}
</style>
