<template>
  <div
    :class="{
      'ds-number-field': true,
      'ds-number-field--is-disabled': disabled,
      'ds-number-field--has-error': hasError
    }"
  >
    <PfLabel
      :model-value="label"
      :required="required"
      @click="focusModelInput"
    />

    <input
      ref="modelInput"
      :name="name"
      :value="modelValue"
      :type="amountFormat ? 'text' : 'number'"
      class="field-input"
      :placeholder="placeholder?.toString()"
      :min="min"
      :max="max"
      :step="step"
      :disabled="disabled || readonly"
      @input="onInput"
      @blur="$emit('blur')"
    />
  </div>
</template>

<script lang="ts" setup>
import { watchDebounced } from '@vueuse/core';
import { onMounted, ref } from 'vue';
import { CurrencyDisplay, useCurrencyInput } from 'vue-currency-input';
import { useI18n } from 'vue-i18n';

import PfLabel from '@/components/NewDesignSystem/PfLabel/PfLabel.vue';

const { locale } = useI18n();

const props = defineProps({
  modelValue: {
    type: Number,
    default: null
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: [Number, String],
    default: null
  },
  min: {
    type: [Number, String],
    default: null
  },
  max: {
    type: [Number, String],
    default: null
  },
  step: {
    type: [Number, String],
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  autofocus: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    required: false,
    default: false
  },
  amountFormat: {
    type: Boolean,
    required: false,
    default: false
  },
  hasError: {
    type: Boolean,
    required: false,
    default: false
  }
});

const emit = defineEmits(['update:modelValue', 'blur']);

let modelInput = ref<HTMLElement>(null);

if (props.amountFormat) {
  const { inputRef, numberValue } = useCurrencyInput({
    locale: locale.value,
    currency: 'EUR',
    currencyDisplay: CurrencyDisplay.hidden,
    precision: 2,
    hideCurrencySymbolOnFocus: true,
    hideGroupingSeparatorOnFocus: false,
    hideNegligibleDecimalDigitsOnFocus: false,
    autoDecimalDigits: false,
    useGrouping: true,
    accountingSign: true,
    valueRange: {
      min: props.min
        ? typeof props.min === 'string'
          ? Number(props.min)
          : props.min
        : null,
      max: props.max
        ? typeof props.max === 'string'
          ? Number(props.max)
          : props.max
        : 1000000000
    }
  });
  watchDebounced(numberValue, (value) => emit('update:modelValue', value), {
    debounce: 1000
  });
  // eslint-disable-next-line vue/no-ref-as-operand
  modelInput = inputRef;
}

onMounted(() => {
  if (props.autofocus === true) {
    focusModelInput();
  }
});

const onInput = (event) => {
  if (!props.amountFormat) {
    if (event.target.value === '') {
      emit('update:modelValue', null);
    } else {
      emit('update:modelValue', Number(event.target.value));
    }
  }
};

const focusModelInput = () => {
  modelInput.value.focus();
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/field-label.scss';

.field-input {
  @include typo-body;

  cursor: text;

  display: block;

  width: 100%;
  min-width: 96px;
  height: 36px;
  padding: 0 11px;

  color: $gray1000;
  text-align: left;

  background-color: white;
  border: 1px solid $gray100;
  border-radius: 12px;

  &:disabled {
    cursor: not-allowed;
    background-color: $gray50;
    border-color: $gray100;
    &::placeholder {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $gray300;
      letter-spacing: -0.14px;
    }
  }

  &:hover {
    border-color: $gray200;
  }

  &:focus {
    border-color: $blue500;
  }

  &:focus,
  &:active,
  &:active:focus {
    &::placeholder {
      opacity: 0;
    }
  }
}

.ds-number-field {
  flex-grow: 1;
  &.ds-number-field--has-error {
    .field-input {
      border-color: $red500;
    }
  }
}

.label-asterisk {
  color: $red500;
}

/* For Chrome, Safari, and newer versions of Edge */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
