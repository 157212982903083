<template>
  <div class="skeleton" />
</template>

<style lang="scss">
.skeleton {
  width: fill-available;
  width: -webkit-fill-available;
  min-height: 10px;
  margin: 4px;

  opacity: 1;
  background: linear-gradient(
      90deg,
      rgba(211, 212, 219, 0) 0%,
      $gray200 51.78%,
      rgba(211, 212, 219, 0) 100%
    ),
    $gray100;
  border-radius: 4px;

  animation: skeleton 0.8s ease-in-out infinite alternate;
}

@keyframes skeleton {
  0% {
    background: linear-gradient(
        90deg,
        rgba(211, 212, 219, 0) 0%,
        $gray200 51.78%,
        rgba(211, 212, 219, 0) 100%
      ),
      $gray100;
  }

  100% {
    opacity: 0.5;
    background: linear-gradient(
        90deg,
        rgba(211, 212, 219, 0) 0%,
        $gray200 51.78%,
        rgba(211, 212, 219, 0) 100%
      ),
      $gray100;
  }
}
</style>
