<template>
  <Datepicker
    ref="refDatePicker"
    v-model="defaultDate"
    :data-test="dataTest"
    class="calendar"
    auto-apply
    inline
    :enable-time-picker="false"
    :min-date="props.disablesDatesBeforeToday ? new Date() : null"
    :max-date="props.allowDatesAfterToday ? null : new Date()"
    :prevent-min-max-navigation="
      props.allowDatesAfterToday || !props.disablesDatesBeforeToday
        ? false
        : true
    "
    :month-change-on-scroll="false"
    :locale="$i18n.locale"
    @date-update="(date) => emit('update:date', date)"
  >
    <template #preset-date-range-button="{ label, value, presetDate }">
      <span
        role="button"
        :tabindex="4"
        @click="presetDate(value)"
        @keyup.enter.prevent="presetDate(value)"
        @keyup.space.prevent="presetDate(value)"
      >
        {{ label }}
      </span>
    </template>
    <template #arrow-right>
      <DsIcon
        name="chevron-right"
        size="small"
        color="gray1000"
        style="width: 12px; height: 12px"
      />
    </template>
    <template #arrow-left>
      <DsIcon
        name="chevron-left"
        size="small"
        color="gray1000"
        style="width: 12px; height: 12px"
      />
    </template>
    <template #month-overlay-value="{ text, value }">
      <div class="month-overlay-value">
        <span>{{ String(value + 1).padStart(2, '0') }}</span>
        <span>{{ text }}</span>
      </div>
    </template>
    <template #year-overlay-value="{ value }">
      <div
        class="year-overlay-value"
        @click="openMonthOverlay"
      >
        <span>{{ value }}</span>
      </div>
    </template>
    <template #month="{ text }">
      {{ text }}
      <DsIcon
        name="chevron-down"
        size="small"
        color="gray400"
        style="margin-left: 2px"
      />
    </template>
    <template #year="{ value }">
      {{ value }}
      <DsIcon
        name="chevron-down"
        size="small"
        color="gray400"
        style="margin-left: 2px"
      />
    </template>
    <template #calendar-header="{ day }">
      {{ day.substring(0, 1) }}
    </template>
  </Datepicker>
</template>

<script lang="ts" setup>
import '@vuepic/vue-datepicker/dist/main.css';

import Datepicker from '@vuepic/vue-datepicker';
import { ref } from 'vue';

const refDatePicker = ref(null);
const defaultDate = defineModel<Date>();

const props = defineProps({
  disablesDatesBeforeToday: {
    type: Boolean,
    required: false,
    default: false
  },
  allowDatesAfterToday: {
    type: Boolean,
    required: false,
    default: false
  },
  dataTest: {
    type: String,
    required: false,
    default: undefined
  }
});

const emit = defineEmits<{
  (e: 'update:date', date: Date): void;
}>();

const openMonthOverlay = () => {
  refDatePicker.value.switchView('month');
};
</script>

<style lang="scss" scoped>
.calendar {
  overflow: hidden;
  display: inline-block;
  :deep(.dp__menu) {
    border: 0;
  }
  :deep(.dp__inner_nav) {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    padding: 6px;

    background: $gray100;
    border-radius: 8px;
    svg {
      width: none;
      height: none;
    }
  }
  :deep(.dp--header-wrap) {
    gap: 8px;
    padding: 8px 12px;
    border-bottom: 1px solid $gray100;
    button.dp__month_year_select {
      display: flex;
      align-items: left;
      justify-content: left;

      height: auto;
      margin-left: 8px;

      background-color: transparent;
    }
    button.dp__month_year_select:first-child {
      align-items: right;
      justify-content: right;
      margin-left: 0px;
    }
  }
  :deep(.dp__menu_inner) {
    padding: 0 0 10px 0;
    .dp__calendar_header {
      padding: 12px 12px 0 12px;
      color: #83869a;
    }
    .dp__calendar_header_item {
      @include typo-small-body-bold;

      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0px;

      color: $gray500;
      text-align: center;
    }
    .dp__calendar_header_separator {
      display: none;
    }
  }
  :deep(.dp__menu_content_wrapper) {
    display: flex;
    flex-direction: column;
    .dp--preset-dates {
      display: flex;
      align-items: center;
    }
  }
  :deep(.dp__calendar_row) {
    padding: 0 12px;
  }
  :deep(.dp__calendar_item) {
    display: flex;
    justify-content: center;
    padding-right: 0px;
    padding-left: 0px;

    .dp__active_date {
      background-color: $blue500;
    }
    .dp__today {
      background-color: $gray100;
      border-color: transparent;
    }
    .dp__today.dp__active_date {
      background-color: $blue500;
    }
    &:first-child {
      padding-right: 2px;
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      padding-left: 2px;
    }

    &:has(div) {
      &:has(.dp__range_start) {
        background: linear-gradient(90deg, white 50%, $blue100 50%);
      }
      &:has(.dp__range_end) {
        background: linear-gradient(90deg, $blue100 50%, white 50%);
      }
      &:has(.dp__range_between) {
        background-color: $blue100;
      }
    }

    .dp__range_start {
      @include typo-body-bold;

      display: flex;
      flex-direction: column;
      gap: 10px;

      padding: 4px;

      color: white;
      text-align: center;

      background: $blue500;
      border-radius: 8px;
    }
    .dp__range_end {
      @include typo-body-bold;

      display: flex;
      flex-direction: column;
      gap: 10px;

      padding: 4px;

      color: white;
      text-align: center;

      background: $blue500;
      border-radius: 8px;
    }
    .dp__range_between {
      display: flex;
      gap: 4px;
      align-self: stretch;

      background: $blue100;
      border: 0;
      border-radius: 0;
    }
  }
  :deep(.dp__theme_light) {
    --dp-cell-size: 28px;
    --dp-cell-padding: 4px;
    --dp-font-family: Inter, sans-serif;
    --dp-multi-calendars-spacing: 0;

    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.14px;

    border-color: transparent;
    border-radius: 12px;
  }

  :deep(.dp__overlay_container) {
    &:has(.month-overlay-value) {
      padding: 12px;
    }
    .dp__overlay_col:has(.month-overlay-value) {
      align-self: stretch;
      width: 33.333%;
    }
    .dp__overlay_cell:has(.month-overlay-value) {
      height: 100%;
      padding: 0;

      color: none;

      background: none;

      transition: none;
      span:first-child {
        height: 16px;

        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $blue500;
        letter-spacing: -0.12px;
      }
      span:last-child {
        height: 16px;

        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $gray1000;
        letter-spacing: -0.14px;
      }
      .month-overlay-value {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        height: 100%;
        padding: 4px;

        border-radius: 8px;
      }
      &:hover {
        .month-overlay-value {
          background: $gray100;
        }
      }
    }
    .dp__overlay_cell_active:has(.month-overlay-value) {
      height: 100%;
      padding: 0;

      color: none;

      background: none;

      transition: none;
      span:first-child {
        height: 16px;

        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $blue200;
        letter-spacing: -0.12px;
      }
      span:last-child {
        @include typo-body-bold;

        height: 16px;
        color: white;
      }
      .month-overlay-value {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        height: 100%;
        padding: 4px;

        background: $blue500;
        border-radius: 8px;
      }
    }
    &:has(.year-overlay-value) {
      display: flex;
      gap: 4px;
      padding: 12px;
    }
    .dp__overlay_cell:has(.year-overlay-value) {
      &:hover {
        background: none;
      }
    }
    .dp__overlay_row:has(.year-overlay-value) {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;
      .dp__overlay_col {
        width: 100%;
        padding: 4px;
        .dp__overlay_cell_pad {
          padding: 0;
        }
        .year-overlay-value {
          height: 20px;
        }
        &:hover {
          background-color: $gray100;
          border-radius: 8px;
        }
      }
      .dp__overlay_col:has(.dp__overlay_cell_active) {
        background-color: $blue500;
        border-radius: 8px;
        .dp__overlay_cell_active {
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          color: white;
          letter-spacing: -0.14px;

          background-color: $blue500;
        }
      }
    }
  }
}
:deep(.dp__overlay_action) {
  display: none !important;
}
</style>
