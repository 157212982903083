<template>
  <span
    :data-amount="amount"
    :class="{
      'ds-amount': true,
      'ds-amount--colored-positive': colored && amount > 0,
      'ds-amount--colored-negative': colored && amount < 0,
      'ds-amount--color': color
    }"
    v-html="formattedAmount"
  ></span>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { resolveColor } from '@/helpers/design-system';

import CURRENCIES from '../../helpers/currencies.json';

const props = defineProps({
  bold: { type: Boolean, default: false },
  amount: { type: [Number, null], required: true },
  currency: { type: String, default: '' },
  colored: { type: Boolean, default: false },
  color: { type: String, default: '' },
  floor: { type: Boolean, default: false },
  useCustomFormatter: { type: Boolean, default: false },
  currencySign: {
    type: String as PropType<'accounting' | 'standard'>,
    default: 'accounting'
  },
  currencyDisplay: {
    type: String as PropType<'symbol' | 'code' | 'name'>,
    default: 'symbol'
  },
  precision: { type: Number, default: 2 }
});

const { locale } = useI18n();

const fontWeight = computed(() => (props.bold ? 600 : 500));

const amountStr = computed(() => String(Math.abs(props.amount / 100)));

const currencyCode = computed(() => {
  const currency = props.currency?.replace('/2', '');
  const currencyCode = CURRENCIES.find((c) => c.cc === currency);
  return currencyCode ? currencyCode.cc : null;
});

const formattedAmount = computed(() => {
  if (props.useCustomFormatter === true || !currencyCode.value) {
    return customFormattedAmount.value;
  }
  const maximumFractionDigits = props.floor === true ? 0 : props.precision;
  const formatter = new Intl.NumberFormat(locale.value || 'en-us', {
    style: 'currency',
    currency: currencyCode.value, // EUR | USD | ...
    minimumFractionDigits: !maximumFractionDigits ? 0 : 2, // 1,000,000.00
    maximumFractionDigits,
    useGrouping: true, // 1,000,000.00
    currencyDisplay: props.currencyDisplay,
    currencySign: props.currencySign
  });

  const amount =
    props.floor === true ? Math.floor(props.amount / 100) : props.amount / 100;

  return `<strong>${formatter.format(amount)}</strong>`;
});

const customFormattedAmount = computed(() => {
  // Remove leading zeros if any, but keep one zero before decimal point for numbers like .99
  const cleanAmountStr = amountStr.value.replace(/^0+/, '') || '0';

  // Split the number into integer and decimal parts
  const parts = cleanAmountStr.split('.');
  const integerPart = parts[0];
  const decimalPart = parts[1] || '';

  // Format the integer part with commas for thousands
  const formattedUnits = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Adjust the decimal part
  let formattedCentimes = '';
  if (!props.floor && decimalPart.length > 0) {
    formattedCentimes = decimalPart;
  }

  let formattedAmount = `<strong>${formattedUnits}</strong>`;

  if (formattedCentimes) {
    formattedAmount += `.${formattedCentimes}`;
  }

  if (currencyCode.value) {
    if (props.floor) {
      formattedAmount += `<small>${currencyCode.value}</small>`;
    } else {
      formattedAmount += `<small> ${currencyCode.value}</small>`;
    }
  }

  // Handle negative numbers
  if (props.amount < 0) {
    if (props.currencySign === 'accounting') {
      formattedAmount = `(${formattedAmount})`;
    } else {
      formattedAmount = `-${formattedAmount}`;
    }
  }

  return formattedAmount;
});

const scssColor = computed(() => {
  return resolveColor(props.color);
});
</script>

<style lang="scss" scoped>
.ds-amount {
  font-weight: v-bind('fontWeight');
  font-variant-numeric: lining-nums tabular-nums;
  white-space: nowrap;

  :deep(strong) {
    font-weight: v-bind('fontWeight');
  }

  :deep(small) {
    font-size: 75%;

    > strong {
      font-size: 125%;
    }
  }

  &.ds-amount--colored-positive {
    color: $green500;
  }

  &.ds-amount--colored-negative {
    color: $red500;
  }
  &.ds-amount--color {
    color: v-bind('scssColor');
  }
}
</style>
