<template>
  <div>
    <label
      v-if="label"
      class="DS_field-label"
      @click="focusFirstInput"
    >
      {{ label }}
    </label>
    <div class="errors-container">
      <div class="char-inputs-container">
        <input
          ref="firstInput"
          v-model="firstChar"
          type="text"
          maxlength="1"
          class="char-input"
          :class="{ 'char-input-errors': hasErrors }"
          @paste="onPasteOnFirstInput"
          @input="onInputOnFirstInput"
          @keydown="onKeydownOnFirstInput"
        />
        <input
          ref="secondInput"
          v-model="secondChar"
          type="text"
          maxlength="1"
          class="char-input"
          :class="{ 'char-input-errors': hasErrors }"
          @input="onInputOnSecondInput"
          @keydown="onKeydownOnSecondInput"
        />
        <input
          ref="thirdInput"
          v-model="thirdChar"
          type="text"
          maxlength="1"
          class="char-input"
          :class="{ 'char-input-errors': hasErrors }"
          @input="onInputOnThirdInput"
          @keydown="onKeydownOnThirdInput"
        />
        <input
          ref="fourthInput"
          v-model="fourthChar"
          type="text"
          maxlength="1"
          class="char-input"
          :class="{ 'char-input-errors': hasErrors }"
          @input="onInputOnFourthInput"
          @keydown="onKeydownOnFourthInput"
        />
        <input
          ref="fifthInput"
          v-model="fifthChar"
          type="text"
          maxlength="1"
          class="char-input"
          :class="{ 'char-input-errors': hasErrors }"
          @input="onInputOnFifthInput"
          @keydown="onKeydownOnFifthInput"
        />
        <input
          ref="sixthInput"
          v-model="sixthChar"
          type="text"
          maxlength="1"
          class="char-input"
          :class="{ 'char-input-errors': hasErrors }"
          @keydown="onKeydownOnSixthInput"
        />
      </div>
      <div
        v-if="hasErrors"
        class="error-text"
      >
        <label>{{
          $t('components.settings.myProfile.modalSetUpMFA.invalidCodeMessage')
        }}</label>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';

const props = defineProps({
  modelValue: { type: String, required: true },
  label: { type: String, required: false, default: '' },
  autofocus: { type: Boolean, required: false, default: false },
  errors: { type: Boolean, required: false, default: false }
});

const emit = defineEmits<{ (e: 'update:modelValue', value: string): void }>();

const firstChar = ref(props.modelValue[0] || '');
const secondChar = ref(props.modelValue[1] || '');
const thirdChar = ref(props.modelValue[2] || '');
const fourthChar = ref(props.modelValue[3] || '');
const fifthChar = ref(props.modelValue[4] || '');
const sixthChar = ref(props.modelValue[5] || '');

const firstInput = ref(null);
const secondInput = ref(null);
const thirdInput = ref(null);
const fourthInput = ref(null);
const fifthInput = ref(null);
const sixthInput = ref(null);

const hasErrors = computed(() => {
  return (props.errors && props.modelValue.length === 6) || false;
});

watch(
  [firstChar, secondChar, thirdChar, fourthChar, fifthChar, sixthChar],
  () => {
    updateModelValue();
  }
);

const updateModelValue = () => {
  const code =
    firstChar.value +
    secondChar.value +
    thirdChar.value +
    fourthChar.value +
    fifthChar.value +
    sixthChar.value;
  emit('update:modelValue', code);
};

const onPasteOnFirstInput = (event) => {
  const text = event.clipboardData.getData('text');

  if (text.length === 6) {
    event.preventDefault();
    firstChar.value = text[0] || '';
    secondChar.value = text[1] || '';
    thirdChar.value = text[2] || '';
    fourthChar.value = text[3] || '';
    fifthChar.value = text[4] || '';
    sixthChar.value = text[5] || '';
    focusSixthInput();
  }
};

const onInputOnFirstInput = () => {
  if (firstChar.value.length === 1) {
    focusSecondInput();
    secondInput.value.select();
  }
};

const onInputOnSecondInput = () => {
  if (secondChar.value.length === 1) {
    focusThirdInput();
    thirdInput.value.select();
  }
};

const onInputOnThirdInput = () => {
  if (thirdChar.value.length === 1) {
    focusFourthInput();
    fourthInput.value.select();
  }
};

const onInputOnFourthInput = () => {
  if (fourthChar.value.length === 1) {
    focusFifthInput();
    fifthInput.value.select();
  }
};

const onInputOnFifthInput = () => {
  if (fifthChar.value.length === 1) {
    focusSixthInput();
    sixthInput.value.select();
  }
};

const onKeydownOnFirstInput = (event) => {
  if (event.key === 'Backspace') {
    event.preventDefault();
    firstChar.value = '';
  }
};

const onKeydownOnSecondInput = (event) => {
  if (event.key === 'Backspace') {
    event.preventDefault();
    secondChar.value = '';
    focusFirstInput();
  }
};

const onKeydownOnThirdInput = (event) => {
  if (event.key === 'Backspace') {
    event.preventDefault();
    thirdChar.value = '';
    focusSecondInput();
  }
};

const onKeydownOnFourthInput = (event) => {
  if (event.key === 'Backspace') {
    event.preventDefault();
    fourthChar.value = '';
    focusThirdInput();
  }
};

const onKeydownOnFifthInput = (event) => {
  if (event.key === 'Backspace') {
    event.preventDefault();
    fifthChar.value = '';
    focusFourthInput();
  }
};

const onKeydownOnSixthInput = (event) => {
  if (event.key === 'Backspace') {
    event.preventDefault();
    sixthChar.value = '';
    focusFifthInput();
  }
};

const focusFirstInput = () => {
  firstInput.value.focus();
};

const focusSecondInput = () => {
  secondInput.value.focus();
};

const focusThirdInput = () => {
  thirdInput.value.focus();
};

const focusFourthInput = () => {
  fourthInput.value.focus();
};

const focusFifthInput = () => {
  fifthInput.value.focus();
};

const focusSixthInput = () => {
  sixthInput.value.focus();
};

onMounted(() => {
  if (props.autofocus) {
    focusFirstInput();
  }
});
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/field-label.scss';

.DS_field-label {
  margin-bottom: 10px;
}

.errors-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
}
.char-inputs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.error-text {
  display: flex;
  width: 100%;
  margin-top: 4px;
  color: $red500;
}

.char-input {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 56px;
  height: 76px;

  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  color: $gray1000;
  text-align: center;
  letter-spacing: -0.72px;

  background-color: white;
  border: 1px solid $gray100;
  border-radius: 12px;

  &:not(:first-child) {
    margin-left: 10px;
  }

  &:nth-child(4) {
    margin-left: 32px;
  }

  &:focus:not(.char-input-errors) {
    border: 1px solid $blue500;
    outline: 2px solid $blue150;
  }
}

.char-input-errors {
  border: 1px solid $red500;
  outline: 2px solid $red150;
}
</style>
