<template>
  <div
    class="text"
    :class="'typo-' + $props.typo"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { Typo } from '@/constants/typography';
import { COLORS_PALETTE } from '@/helpers/design-system';

const props = withDefaults(
  defineProps<{
    typo?: Typo;
    color?: string;
  }>(),
  {
    typo: Typo.Body,
    color: 'gray1000'
  }
);

const iconColor = computed(() => resolveColor(props.color));

const resolveColor = (name?: string) => {
  if (!name) {
    return undefined;
  }

  if (name.startsWith('#') || name.startsWith('rgb') || name === 'white') {
    return name;
  }

  return COLORS_PALETTE[name];
};
</script>

<style lang="scss" scoped>
.text {
  color: v-bind(iconColor);
  &.typo-headline {
    @include typo-headline;
  }
  &.typo-body {
    @include typo-body;
  }
  &.typo-body-bold {
    @include typo-body-bold;
  }
  &.typo-small-body {
    @include typo-small-body;
  }
  &.typo-small-body-bold {
    @include typo-small-body-bold;
  }
  &.typo-title {
    @include typo-title;
  }
  &.typo-small-title {
    @include typo-small-title;
  }
  &.typo-overline {
    @include typo-overline;
  }
  &.typo-digits {
    @include typo-digits;
  }
  &.typo-digits-bold {
    @include typo-digits-bold;
  }
  &.typo-extra-small {
    @include typo-extra-small;
  }
}
</style>
