<template>
  <hr class="ds-separator" />
</template>

<script>
export default {
  name: 'DsSeparator'
};
</script>

<style lang="scss" scoped>
.ds-separator {
  border: none;
  border-top: 1px solid $blue150;
}
</style>
