import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { getEntities } from '@/api/mystix';
import { EntityModel } from '@/api/schemas/mystix';
import { useCallApi } from '@/composables/useCallApi';

export const useEntitiesStore = defineStore('entities', () => {
  const entitiesArray = ref<EntityModel[]>([]);
  const entitiesIds = computed(() =>
    entitiesArray.value.map((entity) => entity.id)
  );
  const hasGetEntitiesBeenCalled = ref(false);

  const { isPending, hasError, errorMessage, callApi } = useCallApi(
    async () => {
      hasGetEntitiesBeenCalled.value = true;
      const response = await getEntities();
      entitiesArray.value = response.entities;
    }
  );

  const getEntityById = (id: number) => {
    return entitiesArray.value.find((entity) => entity.id === id);
  };

  const callGetEntities = callApi;

  return {
    entitiesArray,
    entitiesIds,
    isPending,
    hasError,
    errorMessage,
    callGetEntities,
    hasGetEntitiesBeenCalled,
    getEntityById
  };
});
