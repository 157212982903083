import { ref } from 'vue';

import { useToastStore } from '@/stores/toast';

export const useCallApi = (callback: any) => {
  const isPending = ref(false);
  const hasError = ref(false);
  const callbackError = ref();
  const errorMessage = ref('');
  const lastApiCallId = ref(0);

  const toast = useToastStore();

  const callApi = async (...args: any[]) => {
    isPending.value = true;
    hasError.value = false;
    const apiCallId = ++lastApiCallId.value;

    try {
      // eslint-disable-next-line n/no-callback-literal
      await callback(...args, () => {
        return apiCallId === lastApiCallId.value;
      });
    } catch (error: any) {
      callbackError.value = error;
      errorMessage.value = error?.response?.data?.message;
      toast.showError(error);
      hasError.value = true;
    }

    isPending.value = false;
  };

  return {
    isPending,
    hasError,
    errorMessage,
    callApi
  };
};
