<template>
  <div>
    <label
      v-if="label"
      class="DS_field-label"
    >
      {{ label }}
    </label>
    <textarea ref="markdownTextarea" />
  </div>
</template>

<script>
export default {
  name: 'DsMarkdownEditor',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      simplemde: null
    };
  },
  mounted() {
    // eslint-disable-next-line
    this.simplemde = new SimpleMDE({
      element: this.$refs.markdownTextarea,
      toolbar: [
        'bold',
        'italic',
        '|',
        'ordered-list',
        'unordered-list',
        '|',
        'quote',
        'code',
        'link',
        '|',
        'preview'
      ],
      spellChecker: false,
      status: false
    });

    this.simplemde.codemirror.on('change', () => {
      this.$emit('update:modelValue', this.simplemde.value());
    });
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/field-label.scss';
</style>

<style lang="scss">
.CodeMirror {
  cursor: text;
}

.editor-preview {
  padding: 16px;

  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: $dark800;
  letter-spacing: -0.14px;

  p:not(:first-child) {
    margin-top: 14px;
  }

  h1 {
    font-size: 38px;

    &:not(:first-child) {
      margin-top: 14px;
    }
  }

  h2 {
    font-size: 34px;

    &:not(:first-child) {
      margin-top: 14px;
    }
  }

  h3 {
    font-size: 30px;

    &:not(:first-child) {
      margin-top: 14px;
    }
  }

  h4 {
    font-size: 26px;

    &:not(:first-child) {
      margin-top: 14px;
    }
  }

  h5 {
    font-size: 22px;

    &:not(:first-child) {
      margin-top: 14px;
    }
  }

  h6 {
    font-size: 18px;

    &:not(:first-child) {
      margin-top: 14px;
    }
  }

  ol,
  ul {
    padding-left: 16px;

    &:not(:first-child) {
      margin-top: 14px;
    }
  }

  blockquote {
    font-style: italic;

    &:not(:first-child) {
      margin-top: 14px;
    }
  }

  pre:not(:first-child) {
    margin-top: 14px;
  }

  a {
    color: $blue500;
    text-decoration: underline;
  }
}
</style>
