<template>
  <template v-if="!$slots['tooltip-text']">
    <slot name="content"></slot>
  </template>

  <div
    v-else
    class="pf-tooltip"
  >
    <div
      ref="triggerRef"
      class="tooltip-content"
      @mouseenter="showTooltip"
      @mouseleave="startHideTimer"
    >
      <slot name="content"></slot>
    </div>
    <Teleport to="body">
      <Transition name="fade">
        <div
          v-if="isVisible"
          ref="tooltipRef"
          class="tooltip"
          :class="[`tooltip--${adjustedPosition}`]"
          :style="tooltipStyle"
          @mouseenter="cancelHideTimer"
          @mouseleave="hideTooltip"
        >
          <div
            class="tooltip-text"
            :style="{ maxWidth: maxWidthCss }"
          >
            <slot name="tooltip-text"></slot>
          </div>
          <svg
            :width="
              adjustedPosition === 'top' || adjustedPosition === 'bottom'
                ? 25
                : 8
            "
            :height="
              adjustedPosition === 'top' || adjustedPosition === 'bottom'
                ? 8
                : 25
            "
            :viewBox="
              adjustedPosition === 'top' || adjustedPosition === 'bottom'
                ? '0 0 25 8'
                : '0 0 8 25'
            "
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="tooltip-arrow"
            :class="[`tooltip-arrow--${adjustedPosition}`]"
          >
            <path
              v-if="adjustedPosition === 'top'"
              d="M24.5 0H0.5C5.51936 0 9.81894 3.0817 11.6099 7.45627C11.7758 7.77912 12.1121 8 12.5 8C12.8879 8 13.2242 7.77912 13.3901 7.45627C15.1811 3.0817 19.4806 0 24.5 0Z"
              fill="white"
            />
            <path
              v-if="adjustedPosition === 'bottom'"
              d="M24.5 8H0.5C5.51936 8 9.81894 4.9183 11.6099 0.54373C11.7758 0.220877 12.1121 0 12.5 0C12.8879 0 13.2242 0.220877 13.3901 0.54373C15.1811 4.9183 19.4806 8 24.5 8Z"
              fill="white"
            />
            <path
              v-if="adjustedPosition === 'left'"
              d="M0 24.5V0.5C0 5.51936 3.0817 9.81894 7.45627 11.6099C7.77912 11.7758 8 12.1121 8 12.5C8 12.8879 7.77912 13.2242 7.45627 13.3901C3.0817 15.1811 0 19.4806 0 24.5Z"
              fill="white"
            />
            <path
              v-if="adjustedPosition === 'right'"
              d="M8 24.5V0.5C8 5.51936 4.9183 9.81894 0.54373 11.6099C0.220877 11.7758 0 12.1121 0 12.5C0 12.8879 0.220877 13.2242 0.54373 13.3901C4.9183 15.1811 8 19.4806 8 24.5Z"
              fill="white"
            />
          </svg>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { useElementBounding } from '@vueuse/core';
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  useSlots,
  watch
} from 'vue';

const props = withDefaults(
  defineProps<{
    position?: 'auto' | 'top' | 'bottom' | 'left' | 'right';
    offset?: number;
    disabled?: boolean;
    maxWidth?: number;
  }>(),
  {
    position: 'auto',
    offset: 8,
    disabled: false,
    maxWidth: 300
  }
);

const slots = useSlots();

const triggerRef = ref<HTMLElement | null>(null);
const tooltipRef = ref<HTMLElement | null>(null);
const isVisible = ref(false);
const hideTimer = ref<number>();

const {
  height: triggerHeight,
  width: triggerWidth,
  x: triggerX,
  y: triggerY,
  top: triggerTop,
  bottom: triggerBottom,
  left: triggerLeft,
  right: triggerRight,
  update: updateTriggerBounds
} = useElementBounding(triggerRef);
const {
  height: tooltipHeight,
  width: tooltipWidth,
  update: updateTooltipBounds
} = useElementBounding(tooltipRef);

const computedPosition = computed(() => {
  if (props.position !== 'auto') return props.position;

  if (triggerTop.value > tooltipHeight.value + props.offset) {
    return 'top';
  } else if (
    window.innerHeight - triggerBottom.value >
    tooltipHeight.value + props.offset
  ) {
    return 'bottom';
  } else if (triggerLeft.value > tooltipWidth.value + props.offset) {
    return 'left';
  } else {
    return 'right';
  }
});

const adjustedPosition = computed(() => {
  let position = props.position;

  if (position === 'auto') {
    position = computedPosition.value;
  }

  // Check if the tooltip overflows the window and adjust if necessary
  if (
    position === 'top' &&
    triggerY.value < tooltipHeight.value + props.offset
  ) {
    position = findBestPosition(['bottom', 'left', 'right']);
  } else if (
    position === 'bottom' &&
    triggerBottom.value + tooltipHeight.value + props.offset >
      window.innerHeight
  ) {
    position = findBestPosition(['top', 'left', 'right']);
  } else if (
    position === 'left' &&
    triggerLeft.value < tooltipWidth.value + props.offset
  ) {
    position = findBestPosition(['right', 'top', 'bottom']);
  } else if (
    position === 'right' &&
    triggerRight.value + tooltipWidth.value + props.offset > window.innerWidth
  ) {
    position = findBestPosition(['left', 'top', 'bottom']);
  }

  return position;
});

const findBestPosition = (
  positions: ('top' | 'bottom' | 'left' | 'right')[]
) => {
  for (const pos of positions) {
    switch (pos) {
      case 'top':
        if (triggerTop.value >= tooltipHeight.value + props.offset) {
          return 'top';
        }
        break;
      case 'bottom':
        if (
          window.innerHeight - triggerBottom.value >=
          tooltipHeight.value + props.offset
        ) {
          return 'bottom';
        }
        break;
      case 'left':
        if (triggerLeft.value >= tooltipWidth.value + props.offset) {
          return 'left';
        }
        break;
      case 'right':
        if (
          window.innerWidth - triggerRight.value >=
          tooltipWidth.value + props.offset
        ) {
          return 'right';
        }
        break;
    }
  }

  return positions[0];
};

const tooltipStyle = computed(() => {
  if (!triggerRef.value || !tooltipRef.value) return {};

  let x = 0;
  let y = 0;

  switch (adjustedPosition.value) {
    case 'top':
      x = triggerLeft.value + triggerWidth.value / 2 - tooltipWidth.value / 2;
      y = triggerTop.value - tooltipHeight.value - props.offset;
      break;
    case 'bottom':
      x = triggerLeft.value + triggerWidth.value / 2 - tooltipWidth.value / 2;
      y = triggerBottom.value + props.offset;
      break;
    case 'left':
      x = triggerLeft.value - tooltipWidth.value - props.offset;
      y = triggerTop.value + triggerHeight.value / 2 - tooltipHeight.value / 2;
      break;
    case 'right':
      x = triggerRight.value + props.offset;
      y = triggerTop.value + triggerHeight.value / 2 - tooltipHeight.value / 2;
      break;
  }

  // Adjust x and y if tooltip overflows window
  x = Math.max(
    props.offset,
    Math.min(x, window.innerWidth - tooltipWidth.value - props.offset)
  );
  y = Math.max(
    props.offset,
    Math.min(y, window.innerHeight - tooltipHeight.value - props.offset)
  );

  return {
    left: `${x}px`,
    top: `${y}px`
  };
});

const showTooltip = () => {
  if (!props.disabled && slots['tooltip-text']) {
    isVisible.value = true;
    // Add small delay to allow tooltip to render before calculating position
    setTimeout(() => {
      // Force update of element bounds
      updateTriggerBounds();
      updateTooltipBounds();
    }, 0);
  }
};

// Add watcher for position updates
watch(
  [
    triggerLeft,
    triggerTop,
    triggerRight,
    triggerBottom,
    triggerX,
    triggerY,
    triggerWidth,
    triggerHeight
  ],
  () => {
    if (isVisible.value) {
      isVisible.value = false;
      nextTick(() => {
        isVisible.value = true;
      });
    }
  }
);

const startHideTimer = () => {
  hideTimer.value = window.setTimeout(() => {
    hideTooltip();
  }, 100);
};

const cancelHideTimer = () => {
  if (hideTimer.value !== null) {
    clearTimeout(hideTimer.value);
    hideTimer.value = null;
  }
};

const hideTooltip = () => {
  isVisible.value = false;
};

const handleScroll = () => {
  if (isVisible.value) {
    hideTooltip();
  }
};

const maxWidthCss = computed(() => {
  return `${props.maxWidth}px`;
});

onMounted(() => {
  window.addEventListener('scroll', handleScroll, true);
  updateTriggerBounds();
  updateTooltipBounds();
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll, true);
  if (hideTimer.value !== null) {
    clearTimeout(hideTimer.value);
  }
});
</script>

<style lang="scss" scoped>
.pf-tooltip {
  display: inline-block;
  width: fit-content;
  max-width: 100%;
}

.tooltip {
  position: fixed;
  z-index: 1000;

  width: fit-content;
  padding: 8px 12px;

  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  .tooltip-text {
    text-align: center;
  }

  .tooltip-arrow {
    position: absolute;

    &.tooltip-arrow--top {
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
    }

    &.tooltip-arrow--bottom {
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
    }

    &.tooltip-arrow--left {
      top: 50%;
      right: -8px;
      transform: translateY(-50%);
    }

    &.tooltip-arrow--right {
      top: 50%;
      left: -8px;
      transform: translateY(-50%);
    }
  }
}

.tooltip-content {
  width: fit-content;
  max-width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
