<template>
  <div class="ds-table-name-cell">
    <span class="name">{{ modelValue?.name || modelValue }}</span>
    <span class="description">{{ modelValue?.description }}</span>
  </div>
</template>

<script setup lang="ts">
import { useClientStore } from '@/stores/data-models/client';

const clientStore = useClientStore();

const props = defineProps<{
  modelValue: any;
}>();
</script>

<style scoped lang="scss">
.ds-table-name-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .name {
    @include typo-body-bold;

    color: $gray1000;
  }

  .description {
    @include typo-small-body;

    color: $gray700;
  }
}
</style>
