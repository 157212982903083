<template>
  <DsSelect
    v-model="model"
    :options="currencyOptions"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';

import DsSelect from '@/components/DesignSystem/DsSelect.vue';
import CURRENCIES from '@/helpers/currencies.json';

const props = defineProps<{
  modelValue: string;
  currencies?: Array<string>;
}>();

const emit = defineEmits<{
  'update:modelValue': [currencyCode: string];
}>();

const model = computed({
  get() {
    return props.modelValue;
  },
  set(newVal) {
    emit('update:modelValue', newVal);
  }
});

const currencyOptions = computed(() => {
  const list = props.currencies?.length
    ? CURRENCIES.filter((cur) => props.currencies.includes(cur.cc))
    : CURRENCIES;

  return list.map((cur) => ({
    value: `${cur.cc}/2`,
    wording: `${cur.symbol} ${cur.cc}`
  }));
});
</script>
