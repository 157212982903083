<template>
  <div class="tags-select">
    <PfLabel :model-value="$t('common.tags')" />
    <button
      class="flex-row justify-between"
      :disabled="isLoading"
      @click="tagsDropdownShown = true"
    >
      <DsSkeleton
        v-if="isLoading"
        style="width: 90px"
      />
      <DsAnalyticalAxesList
        v-if="modelValue.length"
        :model-value="modelValue"
        :editable="false"
        :number-of-lines="1"
      />
      <span v-else>
        {{ $t('components.DsAnalyticalAxesSelect.placeholder') }}
      </span>
      <DsIcon
        :name="tagsDropdownShown ? 'chevron-up' : 'chevron-down'"
        color="gray600"
        size="small"
      />
    </button>

    <DsAnalyticalAxesDropdown
      v-if="tagsDropdownShown"
      v-on-click-outside="() => (tagsDropdownShown = false)"
      :model-value="modelValue"
      class="tags-dropdown"
      @update:model-value="$emit('update:modelValue', $event)"
      @hide="() => (tagsDropdownShown = false)"
    />
  </div>
</template>

<script lang="ts" setup>
import { vOnClickOutside } from '@vueuse/components';
import { computed, onBeforeMount, PropType, ref } from 'vue';

import { AnalyticalAxisAssoc } from '@/api/schemas/common';
import DsIcon from '@/components/DesignSystem/DsIcon.vue';
import PfLabel from '@/components/NewDesignSystem/PfLabel/PfLabel.vue';
import { useAnalyticalAxesStore } from '@/stores/data-models/analytical-axes';

import DsAnalyticalAxesDropdown from './DsAnalyticalAxesDropdown.vue';
import DsAnalyticalAxesList from './DsAnalyticalAxesList.vue';
import DsSkeleton from './DsSkeleton.vue';

const props = defineProps({
  modelValue: {
    type: Array as PropType<AnalyticalAxisAssoc[]>,
    default: () => []
  },
  upwards: { type: Boolean, default: false }
});

const emit = defineEmits<{
  'update:modelValue': [ids: AnalyticalAxisAssoc[]];
}>();

const analyticalAxesStore = useAnalyticalAxesStore();

const tagsDropdownShown = ref(false);

const isLoading = computed(
  () =>
    analyticalAxesStore.isPending || analyticalAxesStore.isGetFlatNodesPending
);

onBeforeMount(() => {
  const promises = [];

  if (!analyticalAxesStore.hasGetAnalyticalAxesBeenCalled) {
    promises.push(analyticalAxesStore.callGetAnalyticalAxis());
  }

  if (!analyticalAxesStore.hasGetFlatNodeBeenCalled) {
    promises.push(analyticalAxesStore.callGetFlatNodes());
  }

  Promise.all(promises);
});
</script>

<style lang="scss" scoped>
.tags-select {
  position: relative;

  > button {
    @include typo-body;

    cursor: pointer;
    user-select: none;

    width: 100%;
    height: 36px;
    padding: 8px 12px;

    color: $gray400;

    background-color: white;
    border: 1px solid $gray100;
    border-radius: 12px;

    &:disabled {
      cursor: not-allowed;
    }

    &:hover {
      border: 1px solid $gray200;
    }
  }

  .tags-dropdown {
    position: absolute;
    z-index: 10;
    bottom: v-bind('props.upwards ? "36px" : "unset"');
    width: 100%;
  }
}
</style>
