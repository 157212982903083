<template>
  <button
    type="button"
    :class="{
      'ds-switch-button': true,
      'ds-switch-button--is-enabled': isEnabled
    }"
    :disabled="isPending"
  >
    <DsLoader
      v-if="isPending"
      size="20"
    />
    <template v-else>
      <div class="switch-box" />
      <div class="switch-disc" />
    </template>
  </button>
</template>

<script>
import DsLoader from './DsLoader.vue';

export default {
  name: 'DsSwitchButton',
  components: {
    DsLoader
  },
  props: {
    isEnabled: {
      type: Boolean,
      required: true
    },
    isPending: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.ds-switch-button {
  cursor: pointer;

  position: relative;

  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 20px;

  border-radius: 10px;

  &:disabled {
    cursor: default;
  }
}

.switch-box {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: $blue100;
  border-radius: 10px;

  .ds-switch-button--is-enabled & {
    background-color: $blue500;
  }
}

.switch-disc {
  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  background-color: white;
  border: 2px solid $blue150;
  border-radius: 50%;

  .ds-switch-button--is-enabled & {
    left: 16px;
    border-color: $blue500;
  }
}
</style>
