import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';

export const useSidebarStore = defineStore('sidebar', () => {
  const size = reactive({
    primary: {
      small: 60,
      large: 256
    },
    secondary: {
      small: 60,
      large: 248
    }
  });

  const full = ref(true);

  const currentRoutePath = ref('');

  // Using computed properties for getters
  const secondarySidebarOpen = computed(() => {
    return currentRoutePath.value?.includes('/settings') || false;
  });

  const sizeOfBothSidebarsInPx = computed(() => {
    if (!full.value && secondarySidebarOpen.value) {
      return size.primary.small + size.secondary.large;
    }
    if (!full.value && !secondarySidebarOpen.value) {
      return size.primary.small;
    }
    if (full.value && !secondarySidebarOpen.value) {
      return size.primary.large;
    }
    if (full.value && secondarySidebarOpen.value) {
      return size.primary.large + size.secondary.large;
    }

    return 0;
  });

  const isMobileSidebarOpen = ref(false);

  // Returning the state and computed properties
  return {
    secondarySidebarOpen,
    sizeOfBothSidebarsInPx,
    isMobileSidebarOpen,
    full,
    currentRoutePath,
    size
  };
});

export default useSidebarStore;
