<template>
  <button
    v-if="button"
    type="button"
    :disabled="disabled"
    :class="{
      'ds-card': true,
      'ds-card--fluid': fluid
    }"
  >
    <slot />
  </button>
  <div
    v-else
    :class="{
      'ds-card': true,
      'ds-card--fluid': fluid
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DsCard',
  props: {
    fluid: {
      type: Boolean,
      default: false
    },
    button: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.ds-card {
  width: fit-content;
  padding: 16px;

  text-align: left;

  background-color: white;
  border-radius: 12px;

  &:disabled {
    cursor: not-allowed;
  }

  &.ds-card--fluid {
    width: 100%;
    padding: 11px 20px;
    border-radius: 8px;
    box-shadow: 0 1px 0 #ebebeb;
  }

  .ds-card {
    width: fit-content;
    padding: 11px 20px;

    background-color: white;
    border: 1px solid $blue150;
    border-radius: 12px;
  }
}
</style>
