<template>
  <div
    class="overlay"
    :class="{ scrollable }"
  >
    <div
      ref="dsPopinRef"
      class="ds-popin"
    >
      <div
        v-if="slots.header || title || description"
        class="header"
      >
        <template v-if="slots.header">
          <slot name="header" />
          <DsButton
            v-if="showCloseButton"
            icon="cross"
            type="button"
            custom-icon-color="gray400"
            class="icon-cross-btn"
            @click="emit('close')"
          />
        </template>
        <template v-else-if="title || description">
          <div class="title">
            {{ title }}
          </div>
          <div class="description">
            {{ description }}
          </div>
          <DsButton
            v-if="showCloseButton"
            icon="cross"
            type="button"
            custom-icon-color="gray400"
            class="icon-cross-btn"
            @click="emit('close')"
          />
        </template>
      </div>
      <div
        ref="body"
        class="body"
        :class="{
          scrollable: scrollable,
          'allow-overflow': allowOverflow
        }"
      >
        <slot />
      </div>
      <div class="footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// COMPONENT DEPRECATED
import { onClickOutside } from '@vueuse/core';
import { onBeforeUnmount, onMounted, ref, useSlots } from 'vue';

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;
    showCloseButton?: boolean;
    scrollable?: boolean;
    allowOverflow?: boolean;
  }>(),
  {
    title: null,
    description: null,
    showCloseButton: false,
    scrollable: true,
    allowOverflow: false
  }
);

const dsPopinRef = ref<HTMLElement>(null);

const emit = defineEmits(['close']);

if (!props.showCloseButton) {
  onClickOutside(dsPopinRef, () => {
    emit('close');
  });
}

const slots = useSlots();

const body = ref<HTMLElement>(null);
const shadowTopOpacity = ref(0);
const shadowBottomOpacity = ref(0);

const scrollListener = (ev: Event) => {
  if (body.value.scrollHeight > body.value.offsetHeight) {
    shadowTopOpacity.value = Math.min(1.0, body.value.scrollTop / 32.0);
    shadowBottomOpacity.value = Math.min(
      1.0,
      (body.value.scrollHeight -
        (body.value.scrollTop + body.value.offsetHeight)) /
        32.0
    );
  } else {
    shadowTopOpacity.value = 0;
    shadowBottomOpacity.value = 0;
  }
};

onMounted(() => {
  body.value.addEventListener('scroll', scrollListener);
});

onBeforeUnmount(() => {
  body.value?.removeEventListener('scroll', scrollListener);
});
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(20, 23, 37, 0.3);
}
.ds-popin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background: white;
  border-radius: 24px;
  box-shadow: 0px 16px 24px rgba(20, 23, 37, 0.16);

  .header {
    z-index: 1;

    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;

    width: 100%;
    padding: 16px 24px 12px 24px;
    // box-shadow: 0px 2px 8px rgba(20, 23, 37, calc(0.08 * v-bind(shadowTopOpacity)));
    box-shadow: inset 0px -1px 0 0 rgba(20, 23, 37, calc(0.08 *
            v-bind(shadowTopOpacity)));

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: $gray1000;
      letter-spacing: -0.2px;
    }

    .description {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $gray700;
      letter-spacing: -0.14px;
    }
    .icon-cross-btn {
      position: absolute;
      right: 24px;
      height: fit-content;
      padding: 4px 0;
    }
  }

  .body {
    isolation: isolate;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    align-items: flex-start;

    width: 100%;
    padding: 12px 12px 12px 12px;
    &.scrollable {
      overflow: auto;
    }
    &:not(.scrollable) {
      z-index: 1;
    }
    &.allow-overflow {
      z-index: 1;
      overflow: hidden;
    }
  }

  .footer {
    position: relative;
    z-index: 0;

    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
    padding: 12px 24px 24px 24px;
    // box-shadow: 0px -2px 8px rgba(20, 23, 37, calc(0.08 * v-bind(shadowBottomOpacity)));
    box-shadow: inset 0px 1px 0 0
      rgba(20, 23, 37, calc(0.08 * v-bind(shadowBottomOpacity)));
  }

  .body:empty {
    display: none;
  }
  .footer:empty {
    display: none;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 470px;
  }
}

:empty {
  display: none;
}

.overlay.scrollable {
  .ds-popin {
    max-height: calc(100vh - 64px);

    @media screen and (max-width: 767px) {
      transform: translateY(calc(50vh - 50%));
    }

    @media screen and (min-width: 768px) {
      transform: translate(calc(50vw - 50%), calc(50vh - 50%));
    }
  }
}

.overlay:not(.scrollable) {
  overflow: scroll;
  display: flex;
  padding: 32px 8px;
  .ds-popin {
    margin: auto 0;

    @media screen and (min-width: 768px) {
      transform: translateX(calc(50vw - 50%));
    }
  }
}
</style>
