import colors from './colors.json';

export type IconSvg = (color: string) => string;

export const COLORS_PALETTE: Record<string, string> = colors;

export const resolveColor = (color?: string) => {
  if (!color) {
    return undefined;
  }

  if (color.startsWith('#') || color.startsWith('rgb') || color === 'white') {
    return color;
  }
  return COLORS_PALETTE[color];
};
