<template>
  <label
    :class="{ radio: true, 'radio--disabled': disabled, 'radio--large': large }"
  >
    <input
      :id="id"
      v-model="inputValue"
      :autofocus="autofocus"
      type="radio"
      :name="name"
      :disabled="disabled"
      :value="value"
      :required="required"
      :checked="checked"
      :class="{ 'radio__input--large': large }"
    />
    <slot></slot>
  </label>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  autofocus?: boolean;
  modelValue?: string;
  value?: string;
  name?: string;
  id?: string;
  disabled?: boolean;
  required?: boolean;
  checked?: boolean;
  large?: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

const inputValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});
</script>

<style lang="scss" scoped>
.radio {
  @include typo-small-body;

  cursor: pointer;
  display: inline-flex;
  gap: 8px;
  align-items: center;

  input[type='radio'] {
    cursor: pointer;

    width: 12px;
    min-width: 12px;
    height: 12px;

    background-color: white;
    border: 1px solid $gray200;
    border-radius: 50%;

    &:checked {
      border: 4px solid $blue500;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $gray50;

      &:checked {
        background-color: $gray400;
        border: 4px solid $gray150;
      }
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      border-color: $gray300;

      &:checked {
        border-color: $blue400;
      }
    }

    &.radio__input--large {
      width: 16px;
      height: 16px;

      &:checked {
        border-width: 6px;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.radio--large {
    @include typo-body;
  }
}
</style>
