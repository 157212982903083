<template>
  <div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
div {
  gap: 24px;
  padding: 12px 24px 12px 24px;
}
</style>
