<template>
  <div>
    <p class="password-compliance-label">
      {{ $t('designSystem.passwordCompliance.label') }}
    </p>
    <div class="conditions-container">
      <p class="condition">
        <DsIcon
          name="success"
          size="small"
          :color="minLengthIconColor"
        />
        <span>
          {{ MIN_LENGTH }}
          {{ $t('designSystem.passwordCompliance.characters') }}
        </span>
      </p>
      <p class="condition">
        <DsIcon
          name="success"
          size="small"
          :color="oneLowercaseIconColor"
        />
        <span>
          {{ $t('designSystem.passwordCompliance.oneLowercase') }}
        </span>
      </p>
      <p class="condition">
        <DsIcon
          name="success"
          size="small"
          :color="oneUppercaseIconColor"
        />
        <span>
          {{ $t('designSystem.passwordCompliance.oneUppercase') }}
        </span>
      </p>
      <p class="condition">
        <DsIcon
          name="success"
          size="small"
          :color="oneSpecialIconColor"
        />
        <span>
          {{ $t('designSystem.passwordCompliance.oneSpecial') }}
        </span>
      </p>
      <p class="condition">
        <DsIcon
          name="success"
          size="small"
          :color="oneDigitIconColor"
        />
        <span>
          {{ $t('designSystem.passwordCompliance.oneDigit') }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { COLORS_PALETTE } from '../../helpers/design-system';
import DsIcon from './DsIcon.vue';

export default {
  name: 'DsPasswordCompliance',
  components: {
    DsIcon
  },
  props: {
    password: {
      type: String,
      required: true
    }
  },
  emits: ['valid', 'invalid'],
  data() {
    return {
      MIN_LENGTH: 8
    };
  },
  computed: {
    passwordCharacters() {
      return this.password.split('');
    },
    hasEightCharacters() {
      return this.passwordCharacters.length >= this.MIN_LENGTH;
    },
    hasOneLowercase() {
      const lowercases = [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z'
      ];

      return this.passwordCharacters.some((c) => lowercases.includes(c));
    },
    hasOneUppercase() {
      const uppercases = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z'
      ];

      return this.passwordCharacters.some((c) => uppercases.includes(c));
    },
    hasOneSpecial() {
      const specials = [
        '`',
        '~',
        '!',
        '@',
        '#',
        '$',
        '%',
        '^',
        '&',
        '*',
        '(',
        ')',
        '-',
        '_',
        '=',
        '+',
        '[',
        '{',
        ']',
        '}',
        '\\',
        '|',
        ';',
        ':',
        "'",
        '"',
        ',',
        '<',
        '.',
        '>',
        '/',
        '?'
      ];

      return this.passwordCharacters.some((c) => specials.includes(c));
    },
    hasOneDigit() {
      const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

      return this.passwordCharacters.some((c) => digits.includes(c));
    },
    minLengthIconColor() {
      return this.getIconColor(this.hasEightCharacters);
    },
    oneLowercaseIconColor() {
      return this.getIconColor(this.hasOneLowercase);
    },
    oneUppercaseIconColor() {
      return this.getIconColor(this.hasOneUppercase);
    },
    oneSpecialIconColor() {
      return this.getIconColor(this.hasOneSpecial);
    },
    oneDigitIconColor() {
      return this.getIconColor(this.hasOneDigit);
    },
    isPasswordValid() {
      return (
        this.hasEightCharacters &&
        this.hasOneLowercase &&
        this.hasOneUppercase &&
        this.hasOneSpecial &&
        this.hasOneDigit
      );
    }
  },
  watch: {
    isPasswordValid(value) {
      if (value === true) {
        this.$emit('valid');
      } else {
        this.$emit('invalid');
      }
    }
  },
  methods: {
    getIconColor(condition) {
      return condition ? COLORS_PALETTE.green500 : COLORS_PALETTE.dark700;
    }
  }
};
</script>

<style lang="scss" scoped>
.password-compliance-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $gray500;
  letter-spacing: -0.12px;
}

.conditions-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.condition {
  display: inline-flex;
  align-items: center;

  width: 150px;
  margin-top: 4px;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $gray500;
  letter-spacing: -0.12px;
  white-space: nowrap;

  > svg {
    margin-right: 8px;
  }
}
</style>
