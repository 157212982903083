const MILLION = 1_000_000;
const BILLION = 1_000_000_000;
const TRILLION = 1_000_000_000_000;

export const formatLargeNumber = (
  value: number,
  locale = 'en-us',
  currency?: string
): string => {
  const abs = Math.abs(value);
  let res = '';

  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: true,
    style: currency ? 'currency' : undefined,
    currency: currency?.replace('/2', '')
  });

  if (abs < MILLION) {
    res = formatter.format(abs);
  } else {
    const largeFormatter = new Intl.NumberFormat(locale, {
      ...formatter,
      ...{
        maximumFractionDigits: 3
      }
    });

    if (abs < BILLION) {
      res = `${largeFormatter.format(abs / MILLION)} M`;
    } else if (abs < TRILLION) {
      res = `${largeFormatter.format(abs / BILLION)} B`;
    } else {
      res = `${largeFormatter.format(abs / TRILLION)} T`;
    }
  }

  return value < 0 ? `(${res})` : res;
};

export const formatLargeNumberValue = (
  value: number,
  locale = 'en-us',
  currency?: string
): number => {
  const isNegative = value < 0;
  const abs = Math.abs(value);
  let res = '';

  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: true,
    style: currency ? 'currency' : undefined,
    currency: currency?.replace('/2', '')
  });

  if (abs < MILLION) {
    res = formatter.format(abs);
  } else {
    const largeFormatter = new Intl.NumberFormat(locale, {
      ...formatter,
      ...{
        maximumFractionDigits: 3
      }
    });

    if (abs < BILLION) {
      res = `${largeFormatter.format(abs / MILLION)}`;
    } else if (abs < TRILLION) {
      res = `${largeFormatter.format(abs / BILLION)}`;
    } else {
      res = `${largeFormatter.format(abs / TRILLION)}`;
    }
  }

  let num = Number(res.replaceAll(',', '.'));

  if (isNegative) {
    num *= -1;
  }
  return num;
};
