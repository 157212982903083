<template>
  <div
    :class="{
      'ds-select': true,
      'ds-select--is-disabled': disabled,
      'ds-select--open': isDropdownShown
    }"
  >
    <PfLabel
      :model-value="label"
      :required="required"
      :disabled="disabled"
      @click="onClickOnLabel"
    />

    <div
      ref="selectContainer"
      class="select-container"
    >
      <template v-if="!noSelector">
        <button
          ref="selectButton"
          :class="{
            'select-button': true,
            'select-button--is-placeholder': modelValue === null
          }"
          type="button"
          :disabled="disabled || options.length === 0"
          @click.stop="onClickOnButton"
          @keydown="onKeydownOnButton"
          @keyup.prevent
        >
          <div class="select-button-left">
            <slot />
            <span
              class="select-button-text"
              :class="{
                'wording-chip': !!buttonText.color,
                'model-value-is-false': modelValue === false
              }"
              :style="
                !!buttonText.color ? 'background-color:' + buttonText.color : ''
              "
            >
              {{ buttonText.wording }}
            </span>
          </div>

          <DsIcon
            v-if="!hideChevron"
            :name="isDropdownShown ? 'chevron-up' : 'chevron-down'"
            color="gray500"
            size="small"
          />
        </button>
        <button
          v-if="clearable && modelValue !== null"
          class="select-clear-button"
          type="button"
        >
          <DsIcon
            name="cross"
            color="gray400"
            @click="
              $emit('update:modelValue', null);
              $emit('pickedOption', { value: null });
            "
          />
        </button>
      </template>
      <div
        v-show="isDropdownShown || noSelector"
        ref="selectDropdown"
        class="select-dropdown"
        :class="{ 'fixed-width': fixedWidth }"
      >
        <div
          v-for="(option, index) in options"
          :key="option"
          :class="{
            'select-option': true,
            'select-option--is-selected': option.value === modelValue,
            'select-option--is-focused': index === focusedOptionIndex,
            'select-option--is-disabled': option.disabled
          }"
          @click.stop="
            option.disabled ? null : onClickOnOption(option.value, option)
          "
        >
          <DsIcon
            v-if="option.icon"
            class="select-option-icon"
            :name="option.icon"
            color="gray700"
          />
          <p
            class="select-option-wording"
            :style="[
              option.indent ? 'padding-left: 21px;' : '',
              option.color ? 'background-color:' + option.color : '',
              option.depth ? 'padding-left: ' + option.depth * 16 + 'px;' : ''
            ]"
            :class="{ 'wording-chip': !!option.color }"
          >
            {{ option.wording }}
            <span
              v-if="option.disabled"
              style="display: block"
              :title="option.disabledReason"
            >
              {{ option.disabledReason }}
            </span>
          </p>
        </div>
        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  useSlots,
  watch
} from 'vue';
import { useI18n } from 'vue-i18n';

import { COLORS_PALETTE } from '../../helpers/design-system';
import PfLabel from '../NewDesignSystem/PfLabel/PfLabel.vue';
import DsIcon from './DsIcon.vue';

const props = defineProps({
  modelValue: { type: [String, Number, Boolean], default: null },
  options: {
    type: Array<any>,
    required: true,
    validator(options: Array<any>) {
      return options.every((option) => {
        return (
          Object.prototype.hasOwnProperty.call(option, 'value') &&
          Object.prototype.hasOwnProperty.call(option, 'wording')
        );
      });
    }
  },
  label: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  hideChevron: { type: Boolean, default: false },
  fixedWidth: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  autofocus: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  noSelector: { type: Boolean, default: false }
});

const emit = defineEmits(['update:modelValue', 'pickedOption']);

const i18n = useI18n();
const isDropdownShown = ref(false);
const focusedOptionIndex = ref(null);
const slots = useSlots();

const selectedOption = computed(() => {
  if (props.modelValue === null) {
    return null;
  }
  return props.options.find((option) => option.value === props.modelValue);
});

const buttonText = computed(() => {
  if (selectedOption.value) {
    return selectedOption.value;
  }
  return {
    wording: props.placeholder || i18n.t('components.dsSelect.select')
  };
});

const chevronIconColor = computed(() => {
  return isDropdownShown.value
    ? COLORS_PALETTE.blue500
    : COLORS_PALETTE.dark700;
});

watch(
  () => props.modelValue,
  (value) => {
    if (value !== null && selectedOption.value === undefined) {
      console.error(
        Error(
          `Design System: "${value}" is not an existing option value for DsSelect component.`
        )
      );
      emit('update:modelValue', null);
    }
  },
  { immediate: true }
);

/**
 * Focus select button
 */

const selectButton = ref(null);

function focusSelectButton() {
  selectButton.value?.focus();
}

onMounted(() => {
  if (props.autofocus) {
    nextTick(focusSelectButton);
  }
});

/**
 * Helpers
 */

const selectDropdown = ref(null);

function updateDropdownScroll() {
  nextTick(() => {
    const selectDropdownElement = selectDropdown.value;

    if (focusedOptionIndex.value > 2) {
      const optionHeight = selectDropdownElement.firstElementChild.offsetHeight;
      selectDropdownElement.scrollTop =
        (focusedOptionIndex.value - 2) * optionHeight;
    } else {
      selectDropdownElement.scrollTop = 0;
    }
  });
}

function getSelectedOptionIndex() {
  return props.options.findIndex((option) => option.value === props.modelValue);
}

/**
 * Click events
 */

function onClickOnLabel() {
  focusSelectButton();
  isDropdownShown.value = true;
}

function onClickOnButton() {
  if (isDropdownShown.value === false) {
    isDropdownShown.value = true;
    if (props.modelValue === null) {
      focusedOptionIndex.value = null;
    } else {
      focusedOptionIndex.value = getSelectedOptionIndex();
    }
    updateDropdownScroll();
  } else {
    isDropdownShown.value = false;
  }
  focusSelectButton(); // Needed for Firefox and Safari
}

function onClickOnOption(optionValue, option = null) {
  isDropdownShown.value = false;
  emit('update:modelValue', optionValue);
  emit('pickedOption', option);
  focusSelectButton();
}

/**
 * Mousedown event
 */

const selectContainer = ref(null);

function handleMousedown(event) {
  if (selectContainer.value.contains(event.target) === false) {
    isDropdownShown.value = false;
  }
}

watch(isDropdownShown, (value) => {
  if (value === true) {
    window.document.addEventListener('mousedown', handleMousedown);
  } else {
    window.document.removeEventListener('mousedown', handleMousedown);
  }
});

onUnmounted(() => {
  window.document.removeEventListener('mousedown', handleMousedown);
});

/**
 * Keyboard navigation
 */

function onKeydownOnButton(event) {
  function handleKeyArrowDown() {
    if (isDropdownShown.value === false) {
      isDropdownShown.value = true;
      if (props.modelValue === null) {
        focusedOptionIndex.value = 0;
      } else {
        focusedOptionIndex.value = getSelectedOptionIndex();
      }
    } else {
      if (focusedOptionIndex.value === null) {
        focusedOptionIndex.value = 0;
      } else if (focusedOptionIndex.value < props.options.length - 1) {
        focusedOptionIndex.value += 1;
        // while it's disabled we skip it
        while (props.options[focusedOptionIndex.value].disabled) {
          focusedOptionIndex.value += 1;
        }
      }
    }
    updateDropdownScroll();
  }

  function handleKeyArrowUp() {
    if (isDropdownShown.value === false) {
      isDropdownShown.value = true;
      if (props.modelValue === null) {
        focusedOptionIndex.value = 0;
      } else {
        focusedOptionIndex.value = getSelectedOptionIndex();
      }
    } else {
      if (focusedOptionIndex.value === null) {
        focusedOptionIndex.value = 0;
      } else if (focusedOptionIndex.value > 0) {
        focusedOptionIndex.value -= 1;
        while (props.options[focusedOptionIndex.value].disabled) {
          focusedOptionIndex.value -= 1;
        }
      }
    }
    updateDropdownScroll();
  }

  function handleKeyEnter() {
    if (isDropdownShown.value === true && focusedOptionIndex.value !== null) {
      isDropdownShown.value = false;
      emit('update:modelValue', props.options[focusedOptionIndex.value].value);
    }
  }

  function handleKeySpace() {
    if (isDropdownShown.value === false) {
      isDropdownShown.value = true;
      if (props.modelValue === null) {
        focusedOptionIndex.value = null;
      } else {
        focusedOptionIndex.value = getSelectedOptionIndex();
      }
      updateDropdownScroll();
    } else {
      isDropdownShown.value = false;
      if (focusedOptionIndex.value !== null) {
        emit(
          'update:modelValue',
          props.options[focusedOptionIndex.value].value
        );
      }
    }
  }

  switch (event.code) {
    case 'ArrowDown':
      event.preventDefault();
      handleKeyArrowDown();
      break;
    case 'ArrowUp':
      event.preventDefault();
      handleKeyArrowUp();
      break;
    case 'Enter':
      event.preventDefault();
      handleKeyEnter();
      break;
    case 'Space':
      event.preventDefault();
      handleKeySpace();
      break;
    case 'Escape':
    case 'Tab':
      isDropdownShown.value = false;
      break;
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/field-label.scss';

.ds-select {
  flex-grow: 1;

  &.ds-select--is-disabled {
    opacity: 1;

    .select-button--is-placeholder & {
      color: $gray300;
    }

    :deep(.select-button-text) {
      color: $gray500;
    }
  }
}

.select-container {
  position: relative;
}

.select-button {
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 36px;
  padding: 8px 12px;

  background-color: white;
  border: 1px solid $gray100;
  border-radius: 12px;

  .select-button-left {
    overflow: hidden;
    display: flex;
    gap: 8px;
    align-items: center;

    text-overflow: ellipsis;
  }

  &:hover {
    border-color: $gray200;
  }

  &:focus {
    border-color: $blue500;
    outline: 2px solid $blue150;
  }

  &:disabled {
    cursor: not-allowed;
    color: $gray500;
    background-color: $gray50;
  }
}

.select-button-text {
  @include typo-body;

  overflow: hidden;

  margin-right: 6px;

  color: $gray1000;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  .select-button--is-placeholder & {
    color: $gray400;
  }

  &.wording-chip {
    @include typo-small-body-bold;

    padding: 4px 8px 4px 8px;
    padding-bottom: 4px;
    color: white;
    border-radius: 6px;
  }
}

.select-dropdown {
  position: absolute;
  z-index: 3;
  top: v-bind('noSelector ? "0px" : "36px"');
  left: 0;

  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;

  width: 100%;
  min-width: max-content;
  max-height: 50vh;
  padding: 4px;

  background-color: white;
  border-radius: 12px;
  box-shadow: 0 3px 14px rgba(black, 0.2);
  &.fixed-width {
    right: 0;
  }
}

.select-option {
  @include typo-body;

  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;

  padding: 8px 12px;

  border-radius: 8px;

  &:hover,
  &.select-option--is-focused {
    background-color: $gray50;
  }

  &.select-option--is-disabled {
    cursor: not-allowed;
    color: $gray200;
    background-color: $gray25;

    :deep(.select-option-wording) {
      color: $gray200;
    }
  }
}

.select-option-icon {
  margin-right: 12px;

  .select-option--is-selected & :deep(path) {
    fill: $blue500;
  }
}

.select-option-wording {
  overflow: hidden;

  min-width: 30px;

  color: $gray1000;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  .select-option--is-selected & {
    color: $blue500;
  }

  &.wording-chip {
    @include typo-small-body-bold;

    padding: 4px 8px 4px 8px;
    padding-bottom: 4px;
    border-radius: 6px;
  }
}

.select-clear-button {
  position: absolute;
  top: 50%;
  right: 35px;
  transform: translate(0%, -50%);

  display: flex;
  align-items: center;

  height: 100%;
}

.model-value-is-false {
  color: $gray300;
}
</style>
