<template>
  <div
    :class="'ds-counter color-' + color"
    :style="{ minWidth: size + 'px', height: size + 'px' }"
  >
    {{ value || 0 }}
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    value?: number;
    color?: string;
  }>(),
  {
    value: null,
    color: 'blue'
  }
);

const size = 20;
</script>

<style scoped lang="scss">
.ds-counter {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 8px;
  color: $blue500;
  letter-spacing: 0.16px;
  &::before {
    content: '';

    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;

    border-radius: 50%;
    box-shadow: inset 0 0 0 2px $blue200;
  }
  &.color-blue {
    color: $blue500;
    &::before {
      box-shadow: inset 0 0 0 2px $blue200;
    }
  }
  &.color-gray {
    color: $gray700;
    &::before {
      box-shadow: inset 0 0 0 2px $gray200;
    }
  }
}
</style>
