import { API } from '@aws-amplify/api';

import {
  BankOrder,
  BankOrderOutput,
  BankOrderPriority,
  CreateOnboardingInput,
  CreateSingleBankOrderInput,
  DirectDebitOutput,
  GetBankOrderReportReasonListOutput,
  getBankOrdersPreviewInput,
  getBankOrdersPreviewOutput,
  GetBankOrderTagsOutput,
  GetCSVColumnsInput,
  GetCSVColumnsOutput,
  GetCSVColumnsWithPresetOutput,
  GetFundingSourcesOutput,
  GetFundingSourcesQuery,
  GetGroupByAuthenticationOutput,
  GetPaymentDetailsPresetOutput,
  GetPaymentPresetsOutput,
  GetPaymentPresetTreeOutput,
  GetTagsConfidentialOrdersOutput,
  InflowBulkBankOrderColumnsMappingV2,
  OnboardingOutput,
  OutflowBulkBankOrderColumnsMappingV2,
  PaymentAccountOutput,
  PaymentAccountProviderId,
  PaymentOrderOutput,
  PostBankOrderCSVInput,
  PostBankOrderFromXMLInput,
  PostBankOrderMultiExecutePassphraseBody,
  PostBankOrderPrefillCSVWithPresetBody,
  PostBankOrdersValidationInput,
  PostBankOrdersValidationOutput,
  PostBankOrderValidateCSVWithPresetBody,
  PostBankOrderValidateCSVWithPresetOutput,
  PostBankOrderWithPresetBody,
  PostBoSecrecyBody,
  PostMarkAnomaliesStatusBody,
  PostMultiExecuteBankOrderInput,
  PostMultipleBankOrderExecuteDownloadOutput,
  PostMultipleBankOrderExecuteMFAInput,
  PostPaymentBankOrderItemTagsBody,
  PostPaymentBankOrderTagsBody,
  PostPaymentPresetsBody,
  RetrieveBankOrdersQuery,
  RetrieveBankOrdersQueryOutput,
  RetrievePaymentAccountsOutput,
  RetrieveProviderAccountsOutput,
  RetrieveProviderAccountsQuery,
  SerializationMethod,
  ValidateOnboardingInput
} from '@/api/schemas/payments';
import { AccountStatusOnboarding } from '@/custom-types/account';
import { CountryCode } from '@/custom-types/counterparties';
import { getTenantId, getUserJwtToken } from '@/helpers/amplify';

import {
  AWSDocument,
  FilterPresetInput,
  FilterPresetOutput,
  RetrieveFilterPresetsOutput
} from './schemas/common';

export const getProviderAccounts = async (
  queryStringParameters?: RetrieveProviderAccountsQuery
): Promise<RetrieveProviderAccountsOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('payments', `/rest/v1/${tenantId}/provider-accounts`, {
    headers,
    queryStringParameters
  });
};

export const getPaymentAccounts = async (
  bankOrderId?: number
): Promise<RetrievePaymentAccountsOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = bankOrderId
    ? { bank_order_id: bankOrderId }
    : undefined;

  return API.get('payments', `/rest/v1/${tenantId}/payment-accounts`, {
    queryStringParameters,
    headers
  });
};

export const getPaymentAccount = async (
  accountId: number,
  bankOrderId?: number
): Promise<PaymentAccountOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = bankOrderId
    ? { bank_order_id: bankOrderId }
    : undefined;

  return API.get(
    'payments',
    `/rest/v1/${tenantId}/payment-accounts/${accountId}`,
    { queryStringParameters, headers }
  );
};

export interface RetrieveManyProviderUserAccountsQuery {
  user_id?: number;
  account_id?: number;
  page: number;
  page_size: number;
}

export const getSingleProviderUserAccount = async (
  providerAccountId: number
): Promise<RetrieveProviderAccountsOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'payments',
    `/rest/v1/${tenantId}/provider-user-accounts/${providerAccountId}`,
    { headers }
  );
};

export interface PostSwanResolveAuthInput {
  auth_data: {
    oauth_code: string;
    oauth_state: string;
  };
}

export const postSwanResolveAuth = async (
  body: PostSwanResolveAuthInput
): Promise<any> => {
  return API.post('payments', '/webhooks/resolve-auth-swan-user', { body });
};

export const deleteProviderUserAccount = async (
  providerAccountId: number
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.del(
    'payments',
    `/rest/v1/${tenantId}/provider-user-accounts/${providerAccountId}`,
    { headers }
  );
};

export const createOnboarding = async (
  providerId: PaymentAccountProviderId
): Promise<OnboardingOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body: CreateOnboardingInput = {
    provider_id: providerId
  };

  return API.post('payments', `/rest/v1/${tenantId}/onboardings`, {
    headers,
    body
  });
};

export const validateOnboarding = async (
  validateOnboardingInput: ValidateOnboardingInput
): Promise<OnboardingOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body = validateOnboardingInput;

  return API.post('payments', `/rest/v1/${tenantId}/onboardings/authenticate`, {
    headers,
    body
  });
};

export const getBankOrders = async (
  query: RetrieveBankOrdersQuery
): Promise<RetrieveBankOrdersQueryOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('payments', `/rest/v1/${tenantId}/bank-orders`, {
    queryStringParameters,
    headers
  });
};

// have to have this one also, because we retrieve the counters by sending a full request for each tab
// using getBankOrders instead of explicit duplication doesn't cancel the query O_o
let getBankOrdersPromise: Promise<RetrieveBankOrdersQueryOutput>;
export const getBankOrdersCancellable = async (
  query: RetrieveBankOrdersQuery
): Promise<RetrieveBankOrdersQueryOutput> => {
  if (getBankOrdersPromise) {
    API.cancel(getBankOrdersPromise);
  }

  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  getBankOrdersPromise = API.get(
    'payments',
    `/rest/v1/${tenantId}/bank-orders`,
    {
      queryStringParameters,
      headers
    }
  );

  return getBankOrdersPromise;
};

export const getBankOrder = async (bankOrderId: number): Promise<BankOrder> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/${bankOrderId}`,
    { headers }
  );
};

export const createSingleBankOrder = async (
  createSingleBankOrderInput: CreateSingleBankOrderInput
): Promise<BankOrderOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body = createSingleBankOrderInput;

  return API.post('payments', `/rest/v1/${tenantId}/bank-orders`, {
    headers,
    body
  });
};

export interface GetBankOrderItemsInput {
  bank_order_id: number;
  page_size?: number;
  page?: number;
}

export const getDirectDebits = async (
  query: GetBankOrderItemsInput
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('payments', `/rest/v1/${tenantId}/direct-debits`, {
    queryStringParameters,
    headers
  });
};

export const getDirectDebit = async (
  directDebitId: number
): Promise<DirectDebitOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'payments',
    `/rest/v1/${tenantId}/direct-debits/${directDebitId}`,
    { headers }
  );
};

export const getFundingSources = async (
  query: GetFundingSourcesQuery
): Promise<GetFundingSourcesOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('payments', `/rest/v1/${tenantId}/funding-sources`, {
    queryStringParameters,
    headers
  });
};

export const getFundingSource = async (
  fundingSourceId: number
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'payments',
    `/rest/v1/${tenantId}/funding-sources/${fundingSourceId}`,
    { headers }
  );
};

export const getPaymentOrder = async (
  paymentOrderId: number
): Promise<PaymentOrderOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'payments',
    `/rest/v1/${tenantId}/payment-orders/${paymentOrderId}`,
    { headers }
  );
};

export interface Amount {
  amount: number;
  currency: string;
}

export enum LimitType {
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
  DOMESTIC = 'DOMESTIC',
  INTERNATIONAL = 'INTERNATIONAL'
}

export interface PaymentOrder {
  account_id: number;
  amount: Amount;
  bank_order_id: number;
  booked_at: null | string;
  canceled_at: null | string;
  consent_url: null | string;
  counterparty_payment_id: number;
  id: number;
  initiated_at: null | string;
  label: string;
  pending_at: null | string;
  reference: string;
  rejected_at: null | string;
  requested_execution_date: string;
  status: string;
  transaction_id: null | string;
  upcoming_at: null | string;
}

export interface GetPaymentOrdersOutput {
  pagination: {
    has_more: boolean;
    next_page: number;
    previous_page: number;
  };
  payment_orders: PaymentOrder[];
}

export const getPaymentOrders = async (
  query: GetBankOrderItemsInput
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('payments', `/rest/v1/${tenantId}/payment-orders`, {
    queryStringParameters,
    headers
  });
};

export const getCSVColumns = async (
  query: GetCSVColumnsInput
): Promise<GetCSVColumnsOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('payments', `/rest/v1/${tenantId}/csv-columns`, {
    queryStringParameters,
    headers
  });
};

export const postBankOrdersValidation = async (
  body: PostBankOrdersValidationInput
): Promise<PostBankOrdersValidationOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('payments', `/rest/v1/${tenantId}/bank-orders-validation`, {
    headers,
    body
  });
};

export interface PostBankOrderPrefillXMLInput {
  aws_document: AWSDocument;
}

export enum BankOrderType {
  DOMESTIC = 'DOMESTIC',
  INTERNATIONAL = 'INTERNATIONAL',
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER'
}

export interface BankOrderItemPreview {
  counterparty_name: string;
  reference: string;
  account_identifier: string;
  bank_identifier?: string;
  amount: Amount;
  country_code: CountryCode;
  mandate_id?: string;
  mandate_signature_date?: Date;
}

export interface BankOrderPreview {
  total_amount: Amount;
  total_items: number;
  item_previews: BankOrderItemPreview[];
}

export interface BankOrderInvalidItemPreview {
  item_line: number;
  error_message: string;
}

export interface BankOrderInvalidPreview {
  total_items: number;
  item_previews: BankOrderInvalidItemPreview[];
}

export interface PostBankOrderPrefillXMLOutput {
  account_id?: number;
  date_to_be_sent_at?: string;
  reference?: string;
  priority?: BankOrderPriority;
  order_type?: BankOrderType;
  bo_preview: BankOrderPreview;
  bo_invalid_preview?: BankOrderInvalidPreview;
  is_batch?: boolean;
}

export const postBankOrderPrefillXML = async (
  body: PostBankOrderPrefillXMLInput
): Promise<PostBankOrderPrefillXMLOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/prefill-from-xml`,
    { headers, body }
  );
};

export interface PostBankOrderPrefillCSVInput {
  aws_document: AWSDocument;
  column_mapper:
    | OutflowBulkBankOrderColumnsMappingV2
    | InflowBulkBankOrderColumnsMappingV2;
  delimiter: string;
  currency: string;
}

export interface PostBankOrderPrefillCSVOutput {
  account_id?: number;
  date_to_be_sent_at?: Date;
  reference?: string;
  priority?: BankOrderPriority;
  order_type?: BankOrderType;
  bo_preview: BankOrderPreview;
  bo_invalid_preview?: BankOrderInvalidPreview;
}

export const postBankOrderPrefillCSV = async (
  body: PostBankOrderPrefillCSVInput
): Promise<PostBankOrderPrefillCSVOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/prefill-from-csv`,
    { headers, body }
  );
};

export const postBankOrdersFromXML = async (
  query: PostBankOrderFromXMLInput
): Promise<any[]> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body = JSON.parse(JSON.stringify(query));

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/create-from-xml`,
    { body, headers }
  );
};

export const postBankOrdersFromCSV = async (
  query: PostBankOrderCSVInput
): Promise<any[]> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body = JSON.parse(JSON.stringify(query));

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/create-from-csv`,
    { body, headers }
  );
};

export const getBankOrdersInvalidPreview = async (
  query: getBankOrdersPreviewInput
): Promise<getBankOrdersPreviewOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get(
    'payments',
    `/rest/v1/${tenantId}/bank-orders-invalid-preview`,
    { queryStringParameters, headers }
  );
};

export const getBankOrdersValidPreview = async (
  query: getBankOrdersPreviewInput
): Promise<getBankOrdersPreviewOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('payments', `/rest/v1/${tenantId}/bank-orders-valid-preview`, {
    queryStringParameters,
    headers
  });
};

export interface ExecuteBankOrderInput {
  consent_redirect_url?: string;
  base64_signature?: string;
  payment_passphrase?: string;
  mfa_code?: string;
  access_token?: string;
}

export const postBankOrderExecute = async (
  bankOrderId: number,
  body: ExecuteBankOrderInput
): Promise<BankOrderOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/${bankOrderId}/execute`,
    { body, headers }
  );
};

export const postMultipleBankOrderExecuteDownload = async (
  bankOrderIdList: number[]
): Promise<PostMultipleBankOrderExecuteDownloadOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body = {
    bank_order_ids: bankOrderIdList
  };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/multi-execute/download`,
    { body, headers }
  );
};

export const postMultipleBankOrderExecuteMFA = async (
  body: PostMultipleBankOrderExecuteMFAInput
): Promise<PostMultipleBankOrderExecuteDownloadOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/multi-execute/mfa`,
    { body, headers }
  );
};

export const postBankOrderMultiExecute = async (
  body: PostMultiExecuteBankOrderInput[]
): Promise<BankOrderOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/multi-execute/3skey`,
    { body, headers }
  );
};

export const postBankOrderMultiExecutePassphrase = async (
  body: PostBankOrderMultiExecutePassphraseBody
): Promise<BankOrderOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/multi-execute/passphrase`,
    { body, headers }
  );
};

export interface DownloadOrderInputV2 {
  serialization_method?: SerializationMethod;
}

export const postBankOrderDownloadTransactionFile = async (
  bankOrderId: number,
  body?: DownloadOrderInputV2
): Promise<BankOrderOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/${bankOrderId}/download-transaction-file-v2`,
    { body: {}, headers }
  );
};

export interface PrepareBankOrderInputV2 {
  serialization_method: SerializationMethod;
}

export const postBankOrderCancelMultiple = async (
  bankOrderIds: number[]
): Promise<BankOrderOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body = {
    bank_order_ids: bankOrderIds
  };

  return API.post('payments', `/rest/v1/${tenantId}/bank-orders/cancel`, {
    headers,
    body
  });
};

export interface PostBankOrderDeclineInput {
  bank_order_ids: number[];
}

export interface PostBankOrderApproveInput {
  bank_order_ids: number[];
}

export const postBankOrderApprove = async (
  body: PostBankOrderApproveInput
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('payments', `/rest/v1/${tenantId}/bank-orders/approve`, {
    headers,
    body
  });
};

export const postBankOrderDecline = async (
  body: PostBankOrderDeclineInput
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('payments', `/rest/v1/${tenantId}/bank-orders/decline`, {
    headers,
    body
  });
};

export interface PostBankOrdersExportOutput {
  bucket: string;
  key: string;
}

export const postBankOrdersExport = async (
  body: RetrieveBankOrdersQuery
): Promise<PostBankOrdersExportOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('payments', `/rest/v1/${tenantId}/bank-orders/export-async`, {
    body,
    headers
  });
};

export interface GetBankOrderTransactionIdsOutput {
  transaction_ids: number[];
}

export const getBankOrderTransactionIds = async (
  bankOrderId: number
): Promise<GetBankOrderTransactionIdsOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  if (!bankOrderId) {
    throw new Error('bankOrderId is required in getBankOrderTransactionIds');
  }

  return API.get(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/${bankOrderId}/transaction-ids`,
    { headers }
  );
};

export interface PostBankOrderRemoveFromAnomaliesInput {
  bank_order_ids: number[];
}

export const postBankOrdersRemoveFromAnomalies = async (
  body: PostBankOrderRemoveFromAnomaliesInput
): Promise<void> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/remove-from-anomalies`,
    { body, headers }
  );
};

export interface PostUserPaymentLimitOutput {
  id: number;
  user_id: number;
  amount?: Amount | null;
  limit_type: LimitType;
}

export interface PostUserPaymentLimitInput {
  user_id: number;
  amount?: Amount | null;
  limit_type: LimitType;
}

export interface GetUserPaymentLimitInput {
  user_id?: number | null;
}

export interface GetUserPaymentLimitOutput {
  user_payment_limits: PostUserPaymentLimitOutput[];
  has_3skey: boolean | null;
}

export const getUserPaymentLimits = async (
  query: GetUserPaymentLimitInput
): Promise<GetUserPaymentLimitOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('payments', `/rest/v1/${tenantId}/user-payment-limits`, {
    queryStringParameters,
    headers
  });
};

export const getUserPaymentLimit = async (
  userPaymentLimitId: number
): Promise<PostUserPaymentLimitOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  if (!userPaymentLimitId) {
    throw new Error('userPaymentLimitId is required in getUserPaymentLimit');
  }

  return API.get(
    'payments',
    `/rest/v1/${tenantId}/user-payment-limits/${userPaymentLimitId}`,
    { headers }
  );
};

export const postUserPaymentLimit = async (
  body: PostUserPaymentLimitInput
): Promise<PostUserPaymentLimitOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('payments', `/rest/v1/${tenantId}/user-payment-limits`, {
    body,
    headers
  });
};

export const deleteUserPaymentLimit = async (id: number): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.del('payments', `/rest/v1/${tenantId}/user-payment-limits/${id}`, {
    headers
  });
};

export interface onboardingAccount {
  added_by: number;
  id: number;
  onboarding_link: string;
  provider_id: number;
  status: AccountStatusOnboarding;
}

export interface GetOnboardingAccountsInput {
  status_not_in?: string[];
}

export interface GetOnboardingAccountsOutput {
  items: onboardingAccount[];
  pagination: {
    has_more: boolean;
    next_page: number;
    previous_page: number;
  };
}

export const getOnboardingAccounts = async (
  filters: GetOnboardingAccountsInput
): Promise<GetOnboardingAccountsOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(filters));

  return API.get('payments', `/rest/v1/${tenantId}/onboardings`, {
    queryStringParameters,
    headers
  });
};

export interface GetBankOrdersDuplicateInput {
  bank_order_id_in: number;
  solved?: boolean;
}

export interface BankOrderConflict {
  item_conflicts: BankOrder[];
  item_to_check: BankOrder;
}

export const getPaymentOrderDuplicate = async (
  query: GetBankOrdersDuplicateInput
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('payments', `/rest/v1/${tenantId}/payment-order-conflicts`, {
    queryStringParameters,
    headers
  });
};

export const getDirectDebitDuplicate = async (
  query: GetBankOrdersDuplicateInput
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get('payments', `/rest/v1/${tenantId}/direct-debit-conflicts`, {
    queryStringParameters,
    headers
  });
};

export interface PostPaymentOrderConflictsSolveInput {
  payment_order_id_in: number[];
}

export interface PostDirectDebitConflictsSolveInput {
  direct_debits_id_in: number[];
}

export const postPaymentOrderConflictsSolve = async (
  body: PostPaymentOrderConflictsSolveInput
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/payment-order-conflicts/solve`,
    { body, headers }
  );
};

export const postDirectDebitConflictsSolve = async (
  body: PostDirectDebitConflictsSolveInput
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/direct-debit-conflicts/solve`,
    { body, headers }
  );
};

export const postPaymentOrderConflictsDelete = async (
  id: number
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/payment-orders/${id}/delete`,
    { headers }
  );
};

export const postDirectDebitConflictsDelete = async (
  id: number
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/direct-debits/${id}/delete`,
    { headers }
  );
};

export interface EbicsTSUser {
  user_id: number;
  ebics_user_id: string;
  corp_id: string;
  status: string;
  letter_bucket?: string;
  letter_key?: string;
}

export interface GetEbicsTSUsersOutput {
  [accountNumber: number]: EbicsTSUser[];
}

export const getEbicsTSUsers = async (): Promise<GetEbicsTSUsersOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('payments', `/rest/v1/${tenantId}/ebics/users`, { headers });
};

export interface InitAuthenticationEbicsTSConnectionInput {
  connector_id: number;
  user_id: number;
  ebics_user_id: string;
  payment_passphrase: string;
}

export interface InitAuthenticationEbicsTSConnectionOutput {
  letter_bucket: string;
  letter_path: string;
}

export const InitAuthenticationEbicsTSConnection = async (
  body: InitAuthenticationEbicsTSConnectionInput
): Promise<InitAuthenticationEbicsTSConnectionOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/ebics/init-authentication`,
    { headers, body }
  );
};

export interface setSKeyInput {
  corp_id: string;
  user_id: number;
  certificate_bucket: string;
  certificate_path: string;
}

export const setSKey = async (body: setSKeyInput): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('payments', `/rest/v1/${tenantId}/ebics/3skey`, {
    headers,
    body
  });
};

export const removeUserLinkedAccount = async (
  connectorId: number,
  userId: number
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.del(
    'payments',
    `/rest/v1/${tenantId}/ebics/${connectorId}/unlink-user/${userId}`,
    { headers }
  );
};
export interface getBankOrderFromTransactionIdOutput {
  bank_order_id?: number;
}

export const getBankOrderFromTransactionId = async (
  transactionId: number
): Promise<getBankOrderFromTransactionIdOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/${transactionId}/bank-order-id`,
    { headers }
  );
};

export interface GetBankOrderApprovalSettingsOutput {
  is_required_for_domestic: boolean;
  is_required_for_treasury: boolean;
  is_required_for_international: boolean;
}

export const getBankOrderApprovalSettings =
  async (): Promise<GetBankOrderApprovalSettingsOutput> => {
    const tenantId = await getTenantId();
    const headers = { token: await getUserJwtToken() };

    return API.get(
      'payments',
      `/rest/v1/${tenantId}/bank-order-approval-settings`,
      { headers }
    );
  };

export interface PostBankOrderApprovalSettingsBody {
  is_required_for_domestic: boolean;
  is_required_for_treasury: boolean;
  is_required_for_international: boolean;
}

export const postBankOrderApprovalSettings = async (
  body: PostBankOrderApprovalSettingsBody
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-order-approval-settings`,
    { headers, body }
  );
};

export const postMarkAnomaliesStatus = async (
  id: number,
  body: PostMarkAnomaliesStatusBody
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/mark-anomalies-status/${id}`,
    { headers, body }
  );
};

export const getBankOrderReportReasonList = async (
  bankOrderReportId: number
): Promise<GetBankOrderReportReasonListOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'payments',
    `/rest/v1/${tenantId}/bank-order-report/${bankOrderReportId}`,
    { headers }
  );
};

export const postForceApproveFlaggedBO = async (
  bankOrderId: number
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/${bankOrderId}/force-approve`,
    { headers }
  );
};

export const getBankFilterPresets =
  async (): Promise<RetrieveFilterPresetsOutput> => {
    const tenantId = await getTenantId();
    const headers = { token: await getUserJwtToken() };
    return API.get(
      'payments',
      `/rest/v1/${tenantId}/bank-order-filter-presets`,
      {
        headers
      }
    );
  };

export const createBankFilterPreset = async (
  body: FilterPresetInput
): Promise<FilterPresetOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-order-filter-presets`,
    {
      headers,
      body
    }
  );
};

export const updateBankFilterPreset = async (
  presetId: number,
  body: FilterPresetInput
): Promise<FilterPresetOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  return API.put(
    'payments',
    `/rest/v1/${tenantId}/bank-order-filter-presets/${presetId}`,
    {
      headers,
      body
    }
  );
};

export const deleteBankFilterPreset = async (
  presetId: number
): Promise<void> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  return API.del(
    'payments',
    `/rest/v1/${tenantId}/bank-order-filter-presets/${presetId}`,
    {
      headers
    }
  );
};

export const getGroupByAuthentication = async (
  bankOrderIdIn: number[]
): Promise<GetGroupByAuthenticationOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = {
    bank_order_id_in: bankOrderIdIn
  };

  return API.get(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/group-by-authentication`,
    { headers, queryStringParameters }
  );
};

export const getTagsConfidentialOrders =
  async (): Promise<GetTagsConfidentialOrdersOutput> => {
    const tenantId = await getTenantId();
    const headers = { token: await getUserJwtToken() };

    return API.get('payments', `/rest/v1/${tenantId}/secret-keywords`, {
      headers
    });
  };

export const postTagsConfidentialOrders = async (tag: string): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const body = {
    secret_keyword: tag
  };

  return API.post('payments', `/rest/v1/${tenantId}/secret-keywords`, {
    headers,
    body
  });
};

export const deleteTagsConfidentialOrders = async (
  id: number
): Promise<void> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  return API.del('payments', `/rest/v1/${tenantId}/secret-keywords/${id}`, {
    headers
  });
};

export const postBoSecrecy = async (body: PostBoSecrecyBody): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('payments', `/rest/v1/${tenantId}/bank-orders/secret`, {
    headers,
    body
  });
};

interface GetPaymentsCardTransactionOutput {
  id: number;
  card_id: number;
  transaction_id?: number | null; // fawkes
  amount?: Amount | null;
  ext_transaction_id?: string | null;
  status: string;
  reject_reason?: string | null;
}

interface GetPaymentsCardTransactionsOutput {
  items: GetPaymentsCardTransactionOutput[];
  pagination: {
    has_more: boolean;
    next_page: number;
    previous_page: number;
  };
}

export const getPaymentsCardTransactions = async (queryStringParameters: {
  id_in: number[];
}): Promise<GetPaymentsCardTransactionsOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('payments', `/rest/v1/${tenantId}/card-transactions`, {
    headers,
    queryStringParameters
  });
};

export const getPaymentPresets = async (): Promise<GetPaymentPresetsOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('payments', `/rest/v1/${tenantId}/payment-configs`, {
    headers
  });
};

export const downloadPaymentPresetsCSV = async (
  id: number
): Promise<AWSDocument> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('payments', `/rest/v1/${tenantId}/payment-configs/${id}/csv`, {
    headers
  });
};

export const postPaymentPresets = async (
  body: PostPaymentPresetsBody
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('payments', `/rest/v1/${tenantId}/payment-configs`, {
    headers,
    body
  });
};

export const updatePaymentPresets = async (
  body: PostPaymentPresetsBody,
  id: number
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('payments', `/rest/v1/${tenantId}/payment-configs/${id}`, {
    headers,
    body
  });
};

export const getPaymentPresetTree =
  async (): Promise<GetPaymentPresetTreeOutput> => {
    const tenantId = await getTenantId();
    const headers = { token: await getUserJwtToken() };

    return API.get('payments', `/rest/v1/${tenantId}/payment-configs/tags`, {
      headers
    });
  };

export const getPaymentDetailsPreset = async (
  id: number
): Promise<GetPaymentDetailsPresetOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'payments',
    `/rest/v1/${tenantId}/payment-configs/${id}/details`,
    {
      headers
    }
  );
};

export const deletePreset = async (id: number): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.del('payments', `/rest/v1/${tenantId}/payment-configs/${id}`, {
    headers
  });
};

export const getCSVColumnsWithPreset = async (
  id: number,
  query: GetCSVColumnsInput
): Promise<GetCSVColumnsWithPresetOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = JSON.parse(JSON.stringify(query));

  return API.get(
    'payments',
    `/rest/v1/${tenantId}/payment-configs/${id}/column-mapper`,
    {
      queryStringParameters,
      headers
    }
  );
};

export const postBankOrderValidateCSVWithPreset = async (
  body: PostBankOrderValidateCSVWithPresetBody
): Promise<PostBankOrderValidateCSVWithPresetOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/config/validate-csv`,
    {
      headers,
      body
    }
  );
};

export const postBankOrderPrefillCSVWithPreset = async (
  body: PostBankOrderPrefillCSVWithPresetBody
): Promise<PostBankOrderPrefillCSVOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/config/prefill-csv`,
    {
      headers,
      body
    }
  );
};

export const postBankOrderWithPreset = async (
  body: PostBankOrderWithPresetBody
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('payments', `/rest/v1/${tenantId}/bank-orders/config`, {
    headers,
    body
  });
};

export const getPaymentBankOrderItemTags = async (
  id: number
): Promise<GetBankOrderTagsOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('payments', `/rest/v1/${tenantId}/bank-orders/tags/${id}`, {
    headers
  });
};

export const postPaymentBankOrderTags = async (
  body: PostPaymentBankOrderTagsBody
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('payments', `/rest/v1/${tenantId}/bank-orders/update-tags`, {
    headers,
    body
  });
};

export const postPaymentBankOrderItemTags = async (
  body: PostPaymentBankOrderItemTagsBody
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'payments',
    `/rest/v1/${tenantId}/bank-orders/update-item-tags`,
    {
      headers,
      body
    }
  );
};
