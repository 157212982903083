<template>
  <div class="wrapper">
    <PfLabel
      :model-value="label"
      :required="required"
    />

    <button
      class="field"
      :class="{
        'field--focused': isDropdownVisible,
        'field--disabled': disabled,
        'field--error': hasError
      }"
      :disabled="disabled || isPending"
      @click="onOpenDropdown"
    >
      <DsSkeleton
        v-if="isPending"
        style="width: 90px"
      />
      <div
        v-else
        class="left"
      >
        <span
          v-if="!selectedAccountData"
          class="field__placeholder"
        >
          {{
            placeholder ||
            $t('bankOrdersCreateAccountSelectionField.placeholder')
          }}
        </span>

        <div
          v-else
          class="item"
          data-test="account"
        >
          <img
            class="item__icon"
            :src="
              selectedAccountData.institution?.logo_link ||
              '/img/bank-logo-placeholder@2x.png'
            "
          />

          <div>
            <p class="item__name">
              {{ selectedAccountData.account?.name }}
            </p>
            <p class="item__balance">
              <DsAmount
                :amount="selectedAccountData.balance?.amount || 0"
                :currency="
                  selectedAccountData.balance?.currency ||
                  selectedAccountData.account?.principal_currency
                "
              />
            </p>
          </div>
        </div>
      </div>

      <div class="right">
        <DsIcon
          :name="isDropdownVisible ? 'chevron-up' : 'chevron-down'"
          color="gray400"
          size="small"
        />
      </div>
    </button>
    <div
      v-if="isDropdownVisible"
      ref="dropdownRef"
      v-on-click-outside="() => (isDropdownVisible = false)"
      data-test="dropdown"
      class="dropdown"
    >
      <div>
        <DsSearchField
          id="account-selector-search"
          v-model="search"
          :placeholder="$t('common.search')"
          clearable
          autofocus
        />
      </div>

      <div class="section">
        <p class="section__title">
          {{ $t('bankOrdersCreateAccountSelectionField.sftpAccounts') }}
        </p>
        <div
          v-for="accData in sftpAccountsFiltered"
          :key="accData.providerAccount.account_id"
          data-test="connector-account"
          class="item"
          @click="selectAccount(accData)"
        >
          <img
            class="item__icon"
            :src="
              accData.institution?.logo_link ||
              '/img/bank-logo-placeholder@2x.png'
            "
          />

          <div>
            <p class="item__name">
              {{ accData.account?.name }}
            </p>
            <p class="item__balance">
              <DsAmount
                :amount="accData.balance?.amount || 0"
                :currency="
                  accData.balance?.currency ||
                  accData.account?.principal_currency
                "
              />
            </p>
          </div>
        </div>
        <div v-if="sftpAccountsFiltered.length === 0">
          {{ $t('common.no-results') }}
        </div>
      </div>
      <div class="section">
        <p class="section__title">
          {{ $t('bankOrdersCreateAccountSelectionField.ebicsAccounts') }}
        </p>
        <div
          v-for="accData in ebicsAccountsFiltered"
          :key="accData.providerAccount.account_id"
          data-test="connector-account"
          class="item"
          @click="selectAccount(accData)"
        >
          <img
            class="item__icon"
            :src="
              accData.institution?.logo_link ||
              '/img/bank-logo-placeholder@2x.png'
            "
          />

          <div>
            <p class="item__name">
              {{ accData.account?.name }}
            </p>
            <p class="item__balance">
              <DsAmount
                :amount="accData.balance?.amount || 0"
                :currency="
                  accData.balance?.currency ||
                  accData.account?.principal_currency
                "
              />
            </p>
          </div>
        </div>
        <div v-if="ebicsAccountsFiltered.length === 0">
          {{ $t('common.no-results') }}
        </div>
      </div>
      <div class="section">
        <p class="section__title">
          {{ $t('bankOrdersCreateAccountSelectionField.otherAccounts') }}
        </p>
        <div
          v-for="accData in connectorAccountsFiltered"
          :key="accData.providerAccount.account_id"
          data-test="connector-account"
          class="item"
          @click="selectAccount(accData)"
        >
          <img
            class="item__icon"
            :src="
              accData.institution?.logo_link ||
              '/img/bank-logo-placeholder@2x.png'
            "
          />

          <div>
            <p class="item__name">
              {{ accData.account?.name }}
            </p>
            <p class="item__balance">
              <DsAmount
                :amount="accData.balance?.amount || 0"
                :currency="
                  accData.balance?.currency ||
                  accData.account?.principal_currency
                "
              />
            </p>
          </div>
        </div>

        <div
          v-if="!accountsStore.connectorAccounts.length"
          class="no-accounts"
        >
          <p>
            {{ $t('bankOrdersCreateAccountSelectionField.noOtherAccounts') }}
          </p>
          <button
            data-test="link-connector-account-button"
            @click="linkAccount"
          >
            {{
              $t('bankOrdersCreateAccountSelectionField.linkExternalAccount')
            }}
          </button>
        </div>

        <div v-else-if="connectorAccountsFiltered.length === 0">
          {{ $t('common.no-results') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { vOnClickOutside } from '@vueuse/components';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import DsAmount from '@/components/DesignSystem/DsAmount.vue';
import DsIcon from '@/components/DesignSystem/DsIcon.vue';
import DsSearchField from '@/components/DesignSystem/DsSearchField.vue';
import PfLabel from '@/components/NewDesignSystem/PfLabel/PfLabel.vue';
import { BankOrdersCreateAccountSelectionFieldItem } from '@/helpers/bank-orders';
import { RouteNames } from '@/router/routes';
import { useAccountsStore } from '@/stores/data-models/accounts';
import { useToastStore } from '@/stores/toast';

import DsSkeleton from './DsSkeleton.vue';

const props = defineProps<{
  modelValue?: number;
  disabled?: boolean;
  placeholder?: string;
  hasError?: boolean;
  label?: string;
  required?: boolean;
  removeAccountsWithoutIban?: boolean;
}>();

const emit = defineEmits<{
  'update:modelValue': [accountId: number];
}>();

const router = useRouter();
const toast = useToastStore();
const accountsStore = useAccountsStore();

const isDropdownVisible = ref(false);
const isPending = ref(false);

const search = ref('');

const ebicsAccountsFiltered = computed(() => {
  const res = accountsStore.ebicsAccounts
    .filter((account) => account.providerAccount?.connection_method === 'EBICS')
    .filter((account) =>
      account.account?.name?.toLowerCase().includes(search.value.toLowerCase())
    );

  return props.removeAccountsWithoutIban
    ? res.filter((account) => !!account.account?.iban)
    : res;
});

const sftpAccountsFiltered = computed(() => {
  return accountsStore.sftpAccounts
    .filter((account) => account.providerAccount?.connection_method === 'SFTP')
    .filter((account) =>
      account.account?.name?.toLowerCase().includes(search.value.toLowerCase())
    );
});

const connectorAccountsFiltered = computed(() => {
  const res = accountsStore.connectorAccounts.filter((account) =>
    account.account?.name?.toLowerCase().includes(search.value.toLowerCase())
  );

  return props.removeAccountsWithoutIban
    ? res.filter((account) => !!account.account?.iban)
    : res;
});

const onOpenDropdown = () => {
  isDropdownVisible.value = true;
  nextTick(() => {
    document
      .getElementById('account-selector-search')
      ?.querySelector('input')
      ?.focus();
  });
};

const linkAccount = () => {
  router.push({ name: RouteNames.SETTINGS_ACCOUNTS });
};

const selectAccount = (data: BankOrdersCreateAccountSelectionFieldItem) => {
  if (data) {
    isDropdownVisible.value = false;
    emit('update:modelValue', data?.account.id);
  }
};

const selectedAccountData = computed<BankOrdersCreateAccountSelectionFieldItem>(
  () => {
    const account = accountsStore.allAccounts.find(
      (accData: BankOrdersCreateAccountSelectionFieldItem) =>
        accData.account?.id === props.modelValue
    );

    return account;
  }
);

onMounted(async () => {
  try {
    if (!accountsStore.allAccountsHasBeenCalled) {
      isPending.value = true;
      await accountsStore.getAllAccounts();
    }
  } catch (error) {
    toast.showError(error);
  } finally {
    isPending.value = false;
  }
});
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;

  .field {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 8px 12px;

    color: $gray1000;

    background-color: white;
    border: 1px solid $gray100;
    border-radius: 12px;

    &__placeholder {
      color: $gray400;
    }

    &:hover {
      border: 1px solid $gray200;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &--disabled {
      color: $gray500;
      background-color: $gray50;

      &__placeholder {
        color: $gray300;
      }
    }

    &--focused {
      border: 1px solid $blue500;
      outline-color: #d2d9ff;
      outline-style: solid;
      outline-width: 2px;
    }

    &--error {
      border: 1px solid $red500;
      outline: 2px solid #f9d4cf;
    }
  }
}

.item {
  cursor: pointer;

  display: flex;
  gap: 8px;
  align-items: center;
  align-self: stretch;

  text-align: left;

  &___name {
    @include typo-body;

    color: $gray1000;
  }

  &__balance {
    @include typo-small-body;

    color: $gray700;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }
}

.dropdown {
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
  left: 0;

  overflow-y: auto;

  height: 210px;
  padding: 4px;

  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 16px 0px rgba(20, 23, 37, 0.08);

  .section {
    &:not(:first-child) {
      margin-top: calc(8px + 4px);
    }

    &__title {
      @include typo-overline;

      margin-bottom: 4px;
      padding: 4px 12px;

      color: $gray700;
      text-transform: uppercase;

      background-color: $gray150;
      border-radius: 8px;
    }
  }

  .item {
    padding: 8px 12px;

    &:hover {
      background-color: $gray50;
      border-radius: 8px;
    }
  }
}

.no-accounts {
  @include typo-body;

  padding: 8px 12px;
  color: $gray1000;
  text-align: center;

  button {
    margin-top: 8px;
    font-weight: 600;
    color: $blue500;
  }
}
</style>
